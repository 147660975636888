import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import {
    createStringDataField, createDateField, createNumberDataField, createStatusField
} from '../../util/format';
import { BooleanEquators, dateEquators, integerEquators, StringEquators } from '../../store/constants/pages';
import ClaimAuditsPageComponent from './ClaimAuditsPage';
import { claimAuditsLanding, resetPage } from '../../store/actions/pages';
import { getByName, reset } from '../../store/actions/search';
import { auditErrorReset,processClaimsFromAudit,processClaimFromAudit } from '../../store/actions/claimAnalysis';
import { getPaginationFilter } from '../../util/pagination';
import { set ,setMulti} from '../../store/actions/pages';

const DEFAULT_SORT = [
    { id: 'id', direction: 'asc' }
];

const statusDropdownOptions = [
    {
        label: 'COMPLETED',
        value: 'COMPLETED'
    },
    {
        label: 'FAILED',
        value: 'FAILED'
    },
    {
        label: 'IGNORED',
        value: 'IGNORED'
    }
];
const selectClaimNumber = (state, props) => props.match.params.cn;

const selectFields = createSelector(
    selectClaimNumber,
    (claimNumber) => {
        return [
            createNumberDataField('id', 'Audit Id', { sortable: true, equators: integerEquators }),
            createStringDataField('claimnumber', 'Claim Number', { sortable: true, equators: StringEquators,hideFromFilter: claimNumber ? true : false  }),
            createStringDataField('executionType', 'Execution Type', { sortable: true, equators: StringEquators }),
            createStringDataField('message', 'Message', { sortable: true, equators: StringEquators, width: '300px' }),
            createDateField('updatedAt', 'Updated At', { sortable: true, equators: dateEquators }),
            createStringDataField('updatedBy', 'Updated By', { sortable: true, equators: StringEquators }),
            createStatusField('status', 'Status', { equators: BooleanEquators, returnWholeRow: true, isStrictDropdown: true, dropdownValues: statusDropdownOptions,haveLink:true })
        ]
    }
) as any;

const selectAuditData = createSelector(
    state => state.pages['claim-audits-landing'].list,
    data => data
); 

const selectFilter = createSelector(
    state => state.pages['claim-audits-landing'],
    selectFields,
    (table, fields) => getPaginationFilter(table.filter, null, null, table.customFilter)
) as any;

const selectSort = createSelector(
    state => state.pages['claim-audits-landing'],
    table => table.sort.length === 0 ? [...DEFAULT_SORT] : table.sort
);

export const ClaimAuditsPage = connect(
    () => {
        return (state,props) => ({
            fields: selectFields(state,props),
            auditData: selectAuditData(state),
            filter: selectFilter(state,props),
            sort: selectSort(state), 
            claimNumber:selectClaimNumber(state,props),

            processClaimsPending: state.claimAnalysis.processClaimsFromAudit.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            claimAuditsLanding: claimAuditsLanding,
            processClaimsFromAudit: processClaimsFromAudit,
            processClaimFromAudit: processClaimFromAudit,
            searchGetByName: getByName,
            searchReset: reset,
            auditErrorReset: auditErrorReset,
            resetPagedTable: resetPage,
            resetCriteriaWaiting: (pageName) => set(pageName, 'waitForCriteriaFilter', false),
            setMulti: obj => setMulti('claim-audits-landing', obj),
        }, dispatch)
    })
)(injectIntl(ClaimAuditsPageComponent));