import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({

    dialogContent: {
        margin: "0px 10px 0px 10px",
        padding: "10px 20px",
    },
}));

function CriteriaDependencyDialog({
    open,
    totalDependencies,
    handleClose,
    handleCloseAndCancel,
}: {
    open: boolean;
    totalDependencies: number;
    handleClose: () => void;
    handleCloseAndCancel: () => void;
}) {
    const classes = useStyles();


    return (
        <div>
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="sm"
                open={open}
                // onClose={handleClose}
            >
                <DialogTitle>
                    <Grid container alignItems="center">
                        <Grid item xs={11}>
                            Are you Sure ?
                        </Grid>
                        <Grid item xs={1} className="text-right">
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseAndCancel}

                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                   <Typography>
                        This criteria cannot be set to private because it is currently in use by  other users . To proceed please create a copy using the 'Save As' option.
                            </Typography>
                </DialogContent>

                <DialogActions className="p-4">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCloseAndCancel}
                        type="button"
                    >
                        OK
                    </Button>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={handleClose}
                    >
                        Yes
                    </Button> */}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CriteriaDependencyDialog;
