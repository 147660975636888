import { CircularProgress, Table, TableBody, TableCell, TableFooter, TableRow, Tooltip, Typography } from '@mui/material';
import { useData, useStatePtr } from "@pure-ptr/react";
import React, { useContext } from 'react';

import { getCasCodesSummary } from "../../store/api/summaryDashboard";
import { asDollarsWithZero } from '../../util/format';
import { DashboardFiltersContext, useStyles } from './Dashboard';
import { setMulti } from '../../store/actions/pages';
import { useDispatch } from 'react-redux';


export const CasCodesWidget = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { dateRange, history } = useContext(DashboardFiltersContext);

    const { data, isPending } = useData( async () => {
        const result = await getCasCodesSummary( dateRange.startdate, dateRange.enddate );
        return result;
    }, [ dateRange ]);

    const getTotalCasCodes = () => {
        let total = data?.slice(0, 10).reduce((sum, item) => sum + (item.amount || 0), 0); 
        return total;
    }

    const handleCasCodeAmountClick = (ids) => {
        sessionStorage.removeItem("duplicated");

        let filters = [
            {
                actualFilter: true, 
                equator: "equals", 
                field: "reasoncode", 
                value: ids
            }
        ]

        dispatch(setMulti("claimAnalysis-landing", {
            filter: filters,
            selectedFilter: null,
            customFilter: [
                {
                    customFilter: true, 
                    equator: "greater_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRange.startdate,
                    relativeFilter: dateRange.relativeFilter

                },
                {
                    customFilter: true, 
                    equator: "less_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRange.enddate,
                    relativeFilter:dateRange.relativeFilter
                }
            ],
            list: [],
            significance: false,
            searchTerm:"",
            ignoreAcknowledgement:false,
            showIgnoredFilterDialog:false
        }));
        
        history.push("/cms/claimAnalysis/fromSummary");
    }

    return isPending ? (
        <CircularProgress />
        ) : (
        <Table>
            <TableBody sx={{paddingLeft: "10px"}}>
            {data?.slice(0, 10)?.map((cas) => (
                <TableRow key={cas.casCode}>
                <TableCell>
                    <Tooltip title={cas.fullDesc}>
                        <Typography variant='body2' ><strong>{cas.casCode}</strong> - {cas.shortDesc}</Typography>
                    </Tooltip>
                    <Typography onClick={()=> handleCasCodeAmountClick(cas.casCode)} variant="subtitle1" className={classes.link} sx={{ display: "block", paddingTop: "0.5rem" }}>{`${asDollarsWithZero(cas.amount)} (${cas.claimsCount} claims)`}</Typography>
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
            <TableFooter className={classes.blueBg}>
                <TableRow key={`totalCasCodes`}>
                    <TableCell colSpan={2} sx={{ fontWeight: 800, padding: "10px" }}>
                        <Typography variant='h6'gutterBottom>Total: {asDollarsWithZero(getTotalCasCodes())}</Typography>
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
        )
}