import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card } from "react-bootstrap";
import { connect } from 'react-redux';
import { Loadable } from '../loadable';
import CriteriaDependencyDialog from '../searchTitle/CriteriaDependencyDialog';


const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        position:'absolute',
        bottom:"8px",
        right:"240px"
    },
    criteriaCard:{
        marginTop:'15px',
        position:"static",
        width:"100%"
    },
    saveBtn:{
        background:'#196540 !important',
        color:'white',
        marginBottom:'12px'
    },
    dialogHeaderContainer:{
        display:'flex',
        justifyContent:'space-between'
    },
    dialogCloseBtn:{
        height: "20px",
        width: "20px",
        marginTop: "auto",
        marginBottom: "auto",
        marginRight: "10px"
    },
    staticPosition:{
        position:"static"
    }
}));


const initialValue = {
    criteriaName: '',
    searchCriteriaTypeId: '',
    isDefault: false,
    isPublic: false,
    id: undefined
}

function SaveCriteriaDialog({ intl, open, handleClose, handleSaveSearch, createPending, updatePending, criteria, searchAction, searchCriteriaTypes,isNotCurrentUserCriteria,defaultCustomFilters }) {
    const [initialValues, setInitialValues] = useState(initialValue);
    const [openDependencyDialog, setOpenDependencyDialog] = useState(false);
    const classes = useStyles();
    const criteriaReferencedLength=criteria?.userPublicSearchCriteriaXref?.length

    useEffect(() => {
        if (criteria && criteria.id) {
            setInitialValues({
                criteriaName: criteria.criteriaName,
                searchCriteriaTypeId: criteria.searchCriteriaTypeId,
                isDefault: criteria.isDefault,
                isPublic: criteria.isPublic,
                id: criteria.id,
            });
        } else {
            setInitialValues(initialValue);
        }
    }, [criteria]);

    useEffect(() => {
        return () => {
            setOpenDependencyDialog(false)
        };
    }, []);

    const closeDialog = () => {
        //setInitialValues(initialValue);
        handleClose();
    }

    const title = searchAction === 'Save' ? 'Update' : 'New';
    const shouldShowFields=!isNotCurrentUserCriteria || (isNotCurrentUserCriteria && searchAction!=='Save')

    const setCriteriaCacheAsPerDefaultState = (values, criteria, filters, payload, defaultCustomFilters) => {
        const navigationType={type:'reload'}

        if (criteria && criteria.isDefault !== values?.isDefault && !values?.isDefault && criteria.id) {
            sessionStorage.removeItem('duplicated')
            sessionStorage.setItem('pages', JSON.stringify({
                ...filters, 'claimAnalysis-landing': {
                    selectedFilter: null,
                    customFilter: defaultCustomFilters,
                    filter: [],
                    navigationType
                }
            }))

        }
        else if (!payload.id && payload.isDefault) {
            sessionStorage.removeItem('duplicated')
            sessionStorage.setItem('pages', JSON.stringify({
                ...filters, 'claimAnalysis-landing': {
                    ...(filters['claimAnalysis-landing'] || {}),
                    selectedFilter: payload,
                    navigationType
                }
            }))

        }
    }
    
    return (
        (<Card className={classes.criteriaCard}>
            <Loadable loading={createPending || updatePending} parentClassName={classes.staticPosition}>
                <Box className={classes.dialogHeaderContainer}>
                <DialogTitle>{`${title} Criteria`}</DialogTitle>

                <IconButton className={classes.dialogCloseBtn} onClick={closeDialog} size="large"><CloseIcon /></IconButton>
                </Box>
                <DialogContent>
                <Loadable parentClassName={classes.staticPosition}>
                <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors : any = {};
                            if (!values.criteriaName) {
                                errors.criteriaName = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.searchCriteriaTypeId) {
                                errors.searchCriteriaTypeId = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            if(values.isDefault) //If criteria is clear & User chooses a new defaultt we want to apply the new default
                            {
                                localStorage.removeItem("isCriteriaCleared")
                            }
                            let payload = {
                                criteriaName: values.criteriaName,
                                searchCriteriaTypeId: values.searchCriteriaTypeId,
                                isDefault: values.isDefault,
                                isPublic: values.isPublic,
                                id: undefined
                            }
                            if (criteria && criteria.id) {
                                payload = {
                                    ...payload,
                                    id: values.id,
                                }
                                handleSaveSearch(payload, closeDialog, criteria);
                            }
                            else {
                                handleSaveSearch(payload, closeDialog, criteria);
                            }
                            
                            let filters: any = sessionStorage.getItem("pages");
                            filters = JSON.parse(filters);

                            if (filters) {
                                setCriteriaCacheAsPerDefaultState(
                                    values,
                                    criteria,
                                    filters,
                                    payload,
                                    defaultCustomFilters
                                );
                            }
                        }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Grid container spacing={1}>
                           {shouldShowFields && <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    required
                                    label="Criteria Name"
                                    name="criteriaName"
                                    margin="dense"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.criteriaName}
                                    helperText={touched.criteriaName && errors.criteriaName}
                                    error={Boolean(touched.criteriaName && errors.criteriaName)}
                                />
                            </Grid>}
                          {shouldShowFields &&  <Grid item xs={12}>
                            <FormControl fullWidth error={Boolean(touched.searchCriteriaTypeId && errors.searchCriteriaTypeId)}>
                                <InputLabel htmlFor="searchCriteriaTypeId">Criteria Type *</InputLabel>
                                <Select
                                    required
                                    fullWidth
                                    value={values.searchCriteriaTypeId}
                                    name="searchCriteriaTypeId"
                                    onChange={handleChange}
                                    error={Boolean(touched.searchCriteriaTypeId && errors.searchCriteriaTypeId)}
                                    input={<Input id="searchCriteriaTypeId" />}
                                    inputProps={{
                                        name: "searchCriteriaTypeId",
                                        id: "searchCriteriaTypeId"
                                    }}
                                    >
                                    {searchCriteriaTypes.map(({ id, name }) => (
                                        <MenuItem
                                            key={name}
                                            value={id}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error>{touched.searchCriteriaTypeId && errors.searchCriteriaTypeId}</FormHelperText>
                                </FormControl>
                            </Grid>}
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="isDefault"
                                            color="primary"
                                            checked={values.isDefault}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="ml-0"
                                            value={values.isDefault}
                                        />
                                    }
                                    label="Is Default?"
                                />
                            </Grid>
                           {shouldShowFields && <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="isPublic"
                                            color="primary"
                                            checked={values.isPublic}
                                            onChange={(e) => {
                                                handleChange(e);

                                                if (!e.target.checked && criteriaReferencedLength > 0)
                                                 {
                                                    setOpenDependencyDialog(
                                                        true
                                                    );
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            className="ml-0"
                                            value={values.isPublic}
                                        />
                                    }
                                    label="Is Public?"
                                />
                            </Grid>}
                            <hr />
                            <Button
                                variant="contained"
                                className={`${classes.button} ${classes.saveBtn} ml-3`}
                                type="submit"
                                onClick={ e => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                {searchAction==="Save"?   "Update Criteria":"Save Criteria"}
                            </Button>
                            <CriteriaDependencyDialog
                                open={openDependencyDialog}
                                totalDependencies={criteriaReferencedLength}
                                handleClose={() =>
                                    setOpenDependencyDialog(false)
                                }
                                handleCloseAndCancel={()=>{setOpenDependencyDialog(false)
                                    setFieldValue('isPublic',true)
                                }}
                            />
                        </Grid>
                    )}
                            
                    </Formik>    
                </Loadable>
                </DialogContent>
            </Loadable>
        </Card>)
    );
}

const mapStateToProps = ({ search }) => ({
    searchCriteriaTypes: search.types,
    createPending: search.create.pending,
    updatePending: search.update.pending,
});
export default connect(mapStateToProps)(SaveCriteriaDialog);