import React, { Component } from "react";
import * as actions from "../../store/actions/session";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { set,setMulti } from '../../store/actions/pages';
import { LayoutSplashScreen } from "../../../_metronic";

class Logout extends Component {
  componentDidMount() {
    //TODO need to pass manual flag here.
    this.props.logout(true);
    localStorage.removeItem("isCriteriaCleared") //Remove both custom maintained states from storage after logout even on same tab
    sessionStorage.removeItem("pages")
    this.props.setMulti("claimAnalysis-landing", {
        waitForCriteriaFilter: true,
        searchTerm: "",
        selectedFilter: null,
        showIgnoredFilterDialog: false,
        isSearchApiCompleted:false,
        ignoreAcknowledgement: false,
        filter:[],
        customFilter:[]
    });
    this.props.setMulti("triage-landing", {
      waitForCriteriaFilter: true,
      searchTerm: "",
      selectedFilter: null,
      showIgnoredFilterDialog: false,
      isSearchApiCompleted:false,
      ignoreAcknowledgement: false,
      filter:[],
      customFilter:[]
    });
    sessionStorage.removeItem("duplicated");    
    localStorage.removeItem("rcm-range");             

  }

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}

export default connect(
  ({ session }) => ({ hasAuthToken: Boolean(session.authToken) }),
  {...actions,setMulti}
)(Logout);
