import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/claimAnalysis';
import * as API from '../api/claimAnalysis';
import { sessionErrorHandling } from './session';

function* get(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_GET_PENDING });
        const { analysisId, callback } = action.payload;
        const payload = yield call(API.get, analysisId);
        yield put({ type: actions.CLAIM_ANALYSIS_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

// consolidated data 
function* getConsolidatedClaims(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_PENDING });
        const { claimId, callback,claimNumber } = action.payload;
        const payload = yield call(API.getConsolidatedClaims, claimId,claimNumber);
        yield put({ type: actions.CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}
// consolidated data 



function* acknowldegeClaims(action) {
    try {
        yield put({ type: actions.CLAIM_ACKNOWLEDGE_PENDING });
        const {
            acknowledgePayload,
            callback,
            shouldReloadGrid,
        } = action.payload;

        yield call(API.acknowledge, acknowledgePayload);
        yield put({ type: actions.CLAIM_ACKNOWLEDGE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ACKNOWLEDGE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getClaimStats(action) {
    const { pagination, sort, filter } = action.payload;
    try {
        yield put({ type: actions.CLAIM_ANAYLYSIS_STATISTICS_PENDING });
        let options = {
            page: {
                ...pagination,
            },
            filter: filter || null,
            sort: sort || [],
        };

        const payload = yield call(API.getStats, options);
        yield put({ type: actions.CLAIM_ANAYLYSIS_STATISTICS_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANAYLYSIS_STATISTICS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* download(action) {
    try {
        const { payload, callback ,showSignificantVariance} = action.payload;
        yield put({ type: actions.CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_PENDING });
        const response = yield call(API.downloadReport, payload,showSignificantVariance);
        yield put({ type: actions.CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_FULFILLED });

        if (callback) {
            callback(response);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* flagClaims(action) {
    try {
        yield put({ type: actions.CLAIM_FLAG_PENDING });
        const {
            flagPayload,
            callback,
            shouldReloadGrid,
        } = action.payload;

        yield call(API.flag, flagPayload);
        yield put({ type: actions.CLAIM_FLAG_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_FLAG_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}



function* processClaims(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_PROCESS_PENDING });
        const {
            payload,
            callback,
        } = action.payload;

        yield call(API.processClaims, payload);
        yield put({ type: actions.CLAIM_ANALYSIS_PROCESS_FULFILLED });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { errorCallback } = action.payload;
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_PROCESS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
        if (errorCallback) {
            errorCallback();
        }
    }
}

function* processBulkClaims(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_BULK_PROCESS_PENDING });
        const {
            payload,
            significanceFlag,
            callback,
        } = action.payload;

        yield call(API.processBulkClaims, payload, significanceFlag);
        yield put({ type: actions.CLAIM_ANALYSIS_BULK_PROCESS_FULFILLED });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { errorCallback } = action.payload;

        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_BULK_PROCESS_REJECTED, payload: errorMessage });

        yield call(sessionErrorHandling, error);
        if (errorCallback) {
            errorCallback();
        }
    }
}

function* getJSONView(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_JSON_VIEW_PENDING });
        const { claimNumber } = action.payload;
        const payload = yield call(API.getJSONView, claimNumber);
        yield put({ type: actions.CLAIM_ANALYSIS_JSON_VIEW_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_JSON_VIEW_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteByPayerContractId(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_PENDING });
        const { payerContractId, callback } = action.payload;
        const payload = yield call(API.deleteByPayerContractId, payerContractId);
        yield put({ type: actions.CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_FULFILLED, payload });

        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}


function* deleteByContractId(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_PENDING });
        const { contractId, callback } = action.payload;
        const payload = yield call(API.deleteByContractId, contractId);
        yield put({ type: actions.CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_FULFILLED, payload });

        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHistory(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_HISTORY_PENDING });
        const { analysisId, callback } = action.payload;
        const payload = yield call(API.getClaimHistory, analysisId);
        yield put({ type: actions.CLAIM_ANALYSIS_HISTORY_FULFILLED, payload });

        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_HISTORY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getClaimAdjustment(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_ADJUSTMENTS_PENDING });
        const { paymentId,analysisId } = action.payload;
        const payload = yield call(API.getClaimAdjustments, paymentId,analysisId);
        yield put({ type: actions.CLAIM_ANALYSIS_ADJUSTMENTS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_ADJUSTMENTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getServiceAdjustment(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_PENDING });
        const { servicePaymentId,analysisId } = action.payload;
        const payload = yield call(API.getServiceAdjustments, servicePaymentId,analysisId);
        yield put({ type: actions.CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}


function* getDRGS(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_GET_DRGS_PENDING });
        const payload = yield call(API.getDRGS);
        yield put({ type: actions.CLAIM_ANALYSIS_GET_DRGS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_GET_DRGS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getCPTS(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_GET_CPTS_PENDING });
        const { options } = action.payload;
        const payload = yield call(API.getCPTS, options);
        yield put({ type: actions.CLAIM_ANALYSIS_GET_CPTS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_GET_CPTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getRevenueCodes(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_GET_REVENUE_CODES_PENDING });
        const payload = yield call(API.getRevenueCodes);
        yield put({ type: actions.CLAIM_ANALYSIS_GET_REVENUE_CODES_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_GET_REVENUE_CODES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getAssociatedCharges(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_PENDING });
        const { options } = action.payload;
        const payload = yield call(API.getAssociatedCharges, options);
        yield put({ type: actions.CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* estimate(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_ESTIMATE_PENDING });
        const { options, callback } = action.payload;
        const payload = yield call(API.estimate, options);
        yield put({ type: actions.CLAIM_ANALYSIS_ESTIMATE_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_ESTIMATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* processClaimsFromAudit(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_AUDIT_PROCESS_PENDING });
        const { options, callback } = action.payload;
        const payload = yield call(API.processClaimsFromAudit, options);
        yield put({ type: actions.CLAIM_ANALYSIS_AUDIT_PROCESS_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_AUDIT_PROCESS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* processClaimFromAudit(action) {
    try {
        yield put({ type: actions.SINGLE_CLAIM_ANALYSIS_AUDIT_PROCESS_PENDING });
        const { options, callback } = action.payload;
        const payload = yield call(API.processClaimsFromAudit, options);
        yield put({ type: actions.SINGLE_CLAIM_ANALYSIS_AUDIT_PROCESS_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SINGLE_CLAIM_ANALYSIS_AUDIT_PROCESS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getClaimServiceAdjustment(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_PENDING });
        const { paymentId,claimId } = action.payload;
        const payload = yield call(API.getClaimServiceAdjustments, paymentId,claimId);
        yield put({ type: actions.CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* calculateOutOfPocketCost(action) {
    try {
        yield put({ type: actions.CLAIM_ESTIMATOR_CALCULATE_OOP_PENDING });
        const { options, callback } = action.payload;
        const payload = yield call(API.calculateOutOfPocketCost, options);
        yield put({ type: actions.CLAIM_ESTIMATOR_CALCULATE_OOP_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ESTIMATOR_CALCULATE_OOP_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getEstimateById(action) {
    try {
        yield put({ type: actions.CLAIM_ESTIMATOR_GET_BY_ID_PENDING });
        const { id } = action.payload;
        const payload = yield call(API.getEstimateById, id);
        yield put({ type: actions.CLAIM_ESTIMATOR_GET_BY_ID_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ESTIMATOR_GET_BY_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* editEstimate(action) {
    try {
        yield put({ type: actions.CLAIM_ESTIMATOR_EDIT_BY_ID_PENDING });
        const { options, callback } = action.payload;
        const payload = yield call(API.editEstimateById, options);
        yield put({ type: actions.CLAIM_ESTIMATOR_EDIT_BY_ID_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ESTIMATOR_EDIT_BY_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getDXCodes(action) {
    try {
        yield put({ type: actions.CLAIM_ESTIMATOR_GET_DX_CODES_PENDING });
        const { keyword } = action.payload;
        const payload = yield call(API.getDXCodes, keyword);
        yield put({ type: actions.CLAIM_ESTIMATOR_GET_DX_CODES_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ESTIMATOR_GET_DX_CODES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getClaimAllDetails(action) {
    try {
        const { analysisId } = action.payload;
        yield put({ type: actions.CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_PENDING });
        const payload = yield call(API.getClaimAllDetails, analysisId);
        yield put({ type: actions.CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* processBulkOnDemand(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.CLAIM_ANALYSIS_BULK_ONDEMAND_PENDING });
        const payload = yield call(API.processBulkOnDemand, options);
        yield put({ type: actions.CLAIM_ANALYSIS_BULK_ONDEMAND_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { errorCallback } = action.payload;
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_BULK_ONDEMAND_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
        if (errorCallback) {
            errorCallback();
        }
    }
}

function* overrideContract(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.CLAIM_ANALYSIS_OVERRIDE_CONTRACT_PENDING });
        const payload = yield call(API.overrideContract, options);
        yield put({ type: actions.CLAIM_ANALYSIS_OVERRIDE_CONTRACT_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_OVERRIDE_CONTRACT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getClaimComments(action) {
    try {
        const { analysisId } = action.payload;
        yield put({ type: actions.CLAIM_ANALYSIS_GET_COMMENTS_PENDING });
        const payload = yield call(API.getClaimComments, analysisId);
        yield put({ type: actions.CLAIM_ANALYSIS_GET_COMMENTS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_GET_COMMENTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* removeOverridenContract(action) {
    try {
        const { overriddenPayload, callback } = action.payload;
        yield put({ type: actions.CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_PENDING });
        const payload = yield call(API.removeOverridenContract, overriddenPayload);
        yield put({ type: actions.CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getClaimStatuses(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_GET_STATUS_LIST_PENDING });
        const payload = yield call(API.getClaimStatuses);
        yield put({ type: actions.CLAIM_ANALYSIS_GET_STATUS_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_GET_STATUS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}
function* updateClaimStatuses(action) {
    try {
        const { claimStatusPayload, callback } = action.payload;

        yield put({ type: actions.UPDATE_CLAIM_ANALYSIS_STATUS_LIST_PENDING });
        const payload = yield call(API.updateClaimStatus,claimStatusPayload);
        yield put({ type: actions.UPDATE_CLAIM_ANALYSIS_STATUS_LIST_FULFILLED, payload });
        if(callback)
        {callback()}
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.UPDATE_CLAIM_ANALYSIS_STATUS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateSingleClaimStatus(action) {
    try {
        yield put({ type: actions.UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_PENDING });
        const {
            payload,
            status,
            analysisId,
            callback,
        } = action.payload;

        yield call(API.updateSingleClaimStatus, payload, status,analysisId);
        yield put({ type: actions.UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_FULFILLED });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { errorCallback } = action.payload;

        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_REJECTED, payload: errorMessage });

        yield call(sessionErrorHandling, error);
        if (errorCallback) {
            errorCallback();
        }
    }
}

function* claimStatusLogsList(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_STATUS_LOGS_LIST_PENDING });
        const { analysisId } = action.payload;
        const payload = yield call(API.listClaimStatusLogs, analysisId);
        yield put({ type: actions.CLAIM_ANALYSIS_STATUS_LOGS_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_STATUS_LOGS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getConfidenceMeasures(action) {
    try {
        yield put({ type: actions.TRIAGE_MEASURES_LIST_PENDING });
        const payload = yield call(API.getConfidenceMeasures);
        yield put({ type: actions.TRIAGE_MEASURES_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.TRIAGE_MEASURES_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.CLAIM_ANALYSIS_GET, get);
    yield takeEvery(actions.CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET, getConsolidatedClaims);
    
    yield takeEvery(actions.CLAIM_ACKNOWLEDGE, acknowldegeClaims);
    yield takeEvery(actions.CLAIM_ANAYLYSIS_STATISTICS, getClaimStats);
    yield takeEvery(actions.CLAIM_ANAYLYSIS_DOWNLOAD_REPORT, download);
    yield takeEvery(actions.CLAIM_FLAG, flagClaims);

    yield takeEvery(actions.CLAIM_ANALYSIS_PROCESS, processClaims);
    yield takeEvery(actions.CLAIM_ANALYSIS_BULK_PROCESS, processBulkClaims);
    yield takeEvery(actions.CLAIM_ANALYSIS_JSON_VIEW, getJSONView);

    yield takeEvery(actions.CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID, deleteByPayerContractId);
    yield takeEvery(actions.CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID, deleteByContractId);
    yield takeEvery(actions.CLAIM_ANALYSIS_HISTORY, getHistory);
    yield takeEvery(actions.CLAIM_ANALYSIS_ADJUSTMENTS, getClaimAdjustment);
    yield takeEvery(actions.CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS, getServiceAdjustment);

    yield takeEvery(actions.CLAIM_ANALYSIS_GET_DRGS, getDRGS);
    yield takeEvery(actions.CLAIM_ANALYSIS_GET_CPTS, getCPTS);
    yield takeEvery(actions.CLAIM_ANALYSIS_GET_REVENUE_CODES, getRevenueCodes);
    yield takeEvery(actions.CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES, getAssociatedCharges);
    yield takeEvery(actions.CLAIM_ANALYSIS_ESTIMATE, estimate);
    yield takeEvery(actions.CLAIM_ANALYSIS_AUDIT_PROCESS, processClaimsFromAudit);
    yield takeEvery(actions.SINGLE_CLAIM_ANALYSIS_AUDIT_PROCESS, processClaimFromAudit);
    yield takeEvery(actions.CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS, getClaimServiceAdjustment);
    yield takeEvery(actions.CLAIM_ESTIMATOR_CALCULATE_OOP, calculateOutOfPocketCost);
    yield takeEvery(actions.CLAIM_ESTIMATOR_GET_BY_ID, getEstimateById);
    yield takeEvery(actions.CLAIM_ESTIMATOR_EDIT_BY_ID, editEstimate);
    yield takeEvery(actions.CLAIM_ESTIMATOR_GET_DX_CODES, getDXCodes);
    yield takeEvery(actions.CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS, getClaimAllDetails);
    yield takeEvery(actions.CLAIM_ANALYSIS_BULK_ONDEMAND, processBulkOnDemand);
    yield takeEvery(actions.CLAIM_ANALYSIS_OVERRIDE_CONTRACT, overrideContract);
    yield takeEvery(actions.CLAIM_ANALYSIS_GET_COMMENTS, getClaimComments);
    yield takeEvery(actions.CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT, removeOverridenContract);
    yield takeEvery(actions.CLAIM_ANALYSIS_GET_STATUS_LIST, getClaimStatuses);
    yield takeEvery(actions.UPDATE_CLAIM_ANALYSIS_STATUS_LIST, updateClaimStatuses);
    yield takeEvery(actions.UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS, updateSingleClaimStatus);
    yield takeEvery(actions.CLAIM_ANALYSIS_STATUS_LOGS_LIST, claimStatusLogsList);
    yield takeEvery(actions.TRIAGE_MEASURES_LIST, getConfidenceMeasures);


}


