export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGIN_PENDING = 'SESSION_LOGIN_PENDING';
export const SESSION_LOGIN_FULFILLED = 'SESSION_LOGIN_FULFILLED';
export const SESSION_LOGIN_REJECTED = 'SESSION_LOGIN_REJECTED';

export const SESSION_CONSTRUCT = 'SESSION_CONSTRUCT';
export const SESSION_CONSTRUCT_PENDING = 'SESSION_CONSTRUCT_PENDING';
export const SESSION_CONSTRUCT_FULFILLED = 'SESSION_CONSTRUCT_FULFILLED';
export const SESSION_CONSTRUCT_REJECTED = 'SESSION_CONSTRUCT_REJECTED';

export const SESSION_ACCEPT_EULA = 'SESSION_ACCEPT_EULA';
export const SESSION_ACCEPT_EULA_PENDING = 'SESSION_ACCEPT_EULA_PENDING';
export const SESSION_ACCEPT_EULA_FULFILLED = 'SESSION_ACCEPT_EULA_FULFILLED';
export const SESSION_ACCEPT_EULA_REJECTED = 'SESSION_ACCEPT_EULA_REJECTED';

export const SESSION_CHANGE_PASSWORD = 'SESSION_CHANGE_PASSWORD';
export const SESSION_CHANGE_PASSWORD_PENDING = 'SESSION_CHANGE_PASSWORD_PENDING';
export const SESSION_CHANGE_PASSWORD_FULFILLED = 'SESSION_CHANGE_PASSWORD_FULFILLED';
export const SESSION_CHANGE_PASSWORD_REJECTED = 'SESSION_CHANGE_PASSWORD_REJECTED';

export const SESSION_DECONSTRUCT = 'SESSION_DECONSTRUCT';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';

export const SESSION_USER_OBJECT = 'SESSION_USER_OBJECT';
export const FORCE_FORGOT_PW = 'FORCE_FORGOT_PW';

export const Register = '[Register] Action';
export const UserRequested = '[Request User] Action 1';
export const UserLoaded = '[Load User] Auth API';

export const SESSION_REFRESH_TOKEN = 'SESSION_REFRESH_TOKEN';
export const SESSION_REFRESH_TOKEN_PENDING = 'SESSION_REFRESH_TOKEN_PENDING';
export const SESSION_REFRESH_TOKEN_FULFILLED = 'SESSION_REFRESH_TOKEN_FULFILLED';
export const SESSION_REFRESH_TOKEN_REJECTED = 'SESSION_REFRESH_TOKEN_REJECTED';

export const SESSION_FORCED_RESET_PASSWORD = 'SESSION_FORCED_RESET_PASSWORD';
export const SESSION_FORCED_RESET_PASSWORD_PENDING = 'SESSION_FORCED_RESET_PASSWORD_PENDING';
export const SESSION_FORCED_RESET_PASSWORD_FULFILLED = 'SESSION_FORCED_RESET_PASSWORD_FULFILLED';
export const SESSION_FORCED_RESET_PASSWORD_REJECTED = 'SESSION_FORCED_RESET_PASSWORD_REJECTED';

export const SESSION_CONSTRUCT_FROM_LOGIN = 'SESSION_CONSTRUCT_FROM_LOGIN';

export const IDLE_SESSION_TIMEOUT_ALERT = 'IDLE_SESSION_TIMEOUT_ALERT';
export const SESSION_SET_ERROR = 'SESSION_SET_ERROR';
export const SESSION_SET_ERROR_AS_FALSE = 'SESSION_SET_ERROR_AS_FALSE';

export const construct = (user) => ({
    type: SESSION_CONSTRUCT,
    payload: { user },
});

export const deconstruct = () => ({
    type: SESSION_DECONSTRUCT,
    payload: {},
});

export const login = (username = '', password = '', rememberMe = false) => ({
    type: SESSION_LOGIN,
    payload: { username, password, rememberMe }
});

export const logout = (manual = false) => ({
    type: SESSION_LOGOUT,
    payload: { manual },
});

export const register = (authToken) => ({
    type: Register,
    payload: { authToken },
});

export const requestUser = (user) => ({
    type: UserRequested,
    payload: { user }
});

export const fulfillUser = (user) => ({
    type: UserLoaded,
    payload: { user }
});

export const acceptEula = (callback) => ({
    type: SESSION_ACCEPT_EULA,
    payload: { callback },
});

export const changePassword = (oldPassword = '', newPassword = '', callback) => ({
    type: SESSION_CHANGE_PASSWORD,
    payload: { oldPassword, newPassword, callback }
});

export const setIdleAlert = (idleAlert = false) => ({
    type: IDLE_SESSION_TIMEOUT_ALERT,
    payload: { idleAlert }
});

export const forcedResetPassword = (oldPassword = '', newPassword = '', callback) => ({
    type: SESSION_FORCED_RESET_PASSWORD,
    payload: { oldPassword, newPassword, callback }
});

// export const setErrorToast = (errorMessage, innerException, errorDetails) => ({
//     type: SESSION_SET_ERROR,
//     payload: {
//         errorMessage,
//         innerException,
//         errorDetails
//     }
// });

// export const closeErrorToast = () => ({
//     type: SESSION_SET_ERROR_AS_FALSE,
// })