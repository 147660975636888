import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useStatePtr } from '@pure-ptr/react';
import React, { useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { injectIntl } from "react-intl";
import { useDispatch } from 'react-redux';
import { pageStore, renderHoverLink, useGlobalStore, XClientDataGrid } from '../../../../common';
import { getClaimServiceAdjustments, setClaimServiceAdjustments } from '../../../../store/actions/claimAnalysis';
import { set } from '../../../../store/actions/pages';
import { selectClaimAdjustmentsData, selectClaimServiceAdjustments } from '../../../../store/selectors/claimAnalysis';
import { createCurrencyDataField, createNumberDataField, createStringDataField, createTooltipIconField } from '../../../../util/format';

const DEFAULT_SORT = [{ id: 'groupCode', direction: 'asc' }];
const { usePageStore } = pageStore('claim-service-adjustments-landing');

function ServiceAdjustments({ intl, claimId }) {
    const dispatch = useDispatch();

    // Replace useSelector with useGlobalStore.
    const open = useGlobalStore(state => {
        const adjustment = selectClaimServiceAdjustments(state);
        return adjustment?.open || false;
    });
    const paymentId = useGlobalStore(state => {
        const adjustment = selectClaimServiceAdjustments(state);
        return adjustment?.id || null;
    });

    // Static fields defined once.
    const fields = useMemo(() => ([
        createStringDataField('groupCode', 'Group Code', { 
            sortable: true, 
            renderCell: renderHoverLink( row => [
                {
                    label: "Grouping",
                    data: row.grouping || ''
                }
            ])
        }),
        createStringDataField('reasonCode', 'Reason Code', { sortable: true }),
        createStringDataField('reasonShortDescription', 'Reason Description', { 
            sortable: true, 
            width: 400,
            renderCell: renderHoverLink( row => [
                {
                    label: "Reason Description",
                    data: row.reasonDescription || ''
                }
            ], true) 
        }),
        createCurrencyDataField('adjustmentAmount', 'Amount', { sortable: true }),
        createNumberDataField('adjustmentQuantity', 'Quantity', { sortable: true }),
        createTooltipIconField('isActionable', 'Actionable', {
            iconClass: 'fas fa-check-circle',
            sortable: true,
            returnWholeRow: true,
            checkedTooltip: 'Actionable',
            uncheckedTooltip: 'Unactionable'
        }),
    ]), []);

    // Use pageStore for page state.
    const sortPtr = useStatePtr( DEFAULT_SORT );

    // Use GlobalStore for global data.
    const filteredData = useGlobalStore(state => {
        const rawData = selectClaimAdjustmentsData(state);
        // ...existing filtering and sorting logic...
        return rawData.map( ( x, i ) => ({ id: i, ...x }));   
    });
    const getAdjusmentsPending = useGlobalStore(state =>
        state.claimAnalysis.getClaimServiceAdjustments.pending
    );

    // Inline action callbacks.
    const actions = {
        setFilter: (value) => dispatch(set(`claim-service-adjustments-landing`, 'filter', value)),
        setOrder: (value) => dispatch(set(`claim-service-adjustments-landing`, 'order', value)),
        setSort: (value) => dispatch(set(`claim-service-adjustments-landing`, 'sort', value)),
        setClaimServiceAdjustments: (payload) => dispatch(setClaimServiceAdjustments(payload)),
        getClaimServiceAdjustments: (paymentId, claimId) =>
            dispatch(getClaimServiceAdjustments(paymentId, claimId))
    };

    const [refresh, setRefresh] = useState(Promise.resolve());

    useEffect(() => {
        if (open) {
            actions.getClaimServiceAdjustments(paymentId, claimId);
        }
    }, [open, refresh]);

    const closeAdjustmentHandler = () => {
        actions.setClaimServiceAdjustments({ open: false });
    };

    const handleRefresh = () => {
        setRefresh(Promise.resolve());
    };

    function PaperComponent(props: any) {
        return (
            <Draggable handle="#service-adjustments-dialog" cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <div>
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="md"
                open={open}
                onClose={closeAdjustmentHandler}
                PaperComponent={PaperComponent}
                aria-labelledby="service-adjustments-dialog">
                <DialogTitle style={{ cursor: 'move' }} id="service-adjustments-dialog">
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            Service Adjustments
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={closeAdjustmentHandler} size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <XClientDataGrid
                        fields={fields}
                        data={filteredData}
                        sortPtr={sortPtr}
                        loading={getAdjusmentsPending}
                        slots={{
                            toolbar: Toolbar as any
                        }}
                        slotProps={{
                            toolbar: {
                                reload: handleRefresh
                            } as any
                        }}
                        hideFooter
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

const Toolbar = ({ reload }) =>
    <GridToolbarContainer>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
            onClick={reload}
        >
            <RefreshIcon />
        </IconButton>
    </GridToolbarContainer>

export default injectIntl(ServiceAdjustments);