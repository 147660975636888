import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { contractEditReset, create, get, getAlertUsers, getCascodes, getContractCriteria, getDefaults, getDocumentList, getStates, update, upload } from '../../../store/actions/contracts';

import {
    createContractSelector, selectAlertUsers, selectBaseRateTypes, selectCarveOutTypes, selectContractCasCodes, selectContractsCriteria, selectContractStates, selectMethodTypes, selectServicesTypes
} from "../../../store/selectors/contracts";

import { getDRGTiny } from '../../../store/actions/drg';
import { getEAPGTiny } from '../../../store/actions/eapg';
import { list } from '../../../store/actions/facilities';
import { getOPPSTiny } from '../../../store/actions/opps';
import { CONTRACT_STATE_DRAFT, RULE_CRITERIA_STRING } from '../../../store/constants/contract';
import { selectSelectedClient } from '../../../store/selectors/clients';
import { selectFacilityList } from '../../../store/selectors/facilities';
import { getHierarchy } from "../../../store/actions/contractsv2";
import { asIsoDate } from '../../../util/date';
import ContractsEditPageComponent from './ContractsEditPage';

const contact = {
    name: '',
    title: '',
    emails: '',
    phone: '',
}

const selectionCriteriaTemplate = {
    contractSelectionCriteriaId: null,
    equator: '',
    value: '',
    startsAt: '',
    endsAt: '',
    isBetween: false
}

const PpsSelectionCriteriaTemplate = {
    drgConfigurationId: null,
    effectiveFrom: '',
    effectiveTo: '',
    percentage: 100,
    includeIME: false,
    includeDSH: false
}

const OPPSSelectionCriteriaTemplate = {
    oppsConfigurationId: null,
    effectiveFrom: '',
    effectiveTo: '',
    percentage: 100
}

const EAPGSelectionCriteriaTemplate = {
    eapgConfigurationId: null,
    effectiveFrom: '',
    effectiveTo: '',
    percentage: 100
}

export const ContractsEditPage = connect(
    () => {
        const selectContractId = (state, props) => props.match.params.contractId;

        const selectMode = (state, props) => props.match.params.contractId === 'new' ? 'create' : 'edit';
        const selectDirectoryId = (state, props) => props.match.params.directoryId;


        const selectTinyDRGs = (state) => state.drg.tiny;
        const selectTinyOpps = (state) => state.opps.tiny;
        const selectTinyEapg = (state) => state.eapg.tiny;

        const selectContract = createContractSelector(selectContractId);

        const getDefaultSelectionCriteria = (contract, contractCriteria) => {
            if (contract && contract.contractSelectionCriteriaXref?.length > 0) {
                return contract.contractSelectionCriteriaXref.map(item => {
                    const criteria = contractCriteria.find(cc => cc.id === item.contractSelectionCriteriaId);
                    if (item.equator === 'alpha_between' || item.equator === 'in_between') {
                        const splitedValue = item.value.split('&');
                        return {
                            ...item,
                            contractSelectionCriteriaId: criteria ?? null,
                            type: criteria ? criteria.fieldType : RULE_CRITERIA_STRING,
                            isBetween: true,
                            startsAt: splitedValue[0],
                            endsAt: splitedValue[1],
                        }
                    }
                    return {
                        ...item,
                        contractSelectionCriteriaId: criteria ?? null,
                        type: criteria ? criteria.fieldType : RULE_CRITERIA_STRING,
                        isBetween: false,
                    }
                })
            }
            return [selectionCriteriaTemplate];
        }

        const mapDenialCodeObject = (contract, casCodes) => {
            if (contract && contract.contractDenialCascodeXref?.length > 0) {
                return contract.contractDenialCascodeXref.map(item => {
                    return casCodes.find(cc => cc.id === item.casCodeId);
                })
            }
            return []
        }

        const mapPRAndAllowableCodeObject = (contract, casCodes, isPR) => {
            if (contract && isPR && (!contract.contractOtherAllowableXref || !contract.contractOtherAllowableXref.some(x => x.isPR))) {
                return casCodes.filter(cc => ['1', '2', '3'].includes(cc.code));
            }
            if (contract && contract.contractOtherAllowableXref?.length > 0) {
                return contract.contractOtherAllowableXref.filter(x => x.isPR === isPR).map(item => {
                    return casCodes.find(cc => cc.id === item.casCodeId);
                });
            }
            return [];
        }

        const mapDRGConfigObject = (contract, drgs) => {
            if (contract && contract.drgconfigurationToContractXref?.length > 0) {
                return contract.drgconfigurationToContractXref.map(item => {
                    //const drgObj = drgs.find(dd => dd.id === item.drgConfigurationId);
                    return {
                        ...item,
                        drgConfigurationId: null,
                        effectiveFrom: asIsoDate(item?.effectiveFrom),
                        percentage: item?.percentage ?? '',
                        includeIME: item?.includeIME ?? false,
                        includeDSH: item?.includeDSH ?? false
                    }
                })
            }
            return [PpsSelectionCriteriaTemplate]
        }

        const mapOPPSConfigObject = (contract, oppsList) => {
            if (contract && contract.contractOppsconfigurationXref?.length > 0) {
                return contract.contractOppsconfigurationXref.map(item => {
                    //const oppsObj = oppsList.find(dd => dd.id === item.oppsConfigurationId);
                    return {
                        ...item,
                        oppsConfigurationId: null,
                        effectiveFrom: asIsoDate(item.effectiveFrom),
                        percentage: item?.percentage ?? ''
                    }
                })
            }
            return [OPPSSelectionCriteriaTemplate]
        }

        const mapEAPGConfigObject = (contract, eapgList) => {
            if (contract && contract.contractEapgconfigurationXref?.length > 0) {
                return contract.contractEapgconfigurationXref.map(item => {
                    const eapgObj = eapgList.find(dd => dd.id === item.eapgConfigurationId);
                    return {
                        ...item,
                        eapgConfigurationId: eapgObj ?? null,
                        effectiveFrom: asIsoDate(item.effectiveFrom),
                        percentage: item?.percentage ?? ''
                    }
                })
            }
            return [EAPGSelectionCriteriaTemplate]
        }

        const getDefaultSelectedDenialCodes = (cascodes) => {
            if (cascodes && cascodes.length > 0) {
                return cascodes.filter(item => item.isDenial === true);
            }
            return [];
        }

        const getDefaultAllowableCodes = (cascodes) => {
            if (cascodes && cascodes.length > 0) {
                return cascodes.filter(item => parseInt(item.code) === 1 || parseInt(item.code) === 2 || parseInt(item.code) === 3);
            }
            return [];
        }

        const selectSortedUsers = createSelector(
            selectAlertUsers,
            (users) => {
                return users.sort((a, b) => a.name.localeCompare(b.name))
            }
        );



        const selectSortedCascodes = createSelector(
            selectContractCasCodes,
            (cascodes) => {
                return cascodes.sort((a, b) => a?.code?.localeCompare(b?.code, 'en', { numeric: true }))
            }
        );

        const selectInitialValues = createSelector(
            selectMode,
            selectContract,
            selectAlertUsers,
            selectFacilityList,
            selectContractsCriteria,
            selectContractStates,
            selectContractCasCodes,
            selectTinyDRGs,
            selectTinyOpps,
            selectTinyEapg,
            (mode, contract, alertUsers, allFacilities, criteria, contractStates, casCodes, drgs, oppsList, eapgList) => {
                if (mode === 'edit' && contract && alertUsers && allFacilities) {
                    let payload = {
                        id: contract.id,
                        name: contract.name,
                        effectiveDate: asIsoDate(contract.effectiveDate),
                        terminationDate: asIsoDate(contract.terminationDate),
                        isPayer: contract.isPayer ? "true" : "false",
                        description: contract.description,
                        notification: contract.terms?.[0]?.notification ?? false,
                        alert: asIsoDate(contract.terms?.[0]?.alert) ?? '',
                        renew: asIsoDate(contract.terms?.[0]?.renew ) ?? '',
                        documentMetadata: [{
                            documentName: '',
                            file: {},
                        },
                        ...contract.documentMetadata,
                        ],
                        vendorContacts: contract.vendorContacts,
                        alertContacts: alertUsers.filter(user => contract.alertContacts.some(y => y.userId == user.userId)),
                        facilityIds: allFacilities.filter(facility => contract.contractFacilityXref?.some(y => y.facilityId == facility.id)),
                        expectedDaysToPay: contract.expectedDaysToPay,
                        contractStateId: contract.contractStateId,
                        finalizedDate: asIsoDate(contract.finalizedDate, 'date-time'),
                        isCriteriaActive: contract.contractSelectionCriteriaXref.length > 0,
                        contractSelectionCriteriaXref: getDefaultSelectionCriteria(contract, criteria),
                        isDenialCasCodeActive: contract.contractDenialCascodeXref.length > 0,
                        contractDenialCascodeXref: mapDenialCodeObject(contract, casCodes),
                        isAllowableCodeActive: contract.contractOtherAllowableXref.filter(x => x.isPR === false).length > 0,
                        contractPatientResponsibilityXref: mapPRAndAllowableCodeObject(contract, casCodes, true),
                        contractOtherAllowableXref: mapPRAndAllowableCodeObject(contract, casCodes, false),
                        isPpsSelectionActive: contract.drgconfigurationToContractXref.length > 0 || contract.contractOppsconfigurationXref.length > 0,
                        isEAPGSelectionActive: contract.contractEapgconfigurationXref.length > 0,
                        drgconfigurationToContractXref: mapDRGConfigObject(contract, drgs),
                        contractOppsconfigurationXref: mapOPPSConfigObject(contract, oppsList),
                        contractEapgconfigurationXref: mapEAPGConfigObject(contract, eapgList)
                    }

                    return payload;
                }
                let payload = {
                    vendorContacts: [],
                    name: '',
                    terminationDate: '',
                    isPayer: 'true',
                    effectiveDate: '',
                    description: '',
                    notification: false,
                    documentMetadata: [{
                        file: {},
                    }],
                    alertContactIds: [],
                    alertContacts: [],
                    facilityIds: [],
                    expectedDaysToPay: '',
                    isCriteriaActive: false,
                    // criteria: criteria
                    contractSelectionCriteriaXref: [selectionCriteriaTemplate],
                    contractStateId: contractStates.find(state => state.name === CONTRACT_STATE_DRAFT)?.id ?? '',
                    isDenialCasCodeActive: true,
                    contractDenialCascodeXref: getDefaultSelectedDenialCodes(casCodes),
                    isAllowableCodeActive: false,
                    contractPatientResponsibilityXref: getDefaultAllowableCodes(casCodes),
                    contractOtherAllowableXref: [],
                    isPpsSelectionActive: false,
                    isEAPGSelectionActive: false,
                    drgconfigurationToContractXref: [PpsSelectionCriteriaTemplate],
                    contractOppsconfigurationXref: [OPPSSelectionCriteriaTemplate],
                    contractEapgconfigurationXref: [EAPGSelectionCriteriaTemplate]

                }

                return payload
            }
        );

        const selectSortedFacilities = createSelector(
            selectMode,
            selectFacilityList,
            (mode, facilities) => {
                if (mode === 'edit') {
                    return facilities.sort((a, b) => a.name.localeCompare(b.name))
                }
                return facilities.filter(item => item.active).sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        return (state, props) => ({
            //create contract
            contractsCreatePending: state.contracts.create.pending,

            // update dashboard
            contractsUpdatePending: state.contracts.update.pending,
            contractsGetPending: state.contracts.get.pending,

            contractsGetDefaultPending: state.contracts.defaults.pending,
            methodTypes: selectMethodTypes(state),
            baseRateTypes: selectBaseRateTypes(state),
            carveOutTypes: selectCarveOutTypes(state),
            serviceTypes: selectServicesTypes(state),
            alertUsers: selectSortedUsers(state),
            facilities: selectSortedFacilities(state, props),
            contactTemplate: contact,
            clientId: selectSelectedClient(state),
            contractCriteria: selectContractsCriteria(state),
            contractsStates: selectContractStates(state),
            casCodes: selectSortedCascodes(state),
            drgs: selectTinyDRGs(state),
            oppsList: selectTinyOpps(state),
            eapgList: selectTinyEapg(state),
            // edit
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            contractId: selectContractId(state, props),
            directoryId:selectDirectoryId(state,props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            contractCreate: create,
            contractUpdate: update,
            contractGet: get,
            contractsGetDefault: getDefaults,
            contractUpload: upload,
            contractDocumentList: getDocumentList,
            contractGetAlertUsers: getAlertUsers,
            contractGetFacilities: list,
            contractEditReset: contractEditReset,
            getContractCriteria: getContractCriteria,
            getStates: getStates,
            getCascodes: getCascodes,
            getDRGTiny: getDRGTiny,
            getOPPSTiny: getOPPSTiny,
            getEAPGTiny: getEAPGTiny,
            getHierarchy:getHierarchy
        }, dispatch)
    })
)(injectIntl(ContractsEditPageComponent as any));