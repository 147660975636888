import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import PayerContractsComponent from './PayerContractsPage';

import { payerContractsLanding } from '../../store/actions/pages';
import { StringEquators } from '../../store/constants/pages';
import { selectSessionHasClaimProcessorAccess, selectSessionRole } from '../../store/selectors/session';
import { createIconDataField, createStringDataField, createStringFieldFromArrayJoin, createTooltipIconForNonEmptyField } from '../../util/format';
import { deleteBulk, payerContractEditReset, setPayerContractDependencyDialog } from '../../store/actions/payers';
import { deleteClaimByPayerContractId } from '../../store/actions/claimAnalysis';
import { setV2 } from '../../store/actions/pagesV2';

const selectFields = createSelector(
    () => [
        createStringDataField('displayName', 'Display Name', { sortable: true, equators: StringEquators }),
        createStringFieldFromArrayJoin('contracts', 'Contract Name', 'name', { sortable: false, hideFromFilter: true, truncate: true }),
        createIconDataField('autoClaimAnalysis', 'Auto Analyze', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        createTooltipIconForNonEmptyField('isAssociated', ' ', { iconClass: 'fas fa-exclamation-circle', iconColor: 'text-warning', sortable: true, equators: StringEquators, whiteSpace: 'break-spaces', errorTooltip: 'It has duplicate contracts.' }),
    ]
);

const selectPayerContracts = createSelector(
    (state) => state.pages['payers-contracts-landing'].list,
    (data) => data
);

export const PayerContractsPage = connect(
    () => {
        return (state, props) => ({
            // allowablesListPending: state.allowables.list.pending,
            //updateStatusPending: state.allowables.updateStatus.pending,
            hasClaimProcessorAccess: selectSessionHasClaimProcessorAccess(state),
            data: selectPayerContracts(state),
            fields: selectFields(state as never),
            userRole: selectSessionRole(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            payerContractsLanding: payerContractsLanding,
            payerContractEditReset: payerContractEditReset,
            deleteClaimByPayerContractId: deleteClaimByPayerContractId,
            setPayerContractDependencyDialog: setPayerContractDependencyDialog,
            setSelected: (value) => setV2('payers-contracts-landing', 'selected', value),
        }, dispatch)
    })
)(injectIntl(PayerContractsComponent));