import React, { useEffect } from 'react';
import { TablePagination, Grid } from '@mui/material';
import { DataTable } from '../dataTable';
import { DataTableHeader } from '../dataTableHeader';
import { getPaginationFilter,getTableSearchFilter } from '../../util/pagination';
import { TimeFilter, TablePaginationActions } from '../../common';

export default function PagedTable({
    name, pageName, fields, data = [], onLoad, onAdd, refresh, filter, customFilter, hasCustomFilter,
    sort, pagination, pending, hasActions, intl,
    hasCheckbox, onCheckbox, tableLevelActions, tableLevelLoading, actionLevelGrid,
    showSelectAll, shouldRenderLazy = false,
    onRowClick=undefined,
    loading, timeFilterField, timeFilter, hoverData, criteria, showSearchFilters,
    isCollapsible, collapsibleItem, handleClickColumn,
    criteriasSaveEnabled, renderActionColumn, tableInModal, showReorderColumnsDialog,
    waitForCriteriaFilter, showFilterColumnsDialog, hiddenFields, orderPreference,filterData, hideRefresh = false,
    actions: { setSort, setFilter, setTimeFilter,setPage,
        setCustomFilter, saveCriteriaFilter, updateCriteriaFilter,
        setSelectedFilter, searchGetByName, setListData
    }
}) {
    const filteredFields = fields.filter(x => !x.options?.hidden);
    const sortedFieldsForHeaders = [...fields].sort((a, b) => a.label.localeCompare(b.label)); // this for sorting filter options and avoid mutation on existing fields array
    const { currentPage, recordsPerPage, totalRecords } = pagination;

    useEffect(() => {
        if (showSearchFilters) {
            if (waitForCriteriaFilter) {
                return
            }
        }

        if (!refresh) {
            handleRefresh();
            return;
        }
        refresh.then(handleRefresh);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, waitForCriteriaFilter]);

    useEffect(() => {
        sessionStorage.removeItem("duplicated");
    }, [pageName]);

    useEffect(() => {
        var navigationType = (window.performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming)
            .type;
            let filters = sessionStorage.getItem("pages") as any;
            filters = JSON.parse(filters);
        if (
            (navigationType === "back_forward" || 
                navigationType === "reload") &&
            !sessionStorage.getItem("duplicated")
        ) {
            //Handling the persist of filters in both reload and duplication case
            if (filters) {
                setPage(filters[pageName]);      
            }
            sessionStorage.setItem("duplicated", "true");
        }
        sessionStorage.setItem("pages", JSON.stringify({...filters, [pageName]: filterData }));
        
    }, [filterData]);

    function handleLoad( { newSort, newFilter, newTimeFilter, newPagination } : {
        newSort?: any, newFilter?: any, newTimeFilter?: any, newPagination?: any
    } = {}) {
        const paginationFilters = getPaginationFilter( newFilter || filter, timeFilterField, newTimeFilter || timeFilter, customFilter.filter(item => item.value !== 'both'));
         if (!hasCustomFilter) {
            setCustomFilter(customFilter);
        }
        const quickSearch = getTableSearchFilter(filterData?.searchTerm,"claimnumber")
        const doesIncludesClaimNumberSearch=JSON.stringify(paginationFilters).includes("claimnumber")        
        onLoad(
            newSort || sort,
           filterData?.searchTerm && filterData?.searchTerm?.length > 2 && !doesIncludesClaimNumberSearch 
            ? {
                      ...( paginationFilters ?? [] ),
                      operands: [
                          ...( paginationFilters?.operands ?? []),
                          ...quickSearch?.operands,
                      ],
                  }
            : paginationFilters,
            newPagination || pagination,
        );
    }

    function handleChangePage(event, newPage) {
        setListData([]);
        const newPagination = { ...pagination, currentPage: newPage + 1 }
        handleLoad({ newPagination });
    }

    function handleChangeRowsPerPage(event) {
        setListData([]);
        const newPagination = { ...pagination, recordsPerPage: event.target.value, currentPage: 1 }
        handleLoad({ newPagination });
    }

    function handleRefresh(hardRefresh?: boolean) {
       setListData([]);
       if (!pending || hardRefresh || sessionStorage.getItem('duplicated')) {
            handleLoad({
                newPagination: pagination,
                newSort: sort,
            });
        }
    }

    function handleSort(newSort) {
        setListData([]);
        if (!pending && newSort.length > 0) {
            setSort(newSort);
            handleLoad({ newSort });
        }
    }

    function handleFilter(newFilter,isAddFilter) {
        if (!pending) {
            if (!isAddFilter) { setListData([]); }
            
            const mergedFilter =
                !newFilter?.equator && !newFilter?.field && !newFilter?.value
                    ? [...filter]
                    : [...filter, newFilter];
                
            setFilter(mergedFilter);
            if(!isAddFilter)
            {handleLoad({
                newFilter: mergedFilter,
                newPagination: {
                    ...pagination,
                    currentPage: 1,
                }
            });}
        }
    }

    function handleTimeFilter(newFilter) {
        if (!pending) {
            setTimeFilter(newFilter);
            handleLoad({
                newTimeFilter: newFilter,
            })
        }
    }

    function handleDeleteFilter(deletedFilter, index) {
        setListData([]);
        filter.splice(index, 1);
        setFilter(filter);

        handleLoad({
            newFilter: filter,
        });

        // // clear groups when filters are none
        // if(filter.length === 0) {
        //     resetGroups();
        // }
    }

    function handleUpdateFilter(newFilterObj, index) {
        if (index !== null) {
            setListData([]);
            filter[index] = newFilterObj;
            setFilter(filter);
            handleLoad({
                newFilter: filter,
            });
        }
    }

    function handleFilterClear() {
        // if (filter && filter.length > 0) { //Even if crieria has no filters we should still be able to clear
            setListData([]);
            setFilter([]);
            setSelectedFilter(null);
            handleLoad({
                newFilter: [],
            });
            
        if (pageName === "claimAnalysis-landing" || pageName === "triage-landing") {
            localStorage.setItem("isCriteriaCleared", "true")

        }
        // }
    }

    function renderPagination() {
        if (data.length <= 0) {
            return null;
        }
        return (
            (<TablePagination
                sx={{border:shouldRenderLazy && 'none'}}
                rowsPerPageOptions={[50, 100, 200, 500, 1000]}
                page={currentPage - 1}
                rowsPerPage={recordsPerPage}
                count={totalRecords}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                }}
                ActionsComponent={TablePaginationActions}
            />)
        );
    }

    function refreshPageCriterias() {
        const pName = {
            name: pageName,
        }
        searchGetByName(pName);
    }

    function SaveFilterCallback(handleClose) {
        handleClose();
        refreshPageCriterias();

    }

    function handleFilterSave(filterCriteria, handleClose, existingCriteria) {
        let searchCriteria = {
            ...filterCriteria,
            name: pageName,
        }
        if (existingCriteria && existingCriteria.id) {
            updateCriteriaFilter(searchCriteria, () => SaveFilterCallback(handleClose));
        } else {
            
            saveCriteriaFilter(searchCriteria, () => SaveFilterCallback(handleClose));
        }
    }

    return (
        (<div className="paged-table-container">
            <Grid container spacing={1}>
                {timeFilterField && (
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} md={6}>
                            <TimeFilter onFilterChange={handleTimeFilter} />
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <DataTableHeader
                        fields={sortedFieldsForHeaders}
                        intl={intl}
                        filter={filter}
                        customFilter={customFilter}
                        onFilterChange={handleFilter}
                        onFilterDelete={handleDeleteFilter}
                        onFilterClear={handleFilterClear}
                        onFilterSave={handleFilterSave}
                        onFilterUpdate={handleUpdateFilter}
                        criteria={criteria}
                        saveEnabled={criteriasSaveEnabled}
                        pending={pending}
                        pageName={pageName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className="paged-table">
                        <DataTable
                            fields={filteredFields}
                            data={data}
                            onRowClick={onRowClick}

                            sort={sort}
                            onSort={handleSort}
                            hasActions={hasActions}
                            loading={pending || loading}
                            footerPagination={renderPagination}
                            showPagination={true}
                            stickyHeader={true}
                            tableInModal={tableInModal}

                            // onCheckbox={onCheckbox}
                            hasCheckbox={hasCheckbox}
                            showSelectAll={showSelectAll}
                            // checkboxkey={checkboxkey}
                            // checkboxApplyNot={checkboxApplyNot}
                            isCollapsible={isCollapsible}
                            collapsibleItem={collapsibleItem}
                            handleClickColumn={handleClickColumn}
                            hoverData={hoverData}
                            showFilterColumnsDialog={showFilterColumnsDialog}
                            hiddenFields={hiddenFields}
                            showReorderColumnsDialog={showReorderColumnsDialog}
                            orderPreference={orderPreference}
                            shouldRenderLazy={shouldRenderLazy}

                            handleRefresh={!hideRefresh ? handleRefresh : null}
                            tableLevelActions={tableLevelActions}
                            name={name}
                            pageName={pageName}
                            onAdd={onAdd}
                            tableLevelLoading={tableLevelLoading}
                            actionLevelGrid={actionLevelGrid}

                            renderActionColumn={renderActionColumn}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>)
    );
};                   