import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET, METHOD_PUT,METHOD_DELETE
} from '../constants/api';
import { headersAuthReceiveJson, headersAuth, headersAuthReceiveOctet, headersAuthSendReceiveJson } from '../headers';
import { apiHandleResponse, apiHandleOctetResponse, apiHandleOctetResponseWithFileName } from '../../util/api';



const getPayerSetupData = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/payercontracts`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getPayerSetupDataDependencies = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/payercontracts/dependencies`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deleteSelectedPayerSetupData = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/payercontracts`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const createPricerPayer = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/payercontract`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const updatePricerPayer = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/payercontract`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const checkPricerPayerAssociation = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/payercontract/checkassociation`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};


const getAllPricerPayerContracts = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/payercontracts/tiny`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};
export {
    getPayerSetupData,
    getPayerSetupDataDependencies,
    deleteSelectedPayerSetupData,
    checkPricerPayerAssociation,
    createPricerPayer,
    updatePricerPayer,
    getAllPricerPayerContracts
};