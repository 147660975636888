import React, { useState } from "react";
import { Loadable } from '../../../common';
import { Dialog, DialogTitle, Typography, IconButton, DialogContent, Breadcrumbs, DialogActions } from '@mui/material';

import { Button, TextField, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { findNodeInTree, findParents } from "../../../util/tree";
import { errorToastify } from "../../../util/toast";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1)
    },
    folderDiv: {
        cursor: 'pointer',
        textDecoration: 'underline',
    }
}));

export default function MoveItemsDialog(props) {
    const { open, selectedItems, hierarchy, handleRefresh,
        actions: { setMoveDialog, resetSelectedContracts, moveDirectoryOrContracts } } = props;

    const classes = useStyles();

    const [selectedDirectory, setSelectedDirectory] = useState('root');

    const getBreadcrumbItems = () => {
        if (selectedDirectory === 'root') {
            return []
        }
        return findParents(parseInt(selectedDirectory), hierarchy);
    }

    const getCurrentDirectory = () => {
        if (selectedDirectory === 'root') {
            return null;
        }
        return findNodeInTree(parseInt(selectedDirectory), hierarchy);
    }

    const handleDialogClose = () => {
        setMoveDialog(null);
        setSelectedDirectory('root');
    }

    function findNodeById(nodeId, nodes) {
        let result = null;

        function searchNodes(node) {
            if (node.parentId === nodeId) {
                result = node;
                return;
            }
            if (node.child && node.child.length > 0) {
                node.child.forEach(childNode => searchNodes(childNode));
            }
        }

        nodes.forEach(node => searchNodes(node));
        return result;
    }

    function getChildrenByNodeId(nodeId, nodes) {
        const parentNode = findNodeById(nodeId, nodes);
        if (parentNode && parentNode.child) {
            return parentNode.child;
        }
        return [];
    }


    const renderFolders = () => {
        const childs = getChildrenByNodeId(selectedDirectory, hierarchy);

        if (selectedDirectory === 'root') {
            return (
                <Grid className="px-3">
                    {hierarchy.map(item => <Grid key={item.parentId} className="d-flex">
                        <Grid className={classes.folderDiv} onClick={e => setSelectedDirectory(item.parentId)}>
                            <IconButton className="p-1 mr-2" size="large">
                                <i style={{ color: '#FFCA35' }} className="fas fa-folder"></i>
                            </IconButton>
                            {item.parentName}
                        </Grid>
                    </Grid>)}
                    {hierarchy.length === 0 && <span>No other folders available.</span>}
                </Grid>
            );
        } else {
            return (
                <Grid className="px-3">
                    {childs.map(item => <Grid key={item.id} className="d-flex">
                        <Grid className={classes.folderDiv} onClick={e => setSelectedDirectory(item.parentId)}>
                            <IconButton className="p-1 mr-2" size="large">
                                <i style={{ color: '#FFCA35' }} className="fas fa-folder"></i>
                            </IconButton>
                            {item.parentName}
                        </Grid>
                    </Grid>)}
                    {childs.length === 0 && <span>No other folders available.</span>}
                </Grid>
            );
        }
    }

    const moveHereHandler = () => {
        let contractIds = [];
        let directoryIds = [];
        if (selectedItems.length > 0) {
            for (let item of selectedItems) {
                const splittedItem = item.split('-');
                if (splittedItem[0] === 'contracts') {
                    contractIds.push(splittedItem[1]);
                } else if (splittedItem[0] === 'folder') {
                    directoryIds.push(splittedItem[1])
                }
            }
        }

        const sameDirectory = directoryIds.find(d => parseInt(d) === parseInt(selectedDirectory));
        if (sameDirectory) {
            errorToastify('Destination Folder is selected as an item. Please uncheck it')
            return
        }

        const payload = {
            contractIds,
            directoryIds,
            destinationDirectoryId: selectedDirectory === 'root' ? undefined : selectedDirectory
        }
        handleDialogClose();
        moveDirectoryOrContracts(payload, handleCallback);
    };

    const handleCallback = () => {
        handleRefresh();
        resetSelectedContracts();
    }

    return (
        (<Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={false}>
                <DialogTitle>
                    <Grid container alignItems="center">
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Move Contracts
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className="text-right">
                            <IconButton
                                className="p-2"
                                aria-label="close"
                                onClick={handleDialogClose}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    {selectedItems.length === 0 ? <Typography>No items selected. Please select items to move</Typography> :
                        <Grid>
                            <Breadcrumbs separator=">" aria-label="breadcrumb">
                             {/* tslint:disable-next-line  */}
                                <Button onClick={() => setSelectedDirectory('root')} className='breadcrumb-link' color="inherit" >
                                    Contracts
                                </Button>
                                {getBreadcrumbItems()?.map((breadcrumb, index) =>
                                    <Button onClick={() => setSelectedDirectory(breadcrumb.id)} className='breadcrumb-link' key={index.toString()} color="inherit" >
                                        {breadcrumb.name}
                                    </Button>
                                )}
                                {getCurrentDirectory() &&
                                    <Typography className='breadcrumb-active'>{getCurrentDirectory()?.parentName}</Typography>
                                }
                            </Breadcrumbs>
                            <hr />
                            {renderFolders()}
                        </Grid>
                    }
                </DialogContent>
                <DialogActions className="px-4 py-4">
                    <Button variant="contained" onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    {selectedItems.length > 0 &&
                        <Button
                            onClick={() => moveHereHandler()}
                            variant="contained"
                            color="primary"
                        >
                            Move Here
                        </Button>
                    }
                </DialogActions>
            </Loadable >
        </Dialog>)
    );
}