import { CircularProgress, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useData, useStatePtr } from "@pure-ptr/react";
import React, { useContext } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { getUnderpaymentsSummary } from "../../store/api/summaryDashboard";
import { asDollarsWithZero } from '../../util/format';
import { DashboardFiltersContext, useStyles } from './Dashboard';
import { useDispatch } from 'react-redux';
import { setMulti } from '../../store/actions/pages';

export const UnderpaymentsWidget = () => {
    const dispatch = useDispatch();
    
    const classes = useStyles();
    const { dateRange, history } = useContext(DashboardFiltersContext);

    const { data, isPending } = useData( async () => {
        const result = await getUnderpaymentsSummary(dateRange.startdate, dateRange.enddate); 
        return result;
    }, [ dateRange ]);

    const openPtr = useStatePtr( {} );

    const handleUnderpaymentsRowClick = (contractName) => {
        openPtr.set((prevState) => ({
            ...prevState, 
            [contractName]: !prevState[contractName],
        }));
    }

    const handleUnderpaymentsAmountClick = (contractName, isDenial, facilityId = null) => {
        sessionStorage.removeItem("duplicated");
        let filters = [
            {
                actualFilter: true, 
                equator: "greater_than", 
                field: "variance", 
                value: 0
            },
            {
                actualFilter: true, 
                equator: "equals", 
                field: "contract.name", 
                value: contractName
            }
        ];

        if(facilityId != null) {
            filters.push({
                actualFilter: true, 
                equator: "equals", 
                field: "facilityId", 
                value: facilityId
            });
        }

        dispatch( setMulti( 'claimAnalysis-landing', {
            filter: filters,
            selectedFilter: null,
            customFilter: [
                {
                    customFilter: true, 
                    equator: "greater_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRange.startdate,
                    relativeFilter:dateRange.relativeFilter
                },
                {
                    customFilter: true, 
                    equator: "less_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRange.enddate,
                    relativeFilter:dateRange.relativeFilter
                },
                {
                    customFilter: true, 
                    equator: "equals", 
                    field: "isDenial", 
                    value: isDenial
                }
            ],
            list: [],
            significance: false,
            searchTerm:"",
            ignoreAcknowledgement:false,
            showIgnoredFilterDialog:false
        }));

        history.push("/cms/claimAnalysis/fromSummary");
    }

    return isPending ? (
        <CircularProgress />
        ) : (
        <Table>
            <TableHead>
            <TableRow key='underpaymentsHeader'>
                <TableCell>Contract</TableCell>
                <TableCell>Underpayments</TableCell>
                <TableCell>Denials</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {data?.slice(0, 10)?.map((entry) => (
                <>
                <TableRow key={entry.contractName} className={entry.underpaymentsByFacilities?.length > 0 ? classes.greenHeaderRow : ''}>
                    <TableCell>
                        {entry.underpaymentsByFacilities?.length > 0 && (
                            <IconButton sx={{padding: "0 10px 0 0"}} onClick={() => handleUnderpaymentsRowClick(entry.contractName)}>
                                {openPtr.value[entry.contractName] ? <ArrowDropDownIcon /> : <ArrowRightIcon />  }
                            </IconButton>
                        )}
                        {entry.contractName}
                    </TableCell>
                    <TableCell><Typography onClick={()=> handleUnderpaymentsAmountClick(entry.contractName,false)} variant="body2" className={entry.totalUnderPayments ? classes.link : ''}>{entry.totalUnderPayments ? asDollarsWithZero(entry.totalUnderPayments) : 'N/A'}</Typography></TableCell>
                    <TableCell><Typography onClick={()=> handleUnderpaymentsAmountClick(entry.contractName,true)} variant="body2" className={entry.totalDenials ? classes.link : ''}>{entry.totalDenials ? asDollarsWithZero(entry.totalDenials) : 'N/A'}</Typography></TableCell>
                </TableRow>

                {entry.underpaymentsByFacilities?.length > 0 && (
                    <TableRow key={`${entry.contractName}-childRow`}>
                        <TableCell colSpan={3} sx={{padding: "0px", border: 0 }}>
                            <Collapse in={openPtr.value[entry.contractName]} timeout="auto" unmountOnExit>
                            <Table sx={{width: "87%", margin: "0px 50px"}}>
                                <TableBody>
                                {entry.underpaymentsByFacilities?.map((facility, index) => (
                                    <TableRow key={`${entry.contractName}-${index}`}>
                                        <TableCell>{facility.facilityName ?? "N/A"}</TableCell>
                                        <TableCell><Typography onClick={()=> handleUnderpaymentsAmountClick(entry.contractName,false, facility.facilityId ?? "none")} variant="body2" className={facility.totalUnderPayments ? classes.link : ''}>{facility.totalUnderPayments ? asDollarsWithZero(facility.totalUnderPayments) : 'N/A'}</Typography></TableCell>
                                        <TableCell><Typography onClick={()=> handleUnderpaymentsAmountClick(entry.contractName,true, facility.facilityId ?? "none")} variant="body2" className={facility.totalDenials ? classes.link : ''}>{facility.totalDenials ? asDollarsWithZero(facility.totalDenials) : 'N/A'}</Typography></TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
                
                </>
            ))}
            </TableBody>
        </Table>
    )
}