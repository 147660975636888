export const CLIENTS_LIST = 'CLIENTS_LIST';
export const CLIENTS_LIST_PENDING = 'CLIENTS_LIST_PENDING';
export const CLIENTS_LIST_FULFILLED = 'CLIENTS_LIST_FULFILLED';
export const CLIENTS_LIST_REJECTED = 'CLIENTS_LIST_REJECTED';

export const CLIENTS_LIST_TINY = 'CLIENTS_LIST_TINY';
export const CLIENTS_LIST_TINY_PENDING = 'CLIENTS_LIST_TINY_PENDING';
export const CLIENTS_LIST_TINY_FULFILLED = 'CLIENTS_LIST_TINY_FULFILLED';
export const CLIENTS_LIST_TINY_REJECTED = 'CLIENTS_LIST_TINY_REJECTED';

export const CLIENTS_GET = 'CLIENTS_GET';
export const CLIENTS_GET_PENDING = 'CLIENTS_GET_PENDING';
export const CLIENTS_GET_FULFILLED = 'CLIENTS_GET_FULFILLED';
export const CLIENTS_GET_REJECTED = 'CLIENTS_GET_REJECTED';

export const CLIENTS_CREATE = 'CLIENTS_CREATE';
export const CLIENTS_CREATE_PENDING = 'CLIENTS_CREATE_PENDING';
export const CLIENTS_CREATE_FULFILLED = 'CLIENTS_CREATE_FULFILLED';
export const CLIENTS_CREATE_REJECTED = 'CLIENTS_CREATE_REJECTED';

export const CLIENTS_UPDATE = 'CLIENTS_UPDATE';
export const CLIENTS_UPDATE_PENDING = 'CLIENTS_UPDATE_PENDING';
export const CLIENTS_UPDATE_FULFILLED = 'CLIENTS_UPDATE_FULFILLED';
export const CLIENTS_UPDATE_REJECTED = 'CLIENTS_UPDATE_REJECTED';

export const CLIENTS_ERROR_RESET = 'CLIENTS_ERROR_RESET';
export const CLIENTS_RESET = 'CLIENTS_RESET';

export const PARSERS_GET = 'PARSERS_GET';
export const PARSERS_GET_PENDING = 'PARSERS_GET_PENDING';
export const PARSERS_GET_FULFILLED = 'PARSERS_GET_FULFILLED';
export const PARSERS_GET_REJECTED = 'PARSERS_GET_REJECTED';

export const BUCKET_CHECK = 'BUCKET_CHECK';
export const BUCKET_CHECK_PENDING = 'BUCKET_CHECK_PENDING';
export const BUCKET_CHECK_FULFILLED = 'BUCKET_CHECK_FULFILLED';
export const BUCKET_CHECK_REJECTED = 'BUCKET_CHECK_REJECTED';

export const FTPUSER_CHECK = 'FTPUSER_CHECK';
export const FTPUSER_CHECK_PENDING = 'FTPUSER_CHECK_PENDING';
export const FTPUSER_CHECK_FULFILLED = 'FTPUSER_CHECK_FULFILLED';
export const FTPUSER_CHECK_REJECTED = 'FTPUSER_CHECK_REJECTED';

export const CLIENTS_ACTIVATE = 'CLIENTS_ACTIVATE';
export const CLIENTS_ACTIVATE_PENDING = 'CLIENTS_ACTIVATE_PENDING';
export const CLIENTS_ACTIVATE_FULFILLED = 'CLIENTS_ACTIVATE_FULFILLED';
export const CLIENTS_ACTIVATE_REJECTED = 'CLIENTS_ACTIVATE_REJECTED';

export const CLIENTS_DEACTIVATE = 'CLIENTS_DEACTIVATE';
export const CLIENTS_DEACTIVATE_PENDING = 'CLIENTS_DEACTIVATE_PENDING';
export const CLIENTS_DEACTIVATE_FULFILLED = 'CLIENTS_DEACTIVATE_FULFILLED';
export const CLIENTS_DEACTIVATE_REJECTED = 'CLIENTS_DEACTIVATE_REJECTED';

export const CLIENTS_CONFIGURATION_GET = 'CLIENTS_CONFIGURATION_GET';
export const CLIENTS_CONFIGURATION_GET_PENDING = 'CLIENTS_CONFIGURATION_GET_PENDING';
export const CLIENTS_CONFIGURATION_GET_FULFILLED = 'CLIENTS_CONFIGURATION_GET_FULFILLED';
export const CLIENTS_CONFIGURATION_GET_REJECTED = 'CLIENTS_CONFIGURATION_GET_REJECTED';

export const CLIENTS_ACCOUNT_CONFIGURATION_GET = 'CLIENTS_ACCOUNT_CONFIGURATION_GET';
export const CLIENTS_ACCOUNT_CONFIGURATION_GET_PENDING = 'CLIENTS_ACCOUNT_CONFIGURATION_GET_PENDING';
export const CLIENTS_ACCOUNT_CONFIGURATION_GET_FULFILLED = 'CLIENTS_ACCOUNT_CONFIGURATION_GET_FULFILLED';
export const CLIENTS_ACCOUNT_CONFIGURATION_GET_REJECTED = 'CLIENTS_ACCOUNT_CONFIGURATION_GET_REJECTED';


export const CLIENTS_CONFIGURATION_CREATE = 'CLIENTS_CONFIGURATION_CREATE';
export const CLIENTS_CONFIGURATION_CREATE_PENDING = 'CLIENTS_CONFIGURATION_CREATE_PENDING';
export const CLIENTS_CONFIGURATION_CREATE_FULFILLED = 'CLIENTS_CONFIGURATION_CREATE_FULFILLED';
export const CLIENTS_CONFIGURATION_CREATE_REJECTED = 'CLIENTS_CONFIGURATION_CREATE_REJECTED';

export const CLIENTS_ACCOUNT_CONFIGURATION_CREATE = 'CLIENTS_ACCOUNT_CONFIGURATION_CREATE';
export const CLIENTS_ACCOUNT_CONFIGURATION_CREATE_PENDING = 'CLIENTS_ACCOUNT_CONFIGURATION_CREATE_PENDING';
export const CLIENTS_ACCOUNT_CONFIGURATION_CREATE_FULFILLED = 'CLIENTS_ACCOUNT_CONFIGURATION_CREATE_FULFILLED';
export const CLIENTS_ACCOUNT_CONFIGURATION_CREATE_REJECTED = 'CLIENTS_ACCOUNT_CONFIGURATION_CREATE_REJECTED';

export const CLIENTS_CONFIGURATION_UPDATE = 'CLIENTS_CONFIGURATION_UPDATE';
export const CLIENTS_CONFIGURATION_UPDATE_PENDING = 'CLIENTS_CONFIGURATION_UPDATE_PENDING';
export const CLIENTS_CONFIGURATION_UPDATE_FULFILLED = 'CLIENTS_CONFIGURATION_UPDATE_FULFILLED';
export const CLIENTS_CONFIGURATION_UPDATE_REJECTED = 'CLIENTS_CONFIGURATION_UPDATE_REJECTED';

export const CLIENTS_ACCOUNT_CONFIGURATION_UPDATE = 'CLIENTS_ACCOUNT_CONFIGURATION_UPDATE';
export const CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_PENDING = 'CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_PENDING';
export const CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_FULFILLED = 'CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_FULFILLED';
export const CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_REJECTED = 'CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_REJECTED';

export const CLIENTS_CONFIGURATION_DELETE = 'CLIENTS_CONFIGURATION_DELETE';
export const CLIENTS_CONFIGURATION_DELETE_PENDING = 'CLIENTS_CONFIGURATION_DELETE_PENDING';
export const CLIENTS_CONFIGURATION_DELETE_FULFILLED = 'CLIENTS_CONFIGURATION_DELETE_FULFILLED';
export const CLIENTS_CONFIGURATION_DELETE_REJECTED = 'CLIENTS_CONFIGURATION_DELETE_REJECTED';

export const CLIENTS_GET_TIMEZONES = 'CLIENTS_GET_TIMEZONES';
export const CLIENTS_GET_TIMEZONES_PENDING = 'CLIENTS_GET_TIMEZONES_PENDING';
export const CLIENTS_GET_TIMEZONES_FULFILLED = 'CLIENTS_GET_TIMEZONES_FULFILLED';
export const CLIENTS_GET_TIMEZONES_REJECTED = 'CLIENTS_GET_TIMEZONES_REJECTED';

export const CLIENTS_GET_PRODUCTS = 'CLIENTS_GET_PRODUCTS';
export const CLIENTS_GET_PRODUCTS_PENDING = 'CLIENTS_GET_PRODUCTS_PENDING';
export const CLIENTS_GET_PRODUCTS_FULFILLED = 'CLIENTS_GET_PRODUCTS_FULFILLED';
export const CLIENTS_GET_PRODUCTS_REJECTED = 'CLIENTS_GET_PRODUCTS_REJECTED';

export const CLIENTS_GET_PRODUCTS_USER_EDIT = 'CLIENTS_GET_PRODUCTS_USER_EDIT';
export const CLIENTS_GET_PRODUCTS_USER_EDIT_PENDING = 'CLIENTS_GET_PRODUCTS_USER_EDIT_PENDING';
export const CLIENTS_GET_PRODUCTS_USER_EDIT_FULFILLED = 'CLIENTS_GET_PRODUCTS_USER_EDIT_FULFILLED';
export const CLIENTS_GET_PRODUCTS_USER_EDIT_REJECTED = 'CLIENTS_GET_PRODUCTS_USER_EDIT_REJECTED';

export const CLIENTS_ACCOUNT_ADD_CLAIMS = 'CLIENTS_ACCOUNT_ADD_CLAIMS';
export const CLIENTS_ACCOUNT_ADD_CLAIMS_PENDING = 'CLIENTS_ACCOUNT_ADD_CLAIMS_PENDING';
export const CLIENTS_ACCOUNT_ADD_CLAIMS_FULFILLED = 'CLIENTS_ACCOUNT_ADD_CLAIMS_FULFILLED';
export const CLIENTS_ACCOUNT_ADD_CLAIMS_REJECTED = 'CLIENTS_ACCOUNT_ADD_CLAIMS_REJECTED';

export const CLIENTS_BRANDING_GET = 'CLIENTS_BRANDING_GET';
export const CLIENTS_BRANDING_GET_PENDING = 'CLIENTS_BRANDING_GET_PENDING';
export const CLIENTS_BRANDING_GET_FULFILLED = 'CLIENTS_BRANDING_GET_FULFILLED';
export const CLIENTS_BRANDING_GET_REJECTED = 'CLIENTS_BRANDING_GET_REJECTED';

export const CLIENTS_BRANDING_CREATE = 'CLIENTS_BRANDING_CREATE';
export const CLIENTS_BRANDING_CREATE_PENDING = 'CLIENTS_BRANDING_CREATE_PENDING';
export const CLIENTS_BRANDING_CREATE_FULFILLED = 'CLIENTS_BRANDING_CREATE_FULFILLED';
export const CLIENTS_BRANDING_CREATE_REJECTED = 'CLIENTS_BRANDING_CREATE_REJECTED';

export const CLIENTS_BRANDING_UPDATE = 'CLIENTS_BRANDING_UPDATE';
export const CLIENTS_BRANDING_UPDATE_PENDING = 'CLIENTS_BRANDING_UPDATE_PENDING';
export const CLIENTS_BRANDING_UPDATE_FULFILLED = 'CLIENTS_BRANDING_UPDATE_FULFILLED';
export const CLIENTS_BRANDING_UPDATE_REJECTED = 'CLIENTS_BRANDING_UPDATE_REJECTED';

export const CLIENTS_BRANDING_DETAILS_GET = 'CLIENTS_BRANDING_DETAILS_GET';
export const CLIENTS_BRANDING_DETAILS_GET_PENDING = 'CLIENTS_BRANDING_DETAILS_GET_PENDING';
export const CLIENTS_BRANDING_DETAILS_GET_FULFILLED = 'CLIENTS_BRANDING_DETAILS_GET_FULFILLED';
export const CLIENTS_BRANDING_DETAILS_GET_REJECTED = 'CLIENTS_BRANDING_DETAILS_GET_REJECTED';

export const CLIENTS_CUSTOM_CLAIM_STATUS = 'CLIENTS_CUSTOM_CLAIM_STATUS';
export const CLIENTS_CUSTOM_CLAIM_STATUS_PENDING = 'CLIENTS_CUSTOM_CLAIM_STATUS_PENDING';
export const CLIENTS_CUSTOM_CLAIM_STATUS_FULFILLED = 'CLIENTS_CUSTOM_CLAIM_STATUS_FULFILLED';
export const CLIENTS_CUSTOM_CLAIM_STATUS_REJECTED = 'CLIENTS_CUSTOM_CLAIM_STATUS_REJECTED';

export const CLIENTS_SUMMARY_DASHBOARD_ACCESS = 'CLIENTS_SUMMARY_DASHBOARD_ACCESS';
export const CLIENTS_SUMMARY_DASHBOARD_ACCESS_PENDING = 'CLIENTS_SUMMARY_DASHBOARD_ACCESS_PENDING';
export const CLIENTS_SUMMARY_DASHBOARD_ACCESS_FULFILLED = 'CLIENTS_SUMMARY_DASHBOARD_ACCESS_FULFILLED';
export const CLIENTS_SUMMARY_DASHBOARD_ACCESS_REJECTED = 'CLIENTS_SUMMARY_DASHBOARD_ACCESS_REJECTED';

export const CLIENTS_GET_PRODUCTS_USER_EDIT_RESET = 'CLIENTS_GET_PRODUCTS_USER_EDIT_RESET';

export const CLIENTS_CONFIGURATION_RESET = 'CLIENTS_CONFIGURATION_RESET';

export const CLIENTS_ACCOUNT_CONFIGURATION_RESET = 'CLIENTS_ACCOUNT_CONFIGURATION_RESET';

export const CLIENTS_ADDED_CLAIMS_RESET = 'CLIENTS_ADDED_CLAIMS_RESET';



export const RESET_BUCKETS = 'RESET_BUCKETS';

export const list = () => ({
    type: CLIENTS_LIST,
});

export const listTiny = (callback) => ({
    type: CLIENTS_LIST_TINY,
    payload: { callback },
});

export const get = (clientId) => ({
    type: CLIENTS_GET,
    payload: { clientId },
});

export const create = (client, callback) => ({
    type: CLIENTS_CREATE,
    payload: { client, callback },
});

export const update = (client, callback) => ({
    type: CLIENTS_UPDATE,
    payload: { client, callback },
});

export const errorReset = (...errorKeys) => ({
    type: CLIENTS_ERROR_RESET,
    payload: { errorKeys },
});

export const getParsers = () => ({
    type: PARSERS_GET,
    payload: {},
});

export const bucketAvailability = (bucketName, index) => ({
    type: BUCKET_CHECK,
    payload: { bucketName, index },
});

export const ftpUserAvailability = (username, index) => ({
    type: FTPUSER_CHECK,
    payload: { username, index },
});

export const resetBuckets = () => ({
    type: RESET_BUCKETS,
});

export const deactivateClient = (clientId, callback) => ({
    type: CLIENTS_DEACTIVATE,
    payload: {
        clientId, callback
    }
});

export const activateClient = (clientId, callback) => ({
    type: CLIENTS_ACTIVATE,
    payload: {
        clientId, callback
    }
});

export const createConfiguration = (clientConfigs, callback) => ({
    type: CLIENTS_CONFIGURATION_CREATE,
    payload: { clientConfigs, callback },
});

export const createAccountConfiguration = (clientAccountConfigs, callback) => ({
    type: CLIENTS_ACCOUNT_CONFIGURATION_CREATE,
    payload: { clientAccountConfigs, callback },
});

export const updateConfiguration = (clientConfigs, callback) => ({
    type: CLIENTS_CONFIGURATION_UPDATE,
    payload: { clientConfigs, callback },
});

export const updateAccountConfiguration = (clientAccountConfigs, callback) => ({
    type: CLIENTS_ACCOUNT_CONFIGURATION_UPDATE,
    payload: { clientAccountConfigs, callback },
});


export const getConfiguration = (clientId) => ({
    type: CLIENTS_CONFIGURATION_GET,
    payload: { clientId },
});
export const getAccountConfiguration = (clientId) => ({
    type: CLIENTS_ACCOUNT_CONFIGURATION_GET,
    payload: { clientId },
});

export const deleteConfiguration = (clientId, callback) => ({
    type: CLIENTS_CONFIGURATION_DELETE,
    payload: { clientId, callback },
});

export const resetClientConfiguration = () => ({
    type: CLIENTS_CONFIGURATION_RESET
});

export const resetClientAccountConfiguration = () => ({
    type: CLIENTS_ACCOUNT_CONFIGURATION_RESET
});

export const resetAddedClaims = () => ({
    type:CLIENTS_ADDED_CLAIMS_RESET,

})

export const getTimesZones = () => ({
    type: CLIENTS_GET_TIMEZONES
});

export const getClientProducts = (clientId) => ({
    type: CLIENTS_GET_PRODUCTS,
    payload: { clientId }
});

export const getProductsForUserEdit = (clientId) => ({
    type: CLIENTS_GET_PRODUCTS_USER_EDIT,
    payload: { clientId }
});

export const addClaims = (claims,callback) => ({
    type: CLIENTS_ACCOUNT_ADD_CLAIMS,
    payload: { claims,callback }
});

export const resetProductsForUserEdit = () => ({
    type: CLIENTS_GET_PRODUCTS_USER_EDIT_RESET
});

export const getBranding = () => ({
    type: CLIENTS_BRANDING_GET,
});

export const createBranding = (branding, callback) => ({
    type: CLIENTS_BRANDING_CREATE,
    payload: { branding, callback },
});

export const updateBranding = (branding, callback) => ({
    type: CLIENTS_BRANDING_UPDATE,
    payload: { branding, callback },
});

export const getBrandingDetails = (facilityId, callback) => ({
    type: CLIENTS_BRANDING_DETAILS_GET,
    payload: { facilityId, callback },
});