import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set, setMulti,setOpenAdvanceFiltersDialog } from '../../../../../store/actions/pages';

import ModelResultsTopFiltersComponent from './ModelResultsTopFilters';
import { setRelativeFilter } from '../../../../../store/actions/claimAnalysis';

export const ModelResultsTopFilters = connect(
    () => {

        const selectCustomFilter = createSelector(
            (state) => state.pages['model-executions-result-landing'],
            (table) => {
                return table.customFilter;
            }
        );

        const selectPending = createSelector(
            (state) => state.pages,
            (state) => state.search.getByName.pending,
            (pages, searchPending) => {
                return pages['model-executions-result-landing'].pending || searchPending
            }
        );

        return (state:any, props) => ({
            customFilter: selectCustomFilter(state),
            relativeFilterValue: state.claimAnalysis.relativeFilterValue,
            tableFilters:state.pages['model-executions-result-landing']?.filter,
            pageState:state.pages['model-executions-result-landing'],
            showHidden:state.pages['model-executions-result-landing']?.showHidden,
            pending: selectPending(state),
            open: state.pages.open

        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setMulti: obj => setMulti('model-executions-result-landing', obj),
            setCustomFilter: (value) => set('model-executions-result-landing', 'customFilter', value),
            setRelativeFilter: setRelativeFilter,
            setOpenAdvanceFiltersDialog
        }, dispatch)
    })
)(injectIntl(ModelResultsTopFiltersComponent));