import React,{useEffect} from "react";
import {TextField} from "@mui/material";
import { pageStore } from "../../../common";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export const ClaimQuickSearch = ({ pending, classes, handleRefresh,setMulti }) => {
    const { usePageStore } = pageStore('claim-audits-landing', {
    });

    const claimsAuditsData:any = usePageStore( x => x );
    const claimsSearchTerm = usePageStore( x => x.searchTerm );
    useEffect(() => {
        return () => {
            setMulti({
                page: { recordsPerPage: 100 }, // reset pg
            });
        };
    }, []);

    const handleClaimNumberSearchChange = (e) => {
        const value = e.target.value;

        setMulti({
            searchTerm: value,
            page: {
                ...claimsAuditsData.page,
                currentPage:
                    value.length === 0 ? 1 : claimsAuditsData.page?.currentPage, //reset search reset pgno
            },
        });
        if (value?.length === 0) {
            handleRefresh();
        }
    };


    const handleSearch = (event) => {
        if (event.key === "Enter" && claimsSearchTerm?.length > 2) {
            const allExcludedFilters = [
                ...(claimsAuditsData.filter || []),
            ]?.filter((item) => item?.field !== "claimnumber");

            setMulti({
                filter: allExcludedFilters,
                page: {
                    ...claimsAuditsData.page,
                    currentPage:1, //reset search reset pgno
                },
                
            });

            handleRefresh();
        }
    };
    

    return (
            <TextField
                label="Claim Search"
                onKeyUp={handleSearch}
                disabled={pending}
                id="standard-start-adornment"
                className={classes.claimSearchField}
                value={claimsSearchTerm}
                onChange={handleClaimNumberSearchChange}
                InputLabelProps={{
                    shrink: true,
                }}
                sx={{ m: 1, width: "200px" }}
                placeholder="Name/#"
                InputProps={{
                    endAdornment: (
                        <>
                            {claimsSearchTerm?.length > 0 && (
                                <CloseIcon
                                    onClick={() =>
                                        handleClaimNumberSearchChange({
                                            target: { value: "" },
                                        })
                                    }
                                    sx={{
                                        mr: "5px",
                                        fontSize: "15px",
                                        cursor: "pointer",
                                    }}
                                />
                            )}
                            <SearchIcon
                                sx={{ fontSize: "14px", opacity: 0.7 }}
                            />
                        </>
                    ),
                }}
                variant="standard"
                helperText="Minimum 3 Characters"
            />
    );
};
