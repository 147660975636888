import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { DataTable, XClientDataGrid } from '../../../../common';
import CloseIcon from '@mui/icons-material/Close';
import { ExecutionErrorDialog } from '../../ExecutionErrorDialog';
import { useStatePtr } from '@pure-ptr/react';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';

const DEFAULT_SORT = [{ id: 'claimNumber', direction: 'asc' }];

function ProcessedClaimsDialog({ openPtr, intl, fields, filteredData, order, sort, actions: { setOrder, setSort } }) {
    const handleClose = () => {
        openPtr.set(false)
    }

    function renderErrorMessage({ rowData }) {
        return [
            <GridActionsCellItem
                icon={rowData?.error ? <ExecutionErrorDialog executionError={rowData?.error} /> : <span>N/A</span>}
                label={'ffff'}
            >
            </GridActionsCellItem>
        ]
    }

    const sortPtr = useStatePtr(DEFAULT_SORT);

    return (
        (<Dialog maxWidth='lg' open={openPtr.value} onClose={handleClose}>
            <DialogTitle>
                <Grid container alignItems='center'>
                    <Grid item xs={10}>
                        <Typography variant="h6">
                            Processed Claims
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className='text-right'>
                        <IconButton aria-label="close" onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <XClientDataGrid
                        fields={fields}
                        getRowId={(row) => row.claimNumber}
                        data={filteredData}
                        sortPtr={sortPtr}
                        loading={false}
                        actionColumnName='Message'
                        actions={renderErrorMessage}
                        hideFooter
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions className='p-3'>
                <Button onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>)
    );
}

export default ProcessedClaimsDialog
