import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton, LinearProgress, MenuItem, Stack } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { PureObject } from '@pure-ptr/core';
import { useClassPtr, useData, useStatePtr } from '@pure-ptr/react';
import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useFitHeight } from '../../../../_metronic/layout/Layout';
import { enumToLabel, Select, XClientDataGrid } from '../../../common';
import { Portlet, PortletHeader } from '../../../partials/content/Portlet';
import { getQuickSightsDataSetDetails, getQuickSightsDataSetList, getQuickSightsDataSetRefreshSchedules, QSData } from '../../../store/api/controlRoom';
import { dateEquators, StringEquators } from '../../../store/constants/pages';
import { createNumberDataField, createStringDataField, createTimestampField } from '../../../util/format';
import { RefreshSchedulePanel } from './RefreshSchedule';

const DEFAULT_SORT = [{ id: 'updatedAt', direction: 'desc' }, { id: 'size', direction: 'desc' }];

function QSDataSetPage() {
    const fields = useMemo(() => [
        createStringDataField('name', 'Dataset name', { sortable: true, equators: StringEquators }),
        createNumberDataField('size', 'Size(GB)', { 
            sortable: true,
            valueFormatter: (value) => value ? value.toFixed(2) : '',
        }),
        createStringDataField('storage', 'Storage', { sortable: true, equators: StringEquators }),
        createTimestampField('createdAt', 'Created At', { sortable: true, equators: dateEquators }),
        createTimestampField('updatedAt', 'Updated At', { sortable: true, equators: dateEquators }),
    ], []);
    
    const statePtr = useClassPtr(QSDataList),
        state = statePtr.value;

    const intervalPtr = useStatePtr<string>( 'all' );
    const hourPtr = useStatePtr<string>( 'all' );

    const { isPending, reload } = useData( async () => {
        statePtr.set( await state.fetch() );
    }, [] );
    
    const { abort } = useData( async abort => {
        if( state.isIncomplete ){
            statePtr.set( await state.fetchDetails( abort ) );
        }
    }, [ state.incomplete ]);

    const sortPtr = useStatePtr( DEFAULT_SORT );

    useFitHeight();

    const getDetailPanelContent = useCallback( 
        ({ row }) => <RefreshSchedulePanel dataSetId={ row.id } />
    , [])

    const filtered = useMemo(() =>
        state.items.filter( x => 
            intervalPtr.value === 'all' || x.refreshSchedules.some( x => x.scheduleFrequency.interval === intervalPtr.value ) ||
            ( intervalPtr.value === 'none' && !x.refreshSchedules.length )
        ).filter( x =>
            intervalPtr.value !== 'DAILY' || hourPtr.value === 'all' || x.refreshSchedules.some( x => x.hour === parseInt( hourPtr.value ) )
        )
    , [ state.items, intervalPtr.value, hourPtr.value ]);

    return (<>
        <Helmet title="QS Datasets" />
        <Portlet>
            <PortletHeader
                title="QS Datasets"
                name="s3-listners-landing"
                toolbar={
                    <div />
                }
            />
                <Stack spacing={2} direction="column" sx={{ height: '100%', minHeight: 280 }}>
                    <XClientDataGrid
                        fields={fields}
                        data={ filtered }
                        loading={ Boolean( isPending )}
                        sortPtr={ sortPtr}
                        slots={{
                            footer: Footer,
                            toolbar: Toolbar as any
                        }}

                        getDetailPanelContent={ getDetailPanelContent }
                        getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.

                        slotProps={{
                            footer: { state } as any,
                            toolbar: { 
                                reload(){
                                    abort();
                                    reload();
                                },
                                intervals: state.refreshScheduleIntervals,
                                intervalPtr, hourPtr,
                                hours: state.hours,
                            } as any
                        }}
                    />
                </Stack>
        </Portlet>
    </>);
}

const Footer = ({ state }) => {
    const percent = ( state.items.length - state.incomplete.length ) / state.items.length * 100 ;

    return <Stack direction="row-reverse" spacing={2} minHeight={36} alignItems="center" paddingRight={2}
        sx={{ backgroundColor:"#f6f6f6", fontWeight: 500 }}>
        <Box>Total: {state.items.length}</Box>

        { state.incomplete.length ?
            <Box width={200}>
                Loading dataset sizes: { Math.round( percent ) }%
                <LinearProgress     
                    value={ percent } 
                    variant="determinate" 
                />
            </Box>
        : null }
    </Stack>
}

const Toolbar = ({ reload, intervals, intervalPtr, hours, hourPtr }) =>
    <GridToolbarContainer>
        <IconButton
            onClick={reload}
        >
            <RefreshIcon />
        </IconButton>

        <Select
            label="Refresh Schedule Interval"
            valuePtr={intervalPtr}
        >
            <MenuItem value='all'>All</MenuItem>
            { intervals.map( x =>
                <MenuItem key={x.id} value={x.id}>{x.label} ({x.count})</MenuItem>
            )}
        </Select>

        <Select
            label="Refresh Schedule Hour"
            valuePtr={hourPtr}
            disabled={ intervalPtr.value !== 'DAILY' }
        >
            <MenuItem value='all'>All</MenuItem>
            { hours.map( x =>
                <MenuItem key={x.hour} value={x.hour}> {x.hour}:MM ({x.count})</MenuItem>
            )}
        </Select>

        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />

    </GridToolbarContainer>

export default QSDataSetPage;

interface RefreshScheduleInterval {
    id: string;
    label: string;
    count: number;
}

class QSDataList extends PureObject {
    items: QSData[] = []
    incomplete: string[] = []
    refreshScheduleIntervals: RefreshScheduleInterval[] = []
    hours: { hour: number, count: number }[] = []

    async fetch(){
        const items = await getQuickSightsDataSetList();

        return this.withChanges({ 
            items,
            incomplete : items.map( x => x.id )
        });
    }

    get isIncomplete(): boolean {
        return this.incomplete.length > 0;
    }

    async fetchDetails( abort : AbortSignal ){
        const ids = this.incomplete.slice(0, 10);
        const [ details, refreshSchedules ] = await Promise.all([
            getQuickSightsDataSetDetails( ids, abort ),
            getQuickSightsDataSetRefreshSchedules( ids, abort ),
        ]);

        return this.withChanges({
            items : this.items.map( item => {
                const idx = ids.findIndex( id => 
                    item.id === id 
                );

                return idx >= 0 ? item.withChanges({
                    size: details[idx] && ( 
                        details[idx].consumedSpiceCapacityInBytes / 1024 / 1024 / 1024 
                    ),
                    refreshSchedules: refreshSchedules[idx] ?? []
                }) 
                : item;
            }),

            incomplete : this.incomplete.slice(10)
        })
    }

    initialize(): void {
        this.refreshScheduleIntervals = this.items.reduce( ( acc, x ) => {
            const intervals = [ ...new Set( x.refreshSchedules.map( x => x.scheduleFrequency.interval ) ) ];

            intervals.forEach( interval => {
                const idx = acc.findIndex( x => x.id === interval );

                if( idx >= 0 ){
                    acc[idx].count++;
                } else {
                    acc.push({ id: interval, label: enumToLabel( interval ), count: 1 });
                }
            });

            if( !intervals.length ){
                const idx = acc.findIndex( x => x.id === 'none' );

                if( idx >= 0 ){
                    acc[idx].count++;
                } else {
                    acc.push({ id: 'none', label: 'None', count: 1 });
                }
            }

            return acc;
        }, [] as RefreshScheduleInterval[] );

        this.hours = this.items.reduce( ( acc, x ) => {
            const hours = x.refreshSchedules.map( x => x.hour ).filter( x => x !== null );

            hours.forEach( hour => {
                const idx = acc.findIndex( x => x.hour === hour );

                if( idx < 0 ){
                    acc.push({ hour, count: 1 });
                }
                else {
                    acc[idx].count++;
                }
            });

            return acc;
        }, [] as { hour: number, count: number }[] );

        this.hours.sort( ( a, b ) => a.hour - b.hour );
    }
}



