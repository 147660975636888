import {
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    SERVER_URL,
} from '../constants/api';
import { http } from './common';

import {
    headersAuth,
    headersAuthReceiveJson, headersAuthSendReceiveJson
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const chatBotConversation = (data) => {
    return fetch(`${SERVER_URL}/api/v1/bot/upload`, {
        method: METHOD_POST,
        headers: headersAuth(),
        body: data
    }).then(apiHandleResponse);
};



const chats = async (data) => {
    return http.post(`/api/v1/bot/chat`, data);
};

export {chatBotConversation,chats}