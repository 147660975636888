import { createReducer } from "../../util";
import * as actions from '../actions/pagesV2';

const initialState = {
    'claimAnalysis-landing': {
        selected: []
    },
    'triage-landing': {
        selected: []
    },
    'feeschedule-items-landing': {
        selected: []
    },
    'payers-contracts-landing': {
        selected: []
    },
    'allowable-landing': {
        selected: []
    },
    'payerinfo-landing': {
        selected: []
    },
    'associated-landing': {
        selected: []
    },
    'pricer-payerinfo-landing': {
        selected: []
    },
    'pricer-config-contracts-landing': {
        selected: []
    },
    'shoppable-landing': {
        selected: []
    },
    'model-claim-selection-landing': {
        selected: []
    },
    'remit-payers-landing': {
        selected: []
    },
    'feeSchedules-landing': {
        selected: []
    },
    'contractsv2-landing': {
        selected: [],
        finalizedState:false
    },
    'hospitalservices-landing': {
        selected: []
    },
    'hospitalservices-master-landing': {
        selected: []
    },
    'mrf-modifiers-landing': {
        selected: []
    },
    'payer-id-mapping-landing': {
        selected: []
    },
};

export default createReducer(initialState, {
    [actions.PAGES_V2_SET]: (state, { page, key, value }) => ({
        ...state,
        [page]: { ...(state[page] || {}), [key]: value },
    }),
});