import React, { useEffect, useRef, useState } from "react";
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import {
    Accordion,
    AccordionSummary,
    CircularProgress,
    AccordionDetails,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UnauthorizedPage } from "../errors/unauthorizedPage";
import { Portlet, PortletBody, } from "../../partials/content/Portlet";
import DashboardControls from '../../common/dashboardControls/DashboardControls';
import Helmet from 'react-helmet';
import { Chatbot } from "../../common/Chatbot/Chatbot";

import './dashboardPage.scss';
const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    headContainer: {
        marginRight: 20,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    detailsPane: {
        padding: 0,
    },
    summaryRoot: {
        paddingTop: 0,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 0,
        minHeight: 40,
    },
    controls: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0,
    },
    progress: {
        margin: theme.spacing(2)
    }
}));

function DashboardPage({
    dashboardUrl, dashboardId, dashboardList, history, dashboadUrlPending,dashboardData,currentClient,
    dashboardUrlObject: { id, clientName, name, customFilters, filters, fiscalYear },
    dashboardFilters,doesDashboardBelongsToCurrentClient,canAccessChatbot,
    actions: { dashboardGetUrl, dashboardUrlReset, dashboardSetFilter }
}) {
    const classes = useStyles();
    const dashboardRef = useRef(null);
    const [quicksightDashboard, setQuicksightDashboard] = useState(null);
    const [dashboardLoaded, setDashboardLoaded] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const loadCallback = () => {
        setDashboardLoaded(true);
    }

    useEffect(() => {
        if (dashboardId && dashboardId !== '[object Object]') {
            dashboardGetUrl(dashboardId);
        } else {
            if (dashboardList && dashboardList.length > 0) {
                history.push(`/dashboard/${dashboardList[0].id}`);
            }
        }
        return function cleanup() {
            dashboardUrlReset();
            setExpanded(false);
            setDashboardLoaded(false);
            setQuicksightDashboard(null);
        }
    }, [dashboardId, dashboardGetUrl,dashboardUrlReset]);

    useEffect(() => {
        if (dashboardUrl && !quicksightDashboard && dashboardList.length>0  && doesDashboardBelongsToCurrentClient) {
            const options = {
                url: dashboardUrl,
                parameters: dashboardFilters,
                container: dashboardRef.current,
                loadCallback: loadCallback,
                printEnabled:true,
            }
            const dashboard = embedDashboard(options);
            setQuicksightDashboard(dashboard);
        }
    }, [dashboardUrl, quicksightDashboard, dashboardFilters]);

    useEffect(() => {
        if (dashboardLoaded && dashboardFilters) {
            quicksightDashboard.setParameters(dashboardFilters);
        }
        // eslint-disable-next-line
    }, [dashboardFilters]);

    const onExpansionChange = (event, expanded) => {
        // only load the components when it's first time expanded.
        if (expanded) {
            setExpanded(expanded);
        }
    };

    return (
        <div className="dashboard-page">
            <Helmet title="Dashboard" />
           {canAccessChatbot && <Chatbot />}
            {(dashboardList && dashboardList.length > 0 && doesDashboardBelongsToCurrentClient) ? (
                dashboardUrl ? (
                    <>
                        <Accordion
                            onChange={(event, expanded) => onExpansionChange(event, expanded)}>
                            <AccordionSummary
                                classes={
                                    {
                                        root: classes.summaryRoot,
                                    }
                                }
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                            >
                                <div className={classes.headContainer}>
                                    <Typography className={classes.heading}>{name}</Typography>
                                </div>
                            </AccordionSummary>
                            {(customFilters && expanded) && (
                                <AccordionDetails classes={{ root: classes.controls }}>
                                    <DashboardControls
                                        dashboardId={id}
                                        filters={filters}
                                        setFilter={dashboardSetFilter}
                                        fiscalYear={fiscalYear}
                                    />
                                </AccordionDetails>
                            )}
                        </Accordion>
                        <div className="dashboard-container" ref={dashboardRef} />
                    </>
                ) : (
                     (dashboadUrlPending) && (
                            <Portlet>
                                <PortletBody>
                                    <span>Loading Dashboard</span>
                                    <CircularProgress
                                        className={classes.progress}
                                        color="secondary"
                                    />
                                </PortletBody>
                            </Portlet>
                        )
                    )
            ) : (!dashboadUrlPending && dashboardId && dashboardData?.clientId && currentClient!==dashboardData?.clientId ? 
                <UnauthorizedPage history={history} isClient={true}/> :
                    (!dashboadUrlPending) && (
                        <div className="no-dashboard">
                            <Portlet>
                                <PortletBody>
                                    <h5>No dashboard allocated. Please contact your administrator.</h5>
                                </PortletBody>
                            </Portlet>
                        </div>
                    )
                )}
        </div>
    )
}

export default DashboardPage;