import React from 'react';
import DateRangeControl from './DateRangeControl';
import { PurePtr } from '@pure-ptr/react';

export { default as DateRangeControl } from './DateRangeControl';
import DateRangeIcon from '@mui/icons-material/DateRange';

export type DateRange = {
    startdate: string;
    enddate: string;
    relativeFilter?: string;
};

export type RelativeFilterOption = {
    id: string;
    value: string;
};

export const DateRangePicker = ({ valuePtr, label, disabled, variant = "standard", enabledSwitch = true, relativeFilterOptions = null, disableDateSection = false} :{
    valuePtr : PurePtr<DateRange | null>,
    label : string,
    disabled? : boolean,
    variant? : "outlined" | "filled" | "standard",
    enabledSwitch?: boolean,
    relativeFilterOptions?: RelativeFilterOption[],
    disableDateSection?: boolean
}) => {
    const { value } = valuePtr;

    return (
        <DateRangeControl 
            setDates={(startdate, enddate, enabled, customField, relativeFilter ) => {
                valuePtr.set( enabled ? { startdate, enddate, relativeFilter } : null);
            }} 
            
            style={{
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
            }}

            refresh={function (): void {
            } } 

            variant={variant}
            size="small"
            icon={<DateRangeIcon style={{ alignSelf: 'baseline', color: '#404040' }}/>}
            
            filterName={label} 
            
            fields={[{
                value: 'date',
                label,
            }]} 
            
            customFilter={ value ? [
                { 
                    equator: 'greater_than_or_equals_to',
                    field: 'date',
                    value: value.startdate ?? '',
                    relativeFilter: value.relativeFilter != '' ? value.relativeFilter : null
                },
                { 
                    equator: 'less_than_or_equals_to',
                    field: 'date',
                    value: value.enddate ?? '',
                    relativeFilter: value.relativeFilter != '' ? value.relativeFilter : null
                },
            ] : []} 
            
            hasCustomField={true} 
            
            relativeFilter={value?.relativeFilter ?? ''} 
            
            relativeFilterValue={''} 

            relativeFilterOptions = {relativeFilterOptions}
            
            setRelativeFilter={function (value: string): void {
            } } 
            
            disabled={ disabled }     
            enabledSwitch= { enabledSwitch }  
            disableDateSection = { disableDateSection }
        />
    );
};