import React, { useState } from 'react';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../../partials/content/Portlet';
import { Helmet } from 'react-helmet';
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { PagedTable } from '../../../../common';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AnalysisResultsDialog } from './AnalysisResultsDialog';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BackButton } from '../../../../common/BackButton';
import { ModelResultsStatistics } from './ModelResultsStatistics';
import { ModelResultsTopFilters } from './ModelResultsTopFilters';
import makeStyles from '@mui/styles/makeStyles';
import { useData, useStatePtr } from '@pure-ptr/react';
import { hideOrUnHideModelClaim } from '../../../../store/api/modeling';

const DEFAULT_SORT = [{ id: 'Claimnumber', direction: 'asc' }];

const useStyles = makeStyles(theme => ({
    statisticsContainer:{
        padding:'20px',
        paddingBottom:0
    }
}));

function ModelResultsPage({ intl, history, fields, modelsData, modelName, showHidden, match,
    actions: { modelExecutionsResultLanding, setList, setFilter, setAnalysisDialog }}) {

    const classes = useStyles();
    const params = useParams();
    const modelId = match.params.modelId;

    const [refresh, setRefresh] = useState(Promise.resolve());
    const includeHiddenPtr = useStatePtr(showHidden || false);
    const hideClaimPtr = useStatePtr(null);

    useEffect(() => {

        return function cleanup() {
            setList([]);
        }
    }, [setList]);

    useEffect(() => {

        return function cleanup() {
            setList([]);
        }
    }, [setList]);

    const { isPending } = useData( async () => {
        if(hideClaimPtr.value) {
            await hideOrUnHideModelClaim(hideClaimPtr.value); 
            handleRefresh();
        }
    }, [ hideClaimPtr.value ]);

    function handleLoad(sort, filter, pagination) {
        modelExecutionsResultLanding(pagination, sort, filter, modelId, includeHiddenPtr.value);
        // HACK - stats will be called inside landing API to avoid render.
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    const handleClickColumn = (fieldName, rowData, index) => {
        // setAnalysisDialog({
        //     open: true,
        //     analysisIds: rowData.AnalysisIds
        // });
        history.push(`/cms/modeling/results/view/${rowData.AnalysisIds}`);
    }

    const handleHideClaim = (rowData) => {
        const analysisIds = rowData?.AnalysisIds?.split('/').map(id => id).filter(id=> id).map(id => id.length > 15 ? id : Number(id)) ?? [];
        hideClaimPtr.set({analysisIds: analysisIds, ishidden: !rowData.IsHidden})
    }

    const modelResultsActions = (rowData) => {
        return (
            <>
                <Grid item>
                    <IconButton
                        className='p-1'
                        aria-label="Edit"
                        onClick={() => handleHideClaim(rowData)}
                        size="small">
                        <Tooltip title={rowData?.IsHidden ? 'Hide' : 'Unhide'}>
                        {rowData?.IsHidden ? (
                            <i className="fa fa-eye-slash text-secondary"></i>
                        ) : (
                                <i className="fa fa-eye"></i>
                            )}
                        </Tooltip>
                    </IconButton>
                </Grid>
            </>
        );
    }


    return (
        <>
            <Helmet title="Model Results" />
            <Portlet>
                <Box className={classes.statisticsContainer}>
                    <ModelResultsStatistics />
                </Box>
                <PortletHeader
                    title={`Model Results (${modelName === '' ? modelId : modelName})`}
                    name="model-executions-result-landing"
                    handleRefresh={handleRefresh}
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                    includeAdvanceFilters={true}    
                    shouldNotSetDefault={modelId ? true : false}
                    toolbar={
                        <PortletHeaderToolbar>
                            <ModelResultsTopFilters handleRefresh={handleRefresh} relativeFilter={''} includeHiddenPtr={includeHiddenPtr} />
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <PagedTable
                                name="model-executions-result-landing"
                                title="Model"
                                fields={fields}
                                data={modelsData || []}
                                onLoad={handleLoad}
                                loading={isPending}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                criteriasSaveEnabled={true}
                                showSearchFilters={false}
                                handleClickColumn={handleClickColumn}
                                hasActions={true}
                                renderActionColumn={modelResultsActions}
                            />
                        </Grid>
                    </Grid>
                </PortletBody>
            </Portlet >
        </>
    )
}

export default ModelResultsPage

