import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { setAnalysisDialog } from '../../../../store/actions/modeling';
import { modelExecutionsResultLanding, set } from '../../../../store/actions/pages';
import { CONTRACTED_SERVICE_INPATIENT, CONTRACTED_SERVICE_OUTPATIENT, CONTRACTED_SERVICE_PROFESSIONAL } from '../../../../store/constants/contract';
import { dateEquators, integerEquators, StringEquators } from '../../../../store/constants/pages';
import { createContractModelField, createCurrencyDataField, createPlainDateField, createStringDataField } from '../../../../util/format';
import ModelResultsPageComponent from './ModelResultsPage';


const visitTypes = [
    CONTRACTED_SERVICE_INPATIENT,
    CONTRACTED_SERVICE_OUTPATIENT,
    CONTRACTED_SERVICE_PROFESSIONAL
];

export const ModelResultsPage = connect(
    () => {

        let globalFields = []; // this variable is used to save the table headers--> because if we apply filter and don't get any results, then headers will gone as well which we don't want.
        let globalModelName = ''; // this variable is used to save the table headers--> because if we apply filter and don't get any results, then headers will gone as well which we don't want.

        const fieldsTypeMap = {
            'Claimnumber': {
                type: 'string',
                name: 'Claim Number',
                onclick: true
            },
            'Visit': {
                type: 'dropdown',
                name: 'Visit'
            },
            'Servicedate': {
                type: 'date',
                name: 'Service Date'
            },
            'ChargedAmount': {
                type: 'currency',
                name: 'Charge Amount'
            },
            'Difference': {

            },
            'Rule Name':{
                type:'secondLevel'
            },
            'drg': {
                type: 'string',
                name: 'Drg',
                hideFromGrid:true,
                hideFromFilter: true
            },
            'revcode': {
                type: 'string',
                name: 'RevCode',
                hideFromGrid:true,
                hideFromFilter: true

            },
            'pxcode': {
                type: 'string',
                name: 'PxCode',
                hideFromGrid:true,
                hideFromFilter: true

            },

            
        }

        const selectResultsData = createSelector(
            (state) => state.pages['model-executions-result-landing'].list,
            (data) => {
                return data
            }
        );

        const selectkeys = createSelector(
            (state) => state.pages['model-executions-result-landing'].list,
            (data) => {
                if (data && data.length > 0) {
                    const keys = Object.entries(data[0]).map(([k, v]) => (k));
                    return keys
                }
                return []
            }
        );

        const selectFields = createSelector(
            selectkeys,
            (keys) => {
                if (keys.length === 0 && globalFields.length > 0) {
                    return globalFields;
                }

                const fields = [...keys,'drg','pxcode', 'revcode','Rule Name']
                    .filter(key => key !== 'ModelName' && key !== "ModelId" && key !== "AnalysisIds")
                    .map(key => {
                        if (fieldsTypeMap[key]) {
                            if (key === 'Difference') {
                                return createCurrencyDataField('Difference', 'Difference', { sortable: false, equators: integerEquators, backgroundColor: 'rgb(117 165 228)', width: '150px', hideFromFilter: true })
                            } else if (fieldsTypeMap[key].type === 'dropdown') {
                                return createStringDataField(key, fieldsTypeMap[key].name, { sortable: true, equators: StringEquators, onClick: fieldsTypeMap[key].onclick, isDropdown: true,dropdownValues: visitTypes })
                            }
                            else if (fieldsTypeMap[key].type === 'string') {
                                return createStringDataField(key, fieldsTypeMap[key].name, { sortable: true, equators: StringEquators, onClick: fieldsTypeMap[key].onclick,hideFromGrid:fieldsTypeMap[key]?.hideFromGrid,hideFromFilter:fieldsTypeMap[key]?.hideFromFilter })
                            }
                            else if (fieldsTypeMap[key].type === 'date') {
                                return createPlainDateField(key, fieldsTypeMap[key].name, { sortable: true, equators: dateEquators })
                            } else if (fieldsTypeMap[key].type === 'currency') {
                                return createCurrencyDataField(key, fieldsTypeMap[key].name, { sortable: true, equators: integerEquators })
                            }
                            else if (fieldsTypeMap[key].type === 'secondLevel') {
                                return createStringDataField('rule.name', 'Rule Name', {
                                    sortable: true, equators: StringEquators, secondLevel: 'name', hideFromGrid: true, hideFromFilter: true, reorder: false
                                })
                            }
                        }
                        if(key.includes('-ExpectedPay')) {
                            return createContractModelField(key, key, { sortable: false, equators: integerEquators, backgroundColor: '#13a86e4a', width: '200px', hideFromFilter: true })
                        }
                        return createCurrencyDataField(key, key, { sortable: true, equators: integerEquators, backgroundColor: '#13a86e4a', width: '200px', hideFromFilter: true })

                    });

                if (fields.length > 0) {
                    globalFields = fields;
                }
                return fields;
            }
        );

        const selectModelName = createSelector(selectResultsData,
            (results) => {
                if (results && results.length > 0) {
                    globalModelName = results[0]?.ModelName;
                    return results[0]?.ModelName;
                }
                return globalModelName;
            })

        return (state: any, props) => ({
            fields: selectFields(state),
            modelsData: selectResultsData(state),
            modelName: selectModelName(state),
            showHidden:state.pages['model-executions-result-landing']?.showHidden,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            modelExecutionsResultLanding: modelExecutionsResultLanding,
            setList: (value) => set('model-executions-result-landing', 'list', value),
            setFilter: (value) => set('model-executions-result-landing', 'filter', value),
            setAnalysisDialog: setAnalysisDialog,
        }, dispatch)
    })
)(injectIntl(ModelResultsPageComponent));
