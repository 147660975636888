import { FormControl, Input, InputLabel, MenuItem, Select, TablePagination } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { DataTable, Loadable } from '../../../common';
import { TablePaginationActions } from '../../../common/tablePaginationActions';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import CriteriaDependencyDialog from './CriteriaSettingsDependencyDialog/CriteriaSettingsDependencyDialog'

const CLAIM_ANALYSIS="claimAnalysis-landing"

const useStyles = makeStyles(theme => {
    return {
        underline: {
            textDecoration: 'underline',
        },
        textField: {
            width: 400,
        },
        button: {
            margin: theme.spacing(1),
        },
        formControl: {
            width: 300,
            margin: '0'
        }
    }
});

export default function CriteriaSettings({ searchPending, searchDeletePending, fields, filteredData, order, sort, clientId, filter, searchOptions,
    user,intl,isSuperAdmin,defaultCustomFilters,
    actions: { getSearchCriterias, setFilter, setSort, setOrder, deleteCriteria, resetDeleteState, getUsersTiny,resetSearchCriteria }
}) {
    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = useState(0);
    const [shouldFilterChange, setShouldFilterChange] = useState(true);

    useEffect(() => {
        getUsersTiny(clientId);
    }, [getUsersTiny, clientId]);

    useEffect(() => {
        if (searchOptions.length > 0 && shouldFilterChange) {
            setFilter(searchOptions[0].id);
            setShouldFilterChange(false);
        }
    }, [searchOptions]);

    useEffect(() => {
        getSearchCriterias();
        return () => {
            setFilter('');
            resetDeleteState('');
        }
    }, [getSearchCriterias, setFilter]);

    
    const filterChangeHandler = (event) => {
        setFilter(event.target.value);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCallBack = (searchCriteria) => {

        let filters:any = sessionStorage.getItem("pages");
        filters =filters? JSON.parse(filters):null;

        //If selected filter is removed then reset the localstorage and reduxstorage
        if (filters && filters[CLAIM_ANALYSIS] && filters[CLAIM_ANALYSIS]?.selectedFilter?.id === searchCriteria?.id) {
            sessionStorage.setItem("pages", JSON.stringify({ ...filters,[CLAIM_ANALYSIS]:{...filters[CLAIM_ANALYSIS],selectedFilter: null,customFilter: defaultCustomFilters,
                filter: [],navigationType:{type:'reload'}} }));
            resetSearchCriteria()
        }
        getSearchCriterias();

    }

    const handleDeleteCriteria = (searchCriteria) => {
        if (searchCriteria) {
            deleteCriteria(searchCriteria.id, () =>
                handleCallBack(searchCriteria)
            );
        }
    }

    const handleRefresh = () => {
        getSearchCriterias();
    }

    function criteriaActions(rowData) {
        return (
            (isSuperAdmin || user?.id === rowData?.userId) && (
                <div className="d-flex">
                    <CriteriaDependencyDialog
                        handleDelete={() => handleDeleteCriteria(rowData)}
                        dependenciesLength={rowData?.userPublicSearchCriteriaXref?.length}
                        rowData={rowData}
                    />
                </div>
            )
        );
    }

    return (<>
        <Helmet title="Criteria" />
        <Loadable loading={searchPending || searchDeletePending}>
            <Portlet >
                <PortletHeader
                    title="Search Criteria"
                    toolbar={
                        <PortletHeaderToolbar>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="filter">Select page</InputLabel>
                                <Select
                                    value={filter}
                                    onChange={filterChangeHandler}
                                    input={<Input id="filter" />}
                                    inputProps={{
                                        name: "filter",
                                        id: "filter"
                                    }}
                                >
                                    {searchOptions.map((module) =>
                                        <MenuItem
                                            key={module.id}
                                            value={module.id}
                                        >
                                            {module.name}
                                        </MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <DataTable
                        size="medium"
                        fields={fields}
                        data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}
                        showPagination={true}
                        shouldRenderLazy={true}
                        footerPagination={() => (
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                colSpan={fields.length + 2}
                                count={filteredData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        )}
                        stickyHeader={true}
                        name="criteria settings"
                        handleRefresh={handleRefresh}

                        hasActions={true}
                        renderActionColumn={criteriaActions}
                    />
                </PortletBody>
            </Portlet>
        </Loadable>
    </>);
}