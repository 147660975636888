import React, { useEffect, useState } from "react";
import { Loadable } from '../../../common';
import { ColorPicker } from '../../../common/colorPicker'
import { Formik } from "formik";
import Helmet from 'react-helmet';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import {
  Button,
  Box,
  TextField,
  Grid,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Chip,
  CircularProgress,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { FooterLinkArray } from "./FooterLinkArray";
import { convertDateToLocal } from "../../../util/date";


const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  textField: {
    margin: theme.spacing(1),
    width: '80%'
  },
  label: {
    margin: theme.spacing(1),
  },
  switch: {
    margin: theme.spacing(1),
  },
  cardTop: {
    marginTop: theme.spacing(1),
  },
  textArea: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '80%',
  },
  checkbox: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(0),
  },
  deploymentHeaderText: {
    fontSize: '15px',
    fontWeight: '500',
    marginBottom:'5px'
  },
  urlString:{
    fontSize:'12px'
  },
  underline:{
    textDecoration:'underline'
  }
}));

const TOOLTIP_TEXT_PRIMARY = 'Primary color would be displayed on Buttons etc.'
const TOOLTIP_TEXT_SECONDARY = 'Secondary color would be displayed on Borders, Radio button etc.'

export default function BrandConfigurationPage(props) {

  const {
    brand, brandCreatePending, brandUpdatePending, initialValues, intl, mode, clientBrandId, 
    getNegotiatedRatesSignOffPending, signOffPending, signOff, mrfs, publishMRFloading, deploymentStatus,getDeploymentStatusPending,
    actions: { brandCreate, brandUpdate, getBrand, negotiatedRatesSignOff, getNegotiatedRatesSignOff, publishMRFs,getDeploymentStatus }
  } = props;

  const [openMRFConfirmationDialog, setOpenMRFConfirmationDialog] = useState(false);
  const [openMRFPublishConfirmationDialog, setOpenMRFPublishConfirmationDialog] = useState(false);
  const [openMRFPublishDialog, setOpenMRFPublishDialog] = useState(false);

  useEffect(() => {
    getBrand();
  }, [getBrand]);

  useEffect(() => {
    getNegotiatedRatesSignOff();
  }, [getNegotiatedRatesSignOff]);

  useEffect(() => {
    getDeploymentStatus()
  }, [getDeploymentStatus]);

  const [file, setFile] = useState(null);

  const classes = useStyles();
  const isEdit = brand && brand.brandId;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  }

  function handleAddLink(values, setValues) {
    values.footerLinks.push({
      label: '',
      url: '',
    });
    setValues(values);
  }

  function handleMRFCheckboxChange(value) {
    if(value === true) {
      setOpenMRFConfirmationDialog(true);
    }
  } 

  function handleMRFConfirmation() {
    handleMRFConfirmationClose();
    negotiatedRatesSignOff(handleSignedOffCallback);
  }

  function handleSignedOffCallback() {
    getNegotiatedRatesSignOff();
    setOpenMRFPublishConfirmationDialog(true);
  }

  function handleMRFConfirmationClose() {
    setOpenMRFConfirmationDialog(false);
  }

  function handleCallback() {
    setOpenMRFPublishDialog(true);
  }

  function handleMRFPublishDialogClose() {
    setOpenMRFPublishDialog(false);
  }

  function handleMRFPublishConfirmation() {
    handleMRFPublishConfirmationClose();
    publishMRFs(handleCallback);
  }

  function handleMRFPublishConfirmationClose() {
    setOpenMRFPublishConfirmationDialog(false);
  }

  return (<>
    <Loadable loading={brandCreatePending || brandUpdatePending || getNegotiatedRatesSignOffPending || signOffPending || publishMRFloading }>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {};

          if (!values.logoRedirectionURL) {
            errors.logoRedirectionURL = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.ein) {
            errors.ein = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.cashDiscountPercent) {
            errors.cashDiscountPercent = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (values.isHealthTalkAIEnabled) {
            if (!values.healthTalkClientId) {
              errors.healthTalkClientId = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.healthTalkSecret && !values.isHealthTalkAINew) {
              errors.healthTalkSecret = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.healthTalkAudience) {
              errors.healthTalkAudience = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.healthTalkGrantType) {
              errors.healthTalkGrantType = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }

          const linkErrors = values.footerLinks.map((link) => {
            const linkErrors = {};
            let hasError = false;

            if (!link.label) {
              linkErrors['label'] = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
              hasError = true;
            }

            if (!link.url) {
              linkErrors['url'] = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
              hasError = true;
            }

            if (hasError) {
              return linkErrors;
            }

            return false;
          });

          const filteredArray = linkErrors.filter(value => Object.keys(value).length > 0);

          if (filteredArray.length > 0) {
            errors['footerLinks'] = linkErrors;
          }
          return errors;
        }}
        onSubmit={(values) => {
          let payload = {
            DomainName: clientBrandId,
            ThemeName: values.themeName,
            ThemeMetadata: JSON.stringify(values.themeMetadata),
            LogoRedirectionURL: values.logoRedirectionURL,
            EIN: values.ein,
            cashDiscountPercent: values.cashDiscountPercent,
            FooterLinks: JSON.stringify(values.footerLinks),
            isOutOfPocketCalculationEnabled: values.isOutOfPocketCalculationEnabled,

            isHealthTalkAIEnabled: values.isHealthTalkAIEnabled,
          }

          // send health talk only when enabled.
          if (values.isHealthTalkAIEnabled) {
            payload = {
              ...payload,
              healthTalkClientId: values.healthTalkClientId,
              healthTalkAudience: values.healthTalkAudience,
              healthTalkGrantType: values.healthTalkGrantType,
            }

            if (values.healthTalkSecret !== initialValues.healthTalkSecret) {
              payload = {
                ...payload,
                healthTalkSecret: values.healthTalkSecret,
              }
            }
          }

          const formData = new FormData();
          if (file) {
            formData.append('File', file);
          }
          formData.append('Payload', JSON.stringify(payload));

          if (mode === 'create') {
            brandCreate(formData);
          }
          else {
            brandUpdate(brand.brandId, formData);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Helmet title="Brand Configuration" />
            <Portlet>
              <PortletHeader
                title={isEdit ? 'Brand Configuration' : 'Brand Configuration'}
                toolbar={
                  <PortletHeaderToolbar>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={brandCreatePending || brandUpdatePending}
                    >
                      Save
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={6}>
                      <Card variant="outlined">
                        <CardHeader title="Branding" />
                        <CardContent>
                          <TextField
                            type="file"
                            label="Upload Logo"
                            name="logoUpload"
                            className={classes.textField}
                            margin="normal"
                            onChange={handleFileChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <TextField
                            label="Logo URL"
                            name="logoRedirectionURL"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.logoRedirectionURL}
                            helperText={touched.logoRedirectionURL && errors.logoRedirectionURL}
                            error={Boolean(touched.logoRedirectionURL && errors.logoRedirectionURL)}
                          />
                          <TextField
                            label="EIN"
                            name="ein"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.ein}
                            helperText={touched.ein && errors.ein}
                            error={Boolean(touched.ein && errors.ein)}
                          />
                          <TextField
                            type="number"
                            label="Cash Discount Percentage"
                            name="cashDiscountPercent"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.cashDiscountPercent}
                            helperText={touched.cashDiscountPercent && errors.cashDiscountPercent}
                            error={Boolean(touched.cashDiscountPercent && errors.cashDiscountPercent)}
                          />

                          <FormControlLabel
                            control={
                              <Switch
                                name="isOutOfPocketCalculationEnabled"
                                checked={values.isOutOfPocketCalculationEnabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.isOutOfPocketCalculationEnabled}
                              />
                            }
                            label="Out Of Pocket Calculation"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                name="isHealthTalkAIEnabled"
                                checked={values.isHealthTalkAIEnabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.isHealthTalkAIEnabled}
                              />
                            }
                            label="HealthTalkAI"
                          />
                        </CardContent>
                      </Card>

                      <Card variant="outlined" className={classes.cardTop}>
                        <CardHeader title="Deployment" />
                        <CardContent>

                                   {!getDeploymentStatusPending  ?  
                                    <Grid container>
                                        <Grid item xs={8}> 
                                            {deploymentStatus?.deployed ? 
                                              <a className={`${classes.urlString} ${classes.underline}`} target="_blank" href={deploymentStatus?.deploymentUrl || ''} >
                                                {deploymentStatus?.deploymentUrl || 'NA'}
                                              </a> :
                                              <span className={classes.urlString}>{deploymentStatus?.deploymentUrl || 'NA'}</span> }
                                        </Grid>
                                        <Grid item xs={4} sx={{textAlign:"right"}}>
                                              <Chip
                                                label={deploymentStatus?.deployed ? 
                                                  'DEPLOYED':
                                                  'PENDING'}
                                                  sx={{
                                                    color:"white",
                                                    borderRadius: '5px',
                                                    height: '18px', 
                                                    fontWeight: '400', 
                                                    paddingTop: '1px',
                                                    background:deploymentStatus?.deployed ? '#95c83e' :'#5c79ff' 
                                                  }}
                                                  
                                              />

                                        </Grid>

                                    </Grid>:
                                    <CircularProgress 
                                      size={25}
                                      color="secondary"
                                      className="loading-overlay-image loading-overlay-image-loading" />
                                      
                                      }
                        </CardContent>
                        </Card>
                      <Card variant="outlined" className={classes.cardTop}>
                        <CardHeader title="Footer Links"
                          action={
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.button}
                              onClick={() => handleAddLink(values, setValues)}
                            >
                              Add
                            </Button>
                          }
                        />
                        <CardContent>
                          <FooterLinkArray
                            footerLinks={values.footerLinks}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                      <Card variant="outlined">
                        <CardHeader title="Color Selection" />
                        <CardContent>
                          <Grid container>
                            <Grid item xs={12} md={4}>
                              <InputLabel className={classes.label}>Primary Color</InputLabel>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <ColorPicker
                                tooltip={TOOLTIP_TEXT_PRIMARY}
                                value={values.themeMetadata.primary}
                                onChange={(color) => setFieldValue("themeMetadata.primary", color)}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12} md={4}>
                              <InputLabel className={classes.label}>Secondary Color</InputLabel>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <ColorPicker
                                tooltip={TOOLTIP_TEXT_SECONDARY}
                                value={values.themeMetadata.secondary}
                                onChange={(color) => setFieldValue("themeMetadata.secondary", color)}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                      {signOff && (
                        <Card variant="outlined" className={classes.cardTop}>
                          <CardHeader title="Latest MRF Template Information" />
                          <CardContent>
                            <Grid container>
                              <Grid item xs={12} md={4}>
                                <InputLabel className={classes.label}>MRF Date </InputLabel>
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <Typography className={classes.label}>{signOff?.mrfDate ? convertDateToLocal(signOff?.mrfDate) : ''} </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12} md={4}>
                                <InputLabel className={classes.label}>MRF Version </InputLabel>
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <Typography className={classes.label}>{`2.0.0`} </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12} md={4}>
                                <InputLabel className={classes.label}>Last Updated </InputLabel>
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <Typography className={classes.label}>{signOff?.signedOffUpdatedOn ? convertDateToLocal(signOff?.signedOffUpdatedOn) : ''} </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12} md={4}>
                                <InputLabel className={classes.label}>Last Updated By </InputLabel>
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <Typography className={classes.label}>{signOff?.signedOffUpdatedBy ?? ''} </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12} md={4}>
                                <InputLabel className={classes.label}>Sign off </InputLabel>
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <Typography className={classes.label}>{`To the best of its knowledge and belief, the hospital has included all applicable standard charge information in accordance with the requirements of 45 CFR 180.50, and the information encoded is true, accurate, and complete as of the date indicated.`} </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12} md={12}>
                                <FormControlLabel
                                  className={classes.checkbox}
                                  control={
                                      <Checkbox
                                          disabled={Boolean(signOff?.signedOff === 'true' ? true : false)}
                                          checked={Boolean(signOff?.signedOff === 'true' ? true : false)}
                                          onChange={((event) => handleMRFCheckboxChange(event.target.checked))}
                                          name='signedOff'
                                          color="primary"
                                      />
                                  }
                                  label="I accept the MRF updates"
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      )}
                      {values.isHealthTalkAIEnabled && (
                        <Card variant="outlined" className={classes.cardTop}>
                          <CardHeader title="HealthTalkAI Configuration" />
                          <CardContent>
                            <TextField
                              label="Client Id"
                              name="healthTalkClientId"
                              className={classes.textField}
                              margin="normal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.healthTalkClientId}
                              helperText={touched.healthTalkClientId && errors.healthTalkClientId}
                              error={Boolean(touched.healthTalkClientId && errors.healthTalkClientId)}
                            />
                            <TextField
                              type="password"
                              label="Secret"
                              name="healthTalkSecret"
                              className={classes.textField}
                              onFocus={() => setFieldValue("healthTalkSecret", '')}
                              margin="normal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.healthTalkSecret}
                              helperText={touched.healthTalkSecret && errors.healthTalkSecret}
                              error={Boolean(touched.healthTalkSecret && errors.healthTalkSecret)}
                            />
                            <TextField
                              label="Audience"
                              name="healthTalkAudience"
                              className={classes.textField}
                              margin="normal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.healthTalkAudience}
                              helperText={touched.healthTalkAudience && errors.healthTalkAudience}
                              error={Boolean(touched.healthTalkAudience && errors.healthTalkAudience)}
                            />
                            <TextField
                              disabled
                              label="Grant Type"
                              name="healthTalkGrantType"
                              className={classes.textField}
                              margin="normal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.healthTalkGrantType}
                              helperText={touched.healthTalkGrantType && errors.healthTalkGrantType}
                              error={Boolean(touched.healthTalkGrantType && errors.healthTalkGrantType)}
                            />
                          </CardContent>
                        </Card>
                      )}

                    </Grid>
                  </Grid>
                </div>
              </PortletBody>
            </Portlet>
          </form>
        )}
      </Formik>
    </Loadable>
    <Dialog open={openMRFConfirmationDialog} onClose={handleMRFConfirmationClose}>
      <DialogTitle>
          Confirmed SIGN OFF?
      </DialogTitle>
      <DialogContent>
          <DialogContentText>
              {`Are you sure you want to Sign off?`}
          </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleMRFConfirmationClose}>
              Cancel
          </Button>
          <Button
              onClick={handleMRFConfirmation}
              color="primary">
              Sign off
          </Button>
      </DialogActions>
    </Dialog>
    <Dialog maxWidth='sm' fullWidth open={openMRFPublishConfirmationDialog && !getNegotiatedRatesSignOffPending} onClose={handleMRFPublishConfirmationClose}>
      <DialogTitle className='py-3'>
          Publish MRFs?
      </DialogTitle>
      <DialogContent dividers>
          <DialogContentText>
            <Typography>You signed off successfully!</Typography>
            <Typography>{`Do you want to publish the MRFs?`}</Typography>
          </DialogContentText>
      </DialogContent>
      <DialogActions className='p-4'>
          <Button variant="contained" onClick={handleMRFPublishConfirmationClose}>
              Cancel
          </Button>
          <Button
              variant="contained"
              onClick={handleMRFPublishConfirmation}
              color="primary">
              Publish
          </Button>
      </DialogActions>
    </Dialog>
    <Dialog maxWidth='sm' fullWidth open={openMRFPublishDialog}>
      <DialogTitle className='py-3'>
          MRFs
      </DialogTitle>
      <DialogContent dividers>
          <Grid>
              {mrfs.map(item => <Grid spacing={2} container className='pt-1'>
                  <Grid item xs={4}>
                      <b>{item.file}</b>
                  </Grid>
                  <Grid item xs={8}>
                      {item.status}
                  </Grid>
              </Grid>
              )}
          </Grid>
      </DialogContent>
      <DialogActions className='p-4'>
          <Button variant="contained" onClick={handleMRFPublishDialogClose}>
              Close
          </Button>
      </DialogActions>
    </Dialog>
  </>);
}
