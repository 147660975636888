import { Box, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useMemo } from "react";
import { useData } from "@pure-ptr/react";
import { pageStore, renderHoverLink, useGlobalStore, XDataGrid } from "../../common";
import { CONTRACTED_SERVICE_INPATIENT, CONTRACTED_SERVICE_OUTPATIENT, CONTRACTED_SERVICE_PROFESSIONAL } from "../../store/constants/contract";
import { StringEquators, integerEquators, dateEquators, BooleanEquators } from "../../store/constants/pages";
import { asDate } from "../../util/date";
import { createClaimAnalysisPatientNameField,createClaimAnalysisSubscriberNameField, createClaimAnalysisPhysicianNameField, createClaimAnalysisStatusField, createClaimNumberFieldWithCopyButton, createCurrencyDataField, createDateField, createIconDataField, createNumberDataField, createPlainDateField, createStringDataField, createCommentsField, createPercentageDataField } from "../../util/format";
import { getPlaceOfServiceCodes } from "../../store/api/claimAnalysis";
import TriagePopoverLink from "./TriagePopoverLink";

export const TriageDataGrid = ({ paginationFilters, selectedClaimsIdsPtr, loading, data, handleSingleFlag, handleAcknowledge,showSearchFilters, handleHideClaim,
    onLoad, refresh,defaultCustomFilter
 }) => {
    
    const columnDefs = useColumnDefinitions();

    const actions = params => [
        <GridActionsCellItem
            icon={
            <Tooltip title={params.row.isHiddenFromDashboard ? 'Hide from Dashboard' : 'Unhide from Dashboard'}>
                {params.row.isHiddenFromDashboard ? (
                    <i className="fa fa-eye-slash text-secondary"></i>
                ) : (
                    <i className="fa fa-eye"></i>
                )}
            </Tooltip>
            }
            label="Hide Dashboard"
            onClick={() => handleHideClaim(params.row.isHiddenFromDashboard, params.row)}
        />
    ];

    return <>
        <XDataGrid
            fields={columnDefs}
            actions={actions}

            data={data}
            loading={loading}

            selectedIdsPtr={selectedClaimsIdsPtr}
            showSearchFilters={showSearchFilters}
            paginationFilters={ paginationFilters }

            onLoad={onLoad}
            refresh={refresh}
            defaultCustomFilter={defaultCustomFilter}

            name="triage-landing"
            defaultSort={DEFAULT_SORT}
            defaultHiddenFields={DEFAULT_HIDDEN_FIELDS}
            criteriasSaveEnabled={false}
        />
    </>
}

const DEFAULT_SORT = [{ id: 'totalSummaryVariance', direction: 'desc' }];
const DEFAULT_HIDDEN_FIELDS = [];
const { usePageStore } = pageStore('triage-landing');

const useColumnDefinitions = () => {
    const tinyContracts = useGlobalStore( x => x.contracts.tiny );
    const recoveredAmountFlag = useGlobalStore( x => x.settings.recoveredAmountFlag );
    const claimStatuses = useGlobalStore( x => x.claimAnalysis.claimStatuses ?? [] );
    const facilities = useGlobalStore( x => x.facilities.data ?? [] );
    const confidenceMeasuresList = useGlobalStore( x => x.claimAnalysis.confidenceMeasuresList ?? [] );

    const { data:placeOfServiceCodes, isPending, error } = useData( async () => {        
        const placeOfServiceCodes = await getPlaceOfServiceCodes();
        return placeOfServiceCodes
    }, []);
    
    const npiRoles = useGlobalStore( x => 
        x.npiRoles.data?.map(
            item => ({ 
                label: item?.name, 
                value: item?.name, 
                name: item?.name 
            })
        )
    );
    
    const paidStatus = usePageStore( x => 
            x.customFilter?.find((item) => item?.field === "isPaid")
        ),
        isUnpaid = paidStatus?.value === "false";

        const renderConfidenceScoreHoverLink = ( hoverData, titleSubstring = false ) => {
            return (params) => (
                params.value !== null ?
                (<TriagePopoverLink
                    columnData={params.value}
                    title={''}
                    hoverData={ hoverData( params.row )}
                />) : 'N/A'
                )
        }

    return useMemo(() => {
        const fields = [
            createClaimNumberFieldWithCopyButton('claimnumber', 'Claim Number', { returnWholeRow: true, sortable: true, equators: StringEquators, group: 'Claim Information', positionFixed: true, reorder: false, customCell: true, hideable: false, isFromTriage: true  }),
            createNumberDataField('confidenceScore', 'Confidence Score %', { sortable: true, equators: integerEquators, group: 'Claim Information',
                renderCell: renderConfidenceScoreHoverLink(
                    row => row?.measureXref ?? []
                )
            }),
            createCurrencyDataField('totalSummaryVariance', 'Total Summary Variance', { sortable: true, equators: integerEquators, labelStyle: 'label label-lg label-inline', group: 'Claim Information', hideFromFilter: isUnpaid }),
            createCurrencyDataField('totalDetailVariance', 'Total Detail Variance', { sortable: true, equators: integerEquators, labelStyle: 'label label-lg label-inline', group: 'Claim Information', hideFromFilter: isUnpaid }),
            createPlainDateField('serviceDate', 'Service Date', { sortable: true, equators: dateEquators, group: 'Claim Information', whiteSpace: 'pre' }),
            createPlainDateField('remitDate', 'Remit Date', { sortable: true, equators: dateEquators, group: 'Claim Information', whiteSpace: 'pre', hideFromFilter: isUnpaid }),
            createStringDataField('contractName', 'Contract Name', { 
                sortable: true, 
                equators: StringEquators, 
                onHover: true, 
                group: 'Claim Information', 
                isDropdown: true, 
                dropdownValues: tinyContracts.map(contract => contract.name), 
                width: '100px',

                renderCell: renderHoverLink(
                    row => [
                        {
                            label: "Name",
                            data: row?.contractName,
                        }, {
                            type: "LINK",
                            label: "Go to Contracts",
                            data: `/cms/contract/view/${row?.contractId}`,
                        },
                    ]
                )
            }),
            //createStringDataField('client', 'Client', { sortable: true, equators: StringEquators, group: 'Claim Information', reorder: false }),
            createStringDataField('facilityName', 'Facility',{sortable: true,hideFromFilter:true,equators: StringEquators, group: 'Claim Information', reorder: false}),
            createStringDataField('facilityId', 'Facility', { equators: BooleanEquators, hidden: true, group: 'Claim Information', isStrictDropdown:true, dropdownValues: facilities?.map((item)=>({label:item?.name,value:item?.id,name:item?.name})) }),
            createStringDataField('key', 'Confidence Measures', { equators: StringEquators, hidden: true, group: 'Claim Information', isStrictDropdown:true, dropdownValues: confidenceMeasuresList?.map((item)=>({label:item?.displayName,value:item?.key,name:item?.displayName})) }),
            
        ];

        return fields;
    }
    , [isUnpaid, claimStatuses, npiRoles, recoveredAmountFlag, tinyContracts ]);
};

const visitTypes = [
    CONTRACTED_SERVICE_INPATIENT,
    CONTRACTED_SERVICE_OUTPATIENT,
    CONTRACTED_SERVICE_PROFESSIONAL
];

const denailDropdownValues = [{
    label: 'Yes',
    value: 'true'
}, {
    label: 'No',
    value: 'false'
}]