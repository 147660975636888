import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { activateClient, bucketAvailability, create, deactivateClient, ftpUserAvailability, get, getParsers, listTiny, resetBuckets, update } from '../../../../store/actions/clients';
import { STATES, TIMEZONES } from '../../../../store/constants/clients';
import { createClientSelector, selectBucketValidity, selectFtpUserValidity, selectParserList } from '../../../../store/selectors/clients';
import { selectIsSuperAdmin } from '../../../../store/selectors/session';
import { asIsoDate, getFiscalDay, getFiscalMonth } from '../../../../util/date';
import ClientsEditPageComponent from './ClientsEditPage';

let bucket = {
    name: "",
    parser: "",
    description: "",
    ftpUser: {
        name: "",
        password: "",
        confirmPassword: "",
        hasPassword:true,
        publicKey:"",
        allowedIp:"0.0.0.0/0,34.239.213.117/32"
    }
}

export const ClientsEditPage = connect(
    () => {
        const selectClientId = (state, props) => props.match.params.clientId;

        const selectMode = (state, props) => props.match.params.clientId === 'new' ? 'create' : 'edit';

        const selectClient = createClientSelector(selectClientId);

        function getFormattedTimezone(timezone, timezones) {
            const foundZone = timezones.find(item => item.id === timezone);
            return foundZone ?? null
        }

        function getFormattedState(state, states) {
            const foundState = states.find(item => item.abbreviation === state);
            return foundState ?? null
        }

        const selectInitialValues = createSelector(
            selectMode,
            selectClient,
            (mode, client) => {
                if (mode === 'edit' && client) {
                    return {
                        clientId: client.clientId,
                        name: client.name,
                        licenseNumber: client.licenseNumber,
                        displayName: client.displayName,
                        bucketName: client.bucketName,
                        schema: client.schema,
                        parserName: client.parserName,
                        goLive: asIsoDate(client.goLive),
                        address: client.address || '',
                        contact: client.contact || '',
                        buckets: client.buckets?.map((item)=>({
                            ...item,
                            ftpUser:{
                                ...item?.ftpUser,
                                newPassword:'',
                                confirmNewPassword:'',
                                password:item?.ftpUser?.hasPassword ? item?.ftpUser?.password : ""
                            }})) || [],
                        maxUsers: client.maxUsers || 0,
                        fiscalDay: getFiscalDay(client.fiscalYear),
                        fiscalMonth: getFiscalMonth(client.fiscalYear),
                        timeZone: getFormattedTimezone(client.timeZone, TIMEZONES),
                        state: getFormattedState(client.state, STATES),

                        hasDashboardAccess: client.products?.hasDashboardAccess ?? false,
                        hasCMSAccess: client.products?.hasCMSAccess ?? false,
                        hasCMSRCMStatusReportAccess:client.products?.hasCMSRCMStatusReportAccess ?? false,
                        hasEstimatorAccess: client.products?.hasEstimatorAccess ?? false,
                        hasPricerAccess: client.products?.hasPricerAccess ?? false,
                        hasVendorManagementAccess: client.products?.hasVendorManagementAccess ?? false,
                        useNewFTP:client?.useNewFTP
                    }
                }


                return {
                    name: '',
                    licenseNumber:'',
                    displayName: '',
                    schema: '',
                    goLive: asIsoDate(),
                    address: '',
                    contact: '',
                    maxUsers: 5,
                    fiscalMonth: '01',
                    fiscalDay: '01',
                    buckets: [{
                        ...bucket
                    }],
                    timeZone: null,
                    state: null,

                    hasDashboardAccess: false,
                    hasCMSAccess: false,
                    hasCMSRCMStatusReportAccess: false,
                    hasEstimatorAccess: false,
                    hasPricerAccess: false,
                    hasVendorManagementAccess: false,
                    useNewFTP:true
                }
            }
        );

        const selectClientStatus = createSelector(selectClient, (client) => client?.active ?? false);

        return (state, props) => ({
            //create client
            clientsCreatePending: state.clients.create.pending,
            clientsCreateError: state.clients.create.error,
            clientsCreateErrorMessage: state.clients.create.errorMessage,
            bucket: bucket,
            bucketValidity: selectBucketValidity(state),
            ftpUserValidity: selectFtpUserValidity(state),

            // update client
            clientsUpdatePending: state.clients.update.pending,
            // activate/ deactivate
            clientsActivatePending: state.clients.activate.pending,
            clientsDeactivatePending: state.clients.deactivate.pending,
            clientGetPending: state.clients.get.pending,

            // edit
            client: selectClient(state,props),
            isActive: selectClientStatus(state,props),
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            clientId: selectClientId(state, props),
            isSuperAdmin: selectIsSuperAdmin(state),
            //parser list
            parserList: selectParserList(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            clientCreate: create,
            clientUpdate: update,
            clientGet: get,
            getParsers: getParsers,
            bucketAvailability: bucketAvailability,
            ftpUserAvailability: ftpUserAvailability,
            resetBuckets: resetBuckets,
            deactivateClient: deactivateClient,
            activateClient: activateClient,
            getClientsTiny: listTiny,
        }, dispatch)
    })
)(injectIntl(ClientsEditPageComponent));