import {
    Chip,
    Grid,
    Paper,
    Tooltip,
    Typography,
    Box,
    Popover,
    Popper
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from "formik";
import React, { useEffect, useState } from 'react';
import SaveCriteriaDialog from './SaveCriteriaDialog';
import { isArray } from 'lodash';
import { FIELD_TYPE_DATE, FIELD_TYPE_INT, FIELD_TYPE_STRING } from '../../store/constants/pages';
import { PopoverLink } from '../popoverLink';
import { SplitButton } from '../splitButton';
import AlphaSearchFields from './AlphaSearchFields';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DataTableHeaderFilters } from './DataTableHeaderFilters/DataTableHeadersFilters';


const operatorEmums={
    'Greater than':'>',
    'Equal To':'is',
    'Not Equal To':'is not',
    "Contains":"in",
    "Does not Contain":"not in",
    "Less than":"<"


}
const useStyles = makeStyles(theme => ({
    formControl: {
        width: '93%',
    },
    input: {
        marginTop: theme.spacing(2),
        //marginLeft: theme.spacing(2),
        width: '90%',
    },
    iconButton: {
        marginTop: theme.spacing(1),
        padding: 10,
    },
    autocomplete: {
        width: '90%',
    },
    rootChip: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    rootChipPrimaryFilters: {
        display: 'flex',
        justifyContent: 'left',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        maxHeight: 120,
        flexWrap: "wrap"
    },

    button: {
        marginTop: theme.spacing(2),
        //marginLeft: theme.spacing(1),
    },

    deleteButton: {
        marginTop: theme.spacing(2),
        //marginLeft: theme.spacing(2),
        color: '#d9534f',
        height: '31.91px'
    },
    saveButton: {
        marginTop: theme.spacing(2),
    },
    splitButton: {
        marginTop: theme.spacing(2),
    },
    searchButton: {
        marginTop: theme.spacing(2),
        width: '120px',
        background: "#196540",
        padding: 6,
    },
    groupName: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '2',
        pointerEvents: 'none',
        color: 'black',
        background: 'white'
    },
    drawer: {
        '& .MuiDrawer-paper': {
            width: '40%',
            background: '#F8F9FA',
            padding: "10px 20px 10px 20px",


        },
    },
    card: {
        padding: '0 20px 20px 20px',
        borderRadius: "10px",
        border:"none"

    },
    cardHeader: {
        fontWeight: 500,
        paddingBottom: '10px',
        borderBottom: '0.5px solid #6B7280',
        color: '#6B7280',
        marginBottom: '15px'

    },
    formContainer: {
        // marginTop: '15px'
    },
    filterChip: {
        color: '#196540',
        borderColor: '#196540',
        '& .MuiSvgIcon-root': {
            color: '#196540'
        }
    },
    customFilterChip: {
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    disabledAddBtn: {
        opacity: 0.5
    },
    noBorder: {
        border: 'none'
    },
    closePanelBtn: {
        height: '30px',
        width: '30px',
        cursor: 'pointer'
    },
    additionalFiltersIcon: {
        '& .MuiSvgIcon-root': {
            fontSize: '40px',
            color: '#196540'
        }
    },
    closeBtnContainer: {
        display: "flex",
        justifyContent: 'flex-end'

    },
    submitBtnContainer: {
        display: "flex", justifyContent: 'flex-end', width: '100%', columnGap: '10px'

    },
    helperTextSelect: {
        fontSize: "10px", color: "red"
    },
    popover:{
        '& .MuiPaper-elevation': {
        width:"400px"
        }

    },
    hoverPopper:{
        boxShadow:"0 0 50px 0 rgba(82, 63, 105, 0.15)",padding:"20px",background:'white',width:"400px",zIndex:1000000000000000
    },
    filtersBubble:{
        cursor:"pointer",fontWeight:600,color:"#196540",fontSize:"small"
    },
    adjustableGrid:{
        display:"grid",gridTemplateColumns:'repeat(2,minmax(80px,auto))',gap:"10px"
    }
}));

const nullItemDropdown = { name: 'none', value: 'none', label: 'none' }

const actions = ['Search', 'Save', 'Save As'];
export interface Field {
    id: string;
    label: string;
    xDataField?: GridColDef & { valueOptions?: string[] };  
    renderString? : (value: any) => any;
    options: {
        validate?: (value: any) => string | undefined;
        reorder?: boolean;
        totals?: boolean;
        width?: number;
        sortable?: boolean;
        editable?: boolean | ((value: any) => boolean);
        hideFromGrid?: boolean;
        valueOptions?: string[];
        hideable?: boolean;
        renderCell?: (value: any) => any;
        returnWholeRow?: boolean;
        group?: string;
        hideFromFilter?: boolean;
        equators?: { equatorArr: { value: string; label: string }[]; type: string };
        isDropdown?: boolean;
        isStrictDropdown?: boolean;
        dropdownValues?: { value: string; label: string }[];
        isMultiSelection?: boolean;
    };
}

export interface ColumnSortState {
    id: string;
    orderBy: 'asc' | 'desc';
}

export interface DataTableHeaderProps {
    fields: Field[];
    intl: any;
    onFilterChange: (newFilter: any, isAddFilter: boolean) => void;
    onFilterDelete: (f: any, index: number) => void;
    pending: boolean;
    userSessionId?:any;
    customFilter: any[];
    defaultCustomFilters:any;
    pageName: string;
    significance: any;
    onFilterClear: () => void;
    filter: any[];
    onFilterSave: (criteria: any, handleClose: () => void, existingCriteria: any) => void;
    filterSaveState: any;
    criteria: any;
    saveEnabled: boolean;
    onFilterUpdate: (newFilterObj: any, updatingFieldIndex: number | null) => void;
    openFiltersPanel: boolean;
    anchorEl:null | HTMLElement;
    filtersAnchorEl: null | HTMLElement;
    actions: { setOpenAdvanceFiltersDialog: (open: boolean,anchorEl?:null | HTMLElement,filtersAnchorEl?: null | HTMLElement) => void    };
}

export default function DataTableHeader({
    fields,
    intl,
    onFilterChange,
    onFilterDelete,
    pending,
    customFilter,
    pageName,
    significance,
    onFilterClear,
    filter,
    onFilterSave,
    filterSaveState,
    criteria,
    saveEnabled,
    onFilterUpdate,
    openFiltersPanel,
    anchorEl,
    defaultCustomFilters,
    userSessionId,
    filtersAnchorEl,
    actions: { setOpenAdvanceFiltersDialog }
}: DataTableHeaderProps) {
    const classes = useStyles();
    const [equators, setEquators] = useState([]);
    const [open, setOpen] = useState(false);
    const [searchAction, setSearchAction] = useState('Search');
    const [groupedFields, setGroupedFields] = useState([]);
    const [areFieldsGrouped, setAreFieldsGrouped] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);
    const [isMultiSelect, setIsMultiSelect] = useState(false);
    const [isStrictDropdown, setIsStrictDropdown] = useState(false);
    const [dropdownValues, setDropdownValues] = useState([]);
    const [isFilterUpdate, setFilterUpdate] = useState(false);
    const [updatingFieldIndex, setUpdateFieldIndex] = useState(null);
    const [isFiltersDefaultSliced, setIsFiltersDefaultSliced] = useState(true);

    interface FormValues {
        field: string;
        equator: string;
        search: string;
        isBetween?: boolean;
        startsAt?: string;
        endsAt?: string;
        addFilter?: boolean;
    }

    const [formValues, setFormValues] = useState<FormValues>({ field: '', equator: '', search: '', isBetween: false, startsAt: '', endsAt: '', addFilter: false });
    const [fieldType, setFieldType] = useState(FIELD_TYPE_STRING);


    const openPopover = Boolean(anchorEl);
    const openFiltersPopover = Boolean(filtersAnchorEl);

    const id = openPopover  ? 'simple-popover' : undefined;
    const idPopper = openFiltersPopover ? 'transition-popper' : undefined;


    let setField = null;

    useEffect(() => {
        const groupFields = fields.filter(field => field.options.group).map(field => field.options.group);
        const groupNames = Array.from(new Set(groupFields));
        if (groupNames && groupNames.length > 0) {
            const tempFields = groupNames.map(groupName => {
                return {
                    groupName: groupName,
                    fields: fields.filter(field => field.options.group === groupName)
                }
            })
            setAreFieldsGrouped(true)
            setGroupedFields(tempFields)
        }

    }, [fields]);

    useEffect(() => {
        return () => {
            setOpenAdvanceFiltersDialog(false,null,null)
        };

    }, []);


    function handleDelete(f, index) {
        setFormValues({
            field: '', equator: '', search: ''
        });

        setFilterUpdate(false);
        setUpdateFieldIndex(null);
        onFilterDelete(f, index);

    }

    function handleFilterChange(newFilter, isAddFilter) {
        onFilterChange(newFilter, isAddFilter);
    }

    function handleFilterClear() {
        onFilterClear();
    }

    const slicedFilters = (filters) => (isFiltersDefaultSliced && filters?.length>4 ? filters?.reverse()?.slice(0,4) : filters?.reverse())
    

    function getLabel({ field, value, equator }) {
        const isContainsEquator=equator==="like" || equator==="does_not_contain"
        const { label, options } = fields?.find(x => x.id === field) || {};
        let extractedEquator = null;
        let extractedValue = null;
        if (options) {
            const { equators: { equatorArr } } = options;
            if (equatorArr && isArray(equatorArr)) {
                extractedEquator = equatorArr?.find(eq => eq.value === equator);
            }
            if (options.isStrictDropdown) {
                extractedValue = options.dropdownValues?.find(x => x.value === value);
            }
        }
        if (extractedEquator) {
            if (extractedValue) {


                return {
                    name:isContainsEquator ? extractedValue.label: label,
                    equator:operatorEmums[extractedEquator.label] || extractedEquator.label,
                    value:isContainsEquator ? label:extractedValue.label

                }

            }

            return {
                name:isContainsEquator ? value: label,
                equator:operatorEmums[extractedEquator.label] || extractedEquator.label,
                value:isContainsEquator ? label:value

            }
        }

        // fallback if we don't get the equator.
        return {
            name:isContainsEquator ? value: label,
            equator:operatorEmums[equator] || equator,
            value:isContainsEquator ? label:value

        }
    }

    const getEquators = (value) => {
        const { options } = fields.find(field => field.id === value);
        if (options) {
            const { equators: { equatorArr, type } } = options;
            if (equatorArr && isArray(equatorArr)) {
                setEquators(equatorArr);
                setField('equator', equatorArr[0]?.value);
                setField('isBetween', equatorArr[0]?.value === 'alpha_between' || equatorArr[0]?.value === 'in_between' ? true : false);
                setFieldType(type);
            }

            // set isDropdown flag and array
            if ((options.isDropdown || options.isStrictDropdown) && options.dropdownValues) {
                const nullableDropdownValue = options.isStrictDropdown ? nullItemDropdown : 'none'
                setDropdownValues([nullableDropdownValue, ...options.dropdownValues.sort()])
                setIsDropdown(options.isDropdown ? true : false);

                setIsStrictDropdown(options.isStrictDropdown ? true : false);
                setIsMultiSelect(options?.isMultiSelection ? true : false)
            } else {
                setIsMultiSelect(false)
                setIsDropdown(false);
                setIsStrictDropdown(false);
                setDropdownValues([]);
            }
        }
    }

    const bindSetFeild = (setFieldValue) => {
        setField = setFieldValue;
    };

    const handleSaveSearch = (values, handleClose, existingCriteria) => {
        let criteria;
        if (pageName === 'claimAnalysis-landing') {
            let tempFilters = [];
            const keys = ["acknowledged", "flag", "isDenial", "isPaid", "contract.contractState.name"];

            for (let key of keys) {
                const foundItem = customFilter.find(item => item.field === key);
                if (foundItem) {
                    tempFilters.push({
                        field: key,
                        equator: 'equals',
                        value: foundItem.value // true || false
                    });
                } else {
                    tempFilters.push({
                        field: key,
                        equator: 'equals',
                        value: 'both'
                    });
                }
            }

            const dates = customFilter.filter(cf => cf.field === 'remitdate' || cf.field === 'servicedate');

            if (dates.length > 0) {
                tempFilters.push({
                    field: dates[0]?.field,
                    type: 'DATE',
                    relativeFilter: dates[0]?.relativeFilter,
                    dates: [
                        { equator: dates[0].equator, value: dates[0].value },
                        { equator: dates[1].equator, value: dates[1].value },
                    ]
                });
            }

            tempFilters.push({
                field: 'significance',
                type: 'SIGNIFICANCE',
                value: significance
            });

            criteria = {
                criteria: JSON.stringify(
                    [
                        ...filter.map(item => ({ ...item, actualFilter: true })),
                        ...tempFilters.map(item => ({ ...item, customFilter: true }))
                    ]),
                ...values,
            }
        } else {
            criteria = {
                criteria: JSON.stringify([...filter.map(item => ({ ...item, actualFilter: true }))]),
                ...values,
            }
        }

        onFilterSave(criteria, handleClose, existingCriteria);
    }

    const handleClose = () => {
        setOpen(false);
        setSearchAction("Search")
    }

    const onMenuClick = (option, callback) => {
        setSearchAction(option);
        setOpen(true);

        if (callback) {
            callback();
        }
    }

    const handleUpdateClick = (f, index) => {
        if (f.equator === 'alpha_between' || f.equator === 'in_between') {
            const splitedValue = f.value.split('&');
            setFormValues({
                field: f.field,
                equator: f.equator,
                search: '',
                startsAt: splitedValue[0],
                endsAt: splitedValue[1],
                isBetween: true,
                addFilter: false
            });
        } else {
            setFormValues({
                field: f.field,
                equator: f.equator,
                search: f.value,
                isBetween: false,
                startsAt: '',
                endsAt: '',
                addFilter: false
            });
        }

        getEquators(f.field);

        setFilterUpdate(true);
        setUpdateFieldIndex(index);
    }

    const handleUpdate = (values) => {
        let newFilterObj = {
            field: values.field,
            equator: values.equator,
            value: ''
        }
        if (values.isBetween) {
            newFilterObj = {
                ...newFilterObj,
                value: `${values.startsAt}&${values.endsAt}`,
            }
        } else {
            newFilterObj = {
                ...newFilterObj,
                value: values.search,
            }
        }
        onFilterUpdate(newFilterObj, updatingFieldIndex);

        setFilterUpdate(false);
        setFormValues({
            field: '',
            equator: '',
            search: '',
            startsAt: '',
            endsAt: ''
        });
        setUpdateFieldIndex(null);
    }

    const handleCancelUpdate = () => {
        setFilterUpdate(false);
        setFormValues({
            field: '',
            equator: '',
            search: '',
            startsAt: '',
            endsAt: ''
        });
        setUpdateFieldIndex(null);
    }

    const handleEquatorChange = (setFieldValue) => (e) => {
        const eqValue = e.target.value;
        setFieldValue('equator', eqValue);
        setFieldValue('isBetween', eqValue === 'alpha_between' || eqValue === 'in_between' ? true : false);
    }

    const handleFieldChange = (setFieldValue, value) => {
        const fieldArr = [...fields]
        const fieldType = fieldArr?.find((item) => item?.id === value)
        setFieldValue('field', value);
        setFieldValue('search', (fieldType?.options?.isStrictDropdown || fieldType?.options?.isDropdown) ? fieldType?.options?.isMultiSelection ? ['none'] : 'none' : '');
        setFieldValue('startsAt', '');
        setFieldValue('endsAt', '');
    }

    function hoverColumn(text) {
        return (
            <PopoverLink
                columnData={<i className={`fas fa-question-circle text-info`}></i>}
                hoverData={[
                    {
                        data: text
                    }
                ]}
            />
        );
    }

    const closeAdvancedFiltersPanel = () => {
        setOpenAdvanceFiltersDialog(openFiltersPanel)
        setOpen(false)
        setSearchAction("Search")

    }
    const disableAddBtn = (values) => {
        if (values?.equator === "alpha_between" || values?.equator === "in_between") {
            return !values?.field || !values?.startsAt || !values?.endsAt
        }
        return !values?.field || !values?.equator || values?.search === ''
    }

    const doesHaveFilters = (values) => {
        if (values?.equator === "alpha_between") {
            return filter?.length>0 && !values?.field && !values?.startsAt && !values?.endsAt
        }
        return filter?.length>0 && !values?.field && !values?.equator && values?.search === ''

    }

    return (
        (<Paper>
            <Formik
                initialValues={formValues}
                enableReinitialize
                validate={values => {
                    
                    if (doesHaveFilters(values)) { return }

                    const errors: { field?: string; startsAt?: string; endsAt?: string; search?: string } = {};

                    if (!values.field) {
                        errors.field = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (values.isBetween) {
                        if (values.startsAt === '') {
                            errors.startsAt = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if (values.endsAt === '') {
                            errors.endsAt = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if (fieldType === FIELD_TYPE_STRING && values.startsAt && values.endsAt && (values.endsAt.localeCompare(values.startsAt) === -1)) {
                            errors.endsAt = intl.formatMessage({
                                id: "BETWEEN.FILTER.LESS.THAN.CHARACTER.MESSAGE"
                            });
                        } else if (fieldType === FIELD_TYPE_INT && values.startsAt !== '' && values.endsAt < values.startsAt) {
                            errors.endsAt = intl.formatMessage({
                                id: "BETWEEN.FILTER.LESS.THAN.INTEGER.MESSAGE"
                            });
                        } else if (fieldType === FIELD_TYPE_DATE && values.startsAt && (new Date(values.endsAt) < new Date(values.startsAt))) {
                            errors.endsAt = intl.formatMessage({
                                id: "BETWEEN.FILTER.LESS.THAN.DATE.MESSAGE"
                            });
                        }
                    } else {
                        if (values.search === '') {
                            errors.search = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                    }

                    return errors;
                }}
                onSubmit={(values: FormValues, { setSubmitting, resetForm }) => {
                    if(isDropdown && values?.search===null)
                    {return}
                    if (isFilterUpdate) {
                        handleUpdate(values);
                    } else {
                        let newFilter = {
                            field: values.field,
                            equator: values.equator,
                            value: ''
                        }
                        if (values.isBetween) {
                            newFilter = {
                                ...newFilter,
                                value: `${values.startsAt}&${values.endsAt}`,
                            }
                        } else {
                            newFilter = {
                                ...newFilter,
                                value: values.search,
                            }
                        }
                        handleFilterChange(newFilter, values.addFilter);
                        if (!values?.addFilter) {
                            closeAdvancedFiltersPanel()
                        }
                        resetForm({ values: { field: '', search: '', equator: '', startsAt: '', endsAt: '', addFilter: false } });
                        setFieldType(FIELD_TYPE_STRING);
                    }
                    setSubmitting(false);
                    setIsDropdown(false);
                    setIsStrictDropdown(false);
                    setDropdownValues([]);
                }}
            >

                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                    } = props;
                    bindSetFeild(setFieldValue);
                    return (
                        (
                        
                        <>
                                <Popper id={idPopper} open={openFiltersPanel && filter?.length>0}
                                    className={`filters-panel-popper ${classes.hoverPopper}`}
                                    placement='top-start'

                                    anchorEl={filtersAnchorEl}

                                    onMouseLeave={() => setOpenAdvanceFiltersDialog(false)
                                    }
                                >
                                    <Box>

                                        {filter?.length > 0 && <Grid item xs={12} spacing={1}>
                                            <Grid className={classes.adjustableGrid}>
                                                {slicedFilters(filter)?.map((f, index) => {
                                                    const operations=getLabel(f)
                                                    return (
                                                            <Tooltip
                                                                arrow
                                                                title={<Typography color="inherit">{`${operations?.name} ${operations?.equator} ${operations?.value}`}</Typography>}
                                                            >
                                                                <Chip variant="outlined"
                                                                    size="medium"
                                                                    className={classes.filterChip}
                                                                    key={index}
                                                                    label={<span>{operations?.name} <b>{operations?.equator}</b> {operations?.value}</span>}
                                                                    disabled={pending}
                                                                    onDelete={() => handleDelete(f, index)}
                                                                />
                                                            </Tooltip>
                                                        )
                                                })}

                                                {filter?.length > 4 && <Chip label={`${!isFiltersDefaultSliced ? '...' : `+${filter.length - 4}`}`}
                                                    className={classes.filtersBubble}
                                                    onClick={() => setIsFiltersDefaultSliced(!isFiltersDefaultSliced)}
                                                />
                                                }            </Grid>
                                        </Grid>}</Box>


                                </Popper>

                                <Popover
                                    className={classes.popover}
                                    onClose={() => {
                                        setOpenAdvanceFiltersDialog(false, null, null)
                                        setOpen(false)
                                        setSearchAction("Search")
                                    }}
                                    id={id}
                                    open={openPopover}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >

                                    <DataTableHeaderFilters
                                        classes={classes}
                                        closeAdvancedFiltersPanel={closeAdvancedFiltersPanel}
                                        handleSubmit={handleSubmit}
                                        filter={filter}
                                        slicedFilters={slicedFilters}
                                        open={open}
                                        getLabel={getLabel}
                                        pending={pending}
                                        handleDelete={handleDelete}
                                        handleUpdateClick={handleUpdateClick}
                                        setIsFiltersDefaultSliced={setIsFiltersDefaultSliced}
                                        isFiltersDefaultSliced={isFiltersDefaultSliced}
                                        handleFieldChange={handleFieldChange}
                                        handleBlur={handleBlur}
                                        defaultCustomFilters={defaultCustomFilters}
                                        getEquators={getEquators}
                                        setFieldValue={setFieldValue}
                                        touched={touched}
                                        errors={errors}
                                        values={values}
                                        isDropdown={isDropdown}
                                        isStrictDropdown={isStrictDropdown}
                                        areFieldsGrouped={areFieldsGrouped}
                                        groupedFields={groupedFields}
                                        handleEquatorChange={handleEquatorChange}
                                        dropdownValues={dropdownValues}
                                        isMultiSelect={isMultiSelect}
                                        AlphaSearchFields={AlphaSearchFields}
                                        handleChange={handleChange}
                                        fieldType={fieldType}
                                        FIELD_TYPE_DATE={FIELD_TYPE_DATE}
                                        FIELD_TYPE_INT={FIELD_TYPE_INT}
                                        fields={fields}
                                        equators={equators}
                                        SaveCriteriaDialog={SaveCriteriaDialog}
                                        intl={intl}
                                        hoverColumn={hoverColumn}
                                        disableAddBtn={disableAddBtn}
                                        isFilterUpdate={isFilterUpdate}
                                        handleSaveSearch={handleSaveSearch}
                                        searchAction={searchAction}
                                        criteria={criteria}
                                        handleClose={handleClose}
                                        SplitButton={SplitButton}
                                        pageName={pageName}
                                        onMenuClick={onMenuClick}
                                        saveEnabled={saveEnabled}
                                        actions={actions}
                                        handleFilterClear={handleFilterClear}
                                        handleCancelUpdate={handleCancelUpdate}
                                        userSessionId={userSessionId}
                                    />

                                </Popover>
                        </>)
                    );
                }}
            </Formik>
        </Paper >)
    );
}