import { Autocomplete, Checkbox, Chip, CircularProgress, createFilterOptions, FormControl, FormControlLabel, Grid, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { useData, useStatePtr } from "@pure-ptr/react";
import React, { useContext, useEffect } from 'react';

import { getPayersScoreCardSummary } from "../../store/api/summaryDashboard";

import { DashboardFiltersContext, useStyles } from './Dashboard';
import { useDispatch } from 'react-redux';
import { getPayerContractsTiny } from '../../store/actions/payers';
import { PopoverLink, useGlobalStore } from '../../common';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const PayerScorecardWidget = () => {
    const classes = useStyles();
    const { dateRange, history } = useContext(DashboardFiltersContext);
    const dispatch = useDispatch();

    const paidOnlyPtr = useStatePtr(false);
    const payerContractFilterPtr = useStatePtr([]);
    const filteredDataPtr = useStatePtr([]);
    const payersOptions = useGlobalStore( x => x.payers.payerContracts ?? [] );

    useEffect(() => {
        dispatch(getPayerContractsTiny());
    }, [getPayerContractsTiny]);

    useEffect(() => {
        if(payersOptions){
            payerContractFilterPtr.set(payersOptions);
        }
    }, [payersOptions]);

    const { data, isPending } = useData( async () => {
        const payload = {
            payerContractId: null,
            paidOnly: paidOnlyPtr.value,
            filterDates: true,
            beginDate: dateRange.startdate,
            endDate: dateRange.enddate,
        }
        const result = await getPayersScoreCardSummary(payload);
        return result;
    }, [ dateRange, paidOnlyPtr.value]);

    useEffect(() => {
        if (data && payerContractFilterPtr.value !== null && payerContractFilterPtr.value.length > 0) {
            const payerContractIds = payerContractFilterPtr.value.map(item => item.id);
            const filteredData = data.filter(item => payerContractIds.includes(item.id));
            filteredDataPtr.set(filteredData);
        } else {
            filteredDataPtr.set([]);
        }
    }, [data, payerContractFilterPtr.value]);

    const handleFilterByPayerContract = (e, value) => {
        payerContractFilterPtr.set(value);

    }

    const filterOptions = createFilterOptions<any>({
        stringify: option => option.displayName,
    });

    const getClassName = (id, obj) => {
        const color = id === 'timeToPay' ? obj.timeToPayColor : id === 'payerYield' ? obj.payerYieldColor : id === 'errorRate' ? obj.errorRateColor : obj.denialRateColor;
        return color === 'GREEN' ? 'text-white bg-success'
            : color === 'RED' ? 'bg-danger text-white'
                : color === 'YELLOW' ? 'bg-warning text-dark' : '';
    };
    
    const getHoverData = (id, obj) => {
        if (id === 'timeToPay') {
            return [
                { label: 'Paid On Time', data: obj.detailData.paidOnTime },
                { label: 'Total Claims', data: obj.detailData.totalClaims },
                { label: 'Time To Pay Variance', data: obj.detailData.timeToPayVariance },
            ];
        } else if (id === 'payerYield') {
            return [
                { label: 'Payer Yield Expected', data: obj.detailData.payerYieldExpected },
                { label: 'Payer Yield Actual', data: obj.detailData.payerYieldActual },
                { label: 'Payer Yield Total', data: obj.detailData.payerYieldTotal },
                { label: 'Payer Yield Variance', data: obj.detailData.payerYieldVariance },
            ];
        } else if (id === 'errorRate') {
            return [
                { label: 'Error Claims', data: obj.detailData.errorClaims },
                { label: 'Total Claims', data: obj.detailData.totalClaims },
            ];
        } else if (id === 'denialRate') {
            return [
                { label: 'Denied Claims', data: obj.detailData.deniedClaims },
                { label: 'Total Claims', data: obj.detailData.totalClaims },
            ];
        }
        return [];
    };
    
    const renderChipField = (id, label, obj) => {
        let className = getClassName(id, obj);
        let hoverData = getHoverData(id, obj);
    
        return <PopoverLink
            columnData={
                <Chip
                    style={{ borderRadius: '5px', width: '70px', fontWeight: '600' }}
                    label={id === 'timeToPay' || id === 'errorRate' || id === 'denialRate' ? obj[id] + '%' : obj[id]}
                    className={className}
                />
            }
            hoverData={hoverData}
            title={label}
        />;
    };

    return isPending ? (
        <CircularProgress />
    ) : (
        <>
        <Grid container spacing={3} justifyContent="flex-end" className='pr-3'>
            <Grid item xs={6} md={3} className='text-right mt-2'>
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            checked={paidOnlyPtr.value}
                            size="small"
                            color="primary"
                            onChange={(e, value) => paidOnlyPtr.set(value)}
                        />
                    }
                    label="Paid Only?"
                    labelPlacement="start"
                />
            </Grid>
            <Grid item xs={6} md={6}>
                <FormControl className={classes.formControlAuto}>
                    <Autocomplete
                        fullWidth
                        onChange={handleFilterByPayerContract}
                        filterOptions={filterOptions}
                        value={payerContractFilterPtr.value}
                        multiple
                        options={payersOptions}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.displayName}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.displayName}
                            </li>
                        )}
                        renderTags={(tagValue, getTagProps) => {
                            const numTags = tagValue.length;
                            return (
                                <Typography variant="caption">
                                    {tagValue.slice(0, 2).map((option, index) => (
                                        <Chip
                                            size="small"
                                            color="secondary"
                                            key={index}
                                            label={option.displayName}
                                            {...getTagProps({ index })}
                                        />
                                    ))}
                                    {numTags > 2 && ` + ${numTags - 2}`}
                                </Typography>
                            )
                        }
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Payers"
                            />
                        )}
                    />
                </FormControl>
            </Grid>
        </Grid>
        <Table>
            <TableHead>
                <TableRow key='payerScoreCardHeader'>
                    <TableCell>Display Name</TableCell>
                    <TableCell>Contract Name</TableCell>
                    <TableCell>Error Rate</TableCell>
                    <TableCell>Time to Pay</TableCell>
                    <TableCell>Payer Yield</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredDataPtr?.value?.map((payerScore) => (
                    <TableRow key={payerScore.id}>
                        <TableCell>{payerScore.displayName}</TableCell>
                        <TableCell>{payerScore.contracts.length > 10 ? 
                            <Tooltip 
                                arrow
                                classes={{ tooltip: classes.tooltip }}
                                title={<Typography color="inherit">{payerScore.contracts}</Typography>}>
                                    {payerScore.contracts.substring(0, 10)}
                            </Tooltip> 
                            : payerScore.contracts}</TableCell>
                        <TableCell>{renderChipField('errorRate', 'Error Rate', payerScore)}</TableCell>
                        <TableCell>{renderChipField('timeToPay', 'Time to Pay', payerScore)}</TableCell>
                        <TableCell>{renderChipField('payerYield', 'Payer Yield', payerScore)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </>
    );
}