import React,{useState} from "react";

import {
    Button,
    Chip,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    Box,
    IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";

import { Autocomplete } from "@mui/material";
import { Card } from "react-bootstrap";

export function DataTableHeaderFilters({
    classes,
    closeAdvancedFiltersPanel,
    handleSubmit,
    filter,
    slicedFilters,
    getLabel,
    pending,
    handleDelete,
    handleUpdateClick,
    setIsFiltersDefaultSliced,
    isFiltersDefaultSliced,
    handleFieldChange,
    handleBlur,
    getEquators,
    setFieldValue,
    touched,
    errors,
    values,
    isDropdown,
    isStrictDropdown,
    areFieldsGrouped,
    groupedFields,
    handleEquatorChange,
    dropdownValues,
    isMultiSelect,
    AlphaSearchFields,
    handleChange,
    fieldType,
    FIELD_TYPE_DATE,
    FIELD_TYPE_INT,
    fields,
    equators,
    SaveCriteriaDialog,
    defaultCustomFilters=null,
    intl,
    hoverColumn,
    disableAddBtn,
    isFilterUpdate,
    handleSaveSearch,
    searchAction,
    criteria,
    handleClose,
    SplitButton,
    pageName,
    onMenuClick,
    saveEnabled,
    actions,
    handleFilterClear,
    handleCancelUpdate,
    open,
    userSessionId
}) {
    const isNotCurrentUserCriteria=userSessionId!==criteria?.userId
    const [search, setSearch] = useState("");
    const [openFilterBox, setOpenFilterBox] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    const handleSelectOpen = () => {
        setOpenFilterBox(true);
      };
    
      const handleSelectClose = () => {
        setOpenFilterBox(false);
        setSearch('')
      };

      const filteredFilters = (filters) => {
        const advFilters=[...filters]
        if (search === "" || !search) return advFilters;
        else {
            return advFilters.filter((item: any) =>
                item.label.toLowerCase().includes(search.toLowerCase())
            );
        }
    };

    return (
        <>
       <Box className={classes.closeBtnContainer}>
                <IconButton
                    className={classes.closePanelBtn}
                    onClick={() => closeAdvancedFiltersPanel()}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Card className={`${classes.card}`}>
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        spacing={1}
                        className={classes.formContainer}
                    >
                        {filter?.length > 0 && (
                            <Grid item xs={12}>
                                <Grid className={classes.adjustableGrid}>
                                    {slicedFilters(filter)?.map((f, index) => {
                                    const operations=getLabel(f)

                                        return (
                                                <Tooltip
                                                    arrow
                                                    title={<Typography color="inherit">{`${operations?.name} ${operations?.equator} ${operations?.value}`}</Typography>}
                                                >
                                                    <Chip
                                                        variant="outlined"
                                                        size="medium"
                                                        className={
                                                            classes.filterChip
                                                        }
                                                        key={index}
                                                        label={<span>{operations?.name} <b>{operations?.equator}</b> {operations?.value}</span>}
                                                        disabled={pending}
                                                        onDelete={() =>
                                                            handleDelete(
                                                                f,
                                                                index
                                                            )
                                                        }
                                                        onClick={() =>
                                                            handleUpdateClick(
                                                                f,
                                                                index
                                                            )
                                                        }
                                                    />
                                                </Tooltip>
                                        );
                                    })}
                                    {filter?.length > 4 && (
                                        <Chip
                                            label={`${
                                                !isFiltersDefaultSliced
                                                    ? "..."
                                                    : `+${filter.length - 4}`
                                            }`}
                                            sx={{
                                                cursor: "pointer",
                                                fontWeight: 600,
                                                color: "#196540",
                                                fontSize: "small",
                                            }}
                                            onClick={() =>
                                                setIsFiltersDefaultSliced(
                                                    !isFiltersDefaultSliced
                                                )
                                            }
                                        />
                                    )}{" "}
                                </Grid>
                            </Grid>
                        )}

                        <Grid xs={12} item>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="field">
                                    Select Field
                                </InputLabel>
                                <Select
                                    name="field"
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        handleFieldChange(
                                            setFieldValue,
                                            event.target.value
                                        );
                                        getEquators(event.target.value);
                                    }}
                                    value={values.field}
                                    error={Boolean(
                                        touched.field && errors.field
                                    )}
                                    inputProps={{
                                        name: "field",
                                        id: "field",
                                    }}
                                    open={openFilterBox} // Controlled open state
                                    onOpen={handleSelectOpen}
                                    onClose={(e) => {
                                        const element =
                                            e.target as HTMLButtonElement;
                                        if (element.id === "filter-search")
                                            return;
                                        handleSelectClose();
                                    }}
                                        MenuProps={{
                                            disableAutoFocusItem: true, 
                                          }}

                                >
                                     <TextField
                                        id="filter-search"
                                        onFocus={() => setIsSearching(true)}
                                        onBlur={() => setIsSearching(false)}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                        value={search}
                                        autoFocus
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Search Filter"
                                        size="small"
                                        sx={{
                                            mx: "15px",
                                            mb: "20px",
                                            width: "90%",
                                        }}
                                    />
                                    {areFieldsGrouped
                                        ? groupedFields.map((group) => [
                                              group.groupName && (
                                                  <ListSubheader
                                                      className={
                                                          classes.groupName
                                                      }
                                                  >
                                                      {group.groupName}
                                                  </ListSubheader>
                                              ),
                                              filteredFilters(group?.fields?.sort((a, b) => a?.label?.localeCompare(b?.label))).map(
                                                  ({ id, label, options }) =>
                                                      !options.hideFromFilter && (
                                                          <MenuItem
                                                              className="ml-3"
                                                              key={label}
                                                              disabled={isSearching}                                                      
                                                              value={id}
                                                              tabIndex={isSearching ? -1 : 0} 
                                                          >
                                                              {label}
                                                          </MenuItem>
                                                      )
                                              ),
                                          ])
                                        : filteredFilters(fields?.sort((a, b) => a?.label?.localeCompare(b?.label)))?.map(
                                              ({ id, label, options }) =>
                                                  !options.hideFromFilter && (
                                                      <MenuItem
                                                        disabled={isSearching}                                                      
                                                        tabIndex={isSearching ? -1 : 0} 
                                                          key={label}
                                                          value={id}
                                                      >
                                                          {label}
                                                      </MenuItem>
                                                  )
                                          )}
                                </Select>
                                {touched.field && errors.field && (
                                    <Box
                                        component="span"
                                        className={classes.helperTextSelect}
                                    >
                                        {" "}
                                        Required Field
                                    </Box>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="equator">
                                    Select Equator
                                </InputLabel>
                                <Select
                                    onBlur={handleBlur}
                                    onChange={handleEquatorChange(
                                        setFieldValue
                                    )}
                                    value={values.equator}
                                    error={Boolean(
                                        touched.equator && errors.equator
                                    )}
                                    inputProps={{
                                        name: "equator",
                                        id: "equator",
                                    }}
                                >
                                    {equators &&
                                        equators.map(({ value, label }) => (
                                            <MenuItem key={label} value={value}>
                                                {label}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {isDropdown &&
                                    (values.equator === "equals" ||
                                        values.equator === "not_equal_to") ? (
                                        <>
                                            <Autocomplete
                                                multiple={isMultiSelect}
                                                className={classes.autocomplete}
                                                onChange={(e, value) =>
                                                    setFieldValue(
                                                        "search",
                                                        value
                                                    )
                                                }
                                                value={
                                                    isMultiSelect
                                                        ? values?.search || []
                                                        : values?.search
                                                }
                                                options={dropdownValues}
                                                getOptionLabel={(option) =>
                                                    option === "none"
                                                        ? `--None--`
                                                        : option
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option === value}
                                                renderTags={(
                                                    value,
                                                    getTagProps
                                                ) =>
                                                    value.length > 2
                                                        ? [
                                                              ...value
                                                                  ?.slice(0, 2)
                                                                  ?.map(
                                                                      (
                                                                          option,
                                                                          index
                                                                      ) => (
                                                                          <Chip
                                                                              key={
                                                                                  index
                                                                              }
                                                                              label={
                                                                                  option
                                                                              }
                                                                              {...getTagProps(
                                                                                  {
                                                                                      index,
                                                                                  }
                                                                              )}
                                                                          />
                                                                      )
                                                                  ),
                                                              <Chip
                                                                  label={`+${
                                                                      value.length -
                                                                      2
                                                                  }`}
                                                                  key={
                                                                      value.length
                                                                  }
                                                              />,
                                                          ]
                                                        : value?.map(
                                                              (
                                                                  option,
                                                                  index
                                                              ) => (
                                                                  <Chip
                                                                      key={
                                                                          index
                                                                      }
                                                                      label={
                                                                          option
                                                                      }
                                                                      {...getTagProps(
                                                                          {
                                                                              index,
                                                                          }
                                                                      )}
                                                                  />
                                                              )
                                                          )
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className={
                                                            classes.input +
                                                            "mt-0"
                                                        }
                                                        error={Boolean(
                                                            touched.search &&
                                                                errors.search
                                                        )}
                                                        helperText={
                                                            touched.search &&
                                                            errors.search
                                                        }
                                                        label="Select Option"
                                                    />
                                                )}
                                            />
                                        </>
                                    ) : isStrictDropdown &&
                                      (values.equator === "equals" ||
                                          values.equator === "not_equal_to") ? (
                                        <>
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel htmlFor="equator">
                                                    Select Option
                                                </InputLabel>
                                                <Select
                                                    onBlur={handleBlur}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "search",
                                                            e.target.value
                                                        )
                                                    }
                                                    value={values.search}
                                                    error={Boolean(
                                                        touched.search &&
                                                            errors.search
                                                    )}
                                                    inputProps={{
                                                        name: "search",
                                                        id: "search",
                                                    }}
                                                >
                                                    {dropdownValues &&
                                                        dropdownValues.map(
                                                            ({
                                                                value,
                                                                label,
                                                            }) => (
                                                                <MenuItem
                                                                    key={label}
                                                                    value={
                                                                        value
                                                                    }
                                                                >
                                                                    {label ===
                                                                    "none"
                                                                        ? `--None--`
                                                                        : label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </>
                                    ) : values.equator === "alpha_between" ||
                                      values.equator === "in_between" ? (
                                        <>
                                            <AlphaSearchFields
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                values={values}
                                                classes={classes}
                                                fieldType={fieldType}
                                            />
                                        </>
                                    ) : (
                                        <TextField
                                            type={fieldType}
                                            name="search"
                                            className={classes.input}
                                            placeholder="Search"
                                            color="secondary"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.search}
                                            helperText={
                                                touched.search && errors.search
                                            }
                                            error={Boolean(
                                                touched.search && errors.search
                                            )}
                                            InputLabelProps={
                                                fieldType === FIELD_TYPE_DATE
                                                    ? {
                                                          shrink: true,
                                                      }
                                                    : {}
                                            }
                                            InputProps={{
                                                endAdornment:
                                                    fieldType &&
                                                    fieldType !==
                                                        FIELD_TYPE_DATE ? (
                                                        <InputAdornment position="end">
                                                            <span>
                                                                {hoverColumn(
                                                                    fieldType ===
                                                                        FIELD_TYPE_INT
                                                                        ? "Only single numeric value is allowed"
                                                                        : "Add single string value or use comma separation for multiple values"
                                                                )}
                                                            </span>
                                                        </InputAdornment>
                                                    ) : (
                                                        <></>
                                                    ),
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        sx={{ padding: "5px" }}
                                        disabled={disableAddBtn(values)}
                                        onClick={async () => {
                                            setFieldValue("addFilter", true);
                                            await Promise.resolve();
                                            handleSubmit();
                                        }}
                                        className={
                                            classes.additionalFiltersIcon
                                        }
                                        name="add"
                                        size="large"
                                    >
                                        <AddCircleIcon
                                            className={`${
                                                disableAddBtn(values)
                                                    ? classes.disabledAddBtn
                                                    : ""
                                            }`}
                                        />
                                    </IconButton>
                                </Grid>

                                {open && (
                                    <SaveCriteriaDialog
                                        intl={intl}
                                        isNotCurrentUserCriteria={isNotCurrentUserCriteria}
                                        open={open}
                                        handleClose={handleClose}
                                        defaultCustomFilters={defaultCustomFilters}
                                        handleSaveSearch={handleSaveSearch}
                                        criteria={
                                            searchAction === "Save"
                                                ? criteria
                                                : null
                                        }
                                        searchAction={searchAction}
                                    />
                                )}
                                <Box className={classes.submitBtnContainer}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item sm={7} xs={6}>
                                                {isFilterUpdate ? (
                                                    <Button
                                                        type="submit"
                                                        className={
                                                            classes.searchButton
                                                        }
                                                        color="primary"
                                                        variant="contained"
                                                        size="small"
                                                        startIcon={
                                                            <RefreshIcon />
                                                        }
                                                        disabled={pending}
                                                    >
                                                        Update
                                                    </Button>
                                                ) : saveEnabled ? (
                                                    <SplitButton
                                                        className={
                                                            classes.splitButton
                                                        }
                                                        options={
                                                            (!criteria)
                                                                ? actions?.filter(
                                                                      (item) =>
                                                                          item !==
                                                                          "Save"
                                                                  )
                                                                : actions
                                                        } //In case of no criteria, We don't show the option to update it
                                                        handleSubmit={
                                                            handleSubmit
                                                        }
                                                        onMenuClick={
                                                            onMenuClick
                                                        }
                                                        disabled={
                                                            pageName !==
                                                                "claimAnalysis-landing" ||
                                                            pending
                                                        }
                                                        searchAction={
                                                            searchAction
                                                        }
                                                        pageName={pageName}
                                                    />
                                                ) : (
                                                    <Button
                                                        type="submit"
                                                        className={
                                                            classes.searchButton
                                                        }
                                                        color="primary"
                                                        variant="contained"
                                                        size="small"
                                                        startIcon={
                                                            <SearchIcon />
                                                        }
                                                        disabled={pending}
                                                    >
                                                        Search
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {isFilterUpdate ? (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={handleCancelUpdate}
                                                className={classes.deleteButton}
                                                startIcon={<CancelIcon />}
                                                disabled={pending}
                                            >
                                                Cancel
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={handleFilterClear}
                                                className={classes.deleteButton}
                                                endIcon={<DeleteIcon />}
                                                disabled={pending}
                                            >
                                                Clear
                                            </Button>
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Card>
        </>
    );
}
