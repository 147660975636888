import { apiHandleOctetResponse, apiHandleResponse } from '../../util/api';
import {
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    SERVER_URL
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuthSendReceiveOctet } from '../headers';

const http = {
    get: async (url : string, query? : Record<string,string>) => {
        const queryString = query ? '?' + new URLSearchParams(query).toString() : '';

        const response = await fetch( SERVER_URL + url + queryString, {
            method: METHOD_GET,
            headers: headersAuthReceiveJson(),
        });
        return apiHandleResponse(response);
    },

    post: async (url : string, payload : any, contentType : 'octet-stream' | 'json' = 'json' ) => {
        const response = await fetch( SERVER_URL + url, {
            method: METHOD_POST,
            headers: contentType === 'octet-stream' ? headersAuthSendReceiveOctet() : headersAuthSendReceiveJson(),
            body: JSON.stringify(payload),
        });
        return contentType === 'octet-stream' ? apiHandleOctetResponse(response) : apiHandleResponse(response);
    },

    put: async (url : string, payload : any ) => {
        const response = await fetch( SERVER_URL + url, {
            method: METHOD_PUT,
            headers: headersAuthSendReceiveJson(),
            body: JSON.stringify(payload),
        });
        return apiHandleResponse(response);
    },

    del : async (url : string ) => {
        const response = await fetch( SERVER_URL + url, {
            method: METHOD_DELETE,
            headers: headersAuthReceiveJson(),
        });
        return apiHandleResponse(response);
    }
}

const getCasCodesSummary = async (startDate, endDate) => {
    return http.get(`/api/v1/summary/dashboard/cascodes/${startDate}/${endDate}`);
};

const getClaimsSummary = async (startDate, endDate) => {
    return http.get(`/api/v1/summary/dashboard/claims/${startDate}/${endDate}`);
};

const getUnderpaymentsSummary = async (startDate, endDate) => {
    return http.get(`/api/v1/summary/dashboard/underpayments/${startDate}/${endDate}`);
};

const getPayersScoreCardSummary = (payerScorecardPayload) => {
    return http.post(`/api/v1/cms/payerscorecards`, payerScorecardPayload);
};

const getPayerDenialsSummary = async (startDate, endDate) => {
    return http.get(`/api/v1/summary/dashboard/payerdenials/${startDate}/${endDate}`);
};

const getCasDenialsSummary = async (startDate, endDate) => {
    return http.get(`/api/v1/summary/dashboard/casdenials/${startDate}/${endDate}`);
};

export {
    getCasCodesSummary,
    getClaimsSummary,
    getUnderpaymentsSummary,
    getPayersScoreCardSummary,
    getPayerDenialsSummary,
    getCasDenialsSummary
};
