import GetAppIcon from '@mui/icons-material/GetApp';
import PageviewIcon from '@mui/icons-material/Pageview';
import { Grid, IconButton, Stack, Tooltip } from '@mui/material';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Parser } from 'json2csv';
import React, { useEffect, useMemo } from 'react';
import { injectIntl } from "react-intl";
import { useDispatch } from 'react-redux';
import { TableLevelAction } from '../../../../common';
import { HoverColumn, pageStore, renderHoverLink, renderProcessTypeHoverLink, SortOptions, useGlobalStore, XClientDataGrid } from '../../../../common/XDataGrid';
import { resetClaimLineItem, setAdjustment } from '../../../../store/actions/claimAnalysis';
import {
    createCurrencyDataField, createNumberDataField, createSecondLevelField, createStringDataField
} from '../../../../util/format';
import { useFilteredSortedData } from '../../../../util/selector';


const DEFAULT_SORT : SortOptions = [{ id: 'revcode', direction: 'asc' }];

const { usePageStorePtr } = pageStore('claim-drill-landing');

function ClaimLineItemSection({ refreshLineItems, intl }) {
    const dispatch = useDispatch();
    
    const fields = useMemo(() => [
        createSecondLevelField('rule', 'Rule Name', 'name', { 
            onHover: true,
            renderCell: renderProcessTypeHoverLink( row => {
               let feeScheduleItems:any= [
                {
                    label: "Name",
                    data: row?.rule?.name || ''
                }, {
                    label: "Type",
                    data: row?.rule?.ruleType?.name || ''
                }, {
                    label: "Pay Method",
                    data: row?.rule?.payMethod?.name || ''
                }, {
                    label: "Pay Value",
                    data: row?.rule?.payValue || ''
                },
            ]

            feeScheduleItems.push({
                type: "LINK",
                label: "Go to Rule",
                data: `/cms/contract/${row?.contractid}/rule/${row?.contractServiceId}/edit/${row?.ruleid}/root/true`,
            })
            return feeScheduleItems
        
        })
        }),
        createStringDataField('revcode', 'RevCode', { 
            sortable: true, 
            onHover: true,
            renderCell : renderHoverLink( row => [{
                    label: "",
                    data: row.revcodeDescription || ''
                }]
            )
        }),
        createStringDataField('pxcode', 'PxCode', { 
            sortable: true, 
            onHover: true,
            renderCell : renderHoverLink( row => {
                let pxCodeColumns: HoverColumn[] = [
                    {
                        label: "",
                        data: row.pxcodeDescription || ''
                    }
                ];
        
                if (row?.feeScheduleId && row.pxcode) {
                    if (row.pxcode.toString().includes(':')) {
                        const splitedPxCode = row.pxcode?.split(":");
                        pxCodeColumns.push({
                            type: "LINK",
                            label: "Go to Fee Schedule Item",
                            data: `/cms/feeschedules/view/${row.feeScheduleId}/${splitedPxCode[0]}`,
                        })
                    } else {
                        pxCodeColumns.push({
                            type: "LINK",
                            label: "Go to Fee Schedule Item",
                            data: `/cms/feeschedules/view/${row.feeScheduleId}/${row.pxcode}`,
                        })
                    }
                }
                
                return pxCodeColumns;
            })
         }),
        createNumberDataField('units', 'Qty/Units', { sortable: true }),
        createCurrencyDataField('chargedAmount', 'Charged Amount', { sortable: true, calculateTotal: true, totals: true }),
        createCurrencyDataField('expectedPay', 'Expected Pay', { sortable: true, calculateTotal: true, totals: true }),
        createCurrencyDataField('actualPay', 'Allowable', { sortable: true, calculateTotal: true, totals: true }),
        createCurrencyDataField('variance', 'Variance', { sortable: true, calculateTotal: true, totals: true }),
    ], []);

    const sortPtr = usePageStorePtr('sort', DEFAULT_SORT),
        sort = sortPtr.value;

    const claimAnalysis = useGlobalStore((state) => state.claimAnalysis.detail);

    const filteredData = useFilteredSortedData( fields.map( x => x.id ), "", sort, claimAnalysis);

    const claimDetails = useGlobalStore((state) => state.claimAnalysis.claimAllDetails);
    const claimnumber = claimDetails?.claimnumber || null;
    const claimLineItemPending = useGlobalStore((state) => state.claimAnalysis.get.pending);

    useEffect(() => {
        return () => {
            dispatch(resetClaimLineItem());
        }
    }, [dispatch])

    const handleExportClick = () => {
        let csvFields = fields.map((field) => {
            return {
                label: field.label,
                value: field.id === 'rule' ? 'rule.name' : field.id,
            }
        });

        csvFields = [
            ...csvFields,
            {
                label: 'DRG',
                value: 'drg',
            }
        ];
        const json2csvParser = new Parser({ fields: csvFields });
        const csv = json2csvParser.parse(filteredData);

        const blob = new Blob([csv], { type: 'text/csv' });

        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${claimnumber}_claimDetails.csv`;
        a.click();
    }

    const tableLevelAction = () => {
        return (
            <>
                <Grid item>
                    <Tooltip title="Export">
                        <IconButton aria-label="export" size='small' onClick={handleExportClick} disabled={false}>
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }


    const handleViewServiceAdjustment = (rowData) => {
        dispatch(setAdjustment({
            open: true,
            type: 'SERVICE',
            id: rowData?.servicepaymentid
        }));
    }


    const claimActions = ({ row }) => {
        return (
            [
                <GridActionsCellItem
                    onClick={() => handleViewServiceAdjustment( row )}
                    label='View Service Adjustments'
                    icon={
                        <Tooltip title="View Service Adjustments">
                            <PageviewIcon />
                        </Tooltip>
                    }
                />
            ]
        );
    }

    function actionLevelGrid() {
        return <Grid container>
            <Grid item md={12}>
                <h6 className="">{`Total Line Items : ${filteredData?.length ?? 0}`}</h6>
            </Grid>
        </Grid>
    }

    return (
    <Stack direction="column" spacing={1} sx={{ height: '100%' }}>
        <TableLevelAction 
            actions={tableLevelAction}
            actionLevelGrid={actionLevelGrid}
            handleRefresh={() => {
                refreshLineItems();
            }}
        />
        <XClientDataGrid
            fields={fields}
            data={filteredData}
            displayTotal={true}
            loading={claimLineItemPending}
            sortPtr={ sortPtr }
            actions={claimActions}
            actionColumnName='Adjustments'
        />
    </Stack>
    );
}

export default injectIntl(ClaimLineItemSection);