import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import AllowablesComponent from './AllowablesPage';
import { selectSessionRole } from '../../../store/selectors/session';
import { selectCheckboxStatus } from '../../../store/selectors/allowables';
import { updateStatus, selectDeselectAll,allowablesDeleteDialog } from '../../../store/actions/allowables';
import { StringEquators, integerEquators } from '../../../store/constants/pages';
import { createStringDataField, createCurrencyDataField } from '../../../util/format/index';
import { allowableLanding, resetPage } from '../../../store/actions/pages';
import { getByName, reset } from '../../../store/actions/search.js';
import { setV2 } from '../../../store/actions/pagesV2';

const selectFields:any = createSelector(
    () => [
        createStringDataField('pxCode', 'Base CPT/HCPCS', { sortable: true, equators: StringEquators }),
        createStringDataField('revenueCode', 'Rev Code', { sortable: true, equators: StringEquators }),
        createCurrencyDataField('rateIP', 'Rate IP', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rateOP', 'Rate OP', { sortable: true, equators: integerEquators }),
        createStringDataField('displayName', 'DisplayName', { sortable: true, equators: StringEquators }),
    ]
);

const selectAllowableData = createSelector(
    (state) => state.pages['allowable-landing'].list,
    (data) => data
);

export const AllowablesPage = connect(
    () => {
        return (state:any, props) => ({
            allowablesListPending: state.allowables.list.pending,
            updateStatusPending: state.allowables.updateStatus.pending,
            data: selectAllowableData(state),
            fields: selectFields(state), 
            userRole: selectSessionRole(state),
            checkboxStatus: selectCheckboxStatus(state),
            selectAllStatusPending: state.associated.all.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            updateStatus: updateStatus,
            selectDeselectAll: selectDeselectAll,
            allowableLanding: allowableLanding,
            searchGetByName: getByName,
            searchReset: reset,
            resetPagedTable: resetPage,
            allowablesDeleteDialog,
            resetSelectedContracts: () => setV2('allowable-landing', 'selected', []),
        }, dispatch)
    })
)(injectIntl(AllowablesComponent as any));