import { http } from './common';

const getDashboardData = async (startDate, endDate) =>
    http.get(`/api/v1/cms/rcmstatus/dashboard/${startDate}/${endDate}`);

const getDashboardSummary = async (startDate, endDate) =>
    http.get(`/api/v1/cms/rcmstatus/dashboard/summary/${startDate}/${endDate}`);



const downloadReport = async (startDate, endDate, reportType) => {
    return http.get(`/api/v1/cms/rcmstatus/dashboard/export/${startDate}/${endDate}/${reportType}`,null,"octet-stream");
}

const downloadFullReport = async (startDate, endDate) => {
    
    return http.get(`/api/v1/cms/rcmstatus/dashboard/export-excel/${startDate}/${endDate}`, null, "octet-stream");
}
export {
    getDashboardData,
    getDashboardSummary,
    downloadReport,
    downloadFullReport
}