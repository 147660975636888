import { FormControl, FormControlLabel, FormLabel, Radio, TextField, RadioGroup as MuiRadioGroup } from "@mui/material";
import { Autocomplete, Select as MuiSelect } from '@mui/material';
import { PurePtr } from "@pure-ptr/react";
import React from "react";

export function SelectFromArray<T>({
    valuePtr, options, label, children, getOptionLabel, getOptionKey, icon, disabled, disableClearable, placeholder, variant="standard"
} : {
    children?: React.ComponentProps<typeof Autocomplete>['renderOption'],
    options: T[],
    getOptionLabel: (option: T) => string,
    getOptionKey?: (option: T) => string,
    valuePtr: PurePtr<T>,
    placeholder?: string,
    label : string
    icon?: JSX.Element,
    disableClearable?: boolean,
    disabled? : boolean,
    variant? : "outlined" | "filled" | "standard"
}){
    return (
        (<FormControl fullWidth >
            <Autocomplete
                disableClearable={disableClearable}
                fullWidth
                disabled={disabled}
                onChange={ ( _, x ) => valuePtr.set( x as any ) }
                value={ valuePtr.value }
                options={options}
                getOptionLabel={getOptionLabel}
                getOptionKey={ getOptionKey || getOptionLabel }
                isOptionEqualToValue={(option, value) => option === value }
                renderInput={(params) => (
                    <TextField {...params}
                        name={label}
                        label={valuePtr.isTruthy ? label : placeholder}
                        variant={variant}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: icon,
                        }}
                        size="small"
                    />
                )}
                renderOption={children}
            />
        </FormControl>)
    );
}

export const Select = ({ label, valuePtr, children, ...props }) =>
    <MuiSelect
        label={label}
        value={valuePtr.value }
        onChange={ e => valuePtr.set( e.target.value ) }
        {...props}
    >
        {children}
    </MuiSelect>

export function enumToLabel( x : string ){
    return x.replace( /_/g, ' ' )
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase());
}

export const RadioGroup = ({ valuePtr, label, children }) =>
    <FormControl size='small'>
        <FormLabel>{label}</FormLabel>
        <MuiRadioGroup
            value={valuePtr.value}
            onChange={e => valuePtr.set(e.target.value)}
        >
            {children}
        </MuiRadioGroup>
    </FormControl>

export const RadioGroupOption = ({ value, children }) =>
    <FormControlLabel value={value} control={<Radio />} label={children} />;