import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set, setMulti,setOpenAdvanceFiltersDialog } from '../../../store/actions/pages';

import TriageTopFiltersComponent from './TriageTopFilters';
import { setRelativeFilter } from '../../../store/actions/claimAnalysis';

export const TriageTopFilters = connect(
    () => {

        // const selectSignificance = createSelector(
        //     (state) => state.pages['triage-landing'],
        //     (table) => {
        //         return table.significance;
        //     }
        // );

        const selectCustomFilter = createSelector(
            (state) => state.pages['triage-landing'],
            (table) => {
                return table.customFilter;
            }
        );

        const selectPending = createSelector(
            (state) => state.pages,
            (state) => state.search.getByName.pending,
            (pages, searchPending) => {
                return pages['triage-landing'].pending || searchPending
            }
        );

        return (state:any, props) => ({
            // significanceFlag: selectSignificance(state),
            customFilter: selectCustomFilter(state),
            relativeFilterValue: state.claimAnalysis.relativeFilterValue,
            tableFilters:state.pages['triage-landing']?.filter,
            pageState:state.pages['triage-landing'],
            ignoreDueToQuickSearch:state.pages['triage-landing']?.ignoreAcknowledgement,
            pending: selectPending(state),
            open: state.pages.open,
            showHidden:state.pages['triage-landing']?.showHidden,

        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            set: (value) => set('triage-landing', 'significance', value),
            setMulti: obj => setMulti('triage-landing', obj),
            setCustomFilter: (value) => set('triage-landing', 'customFilter', value),
            setRelativeFilter: setRelativeFilter,
            setOpenAdvanceFiltersDialog
        }, dispatch)
    })
)(injectIntl(TriageTopFiltersComponent));