import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Loadable } from "../loadable";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: "0.25rem",
    },
}));

export function DeleteConfirmation({
    reducerName= undefined,
    pageReducerName= undefined,
    stateName= undefined,
    children = undefined,
    handleDelete,
    isPending=false,
    handleClose,
    tooltip=undefined,
    defaultSelected=undefined,
    canDelete=true,
    defaultDeleteState=undefined,
    buttonColor = undefined,
    customIcon=undefined,
    iconSize = undefined,
    isDeleting = false,
    DELETE_TEXT = "Are you sure you want to delete these Items ?",
    NO_ITEMS_TEXT = "No Items Selected for deleting",
}) {

    const { open, rowData }: any =defaultDeleteState || useSelector((state) =>
        state[reducerName]
            ? state[reducerName][stateName]
            : { open: false, rowData: null }
    );
    const { selected }: any = defaultSelected ? {selected:defaultSelected}:useSelector((state: any) =>
        state?.pagesV2 ? state?.pagesV2[pageReducerName] : { selected: [] }
    );

    const classes = useStyles();

    const handleConfirmDelete = () => {
        handleDelete(rowData ? [rowData?.id] : selected);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{ "& .MuiPaper-root": { minWidth: "350px" } }}
        >
            <Loadable loading={isDeleting || isPending}>
                <DialogTitle>Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selected?.length === 0 && !rowData
                            ? NO_ITEMS_TEXT
                            : DELETE_TEXT}
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {(selected?.length > 0 || rowData) && (
                        <Button
                            disabled={!canDelete}
                            onClick={handleConfirmDelete}
                            color="primary"
                        >
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </Loadable>
        </Dialog>
    );
}
