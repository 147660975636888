import {
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    METHOD_DELETE,
    SERVER_URL,
} from '../constants/api';
import { headersAuthSendReceiveJson } from '../headers';
import { apiHandleResponse } from '../../util/api';
import {http} from './common'

export type SearchCriteriaFilter = {
    field: string;
    equator?: "equals" | "greater_than_or_equals_to" | "less_than_or_equals_to";
    value?: boolean | string;
    customFilter: boolean;
    type?: "DATE" | "SIGNIFICANCE";
    relativeFilter?: string;
    dates?: {
      equator: "greater_than_or_equals_to" | "less_than_or_equals_to";
      value: string;
    }[];
};

export interface SearchCriteria {
    criteria: string; // JSON string for SearchCriteriaFilter[]
    id: number;
    userId: number;
    name: string;
    criteriaName: string;
    isDefault: boolean;
    isPublic: boolean;
    searchCriteriaTypeId: number;
    searchCriteriaType: {
        id: number;
        name: string;
        description: string | null;
        searchCriteria: any[];
    };
    models: any[];
}

async function getCriteriaByPageName(pageName: { name: string; }) : Promise<SearchCriteria[]> {
    const response = await fetch(`${SERVER_URL}/api/v1/cms/searchcriteria/list`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
        // body: JSON.stringify(pageName),
    });

    return apiHandleResponse(response);
}

async function createSearchCriteria(searchCriteria) {
    const response = await fetch(`${SERVER_URL}/api/v1/cms/searchcriteria`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(searchCriteria)
    });
    return apiHandleResponse(response);
}

const getSearchCriteriaDependentsList = async (id) =>
    http.get(`/api/v1/cms/searchcriteria/dependencies/${id}`);


async function getSearchCriteriaList() {
    const response = await fetch(`${SERVER_URL}/api/v1/cms/searchcriteria/list`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    });
    return apiHandleResponse(response);
}

async function deleteSearchCriteriaList(id) {
    const response = await fetch(`${SERVER_URL}/api/v1/cms/searchcriteria/${id}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    });
    return apiHandleResponse(response);
}

async function upateSearchCriteria(searchCriteria) {
    const response = await fetch(`${SERVER_URL}/api/v1/cms/searchcriteria`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(searchCriteria)
    });
    return apiHandleResponse(response);
}

async function getSearchCriteriaTypes() {
    const response = await fetch(`${SERVER_URL}/api/v1/cms/searchcriteria/types`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    });
    return apiHandleResponse(response);
}

export {
    getCriteriaByPageName,
    createSearchCriteria,
    getSearchCriteriaList,
    deleteSearchCriteriaList,
    upateSearchCriteria,
    getSearchCriteriaTypes,
    getSearchCriteriaDependentsList
};