import React from "react";
import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from '@mui/material/styles';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme(
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Poppins"].join(","),
      body1: {
        margin: 0, // Specifically override margin for <p> tags (body1)
      },
    },

    palette: {
      contrastThreshold: 2,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#5d78ff"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#94c83d",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#ffffff"
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#fd397a"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiPopover: {
        defaultProps: {
          elevation: 1,
        },
      },

      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            borderRadius: 4,
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            margin: 0, // Remove extra margin for label
          },
          displayedRows: {
            margin: 0, // Remove extra margin for displayed rows
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          stickyHeader: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: "sticky",
            backgroundColor: "#fafafa"
          },
        },
      },
    },
  }
);

const cache = createCache({ key: 'css', prepend: true });

export default function ThemeProvider(props) {
  const { children } = props;

  return (
    <CacheProvider value={cache}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          {children}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  );
}
