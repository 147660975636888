import { CircularProgress, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useData, useStatePtr } from "@pure-ptr/react";
import React, { useContext } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { getPayerDenialsSummary } from "../../store/api/summaryDashboard";
import { asDollarsWithZero } from '../../util/format';
import { DashboardFiltersContext, useStyles } from './Dashboard';
import { useDispatch } from 'react-redux';
import { setMulti } from '../../store/actions/pages';

export const PayerDenialsWidget = () => {
    const dispatch = useDispatch();
    
    const classes = useStyles();
    const { dateRange, history } = useContext(DashboardFiltersContext);

    const { data, isPending } = useData( async () => {
        const result = await getPayerDenialsSummary(dateRange.startdate, dateRange.enddate); 
        return result;
    }, [ dateRange ]);

    const openPtr = useStatePtr( {} );

    const handlePayerDenialsRowClick = (key) => {
        openPtr.set((prevState) => ({
            ...prevState, 
            [key]: !prevState[key],
        }));
    }

    const handlePayerDenialsAmountClick = (contractName, facilityId = null) => {
        sessionStorage.removeItem("duplicated");
        let filters = [
            {
                actualFilter: true, 
                equator: "greater_than", 
                field: "variance", 
                value: 0
            },
            {
                actualFilter: true, 
                equator: "equals", 
                field: "contract.name", 
                value: contractName
            }
        ];

        if(facilityId != null) {
            filters.push({
                actualFilter: true, 
                equator: "equals", 
                field: "facilityId", 
                value: facilityId
            });
        }

        dispatch( setMulti( 'claimAnalysis-landing', {
            filter: filters,
            selectedFilter: null,
            customFilter: [
                {
                    customFilter: true, 
                    equator: "greater_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRange.startdate,
                    relativeFilter:dateRange.relativeFilter
                },
                {
                    customFilter: true, 
                    equator: "less_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRange.enddate,
                    relativeFilter:dateRange.relativeFilter
                },
                {
                    customFilter: true, 
                    equator: "equals", 
                    field: "isDenial", 
                    value: true
                }
            ],
            list: [],
            significance: false,
            searchTerm:"",
            ignoreAcknowledgement:false,
            showIgnoredFilterDialog:false
        }));

        history.push("/cms/claimAnalysis/fromSummary");
    }

    return isPending ? (
        <CircularProgress />
        ) : (
        <Table>
            <TableHead>
            <TableRow key='PayerDenialsHeader'>
                <TableCell>Contracts</TableCell>
                <TableCell>Count</TableCell>
                <TableCell>Amount</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {data?.map((entry) => (
                <>
                <TableRow key={entry.contractName} className={entry.payerDenialsByFacilities?.length > 0 ? classes.denialsGroup1Row : ''}>
                    <TableCell>
                        {entry.payerDenialsByFacilities?.length > 0 && (
                            <IconButton sx={{padding: "0 10px 0 0"}} onClick={() => handlePayerDenialsRowClick(entry.contractName)}>
                                {openPtr.value[entry.contractName] ? <ArrowDropDownIcon /> : <ArrowRightIcon />  }
                            </IconButton>
                        )}
                        {entry.contractName}
                    </TableCell>
                    <TableCell><Typography variant="body2">{entry.totalClaims ?? 0}</Typography></TableCell>
                    <TableCell><Typography onClick={()=> handlePayerDenialsAmountClick(entry.contractName)} variant="body2" className={entry.totalAmount ? classes.link : ''}>{entry.totalAmount ? asDollarsWithZero(entry.totalAmount) : 'N/A'}</Typography></TableCell>
                </TableRow>

                {entry.payerDenialsByFacilities?.length > 0 && (
                    <TableRow key={`${entry.contractName}-childRow`}>
                        <TableCell colSpan={3} sx={{padding: "0px", border: 0 }}>
                            <Collapse in={openPtr.value[entry.contractName]} timeout="auto" unmountOnExit>
                            <Table sx={{width: "87%", margin: "0px 50px"}}>
                                <TableBody>
                                {entry.payerDenialsByFacilities?.map((facility, index) => (
                                    <>
                                    <TableRow key={`${entry.contractName}-${index}`}>
                                        <TableCell>{facility?.facilityName ?? "N/A"}</TableCell>
                                        <TableCell><Typography variant="body2">{facility.totalClaims ?? 0}</Typography></TableCell>
                                        <TableCell><Typography onClick={()=> handlePayerDenialsAmountClick(entry.contractName, facility.facilityId ?? "none")} variant="body2" className={facility.totalAmount ? classes.link : ''}>{facility.totalAmount ? asDollarsWithZero(facility.totalAmount) : 'N/A'}</Typography></TableCell>
                                    </TableRow>
                                    </>
                                ))}
                                </TableBody>
                            </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
                
                </>
            ))}
            </TableBody>
        </Table>
    )
}