import React, { useState, useEffect } from 'react';
import { getStartDate, getEndDate } from '../../../util/date';

import { FormControl, Select, MenuItem, TextField, InputLabel } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    column: {
        flexBasis: '20%',
        margin: theme.spacing(2),
    },
    textField: {
        width: 200,
        margin: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(2),
        width: 200,
    },
}));

const filterOptions = [
    { value: "ThisMonth", label: "This Month" },
    { value: "LastMonth", label: "Last Month" },
    { value: "Yesterday", label: "Yesterday" },
    { value: "Last7Days", label: "Last 7 Days" },
    { value: "Last30Days", label: "Last 30 Days" },
    { value: "Last60Days", label: "Last 60 Days" },
    { value: "Last90Days", label: "Last 90 Days" },
    { value: "Last120Days", label: "Last 120 Days" },
    { value: "Last6Months", label: "Last 6 Months" },
    { value: "Last12Months", label: "Last 12 Months" },
    { value: "ThisQuarter", label: "This Quarter" },
    { value: "LastQuarter", label: "Last Quarter" },
    { value: "ThisYear", label: "This Year" },
    { value: "LastYear", label: "Last Year" },
    { value: "ThisFiscalYear", label: "Current Fiscal Year" },
    { value: "LastFiscalYear", label: "Previous Fiscal Year" }
  ]
export default function DateControl(props) {
    const classes = useStyles();
    const { setFilter, fiscalYear, startDateParam, endDateParam } = props;

    const [values, setValues] = useState({
        relativeFilter: 'ThisYear',
        startdate: getStartDate('ThisYear'),
        enddate: getEndDate('ThisYear'),
    });

    const handleRelativeFilterChange = (event) => {
        setValues(prevState => {
            return {
                ...prevState,
                relativeFilter: event.target.value,
                startdate: getStartDate(event.target.value, fiscalYear),
                enddate: getEndDate(event.target.value, fiscalYear),
            }
        });
    };

    const handleChange = (event) => {
        event.persist();
        setValues(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    useEffect(() => {
        setFilter(startDateParam, values.startdate);
    }, [values.startdate, setFilter, startDateParam]);

    useEffect(() => {
        setFilter(endDateParam, values.enddate);
    }, [values.enddate, setFilter, endDateParam]);

    return (
        <>
            <div className="column">
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="relativeFilter">Select Filter</InputLabel>
                    <Select
                        value={values.relativeFilter}
                        onChange={handleRelativeFilterChange}
                        inputProps={{
                            name: 'relativeFilter',
                            id: 'relativeFilter',
                        }}
                    >
                        {filterOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className="column">
                <TextField
                    type="date"
                    label="Start Date"
                    name="startdate"
                    className={classes.textField}
                    margin="normal"
                    onChange={handleChange}
                    value={values.startdate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div className="column">
                <TextField
                    type="date"
                    label="End Date"
                    name="enddate"
                    className={classes.textField}
                    margin="normal"
                    onChange={handleChange}
                    value={values.enddate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
        </>
    )
}