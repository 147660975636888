import { Grid, Popover, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, TableFooter } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../partials/content/Portlet';
import { Loadable } from '../../common';

const useStyles = makeStyles(theme => ({
    portlet: {
        'box-shadow': 'none',
        marginBottom: 0,
        "& .kt-portlet__head": {
            borderColor: theme.palette.secondary.main,
            // marginLeft: theme.spacing(3.5),
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        pointerEvents: 'auto',
        width: 500,
    },
    underline: {
        textDecoration: 'underline'
    },
    button: {
        minWidth: 26,
        width: 26,
        textDecoration: 'none'
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    tableHeader: {
        borderBottom: '1px solid #94c83d'
    },
    tableFooter: {
        borderTop: '1px solid #e0e0e0'
    },
    tableBody: {
        borderBottom: 'none'
    }
}));

export default function TriageDetailsPopover({ isPopoverOpen, anchorEl, pending = undefined, title, columnData , items, actions: { paperEnter, popoverLeave } }) {
    const classes = useStyles();
    const [isEllipsisClicked, setEllipsesClicked] = useState(false);

    useEffect(() => {
        return () => {
            setEllipsesClicked(false);
        }
    }, [isPopoverOpen]);

    const handleEllipsisClicked = () => {
        setEllipsesClicked(true);
    }

    return (
        <>
            <Popover
                // id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                PaperProps={{
                    onMouseEnter: paperEnter,
                    onMouseLeave: popoverLeave
                }}
                disableRestoreFocus
            >
                <Loadable loading={pending}>
                    <Portlet className={classes.portlet}>
                        {title && (
                            <PortletHeader
                                toolbar={
                                    <PortletHeaderToolbar>
                                        <Grid className='kt-portlet__head-label py-2'>
                                            <Grid className='kt-portlet__head-title'>
                                                {title}
                                            </Grid>
                                        </Grid>
                                    </PortletHeaderToolbar>
                                }
                            />
                        )}
                        <PortletBody>
                            <Grid container>
                            <Table>
                                <TableHead>
                                    <TableRow key='measuresHeader'>
                                        <TableCell className={classes.tableHeader}>Measure</TableCell>
                                        <TableCell className={classes.tableHeader}>Impact</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {items?.length && items?.map((item, index) => (
                                    <TableRow key={`cf-measure-${index}`}>
                                        <TableCell className={classes.tableBody}>{item?.displayName ?? 'N/A'}</TableCell>
                                        <TableCell className={classes.tableBody}>{`${item?.value} %`}{" "}</TableCell>
                                    </TableRow>
                                ))}
                                    <TableRow key='measuresFooter'>
                                        <TableCell className={`${classes.tableBody} ${classes.tableFooter}`} >Confidence Score (0 - 100%)</TableCell>
                                        <TableCell className={`${classes.tableBody} ${classes.tableFooter}`}>{(columnData && items && items.length > 0) ? columnData : '0'} %</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            </Grid>
                        </PortletBody>
                    </Portlet>
                </Loadable>
            </Popover>
        </>
    )
}

// export default React.memo(TriageDetailsPopover)