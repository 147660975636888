import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { injectIntl } from "react-intl";
import { useDispatch } from 'react-redux';
import { Portlet } from '../../../partials/content/Portlet';
import { get, getClaimAllDetails, getClaimComments, getHistory, resetClaimLineItem, setClaimComments, setClaimCommentsConfirmationDialog } from '../../../store/actions/claimAnalysis';
import { tiny } from '../../../store/actions/contracts';
import { BasicDetailsSection } from './BasicDetailsSection';
import { ClaimAdjustments } from './ClaimAdjustments';
import { ClaimLineItemSection } from './ClaimLineItemSection.js';
import { CommentsForm } from './CommentsForm';
import ServiceAdjustments from './ServiceAdjustments';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => {
    return {
        portlet: {
            'box-shadow': 'none',
        },
        lineItemGrid: {
            padding: '0px 24px',
        },
        lineItemBox: {
            padding: '0 24px 24px 24px',
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
            maxHeight: '500px',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        commentsBox: {
            marginTop: '16px',
            marginBottom: '24px',
            padding: '12px 24px',
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
        }
    }
});

function ClaimViewPage({ intl, match }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const claimId = match.params.id;
    const currentLocation = useLocation().pathname;

    useEffect(() => {
        dispatch(tiny());
        dispatch(getClaimAllDetails(claimId));
        dispatch(get(claimId));
        dispatch(getHistory(claimId));
        dispatch(getClaimComments(claimId));
        dispatch(setClaimCommentsConfirmationDialog({ open: false, state: '' }));
        dispatch(setClaimComments(''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimId]);

    const refreshLineItems = () => {
        dispatch(resetClaimLineItem());
        dispatch(get(claimId));
    }

    const reprocessClaimCallback = () => {
        refreshLineItems();
        dispatch(getHistory(claimId));
        dispatch(getClaimAllDetails(claimId));
    }

    return (
        <>
            <Portlet className={classes.portlet}>

                <BasicDetailsSection isFromTriage={currentLocation?.includes("confidence-score-triage") ?? false} reprocessClaimCallback={reprocessClaimCallback} claimId={claimId} />

                <Grid className={classes.lineItemGrid}>
                    <Grid>
                        <ClaimLineItemSection refreshLineItems={refreshLineItems} />
                    </Grid>
                    <Grid className={classes.commentsBox}>
                        <CommentsForm claimId={claimId} />
                    </Grid>
                </Grid>
            </Portlet>
            <ClaimAdjustments claimId={claimId}/>
            <ServiceAdjustments claimId={claimId}/>
        </>
    )
}

export default injectIntl(ClaimViewPage);
