import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { CustomLabel, DependentsList, Loadable } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function DependencyDialog( { intl, open, contractId, canDelete, contractDependencies, contractDependencyPending, contractDeletePending, contractName,
    handleRefresh,isContractDependencies=false, actions: { setContractDependencyDialog, getContractDependencies, deleteContract } } ) {

    const classes = useStyles();

    useEffect(() => {
        if (open && contractId) {
            getContractDependencies(contractId)
        }
    }, [open, contractId, getContractDependencies]);

    const handleDialogClose = () => {
        setContractDependencyDialog(null);
    }

    const handleDeleteCallback = () => {
        setContractDependencyDialog(null);
        handleRefresh(!isContractDependencies ? true:undefined);
    }

    const handleDelete = () => {
        if(canDelete) {
            deleteContract(contractId, handleDeleteCallback);
        }
    }

    return (
        (<Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={contractDependencyPending || contractDeletePending}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Dependencies ({contractName})
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleDialogClose}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    {contractDependencies.length == 0 &&
                        <Grid>
                            <Typography>Are you sure you want to delete this contract?  It cannot be recovered.</Typography>
                            <Typography>No dependency found. Click to delete</Typography>
                        </Grid> 
                    }
                    {contractDependencies.length > 0 &&
                        <>
                        <Grid>
                            <Typography>Following dependencies are found:</Typography><br />
                        </Grid>
                        <DependentsList dependents={contractDependencies} isSimpleDependency={true}/>
                        </>
                    }
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button color={canDelete ? "inherit" : "primary"} variant="contained" onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    {canDelete && 
                        <Button
                        onClick={handleDelete}
                        variant="contained"
                        disabled={contractDependencyPending || contractDeletePending}
                        color="primary"
                        >
                            Delete
                        </Button>
                    }
                    
                </DialogActions>
            </Loadable >
        </Dialog>)
    );
}

export default DependencyDialog
