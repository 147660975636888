import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CustomLabel, Loadable } from '../../../../common';
import { EllipsisText } from '../../../../common/EllipsisText';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function DependencyDialog(props) {
    const { open, partnerId, dependency, partnerDependencyPending, deletePartnerPending, canDelete, handleRefresh,
        actions: { setDependencyDialog, getPartnerDependency, deletePartner } } = props;

    const classes = useStyles();

    useEffect(() => {
        if (open && partnerId) {
            getPartnerDependency(partnerId)
        }
    }, [open, partnerId, getPartnerDependency]);

    const handleDialogClose = () => {
        setDependencyDialog(null);
    }

    const handleDeleteCallback = () => {
        setDependencyDialog(null);
        handleRefresh();
    }

    const handleDelete = () => {
        deletePartner(partnerId, handleDeleteCallback);
    }

    return (
        (<Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={partnerDependencyPending || deletePartnerPending}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Dependencies
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleDialogClose}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    <Grid>
                        {dependency.length > 0 ?
                            <Typography>Following dependencies are found?</Typography> :
                            <Typography>No dependency found. Click to delete</Typography>
                        }
                    </Grid>
                    {dependency.length > 0 && <Grid className='pt-2' sx={{ml:'25px'}}>
                        <ol className='lower-alpha'>
                            {dependency?.map((item, index) =>
                                <li key={`key-${index}`}>
                                    <b>{item.name} </b>
                                    <Grid>
                                        <ul>
                                            {item?.dependents?.map((dep, pcIndex) => <li key={`contract-key-${pcIndex}`}>
                                                <CustomLabel label={`${dep.dependentType}(${dep.count.toString()})`} data={
                                                    <EllipsisText textArr={dep.names} />
                                                } />
                                            </li>)}
                                        </ul>
                                    </Grid>
                                </li>
                            )}
                        </ol>
                    </Grid>}
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    {canDelete && <Button
                        onClick={handleDelete}
                        variant="contained"
                        disabled={partnerDependencyPending || deletePartnerPending}
                        color="primary"
                    >
                        Delete
                    </Button>}
                </DialogActions>
            </Loadable >
        </Dialog>)
    );
}

export default DependencyDialog
