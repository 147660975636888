import { takeEvery, put, all, call } from 'redux-saga/effects';
import * as actions from '../actions/pages';
import * as claimActions from '../actions/claimAnalysis';
import * as modelActions from '../actions/modeling';
import * as Payers_API from '../api/payers';
import * as PRICER_PAYER_API from '../api/payerSetup';

import * as CONTRACTS_API from '../api/contracts';
import * as CLAIM_ANALYSIS_API from '../api/claimAnalysis';
import * as CHARGE_MASTER_API from '../api/chargeMaster';
import * as SHOPABLE_API from '../api/shoppables';
import * as ASSOCIATED_API from '../api/associated';
import * as Allowable_API from '../api/allowables';
import * as Pricer_Payers_API from '../api/pricerPayers'
import * as CODE_SERVICE_API from '../api/services';
import * as EXECUTIONS_API from '../api/executions';
import * as VENDORS_API from '../api/vendors';
import * as UPLOADS_API from '../api/uploads';
import * as MODELING_API from '../api/modeling';
import * as DRG_API from '../api/drg';
import * as OPPS_API from '../api/opps';
import * as EAPG_API from '../api/eapg';
import * as FEE_SCHEDULES_API_V2 from '../api/feeScheduleV2';
import * as CONTRACTS_FAMILY_API from '../api/contractsFamily';
import * as HS_API from '../api/hospitalServices';
import * as MODIFIERS_API from '../api/modifiers';

import * as APP_ID_API from '../api/appId';

import { sessionErrorHandling } from './session';
import { toLocalStorage } from '../../util/storage';

function getTable(actionName, getApiCall) {
    return function* (action) {
        const { callback, pagination, sort, filter, facility, significance, serviceId, codeType, modelId, showHiddenClaims } = action.payload;
        try {
            let options = {
                page: {
                    ...pagination,
                },
                filter: filter || null,
                sort: sort || [],
                facility:undefined
            };

            if (facility) {
                options = {
                    ...options,
                    facility: facility,
                }
            }
            
            yield put({ type: `${actionName}_PENDING` });

            const [payload, stats] = yield all([
                getApiCall( action.payload, options, significance, serviceId, codeType, modelId, showHiddenClaims),
                actionName === 'CLAIM_ANAYLYSIS_LANDING' ? call(CLAIM_ANALYSIS_API.getStats, options, significance) : null
            ]);

            if (actionName === 'CLAIM_ANAYLYSIS_LANDING' && stats) {
                yield put({ type: `${claimActions.CLAIM_ANAYLYSIS_STATISTICS}_FULFILLED`, payload: stats });
                // claim analysis grid response is saved in local storage for previous next button in claim details
                toLocalStorage('claimAnalysis-backup', [...payload.results.map(item => item.id)]);
            }

            if (actionName === 'TRIAGE_LANDING') {
                const stats = yield call(CLAIM_ANALYSIS_API.getTriageStats, options, showHiddenClaims);
                yield put({ type: `${claimActions.TRIAGE_STATISTICS}_FULFILLED`, payload: stats });
                toLocalStorage('triage-backup', [...payload.results.map(item => item.id)])
            }

            if (actionName === 'MODELING_EXECUTIONS_RESULT_LANDING') {
                const stats = yield call(MODELING_API.getExecutionResultsStats, options, modelId, showHiddenClaims);
                yield put({ type: `${modelActions.MODEL_EXECUTION_RESULTS_STATISTICS}_FULFILLED`, payload: stats });
                toLocalStorage('modelAnalysis-backup', [...payload.results.map(item => item.AnalysisIds)])
            }
            
            yield put({ type: `${actionName}_FULFILLED`, payload });
        } catch (error) {
            const { error: errorMessage } = (error && error.payload) || { error: '' };
            yield put({ type: `${actionName}_REJECTED`, payload: errorMessage });
            if (callback) {
                callback();
            }
            yield call(sessionErrorHandling, error);
        }
    }
}

function getTableFeeScheduleItems(actionName, getApiCall) {

    return function* (action) {
        const { callback, options } = action.payload;
        try {
            yield put({ type: `${actionName}_PENDING` });
            const payload = yield getApiCall(action.payload, options);
            yield put({ type: `${actionName}_FULFILLED`, payload });
        } catch (error) {
            const { error: errorMessage } = (error && error.payload) || { error: '' };
            yield put({ type: `${actionName}_REJECTED`, payload: errorMessage });
            if (callback) {
                callback();
            }
        }
    }
}

export const payersLanding = getTable(actions.PAYERS_LANDING, (payload, options) => {
    return call(Payers_API.getPayers, options);
});

export const contractsLanding = getTable(actions.CONTRACTS_LANDING, (payload, options) => {
    return call(CONTRACTS_API.list, options);
});

export const vendorsLanding = getTable(actions.VENDOR_CONTRACTS_LANDING, (payload, options) => {
    return call(VENDORS_API.vendorsList, options);
});

export const feeScheduleItemsLanding = getTableFeeScheduleItems(actions.CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING, (payload, options) => {
    return call(CONTRACTS_API.getFeescheduleRates, options);
});

export const contractedRatesLanding = getTable(actions.CONTRACTS_CONTRACTED_RATES_LANDING, (payload, options) => {
    return call(CONTRACTS_API.getContractedRates, options);
});

export const paymentsLanding = getTable(actions.PAYMENTS_LANDING, (payload, options) => {
    return call(CONTRACTS_API.paymentGroup, options);
});

export const payerContractsLanding = getTable(actions.PAYERS_CONTRACTS_LANDING, (payload, options) => {
    return call(Payers_API.listPayerContracts, options);
});

export const claimAnalysisLanding = getTable(actions.CLAIM_ANAYLYSIS_LANDING, (payload, options, significance) => {
    return call(CLAIM_ANALYSIS_API.list, options, significance);
});

export const triageLanding = getTable(actions.TRIAGE_LANDING, (payload, options, _, __, ___, ____, showHiddenClaims) => {
    return call(CLAIM_ANALYSIS_API.triageList, options, showHiddenClaims);
});

export const executionsLanding = getTable(actions.EXECUTIONS_LANDING, (payload, options) => {
    return call(EXECUTIONS_API.list, options);
});

export const chargeMasterLanding = getTable(actions.CHARGE_MASTER_LANDING, (payload, options) => {
    return call(CHARGE_MASTER_API.getChargesList, options);
});

export const shoppableLanding = getTable(actions.SHOPPABLE_LANDING, (payload, options) => {
    return call(SHOPABLE_API.getShoppables, options);
});

export const associatedLanding = getTable(actions.ASSOCIATED_LANDING, (payload, options) => {
    return call(ASSOCIATED_API.getAssociatedList, options);
});

export const allowablesLanding = getTable(actions.ALLOWABLE_LANDING, (payload, options) => {
    return call(Allowable_API.getAllowablesList, options);
});

export const pricerPayersLanding = getTable(actions.PRICER_PAYERS_LANDING, (payload, options) => {
    return call(Pricer_Payers_API.getPayers, options);
});

export const codeServiceDescriptionLanding = getTable(actions.CODE_SERVICE_DESCRIPTION_LANDING, (payload, options, significance, serviceId, codeType) => {
    return call(CODE_SERVICE_API.getDescription, options, serviceId, codeType);
});

export const uploadsLanding = getTable(actions.UPLOADS_LANDING, (payload, options) => {
    return call(UPLOADS_API.getUploadsData, options);
});

export const fileExecutionsLanding = getTable(actions.FILE_EXECUTIONS_LANDING, (payload, options) => {
    return call(UPLOADS_API.getFileExecutions, options);
});

export const estimateHistoryLanding = getTable(actions.ESTIMATOR_HISTORY_LANDING, (payload, options) => {
    return call(CLAIM_ANALYSIS_API.estimateHistoryLanding, options);
});

export const claimAuditsLanding = getTable(actions.CLAIM_AUDITS_LANDING, (payload, options) => {
    return call(CLAIM_ANALYSIS_API.listClaimAudits, options);
});

export const searchClaimsForModelingPaginated = getTable(actions.MODEL_CLAIM_SELECTION_LANDING, (payload, options) => {
    return call(MODELING_API.searchClaimsForModelingPaginated, options);
});

export const contractModelingLanding = getTable(actions.CONTRACT_MODELING_LANDING, (payload, options) => {
    return call(MODELING_API.contractModelingLanding, options);
});

export const modelExecutionsLanding = getTable(actions.MODELING_EXECUTIONS_LANDING, (payload, options) => {
    return call(MODELING_API.contractModelingExecutionsLanding, options);
});

export const drgLanding = getTable(actions.DRG_LANDING, (payload, options) => {
    return call(DRG_API.getDrgList, options);
});

export const oppsLanding = getTable(actions.OPPS_LANDING, (payload, options) => {
    return call(OPPS_API.getDrgList, options);
});

export const modelExecutionsResultLanding = getTable(actions.MODELING_EXECUTIONS_RESULT_LANDING, (payload, options, _, __, ___, modelId, showHiddenClaims) => {
    return call(MODELING_API.executionResultsLanding, options, modelId, showHiddenClaims);
});

export const remitPayersLanding = getTable(actions.REMIT_PAYERS_LANDING, (payload, options) => {
    return call(Payers_API.getRemitPayers, options);
});

export const rulesFromCodeServiceLanding = getTable(actions.CODE_SERVICE_GET_RULES_LANDING, (payload, options, _, serviceId) => {
    return call(CODE_SERVICE_API.getRulesFromCodeServiceId, options, serviceId);
});

export const eapgLanding = getTable(actions.EAPG_LANDING, (payload, options) => {
    return call(EAPG_API.getEAPGList, options);
});

export const contractsFamilyLanding = getTable(actions.CONTRACTS_FAMILY_LANDING, (payload, options) => {
    return call(CONTRACTS_FAMILY_API.getContractsFamilylist, options);
})

export const appIdLanding = getTable(actions.APP_ID_LANDING, (payload, options) => {
    return call(APP_ID_API.getAppIdList, options);
});

export const payersStatsLanding = getTable(actions.PAYERS_STATS_LANDING, (payload, options) => {
    return call(Payers_API.getPayersStats, options);
});

export const remitPayersStatsLanding = getTable(actions.REMIT_PAYERS_STATS_LANDING, (payload, options) => {
    return call(Payers_API.getRemitPayersStats, options);
});
export const clientFilesStatsLanding = getTable(actions.CLIENT_FILES_STATS_LANDING, (payload, options) => {
    return call(Payers_API.getClientFilesStats, options,payload);
});
export const stlLoadErrorsLanding = getTable(actions.STL_LOAD_ERRORS_LANDING, (payload, options) => {
    return call(Payers_API.getStlLoadErrors, options);
});

export const hospitalServicesLanding = getTable(actions.HOSPITAL_SERVICES_LANDING, (payload, options) => {
    return call(HS_API.gethospitalServices, options);
});

export const mrfModifiersLanding = getTable(actions.MRF_MODIFIERS_LANDING, (payload, options) => {
    return call(MODIFIERS_API.getMRFModifiers, options);
});

export const pricerExecutionsLanding = getTable(actions.PRICER_EXECUTIONS_LANDING, (payload, options) => {
    return call(EXECUTIONS_API.pricerExecutionList, options);
});

export const payerIdMappingLanding = getTable(actions.PAYER_ID_MAPPING_LANDING, (payload, options) => {
    return call(Payers_API.getPayerIdMappingList, options);
});

export const payerSetupLanding = getTable(actions.PAYER_SETUP_LANDING, (payload, options) => {
    return call(PRICER_PAYER_API.getPayerSetupData, options);
});

export default function* () {
    yield takeEvery(actions.PAYERS_LANDING, payersLanding);
    yield takeEvery(actions.CONTRACTS_LANDING, contractsLanding);
    yield takeEvery(actions.VENDOR_CONTRACTS_LANDING, vendorsLanding);
    yield takeEvery(actions.CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING, feeScheduleItemsLanding);
    yield takeEvery(actions.CONTRACTS_CONTRACTED_RATES_LANDING, contractedRatesLanding);
    yield takeEvery(actions.PAYMENTS_LANDING, paymentsLanding);
    yield takeEvery(actions.PAYERS_CONTRACTS_LANDING, payerContractsLanding);
    yield takeEvery(actions.CLAIM_ANAYLYSIS_LANDING, claimAnalysisLanding);
    yield takeEvery(actions.TRIAGE_LANDING, triageLanding);
    yield takeEvery(actions.CHARGE_MASTER_LANDING, chargeMasterLanding);
    yield takeEvery(actions.PAYER_SETUP_LANDING, payerSetupLanding);
    yield takeEvery(actions.SHOPPABLE_LANDING, shoppableLanding);
    yield takeEvery(actions.ASSOCIATED_LANDING, associatedLanding);
    yield takeEvery(actions.ALLOWABLE_LANDING, allowablesLanding);
    yield takeEvery(actions.EXECUTIONS_LANDING, executionsLanding);
    yield takeEvery(actions.PRICER_PAYERS_LANDING, pricerPayersLanding);
    yield takeEvery(actions.CODE_SERVICE_DESCRIPTION_LANDING, codeServiceDescriptionLanding);
    yield takeEvery(actions.UPLOADS_LANDING, uploadsLanding);
    yield takeEvery(actions.FILE_EXECUTIONS_LANDING, fileExecutionsLanding);
    yield takeEvery(actions.ESTIMATOR_HISTORY_LANDING, estimateHistoryLanding);
    yield takeEvery(actions.CLAIM_AUDITS_LANDING, claimAuditsLanding);
    yield takeEvery(actions.MODEL_CLAIM_SELECTION_LANDING, searchClaimsForModelingPaginated);
    yield takeEvery(actions.CONTRACT_MODELING_LANDING, contractModelingLanding);
    yield takeEvery(actions.MODELING_EXECUTIONS_LANDING, modelExecutionsLanding);
    yield takeEvery(actions.MODELING_EXECUTIONS_RESULT_LANDING, modelExecutionsResultLanding);
    yield takeEvery(actions.DRG_LANDING, drgLanding);
    yield takeEvery(actions.REMIT_PAYERS_LANDING, remitPayersLanding);
    yield takeEvery(actions.CODE_SERVICE_GET_RULES_LANDING, rulesFromCodeServiceLanding);
    yield takeEvery(actions.OPPS_LANDING, oppsLanding);
    yield takeEvery(actions.EAPG_LANDING, eapgLanding);
    yield takeEvery(actions.CONTRACTS_FAMILY_LANDING, contractsFamilyLanding);
    yield takeEvery(actions.APP_ID_LANDING, appIdLanding);
    yield takeEvery(actions.PAYERS_STATS_LANDING, payersStatsLanding);
    yield takeEvery(actions.REMIT_PAYERS_STATS_LANDING, remitPayersStatsLanding);
    yield takeEvery(actions.CLIENT_FILES_STATS_LANDING, clientFilesStatsLanding);
    yield takeEvery(actions.STL_LOAD_ERRORS_LANDING, stlLoadErrorsLanding);
    yield takeEvery(actions.HOSPITAL_SERVICES_LANDING, hospitalServicesLanding);
    yield takeEvery(actions.MRF_MODIFIERS_LANDING, mrfModifiersLanding);
    yield takeEvery(actions.PRICER_EXECUTIONS_LANDING, pricerExecutionsLanding);
    yield takeEvery(actions.PAYER_ID_MAPPING_LANDING, payerIdMappingLanding);
}