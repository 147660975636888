import { CircularProgress, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useData, useStatePtr } from "@pure-ptr/react";
import React, { useContext } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { getCasDenialsSummary } from "../../store/api/summaryDashboard";
import { asDollarsWithZero } from '../../util/format';
import { DashboardFiltersContext, useStyles } from './Dashboard';
import { useDispatch } from 'react-redux';
import { setMulti } from '../../store/actions/pages';

export const CasDenialsWidget = () => {
    const dispatch = useDispatch();
    
    const classes = useStyles();
    const { dateRange, history } = useContext(DashboardFiltersContext);

    const { data, isPending } = useData( async () => {
        const result = await getCasDenialsSummary(dateRange.startdate, dateRange.enddate); 
        return result;
    }, [ dateRange ]);

    const openPtr = useStatePtr( {} );

    const handleCasDenialsRowClick = (key) => {
        openPtr.set((prevState) => ({
            ...prevState, 
            [key]: !prevState[key],
        }));
    }

    const handleAmountClick = (contract, facility = null, casCodeObj = null) => {
        if(casCodeObj && facility && contract) {
            handleCasDenialsAmountClick(contract.contractName, facility.facilityId ?? "none", casCodeObj.casCode);
        }
        else if(facility && contract) {
            let casCodes = Array.from(new Set(facility.casCodes?.map(x=> x.casCode)) ?? [])?.join(',') ?? null;
            if(casCodes && casCodes.length > 0) {
                handleCasDenialsAmountClick(contract.contractName, facility.facilityId ?? "none", casCodes);
            }
            else {
                handleCasDenialsAmountClick(contract.contractName, facility.facilityId ?? "none");
            }
        }
        else {
            let casCodes = Array.from(new Set(contract.facilities?.flatMap(f => f.casCodes?.map(x=> x.casCode))) ?? [])?.join(',') ?? null;
            if(casCodes && casCodes.length > 0) {
                handleCasDenialsAmountClick(contract.contractName, null, casCodes);
            }
            else {
                handleCasDenialsAmountClick(contract.contractName);
            }
        }
    }

    const handleCasDenialsAmountClick = (contractName, facilityId = null, casCode = null) => {
        sessionStorage.removeItem("duplicated");
        let filters = [
            {
                actualFilter: true, 
                equator: "greater_than", 
                field: "variance", 
                value: 0
            },
            {
                actualFilter: true, 
                equator: "equals", 
                field: "contract.name", 
                value: contractName
            }
        ];

        if(facilityId != null) {
            filters.push({
                actualFilter: true, 
                equator: "equals", 
                field: "facilityId", 
                value: facilityId
            });
        }

        if(casCode != null) {
            filters.push({
                actualFilter: true, 
                equator: "equals", 
                field: "reasoncode", 
                value: casCode
            });
        }

        dispatch( setMulti( 'claimAnalysis-landing', {
            filter: filters,
            selectedFilter: null,
            customFilter: [
                {
                    customFilter: true, 
                    equator: "greater_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRange.startdate,
                    relativeFilter:dateRange.relativeFilter
                },
                {
                    customFilter: true, 
                    equator: "less_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRange.enddate,
                    relativeFilter:dateRange.relativeFilter
                },
                {
                    customFilter: true, 
                    equator: "equals", 
                    field: "isDenial", 
                    value: true
                }
            ],
            list: [],
            significance: false,
            searchTerm:"",
            ignoreAcknowledgement:false,
            showIgnoredFilterDialog:false
        }));

        history.push("/cms/claimAnalysis/fromSummary");
    }

    return isPending ? (
        <CircularProgress />
        ) : (
        <Table>
            <TableHead>
            <TableRow key='CasDenialsHeader'>
                <TableCell>Contracts</TableCell>
                <TableCell>Count</TableCell>
                <TableCell>Amount</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {data?.slice(0, 10)?.map((entry) => (
                <>
                <TableRow key={entry.contractName} className={entry.facilities?.length > 0 ? classes.denialsGroup1Row : ''}>
                    <TableCell>
                        {entry.facilities?.length > 0 && (
                            <IconButton sx={{padding: "0 10px 0 0"}} onClick={() => handleCasDenialsRowClick(entry.contractName)}>
                                {openPtr.value[entry.contractName] ? <ArrowDropDownIcon /> : <ArrowRightIcon />  }
                            </IconButton>
                        )}
                        {entry.contractName}
                    </TableCell>
                    <TableCell><Typography variant="body2">{entry.totalClaims ?? 0}</Typography></TableCell>
                    <TableCell><Typography onClick={()=> handleAmountClick(entry)} variant="body2" className={entry.totalAmount ? classes.link : ''}>{entry.totalAmount ? asDollarsWithZero(entry.totalAmount) : 'N/A'}</Typography></TableCell>
                </TableRow>

                {entry.facilities?.length > 0 && (
                    <TableRow key={`${entry.contractName}-childRow`}>
                        <TableCell colSpan={3} sx={{padding: "0px", border: 0 }}>
                            <Collapse in={openPtr.value[entry.contractName]} timeout="auto" unmountOnExit>
                            <Table sx={{width: "87%", margin: "0px 50px"}}>
                                <TableBody>
                                {entry.facilities?.map((facility, index) => (
                                    <>
                                    <TableRow key={`${entry.contractName}-${index}`} className={facility?.casCodes?.length > 0 ? classes.denialsGroup2Row : ''}>
                                        <TableCell>
                                            {facility.casCodes?.length > 0 && (
                                                <IconButton sx={{padding: "0 10px 0 0"}} onClick={() => handleCasDenialsRowClick(`${entry.contractName}-facility-${index}`)}>
                                                    {openPtr.value[`${entry.contractName}-facility-${index}`] ? <ArrowDropDownIcon /> : <ArrowRightIcon />  }
                                                </IconButton>
                                            )}
                                            {facility?.facilityName ?? "N/A"}
                                        </TableCell>
                                        <TableCell><Typography variant="body2">{facility.totalClaims ?? 0}</Typography></TableCell>
                                        <TableCell><Typography onClick={()=> handleAmountClick(entry, facility)} variant="body2" className={facility.totalAmount ? classes.link : ''}>{facility.totalAmount ? asDollarsWithZero(facility.totalAmount) : 'N/A'}</Typography></TableCell>
                                    </TableRow>
                                    {facility.casCodes?.length > 0 && (
                                        <TableRow key={`${entry.contractName}-casCode-${index}-childRow`}>
                                            <TableCell colSpan={3} sx={{padding: "0px", border: 0 }}>
                                                <Collapse in={openPtr.value[`${entry.contractName}-facility-${index}`]} timeout="auto" unmountOnExit>
                                                <Table sx={{width: "100%", margin: "0px 8px"}}>
                                                    <TableBody>
                                                    {facility.casCodes?.map((cas, ind) => (
                                                        <TableRow key={`${entry.contractName}-${cas.casCode}-${ind}`}>
                                                            <TableCell>
                                                                <Tooltip title={cas.fullDesc}>
                                                                    <Typography variant='body2' ><strong>{cas.casCode}</strong> - {cas.shortDesc}</Typography>
                                                                </Tooltip>
                                                                <Typography onClick={()=> handleAmountClick(entry, facility, cas)} variant="subtitle1" className={classes.link} sx={{ display: "block", paddingTop: "0.5rem" }}>{`${asDollarsWithZero(cas.totalAmount)} (${cas.totalClaims} claims)`}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    </TableBody>
                                                </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    </>
                                ))}
                                </TableBody>
                            </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
                
                </>
            ))}
            </TableBody>
        </Table>
    )
}