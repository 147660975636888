import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/AddCircle';
import { Delete as DeleteIcon } from "@mui/icons-material";
import Helmet from 'react-helmet';
import { pageStore, XDataGrid } from "../../../common";
import {
    Grid,
    IconButton,
    Tooltip,
    Box,
} from "@mui/material";
import { useData, PurePtr, useStatePtr } from "@pure-ptr/react";

import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useDispatch } from "react-redux";
import {
    createStringDataField,
    createIconDataField,
    createStringFieldFromArrayJoin,
} from "../../../util/format";
import {
    StringEquators,
} from "../../../store/constants/pages";
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../../partials/content/Portlet";
import { TableLevelAction } from "../../../common";
import { DeleteConfirmation } from "../../../common/GenericDeleteConfirmationDialog/DeleteConfirmationDialog";
import {
    getPayerSetupDataDependencies,
    deleteSelectedPayerSetupData,
    checkPricerPayerAssociation,
    createPricerPayer,
    updatePricerPayer,
    getAllPricerPayerContracts
} from "../../../store/api/payerSetup";
import {
    resetPage,
    set,
    payerSetupLanding,
} from "../../../store/actions/pages";
import { DependentsList } from "../../../common";
import { PayerContractDialog } from "../../payerContractsPage/PayerContractDialog";
import { sessionErrorHandling } from "../../../store/sagas/session"; 
import { errorToastify } from "../../../util/toast";


const { usePageStore, useGridFilters } = pageStore("payer-setup-landing", {
});
const DEFAULT_HIDDEN_FIELDS = [];
const DEFAULT_SORT = [];

export const PricerPayerSetup = (params) => {
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [openDeleteDialog, setOpenDeleteDialog] = useState({
        open: false,
        rowData: null,
    });
    const [openCreateUpdateDialog, setOpenCreateUpdateDialog] = useState({
        open: false,
        rowData: null,
    });
    const [
        pricerPayerSetupCreateUpdatePayload,
        setPricerPayerSetupCreateUpdatePayload,
    ] = useState({ createPayload: {}, editPayload: {} });

    const selectedPricerPayersIds = useStatePtr<number[]>([]);

    const dispatch = useDispatch();

    const payerSetupData = usePageStore((s) => {
        return s.list;
    });

    const pending = usePageStore((s) => s.pending);

    function handleRefresh() {
        dispatch(set("payer-setup-landing", "list", []));
        setRefresh(Promise.resolve());
    }

    useEffect(() => {
        const pageName = { name: "payer-setup-landing" };
        dispatch(set(pageName.name, "waitForCriteriaFilter", false));
        return () => {
            dispatch(resetPage(pageName.name));
        };
    }, [dispatch]);

    const resetAllToInitials = () => {
        setPricerPayerSetupCreateUpdatePayload({
            editPayload: {},
            createPayload: {},
        });
        handleRefresh();
        setOpenCreateUpdateDialog({ open: false, rowData: null });
    };

    const {
        data: dependencies,
        isPending,
        error,
    } = useData(async () => {
        if (
            selectedPricerPayersIds?.value?.length === 0 ||
            !openDeleteDialog?.open
        )
            return;

        const allowableResponse = await getPayerSetupDataDependencies({
            pricerPayerContractIds: [...selectedPricerPayersIds?.value],
        });
      
        return allowableResponse;
    }, [selectedPricerPayersIds?.value, openDeleteDialog?.open]);

    const {
        data,
        isPending: isCreatingPricerSetup,
        error:createError,
    } = useData(async () => {
        if (
            Object.keys(pricerPayerSetupCreateUpdatePayload?.createPayload)
                ?.length === 0
        )
            return;
        const createSetupResponse = await createPricerPayer(
            pricerPayerSetupCreateUpdatePayload?.createPayload
        );
        resetAllToInitials();
        return createSetupResponse;
    }, [pricerPayerSetupCreateUpdatePayload?.createPayload]);


    const {
        data:allPayerContracts,
    } = useData(async () => {
        const payerContracts = await getAllPricerPayerContracts();
        return payerContracts;
    }, []);


    const {
        // data,
        isPending: isUpdatingPricerSetup,
        error:updateError,
    } = useData(async () => {
        if (
            Object.keys(pricerPayerSetupCreateUpdatePayload?.editPayload)
                ?.length === 0
        )
            return;
        const updateSetupResponse = await updatePricerPayer(
            pricerPayerSetupCreateUpdatePayload?.editPayload
        );
        resetAllToInitials();
        return updateSetupResponse;
    }, [pricerPayerSetupCreateUpdatePayload?.editPayload]);

    const {
        isPending: isDeleting,
        error: deletingApiError,
        reload,
    } = useData(async () => {
        if (selectedPricerPayersIds?.value?.length === 0) return;
        await deleteSelectedPayerSetupData({
            pricerPayerContractIds: [...selectedPricerPayersIds?.value],
        });
        handleRefresh();
        setOpenDeleteDialog({ open: false, rowData: null });
    }, []);

    const checkAssociations = async (options, callback) => {
        const associationData = await checkPricerPayerAssociation(options?.ids);

        if (callback) {
            callback(associationData);
        }
    };

    useEffect(() => {
        if (!createError) return;

        errorToastify(createError?.payload?.error);
    }, [createError]);

    useEffect(() => {
        if (!updateError) return;
        
        errorToastify(updateError?.payload?.error);
    }, [updateError]);


    return (
        <>
    <Helmet title="Pricer Payer Contract" />
        <Portlet>
            <PortletHeader
                title={"Payer Setup"}
                name="payer-setup-landing"
                showSearchFilters={true}
                showSearchFiltersDropdown={false}
                handleRefresh={handleRefresh}
            />
            <PortletBody>
                <GridLevelActions
                    dispatch={dispatch}
                    loading={false}
                    setOpen={() =>
                        setOpenDeleteDialog({ ...openDeleteDialog, open: true })
                    }
                    setOpenCreateUpdateDialog={() =>
                        setOpenCreateUpdateDialog({
                            ...openCreateUpdateDialog,
                            open: true,
                        })
                    }
                    handleRefresh={handleRefresh}
                />

                <PayerSetupDataGrid
                    loading={pending}
                    data={payerSetupData}
                    selected={selectedPricerPayersIds}
                    refresh={refresh}
                    dispatch={dispatch}
                    setRowData={(rowData) =>
                        setOpenCreateUpdateDialog({ open: true, rowData })
                    }
                />
            </PortletBody>

            <DeleteConfirmation
                defaultSelected={selectedPricerPayersIds?.value || []}
                handleDelete={reload}
                handleClose={() =>
                    setOpenDeleteDialog({ open: false, rowData: null })
                }
                isDeleting={isDeleting !== null}
                DELETE_TEXT={dependencies?.records?.length>0 ? "Following dependencies are found":"Are you sure you want to delete these Items ?"}
                isPending={isPending !== null}
                defaultDeleteState={openDeleteDialog}
                canDelete={dependencies?.canDelete}
            >
                <DependentsList
                    dependents={dependencies?.records || []}
                    isSimpleDependency={true}
                />
            </DeleteConfirmation>

            <PayerContractDialog
                createPricerPayerSetup={(payload) =>
                    setPricerPayerSetupCreateUpdatePayload({
                        createPayload: payload,
                        editPayload: {},
                    })
                }
                updatePricerPayerSetup={(payload) =>
                    setPricerPayerSetupCreateUpdatePayload({
                        createPayload: {},
                        editPayload: payload,
                    })
                }
                checkAssociations={checkAssociations}
                payerContract={openCreateUpdateDialog?.rowData}
                open={openCreateUpdateDialog?.open}
                handleClose={() =>
                    setOpenCreateUpdateDialog({
                        rowData: null,
                        open: false,
                    })
                }
                isPricerSetup={true}
                isCreatingPricerSetup={
                    isCreatingPricerSetup !== null ||
                    isUpdatingPricerSetup !== null
                }
                allPayerPricerContracts={allPayerContracts}
            />
        </Portlet>
        </>
    );
};

const GridLevelActions = ({
    dispatch,
    loading,
    setOpen,
    setOpenCreateUpdateDialog,
    handleRefresh
}) => {
    return (
        <TableLevelAction
            handleRefresh={() => handleRefresh()}
            actions={() => (
                <>
                    <Grid item>
                        <Tooltip title="Create Pricer Setup">
                            <IconButton
                                aria-label="create"
                                size="small"
                                onClick={() => setOpenCreateUpdateDialog()}
                                disabled={loading}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Delete">
                            <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => setOpen()}
                                disabled={loading}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </>
            )}
            loading={loading}
        />
    );
};

const PayerSetupDataGrid = ({
    refresh,
    data,
    loading,
    dispatch,
    selected,
    setRowData,
}) => {
    const fields = useMemo(
        () => [
            createStringDataField("displayName", "MRF Display Name", {
                sortable: true,
                equators: StringEquators,
            }),
            createStringFieldFromArrayJoin(
                "contracts",
                "Contract Name",
                "name",
                { sortable: false, hideFromFilter: true, truncate: true }
            ),
            createStringDataField("payerPlan.name", "Plan", {
                sortable: true,
                default: true,
                equators: StringEquators,
                secondLevel: "name",
                hideFromFilter: true,
            }),
            createIconDataField("includeInMachineReadable", "MRF", {
                hideFromFilter: true,
                icon: "fas fa-check-circle",
                sortable: true,

                renderCell: (params) => {
                    return params?.value ? (
                        <i style={{fontSize:"20px"}} className="fas fa-check-circle text-success ml-2 null"></i>
                    ) : (
                        <i style={{fontSize:"20px"}} className="fas fa-check-circle text-secondary ml-2 null"></i>
                    );
                },
            }),
        ],
        []
    );

    const tableFilter = useGridFilters();

    const PayerSetupActions = useCallback(
        (props) => [
            <GridActionsCellItem
                icon={<EditIcon sx={{color:"#5c79ff"}}/>}
                label="Edit"
                onClick={() => setRowData(props.row)}
            />,
        ],
        []
    );

    return (
        <XDataGrid
            name="payer-setup-landing"
            fields={fields}
            loading={loading}
            data={data || []}
            paginationFilters={tableFilter}
            onLoad={(sort, filter, pagination) =>
                dispatch(payerSetupLanding(pagination, sort, filter))
            }
            selectedIdsPtr={selected}
            refresh={refresh}
            defaultSort={DEFAULT_SORT}
            criteriasSaveEnabled={true}
            actions={PayerSetupActions}
            showSearchFilters={true}
            defaultHiddenFields={DEFAULT_HIDDEN_FIELDS}
        />
    );
};
