import React from "react";
import { Box } from "@mui/material";
const TypingDots = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={0.5}
    >
      {[...Array(3)].map((_, i) => (
        <Box
          key={i}
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "grey",
            animation: "typingAnimation 1.5s infinite",
            animationDelay: `${i * 0.2}s`,
          }}
        />
      ))}
      <style>
        {`
          @keyframes typingAnimation {
            0% { opacity: 0.3; transform: translateY(0px); }
            50% { opacity: 1; transform: translateY(-4px); }
            100% { opacity: 0.3; transform: translateY(0px); }
          }
        `}
      </style>
    </Box>
  );
};
export default TypingDots;