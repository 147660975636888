import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ClientConfigurationPageComponent from './ClientConfigurationPage';
import { createConfiguration,createAccountConfiguration, deleteConfiguration, get, getConfiguration, getAccountConfiguration,resetClientConfiguration,resetClientAccountConfiguration, updateConfiguration,updateAccountConfiguration } from '../../../../store/actions/clients';
import { createClientSelector, selectClientConfiguration,selectClientAccountConfiguration } from '../../../../store/selectors/clients';
import { RootState } from "../../../../store/reducers";

const selectClientId = (state,props) => props.match.params.clientId;


const selectInitialValues = createSelector(selectClientConfiguration,
    (clientConfigs) => {
        if (clientConfigs && clientConfigs.configuration) {
            const payload = {
                prefixExcluded: clientConfigs?.configuration?.prefixExcluded,
                postfixExcluded: clientConfigs?.configuration?.postfixExcluded,
                fileExtensionsExcluded: clientConfigs?.configuration?.fileExtensionsExcluded,
                notifyOnFileNotReceived: clientConfigs?.configuration?.notifyOnFileNotReceived,
                notifyAfterDaysFileNotReceived: clientConfigs?.configuration?.notifyAfterDaysFileNotReceived,
                customClaimStatusEnabled: clientConfigs?.configuration?.customClaimStatusEnabled,
                summaryDashboardEnabled: clientConfigs?.configuration?.summaryDashboardEnabled ?? true,
            }
            return payload;
        }
        return {
            prefixExcluded: '',
            postfixExcluded: '',
            fileExtensionsExcluded: '',
            notifyOnFileNotReceived: false,
            notifyAfterDaysFileNotReceived: 0,
            customClaimStatusEnabled: false,
            summaryDashboardEnabled: true
        }
    }
);

const selectInitialValuesAccount = createSelector(selectClientAccountConfiguration,
    (clientAccountConfigs) => {
        if (clientAccountConfigs) {
            const payload = {
                mergeClaims:clientAccountConfigs?.mergeClaims,
                accountIdentifierNumbers:clientAccountConfigs?.accountIdentifierNumbers ?? '',
                updatedAt:clientAccountConfigs?.updatedAt,
                updatedBy:clientAccountConfigs?.updatedBy,          
            }
            return payload;
        }
        return {
            mergeClaims: false,
            accountIdentifierNumbers:''
        }
    }
);


const selectIsEditMode = createSelector(selectClientConfiguration,
    (clientConfigs) => {
        if (clientConfigs && clientConfigs?.configuration) {
            return true
        }
        return false
    }
);


const selectIsAccountEditMode = createSelector(selectClientAccountConfiguration,
    (clientAccountConfigs) => {
        if (clientAccountConfigs && clientAccountConfigs?.updatedAt) {
            return true
        }
        return false
    }
);

const selectClient = createClientSelector(selectClientId);


export const ClientConfigurationPage = injectIntl(
    connect(
        (state : RootState, props) => ({
            initialValues: selectInitialValues(state as never),
            initialValuesAccount:selectInitialValuesAccount(state as never),
            clientId: selectClientId(state,props),
            isEdit: selectIsEditMode(state as never),
            isAccountEdit:selectIsAccountEditMode(state as never),
            configuration: selectClientConfiguration(state as never),
            client: selectClient(state, props),

            getClientPending: state.clients.getConfiguration.pending,
            getClientAccountPending: state.clients.getAccountConfiguration.pending,


            createClientPending: state.clients.createConfiguration.pending,

            createClientAccountPending: state.clients.createAccountConfiguration.pending,


            updateClientPending: state.clients.updateConfiguration.pending,
            updateAccountPending: state.clients.updateAccountConfiguration.pending,


            deleteClientPending: state.clients.deleteConfiguration.pending,
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                getConfiguration: getConfiguration,
                getAccountConfiguration: getAccountConfiguration,
                createConfiguration: createConfiguration,
                updateConfiguration: updateConfiguration,
                updateAccountConfiguration,
                createAccountConfiguration,
                deleteConfiguration: deleteConfiguration,
                resetClientConfiguration: resetClientConfiguration,
                resetClientAccountConfiguration:resetClientAccountConfiguration,
                getClient: get
            }, dispatch)
        })
    )(ClientConfigurationPageComponent));