import React from 'react'
import { EllipsisText } from '../EllipsisText';

function DependentsList({ dependents = [],isSimpleDependency=false }) {


    const TreeNode = ({ node }) => {
        return (
            <ol className='lower-alpha pl-3'>
                <li><b>{node.name}</b></li>
                {(node.childDependents && (node.childDependents.length > 0 || node?.dependents?.length>0)) || (isSimpleDependency  && node?.dependents?.length>0) && (
                    <ul className='pl-3'>
                        <ul className='pl-3'>
                            {node?.dependents?.map(dpt => <li style={{fontWeight: 500}} >{dpt.dependentType} ({dpt.count}): {dpt.dissociationMessage ? dpt.dissociationMessage : ' '}
                                <EllipsisText textArr={dpt.names} />
                            </li>)}
                        </ul>
                        {node?.childDependents?.map(childNode => (
                            <TreeNode key={childNode.name} node={childNode} />
                        ))}
                    </ul>
                )}
            </ol>
        );
    };

    const Tree = ({ data }) => {
        return (
            <div>
                {data.map(rootNode => (
                    <TreeNode key={rootNode.name} node={rootNode} />
                ))}
            </div>
        );
    };

    return (
        <ul>
            <Tree data={dependents} />
        </ul>
    );
}

export default DependentsList
