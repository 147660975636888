import { FieldArray } from "formik";
import React, { useEffect, useState } from "react";

import { Button, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete } from '@mui/material';
import { asDate, dateRangeOverlaps } from "../../../../util/date";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    portlet: {
        'box-shadow': 'none',
    },
    formControl: {
        width: '85%',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    autoComplete: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
    clearButton: {
        marginTop: theme.spacing(3),
        marginLeft: '10px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    effectiveBox: {
        height: '100%',
        paddingTop: '25px'
    },
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        width: 400,
        border: `1px solid ${theme.palette.primary.main}`,
    },
}));

export default function ContractOPPSSelectionCriteriaFieldArray({
    values, touched, errors, oppsList, setFieldValue, disabled
}) {
    const [customErrors, setCustomErrors] = useState([]);

    const classes = useStyles();

    useEffect(() => {
        if(disabled) {
            handleClearFields();
        }
    }, [disabled]);

    function getError(key, index) {
        if (errors.contractOppsconfigurationXref) {
            if (errors.contractOppsconfigurationXref[index]) {
                return errors.contractOppsconfigurationXref[index][key];
            }
            return false;
        }
        return false;
    }

    function handleOPPSChangeHandler(index, criteriaObj) {
        if (criteriaObj) {
            setFieldValue(`contractOppsconfigurationXref.${index}.oppsConfigurationId`, criteriaObj);

            const tempXref = [...values.contractOppsconfigurationXref];
            tempXref[index].oppsConfigurationId = criteriaObj;

            const filteredConfgs = tempXref.filter(item => item.oppsConfigurationId)
            const dateAlreadyExist = filteredConfgs.find(item => {
                const isDateOverlapping = dateRangeOverlaps(criteriaObj.effectiveFrom, criteriaObj.effectiveTill, item.oppsConfigurationId.effectiveFrom, item.oppsConfigurationId.effectiveTill)

                return isDateOverlapping && item.oppsConfigurationId.id !== criteriaObj.id;
            });

            if (dateAlreadyExist) {
                const temp = [...customErrors];
                temp[index] = 'Configuration with same date range already exists. Please select different configuration.'
                setCustomErrors(temp);
                setFieldValue(`contractOppsconfigurationXref.${index}.customPPSError`, 'error');
            } else {
                setFieldValue(`contractOppsconfigurationXref.${index}.customPPSError`, undefined);
                const temp = [...customErrors];
                temp[index] = null
                setCustomErrors(temp);
            }
        } else {
            setFieldValue(`contractOppsconfigurationXref.${index}.oppsConfigurationId`, criteriaObj);
            setFieldValue(`contractOppsconfigurationXref.${index}.effectiveFrom`, '');

            const temp = [...customErrors];
            temp[index] = null
            setCustomErrors(temp);
            setFieldValue(`contractOppsconfigurationXref.${index}.customPPSError`, undefined);
        }

    }

    function handlePercentageChage(e, index) {
        setFieldValue(`contractOppsconfigurationXref.${index}.percentage`, e.target.value);
    }

    function handleEffectiveFromChange(e, index) {
        setFieldValue(`contractOppsconfigurationXref.${index}.effectiveFrom`, e.target.value);
        if(index > 0) {
            const previousDate = new Date(values.contractOppsconfigurationXref[index - 1].effectiveFrom);
            const currentDate = new Date(e.target.value);

            if (currentDate <= previousDate) {
                const temp = [...customErrors];
                temp[index] = 'Configuration with same date already exists. Please select different date.'
                setCustomErrors(temp);
                setFieldValue(`contractOppsconfigurationXref.${index}.customPPSError`, 'error');
            } else {
                setFieldValue(`contractOppsconfigurationXref.${index}.customPPSError`, undefined);
                const temp = [...customErrors];
                temp[index] = null
                setCustomErrors(temp);
            }
        }
        else {
            setFieldValue(`contractOppsconfigurationXref.${index}.customPPSError`, undefined);
            const temp = [...customErrors];
            temp[index] = null
            setCustomErrors(temp);
        }
    }

    function handleClearFields() {
        setFieldValue(`contractOppsconfigurationXref`, [{
            oppsConfigurationId: null,
            equator: '',
            value: '',
            percentage: '100',
            effectiveFrom: ''
        }]);

        setCustomErrors([]);
    }

    const renderContractOppsconfigurationXref = (remove, insert) => {
        return (
            (<Grid item xs={12}>
                {values.contractOppsconfigurationXref.map((drgCriteria, index) =>
                    <Grid container>
                        <Grid item xs={3}>
                            <TextField
                                type="text"
                                label="Name"
                                disabled={true}
                                name={`contractOppsconfigurationXref.${index}.name`}
                                className={classes.textField}
                                margin="normal"
                                value={"OPPS"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {/* <Autocomplete
                                disabled={disabled}
                                className={classes.autoComplete}
                                value={drgCriteria?.oppsConfigurationId}
                                disableClearable={false}
                                onChange={(e, value) => { handleOPPSChangeHandler(index, value) }}
                                isOptionEqualToValue={(option, value) => option === value}
                                options={oppsList}
                                getOptionLabel={(option) => `${option.name}`}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        name={`contractOppsconfigurationXref.${index}.oppsConfigurationId`}
                                        label="OPPS"
                                        margin="normal"
                                        error={customErrors[index]}
                                        helperText={customErrors[index]}
                                    />
                                }
                            /> */}
                        </Grid>

                        {/* <Grid item xs={2}>
                            {drgCriteria?.oppsConfigurationId &&
                                <Grid className={classes.effectiveBox}>
                                    <Grid><b>Effective Range</b></Grid>
                                    <span>
                                        {asDate(drgCriteria?.oppsConfigurationId?.effectiveFrom)}
                                        {' to '}
                                        {asDate(drgCriteria?.oppsConfigurationId?.effectiveTill)}
                                    </span>
                                </Grid>
                            }
                        </Grid> */}
                        <Grid item xs={2}>
                            <TextField
                                disabled={disabled}
                                type="date"
                                label="Effective From"
                                name={`contractOppsconfigurationXref.${index}.effectiveFrom`}
                                className={classes.textField}
                                margin="normal"
                                value={drgCriteria.effectiveFrom}
                                onChange={e => handleEffectiveFromChange(e, index)}
                                helperText={getError('effectiveFrom', index)}
                                error={getError('effectiveFrom', index)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                disabled={disabled}
                                type="number"
                                label="Percentage"
                                name={`contractOppsconfigurationXref.${index}.percentage`}
                                className={classes.textField}
                                margin="normal"
                                value={drgCriteria.percentage}
                                onChange={e => handlePercentageChage(e, index)}
                                helperText={getError('percentage', index)}
                                error={getError('percentage', index)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container>
                            {(index >= 0 && values.contractOppsconfigurationXref.length > 1) && (
                                <IconButton
                                    disabled={disabled}
                                    edge="start"
                                    aria-label="Delete"
                                    onClick={() => {
                                        remove(index);
                                        const temp = [...customErrors];
                                        temp[index] = null
                                        setCustomErrors(temp);
                                        setFieldValue(`contractOppsconfigurationXref.${index}.customPPSError`, undefined);
                                    }}
                                    className={classes.iconButton}
                                    size="large">
                                    <DeleteIcon />
                                </IconButton>
                            )}
                            {index === 0 && (
                                <>
                                <IconButton
                                    disabled={disabled}
                                    edge="start"
                                    color="primary"
                                    aria-label="Add"
                                    onClick={() => insert(values.contractOppsconfigurationXref.length, {
                                        oppsConfigurationId: null,
                                        effectiveFrom: '',
                                        percentage: 100,
                                    })}
                                    className={classes.iconButton}
                                    size="large">
                                    <AddCircleIcon />
                                </IconButton>
                                {/* <Button
                                    disabled={disabled}
                                    size="small"
                                    onClick={handleClearFields}
                                    className={classes.clearButton}
                                    startIcon={<DeleteIcon />}
                                >
                                    Clear All
                                </Button> */}
                                </>
                            )}
                            </Grid>
                        </Grid>

                    </Grid>
                )}
            </Grid>)
        );
    }

    return (
        <FieldArray
            name="contractOppsconfigurationXref"
            render={({ remove, insert }) => (
                <Grid container>
                    {disabled ? 
                    <Tooltip
                    arrow
                    classes={{ tooltip: classes.tooltip }}
                    title={<Typography color="inherit">{'OPPS is disabled because EAPG is enabled.'}</Typography>}
                    >
                        {renderContractOppsconfigurationXref(remove, insert)}
                    </Tooltip> : renderContractOppsconfigurationXref(remove, insert) }
                </Grid>
            )}
        />
    );
}
