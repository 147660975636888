import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useStatePtr } from '@pure-ptr/react';
import React, { useEffect } from 'react';
import { XClientDataGrid } from '../../../../common';
import RefreshIcon from '@mui/icons-material/Refresh';

const DEFAULT_SORT = [{ id: 'updatedAt', direction: 'desc' }];

function ClaimStatusLogs({ open, analysisId, fields, filteredData, order, sort, claimStatusLogsPending, 
    actions: { getClaimStatusLogs, setOrder, setSort, setClaimStatusHistoryDialog } }) {

    useEffect(() => {
        if(open && analysisId) {
            getClaimStatusLogs(analysisId);
        }
    }, [open, analysisId, getClaimStatusLogs]);

    const handleRefresh = () => {
        getClaimStatusLogs(analysisId);
    }

    const handleClose = () => {
        setClaimStatusHistoryDialog(false);
    }

    const sortPtr = useStatePtr( DEFAULT_SORT );
    return (
        (<div>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="claim-status_logs-dialog"
            >
                <DialogTitle style={{ cursor: 'move' }} id="claim-status_logs-dialog">
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            Claim Status History
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={handleClose} size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <XClientDataGrid
                        fields={fields}
                        data={filteredData}
                        sortPtr={sortPtr}
                        loading={claimStatusLogsPending}
                        hideFooter

                        slots={{
                            toolbar: Toolbar
                        } as any }

                        slotProps={{
                            toolbar: {
                                handleRefresh
                            } as any
                        }}
                    />
                </DialogContent>
            </Dialog>
        </div>)
    );
}

const Toolbar = ({ handleRefresh }) =>
    <GridToolbarContainer>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleRefresh}>
            <RefreshIcon />
        </IconButton>
    </GridToolbarContainer>
    
export default ClaimStatusLogs;