import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import UsersPageComponent from './UsersPage';
import { list, deleteUser } from '../../../store/actions/users';
import { set } from '../../../store/actions/pages';
import { selectUsers } from '../../../store/selectors/users';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { createIconDataField, createStringDataField, createTimestampField } from '../../../util/format';
import { RootState } from '../../../store/reducers';

const DEFAULT_SORT = [{ id: 'clientName', orderBy: 'asc' }];

const selectFields = createSelector(
    () => [
        createStringDataField('username', 'User Name', { sortable: true }),
        createStringDataField('name', 'Name', { sortable: true }),
        createStringDataField('clientName', 'Client Name', { sortable: true }),
        createStringDataField('partnerName', 'Partner Name', { sortable: true }),
        createStringDataField('email', 'Email', { sortable: true }),
        createStringDataField('role', 'Role', { sortable: true }),
        createTimestampField('lastLoggedIn', 'Last Logged In', { sortable: true, fieldType: 'Date' }),
        createIconDataField('userActive', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['users-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['users-landing'].filter,
    selectSort,
    selectUsers,
);

export const UsersPage = injectIntl(
    connect(
        (state: RootState, props) => ({
            order: state.pages['users-landing'].order,
            filter: state.pages['users-landing'].filter,
            sort: selectSort(state),
            users: selectUsers(state, props),
            usersFilteredData: selectFilteredData(state),
            fields: selectFields(state as never),
            userListPending: state.users.list.pending,
            userDeletePending: state.users.delete.pending,
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                usersList: list,
                userDelete: deleteUser,
                setFilter: (value) => set('users-landing', 'filter', value),
                setOrder: (value) => set('users-landing', 'order', value),
                setSort: (value) => set('users-landing', 'sort', value),
            }, dispatch)
        })
    )(UsersPageComponent));