import { DataGridPro, GridActionsCellItem, GridCellParams, GridColDef, GridRowEditStopReasons, GridRowId, GridRowModes, GridRowModesModel, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { PureObject, PurePtr, useLocalStoragePtr, useStatePtr } from "@pure-ptr/react";
import React, { ComponentProps, useCallback, useContext, useEffect, useMemo } from "react";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useWindowEvent } from "../../util";
import { Field } from "../dataTableHeader/DataTableHeader";
import { createActionsField, dataGridStyles, SortOptions } from "./common";
import { translateLegacyFields } from "./LegacyGridColumns";
import { Button } from "@mui/material";

interface XClientDataGridProps {
    selectedIdsPtr?: PurePtr<readonly (string|number)[]>;
    loading: boolean;
    data?: any[];
    rowsPtr?: PurePtr<any[]>;
    name?: string;
    fields: Field[];
    actions?: (params: any) => React.ReactNode[];
    defaultHiddenFields?: string[];
    sortPtr? : PurePtr<SortOptions>;
    displayTotal?: boolean;
    processRowUpdate?: (newRow: any, prevRow: any) => any;
    processRowDelete?: ( row: any ) => any;
    actionColumnName?: string;
    slots?: ComponentProps<typeof DataGridPro>['slots'];
    slotProps?: ComponentProps<typeof DataGridPro>['slotProps'];
    getDetailPanelContent?: (params: any) => React.ReactNode;
    getDetailPanelHeight?: (params: any) => number | 'auto';
    hideFooter?: boolean;
    getRowId?: (row: any) => GridRowId;
}

export function XClientDataGrid({ 
    defaultHiddenFields, getRowId, processRowDelete, rowsPtr, processRowUpdate, getDetailPanelHeight, hideFooter, getDetailPanelContent, sortPtr, slots, slotProps, selectedIdsPtr, actionColumnName, loading, data, name, fields, actions, displayTotal
}: XClientDataGridProps) {
    const rows = data?.slice() ?? rowsPtr?.value ?? [] ;

    const apiRef = useGridApiRef();
    const rowModesModelPtr = useStatePtr<GridRowModesModel>({});

    const columnDefs = useMemo(() => {
        const editActions = rowsPtr ? createEditActions( rowModesModelPtr, rowsPtr, processRowDelete ) : undefined;
        
        return [
            ...translateLegacyFields(fields),
            ...(actions || editActions ? [
                createActionsField( params => 
                    [ 
                        ...( actions ? actions( params ) : [] ), 
                        ...( editActions ? editActions( params ) : [] ) 
                    ], 
                    actionColumnName
                )
            ] : [])
        ]
    }, [fields, actions, rowModesModelPtr?.value, rowsPtr?.value]);

    const totals = displayTotal ?
        useMemo(() => {
            const totalsColumns = fields.filter( x => x.options?.totals );

            return totalsColumns.length > 0 && rows.length ? 
                Object.fromEntries([
                    [ 'id', 'totals' ],
                    ...totalsColumns
                        .map( x => [
                            x.id, 
                            rows.reduce( (acc, row) => 
                                acc + x.xDataField.valueGetter( undefined as never, row, undefined, undefined ), 
                                0 
                            )
                        ])
                ])
                : undefined;
        }, 
        [ fields, data ])
    : undefined;

    const autosizeOptions = useMemo(() => ({
        columns : columnDefs
            .filter( x => x.field !== 'actions' && !fields.find( y => y.id === x.field )?.options?.width )
            .map( x => x.field ),
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
    }), [ fields ]);

    const initialColumnVisibility = useCallback(() =>
        Object.fromEntries( ( defaultHiddenFields ?? [] ).map( x => [ x, false ]))
    , [defaultHiddenFields]);

    const columnVisibilityModelPtr = name ?
        useLocalStoragePtr( name + '-visibility', initialColumnVisibility ) :
        useStatePtr( initialColumnVisibility );

    const resizeColumns = useCallback(() => {
        if( !loading ){
            // `sleep`/`setTimeout` is required because update rows is an
            // async function throttled to avoid choking on frequent changes.
            setTimeout(() => {
                apiRef.current?.autosizeColumns( autosizeOptions );
            }, 0);
        }
    }, [ apiRef.current, loading ]);

    useEffect( resizeColumns, [ resizeColumns ]);
    useWindowEvent( 'resize', resizeColumns, 100 );

    return <XClientDataGridContext.Provider value={{ rowsPtr, rowModesModelPtr }}>
        <DataGridPro
            apiRef={apiRef}
            autosizeOptions={autosizeOptions}
            density="compact"
            columns={columnDefs}
            rows={ rows}
            disableColumnFilter
            loading={loading}
            sortingOrder={['desc', 'asc']}

            getRowId={ getRowId }

            // Editing support
            { ...( rowsPtr ? {
                editMode: "row",

                processRowUpdate : async ( newRow : any, prevRow ) => {
                    const next = await processRowUpdate( 
                        newRow.id === "__new__" ? { ...newRow, id : undefined } : newRow, 
                        prevRow 
                    );

                    rowsPtr.find( x => x.id === newRow.id ).set( next );
                    return next;
                },

                isCellEditable: ( params : GridCellParams ) => {
                    const { editable } = fields.find( x => x.id === params.field ).options;
                    return typeof editable === 'function' ? editable( params.row ) : editable ?? false;
                },

                rowModesModel: rowModesModelPtr.value,
                onRowModesModelChange: x => rowModesModelPtr.set(x),

                onRowEditStop: (params, event) => {
                    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
                        event.defaultMuiPrevented = true;
                    }
                }
            } : null )}

            // Selection support
            rowSelectionModel={selectedIdsPtr?.value}
            onRowSelectionModelChange={x => selectedIdsPtr.set(x)}

            checkboxSelection={ Boolean(selectedIdsPtr) } 
            disableRowSelectionOnClick

            columnVisibilityModel={columnVisibilityModelPtr.value}
            onColumnVisibilityModelChange={x => columnVisibilityModelPtr.set(x)}

            pageSizeOptions={[10, 25, 50, 100]}
            getDetailPanelHeight={ getDetailPanelContent ? getDetailPanelHeight ?? (() => 'auto') : undefined }
            getDetailPanelContent={ getDetailPanelContent }

            sortModel={sortPtr?.value.map(x => ({
                field: x.id,
                sort: x.direction as any,
            }))}

            onSortModelChange={arr => 
                sortPtr?.set( arr.map(x => ({ id: x.field, direction: x.sort })))
            }
            
            slotProps={{
                ...defaultSlotProps,
                ...slotProps
            }}
            slots={ slots }
            hideFooter={hideFooter}
            pinnedRows={ totals && {
                bottom : [ totals ],

            }}

            sx={ dataGridStyles }
        />
    </XClientDataGridContext.Provider>;
}

import AddCircleIcon from '@mui/icons-material/AddCircle';

const XClientDataGridContext = React.createContext<any>(null);

export const CreateRowButton = ({ create }) => {
    const { rowsPtr, rowModesModelPtr } = useContext( XClientDataGridContext );
    return (
        <Button 
            disabled={ Object.values( rowModesModelPtr.value ).some( ( x : any ) => x.mode === GridRowModes.Edit ) }
            startIcon={ <AddCircleIcon/> } 
            onClick={ () => {
                rowsPtr.push( create( "__new__" ) );
                rowModesModelPtr.set( prev =>  ({     
                    ...prev,
                    ["__new__"]: { mode: GridRowModes.Edit, fieldToFocus: 'name' }, 
                }));
            } }>
                Create
        </Button>
    )
}

function createEditActions( rowModesModelPtr, rowsPtr, processRowDelete ){
    return ({ id }) => {
        const isInEditMode = rowModesModelPtr.value[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={ () =>{
                rowModesModelPtr.set({ 
                    ...rowModesModelPtr.value, 
                    [id]: { mode: GridRowModes.View } 
                });
                // ...?
              }}
            />,

            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={ ()=>{
                    rowModesModelPtr.set({
                        ...rowModesModelPtr.value,
                        [id]: { mode: GridRowModes.View, ignoreModifications: true },
                    });

                    const editedRowPtr = rowsPtr.find((row) => row.id === id);

                    if (editedRowPtr.value.id === '__new__') {
                        editedRowPtr.removeSelf();
                    }
              }}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={ ()=>{
                rowModesModelPtr.set({ 
                    ...rowModesModelPtr.value, 
                    [id]: { mode: GridRowModes.Edit } 
                });
            }}
            color="inherit"
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={ async () => {
                await processRowDelete( id );
                rowsPtr.remove( row => row.id == id );
                //...?
            }}
            color="inherit"
          />,
        ];
    }
} 

const defaultSlotProps = {
    columnsManagement: {
      disableShowHideToggle: true,
      getTogglableColumns( columns : GridColDef[] ) {
        return columns
            .filter( column => column.field !== 'actions' && column.field !== '__check__' )
            .map( column => column.field );
      }
    },
}