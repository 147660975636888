import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import ClaimStatisticsComponent from './ClaimStatistics';
import { selectClaimAnalysisStats, selectTriageStats } from '../../../store/selectors/claimAnalysis';
import { selectRecoveredAmountFlag } from '../../../store/selectors/settings';

export const ClaimStatistics = connect(
    () => {
        return (state, props) => ({
            getStatsPending: props.isFromTriage ? state.claimAnalysis.getTriageStats.pending : state.claimAnalysis.getStats.pending,
            stats: props.isFromTriage ? selectTriageStats(state): selectClaimAnalysisStats(state),
            recoveredAmountFlag: selectRecoveredAmountFlag(state)
        });
    }
)(injectIntl(ClaimStatisticsComponent));