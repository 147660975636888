import { Box, Button, Card, CardContent, CardHeader, Icon, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { createContext, useMemo } from "react";
import GridLayoutContainer, { WidthProvider, Layout } from "react-grid-layout";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import makeStyles from '@mui/styles/makeStyles';
import { PurePtr, useStatePtr } from "@pure-ptr/react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DateRange } from "../../util/date";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
const GridLayout = WidthProvider(GridLayoutContainer);

export type Widget = {
    Component : React.ComponentType<any>,
    id: string,
    title : string,
    options? : Layout
    headerColor?: string
    headerTextColor?: string
}

export interface DashboardFilters {
    dateRange : DateRange
    history: any
}

export const DashboardFiltersContext = createContext<DashboardFilters>( null );

export function Dashboard({ layoutPtr, widgetDefs }:{
    layoutPtr: PurePtr<Layout[]>
    widgetDefs: Widget[]
}) {
    const classes = useWidgetStyles();

    return (
        <GridLayout
            autoSize
            layout={layoutPtr.value}
            onLayoutChange={(layout) => layoutPtr.set(layout)}
            cols={12}
            draggableHandle='.MuiCardHeader-root' 
            draggableCancel='.MuiCardHeader-action'
            rowHeight={30}
            containerPadding={[0, 10]}
        >
            { layoutPtr.value.map( (widget, index) => {
                const def = widgetDefs.find( x => x.id === widget.i );
                return (
                    <Card key={ widget.i } className={classes.card}>
                        <CardHeader
                            sx={{
                                cursor: 'move',
                                backgroundColor: def.headerColor ?? '#f5f5f5',
                                padding: '8px 16px 8px 16px',
                                '& .MuiTypography-root': {
                                    fontSize: '1.2em',
                                    fontWeight: 500,
                                    color: def.headerTextColor ?? 'black',
                                }
                            }} 
                            title={<Box display="flex" flexDirection="row" alignItems="center"><DragIndicatorIcon sx={{ marginRight: "4px" }}>drag</DragIndicatorIcon><Box>{def.title}</Box> </Box>}
                            action={
                                <IconButton
                                    size="small"
                                    onClick={ e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        layoutPtr.removeAt( index );
                                    }}
                                >
                                    <Icon sx={{ fontSize: 18, color: def.headerTextColor ?? 'black' }}>close</Icon>
                                </IconButton>
                            }
                        />
                        <CardContent className={classes.cardContent}>
                            <def.Component />
                        </CardContent>
                    </Card>
                );
            })}
        </GridLayout>
    );
}

const useWidgetStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
        "& .MuiCardContent-root:last-child" : {
            paddingBottom: 0,
        }
    },
    cardContent: {
        flex: 1,
        overflowY: 'auto',
        padding: 0,
        "& root:last-child" : {
            paddingBottom: 0,
        }
    },
    redBg: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.contrastText,
        padding: theme.spacing(1),
    }
}));

// Add widget that is not already in the layout
export function AddWidgetButton({ widgetDefs: a_widgetDefs, layoutPtr }:{
    widgetDefs: Widget[],
    layoutPtr: PurePtr<Layout[]>
}){
    // Add default options to widgetDefs
    const widgetDefs = useMemo( () =>
        a_widgetDefs.map( x => ({
            headerColor: '#f5f5f5',
            ...x,
            options : {
                minH: 5,
                minW: 3,
                maxW: 12,
                w: 6,
                x: 0,
                y: 0,
                h: 10,
                ...x.options
            }
        }))
    , [a_widgetDefs]);

    // Filter out widgets that are already in the layout
    const filteredWidgets = widgetDefs
            .filter( x => !layoutPtr.value.find( y => y.i === x.id ));

    const anchorElPtr = useStatePtr(null);
            
    return (
        <Box display="flex" justifyContent="flex-end">
                
            <Button
                startIcon={<AddCircleOutlineIcon />}
                variant="contained"
                endIcon={<ArrowDropDownIcon />}
                onClick={ e => {
                    anchorElPtr.set(e.currentTarget);
                }}
                disabled={!filteredWidgets.length}
            >
                Add Widget
            </Button>

            <Menu
                anchorEl={anchorElPtr.value}
                open={Boolean(anchorElPtr.isTruthy)}
                onClose={() => anchorElPtr.set(null)}
            >
                { filteredWidgets
                    .map( widget =>
                        <MenuItem key={widget.id} 
                            onClick={() => {
                                layoutPtr.push({ i : widget.id, ...widget.options });
                                anchorElPtr.set(null);
                            }}
                        >
                            {widget.title}
                        </MenuItem>
                    )
                }                                    
            </Menu>
        </Box>
    )
}


import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    borderNone: {
        border: "none",
    },
    greenBg: {
        backgroundColor: "#CDE8D7",
        padding: "10px"
    },
    blueBg: {
        backgroundColor: "#CCE7F6",
        padding: "10px"
    },
    redBg: {
        backgroundColor: "#FFC1C1",
        padding: "10px"
    },

    card: {
        borderRadius: 8,
        "& .MuiCardContent-root:last-child" : {
            paddingBottom: 0,
        }
    },
    cardContent: {
        padding: 0,
        "& root:last-child" : {
            paddingBottom: 0,
        }
    },


    greenHeaderRow: {
        "& .MuiTableCell-root" : {
            fontWeight: 600,
            height: 40,
            backgroundColor: "#f8f8f8",
        }
    },

    totalsRow: {
        "& .MuiTableCell-root" : {
            fontWeight: 800,
            borderStyle: 'double',
            borderWidth: '4px 0 0 0',
            borderColor: 'gray',
        }
    },
    link: {
        fontWeight: 800, 
        color: "#1E88E5",
        textDecoration: "underline",
        cursor: "pointer"
    },
    switch: {
        marginRight: 0,
        marginTop: 20,
        marginLeft: 0
    },
    formControlAuto: {
        width: '99%',
        margin: theme.spacing(1),
    },
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        width: 400,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    denialsGroup1Row: {
        "& .MuiTableCell-root" : {
            fontWeight: 600,
            height: 40,
            backgroundColor: "#f8f8f8",
        }
    },
    denialsGroup2Row: {
        "& .MuiTableCell-root" : {
            fontWeight: 600,
            height: 40,
        }
    },
}));