import React, { useEffect, useState } from 'react';
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../../partials/content/Portlet";
import { Grid,IconButton, Tooltip } from '@mui/material';
import { PagedTable } from '../../../common/pagedTable';
import Helmet from 'react-helmet';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useData } from '@pure-ptr/react';

import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from '../../../store/constants/roles';
import { DeleteConfirmation } from '../../../common/GenericDeleteConfirmationDialog/DeleteConfirmationDialog';
import { deleteAllowables } from '../../../store/api/allowables';

const DEFAULT_SORT = [{ id: 'pxCode', direction: 'asc' }];

export default function AllowablesPage({
    //selectAllStatusPending, allowablesListPending, updateStatusPending, 
    data, fields,
    //checkboxStatus,
    userRole,
    actions: {
        allowableLanding,
        // updateStatus,
        // selectDeselectAll,
        searchReset,
        searchGetByName,
        resetPagedTable,
        allowablesDeleteDialog,
        resetSelectedContracts
    }
}) {

    const [selected, setSelected] = useState([]);
    const [refresh, setRefresh] = useState(Promise.resolve());

    useEffect(() => {
        const pageName = {
            name: 'allowable-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name)
        }
    }, [searchGetByName]);

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    const {
        data:allowablesData,
        isPending,
        error,
    } = useData(
        async (data) => {
            if (selected?.length === 0) return;
            const allowableResponse = await deleteAllowables({
                contractedPayerRateIds: [
                    ...selected
                ],
            });
            setSelected([]);
            handleRefresh()
            allowablesDeleteDialog({ open: false,rowData:null })
            resetSelectedContracts()
            return allowableResponse;
        },
        [selected]
    );

    // const handleCheckbox = (allowable) => {
    //     updateStatus(allowable.id, !allowable.enabled);
    // };

    // const handleSelectAll = () => {
    //     const ids = data.map(({ id }) => id);
    //     const payload = {
    //         ids,
    //         enabled: !checkboxStatus,
    //     };
    //     selectDeselectAll(payload, getList);
    // }

    function handleLoad(sort, filter, pagination) {
        allowableLanding(pagination, sort, filter);
    }

    const allowabledTableLevelAction = () => {
        return (
            <>
                {(userRole === ROLE_SUPERADMIN || userRole === ROLE_ADMIN || userRole === ROLE_PARTNER_ADMIN) &&
                    <Grid item>
                        <Tooltip title="Delete Allowables">
                            <IconButton aria-label="delete" size='small'
                                onClick={()=>allowablesDeleteDialog({open:true})}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </>
        );
    }

    function allowablesActions(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className="p-1"
                    aria-label="Delete"
                    onClick={()=>allowablesDeleteDialog({open:true,rowData:rowData})}
                    size="large">
                    <Tooltip title="Delete">
                        <DeleteIcon sx={{color:"red"}}/>
                    </Tooltip>
                </IconButton>
            </div>
        );
    }

    const handleDeletedNegotiatedRecords = (recordsToDelete) => {
        setSelected([...recordsToDelete])
        
    }

    return (
        <>
            <Helmet title="Negotiated Rates" />
            <Portlet>
                <PortletHeader title="Negotiated Rates"
                    name="allowable-landing"
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                    handleRefresh={handleRefresh}
                />
                <PortletBody>
                    <Grid container>
                        <Grid item md={12}>
                            <PagedTable
                                name="allowable-landing"
                                fields={fields}
                                data={data}
                                hasCheckbox={true}
                                tableLevelActions={allowabledTableLevelAction}

                                showSelectAll={true}
                                onLoad={handleLoad}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                criteriasSaveEnabled={true}
                                showSearchFilters={true}
                                hasActions={true}

                                renderActionColumn={allowablesActions}
                                shouldRenderLazy

                            />
                        </Grid>
                    </Grid>
                </PortletBody>
                {/* Both row wise and multi records deletion dialog  */}
                <DeleteConfirmation
                    pageReducerName="allowable-landing"
                    reducerName="allowables"
                    stateName="deleteDialog"
                    handleDelete={handleDeletedNegotiatedRecords}
                    handleClose={() => allowablesDeleteDialog({ open: false })}
                    isDeleting={isPending!==null}
                />
            </Portlet>
        </>
    );
}