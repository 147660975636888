import { createReducer } from '../../util';
import * as actions from '../actions/contracts';
import { StatusState } from './common';

const buildMyTree = (currentTree, newData, parentId, level) => {
    let groupsData = [];

    if (level === 0) {
        groupsData = newData;
    } else {
        for (var i = 0; i < currentTree.length; i++) {
            if (currentTree[i].id === parentId) {
                currentTree[i].children = newData;
            } else {
                if (currentTree[i].children && currentTree[i].children.length > 0) {
                    buildMyTree(currentTree[i].children, newData, parentId, level);
                }
            }
        }

        groupsData = currentTree;
    }

    return {
        groupsTree: groupsData,
    }
}

const buildGroupsTree = (state, data, { parentId, level }) => {

    const groupsTree = buildMyTree(state.groupsTree, data, parentId, level);
    return {
        ...groupsTree,
    }
}

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    tiny: [],
    isPayer: true,
    methodTypes: [],
    baseRateTypes: [],
    carveOutTypes: [],
    documents: [],
    feeSchedule: [],
    feeScheduleLanding: [],
    labelsTiny: [],
    groups: [],
    groupsTree: [],
    alertUsers: [],
    feeScheduleTypes: [],
    paymentDetails: {},
    servicesTab: 0,
    feeScheduleCodes: "",
    contractsCriteria: [],
    states: [],
    casCodes: [],
    copyContractName: '',
    contractedServices: [],

    dependencyDialog: null,
    dependencies: [],
    canDelete: null,
    tinyContractsByState: [],
    copyContractDialog: null, //{open:true,existingContractId:null, existingContractName:'', existingContractStateId:null}

    feeScheduleList: {
        ...initialStatusState,
    },
    feeScheduleLabelsList: {
        ...initialStatusState,
    },
    documentList: {
        ...initialStatusState,
    },
    rulesList: {
        ...initialStatusState,
    },
    defaults: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    upload: {
        ...initialStatusState,
    },
    feeScheduleLandingList: {
        ...initialStatusState,
    },
    getGroups: {
        ...initialStatusState,
    },
    getGroupsTree: {
        ...initialStatusState,
    },
    getAlertUsers: {
        ...initialStatusState,
    },
    getTiny: {
        ...initialStatusState,
    },
    ruleDelete: {
        ...initialStatusState,
    },
    getFeeScheduleTypes: {
        ...initialStatusState
    },
    createFeeSchedule: {
        ...initialStatusState,
    },
    updateFeeSchedule: {
        ...initialStatusState,
    },
    getFeeScheduleCodes: {
        ...initialStatusState,
    },

    copyContract: {
        ...initialStatusState,
    },
    contractDelete: {
        ...initialStatusState,
    },
    getContractCriteria: {
        ...initialStatusState,
    },
    getStates: {
        ...initialStatusState,
    },
    updateState: {
        ...initialStatusState,
    },
    getCasCodes: {
        ...initialStatusState,
    },
    getContractedServices: {
        ...initialStatusState
    },
    getContractDependency: {
        ...initialStatusState
    },
    getTinyContractsByState: {
        ...initialStatusState
    },
    stateDialog: null,
    rules: {},
    nameValidity: {}, // Keyvalue pair for the rule name validation
    deletePending: false
};

export default createReducer(initialState, {
    [actions.CONTRACTS_GET_DEFAULT_PENDING]: (state) => ({
        ...state,
        defaults: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_GET_DEFAULT_FULFILLED]: (state, { methodTypes, baseRateTypes, carveOutTypes }) => ({
        ...state,
        defaults: {
            ...initialStatusState,
        },
        methodTypes,
        baseRateTypes,
        carveOutTypes,
    }),
    [actions.CONTRACTS_GET_DEFAULT_REJECTED]: (state, errorMessage) => ({
        ...state,
        defaults: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULE_NAME_CHECK_PENDING]: (state, payload) => {
        return {
            ...state,
            nameValidity: {
                ...state.nameValidity,
                [`pending-${payload.index}`]: true,
            }
        };
    },
    [actions.RULE_NAME_CHECK_FULFILLED]: (state, { index, error }) => {
        return {
            ...state,
            nameValidity: {
                ...state.nameValidity,
                [`pending-${index}`]: false,
                [`done-${index}`]: error,
            }
        };
    },
    [actions.CONTRACTS_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_GET_FULFILLED]: (state, contract) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        data: [contract]
    }),
    [actions.CONTRACTS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.CONTRACTS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.CONTRACTS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_EDIT_RESET]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACTS_UPLOAD_PENDING]: (state) => ({
        ...state,
        upload: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_UPLOAD_FULFILLED]: (state) => ({
        ...state,
        upload: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACTS_UPLOAD_REJECTED]: (state, errorMessage) => ({
        ...state,
        upload: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_DOCUMENTS_LIST_PENDING]: (state) => ({
        ...state,
        documentList: {
            pending: true,
        },
        documents: []
    }),
    [actions.CONTRACTS_DOCUMENTS_LIST_FULFILLED]: (state, documents) => ({
        ...state,
        documentList: {
            ...initialStatusState,
        },
        documents: documents,
    }),
    [actions.CONTRACTS_DOCUMENTS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        documentList: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_SET_LANDING_FILTER]: (state, { isPayer }) => ({
        ...state,
        isPayer: isPayer,
    }),
    [actions.CONTRACTS_RULES_LIST_PENDING]: (state) => ({
        ...state,
        rulesList: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_RULES_LIST_FULFILLED]: (state, { rules, serviceId }) => ({
        ...state,
        rulesList: {
            ...initialStatusState,
        },
        rules: {
            ...state.rules,
            [`rules-${serviceId}`]: rules,
        }
    }),
    [actions.CONTRACTS_RULES_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        rulesList: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_PENDING]: (state) => ({
        ...state,
        feeScheduleList: {
            pending: true,
        },
        feeSchedule: []
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_FULFILLED]: (state, feeSchedule) => ({
        ...state,
        feeScheduleList: {
            ...initialStatusState,
        },
        feeSchedule: feeSchedule,
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_REJECTED]: (state, errorMessage) => ({
        ...state,
        feeScheduleList: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_LIST_PENDING]: (state) => ({
        ...state,
        feeScheduleLandingList: {
            pending: true,
        },
        feeScheduleLanding: []
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_LIST_FULFILLED]: (state, feeScheduleLanding) => ({
        ...state,
        feeScheduleLandingList: {
            ...initialStatusState,
        },
        feeScheduleLanding: feeScheduleLanding,
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        feeScheduleLandingList: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_LABELS_TINY_PENDING]: (state) => ({
        ...state,
        feeScheduleLabelsList: {
            pending: true,
        },
        labelsTiny: []
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_LABELS_TINY_FULFILLED]: (state, feeSchedule) => ({
        ...state,
        feeScheduleLabelsList: {
            ...initialStatusState,
        },
        labelsTiny: feeSchedule,
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_LABELS_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        feeScheduleLabelsList: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_GET_GROUPS_PENDING]: (state) => ({
        ...state,
        getGroups: {
            pending: true,
        },
        feeScheduleLanding: []
    }),
    [actions.CONTRACTS_GET_GROUPS_FULFILLED]: (state, groups) => ({
        ...state,
        getGroups: {
            ...initialStatusState,
        },
        groups,
    }),
    [actions.CONTRACTS_GET_GROUPS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getGroups: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_GROUPS_TREE_PENDING]: (state) => ({
        ...state,
        getGroupsTree: {
            pending: true,
        },
        //feeScheduleLanding: []
    }),
    [actions.CONTRACTS_GROUPS_TREE_FULFILLED]: (state, { groupsTree, treePayload }) => ({
        ...state,
        getGroupsTree: {
            ...initialStatusState,
        },
        //groupsTree: sampleGroupData,
        ...buildGroupsTree(state, groupsTree, treePayload),
    }),
    [actions.CONTRACTS_GROUPS_TREE_REJECTED]: (state, errorMessage) => ({
        ...state,
        getGroupsTree: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_GROUPS_RESET]: (state) => ({
        ...state,
        groups: [],
        groupsTree: [],
    }),
    [actions.CONTRACTS_GET_ALERT_USERS_PENDING]: (state) => ({
        ...state,
        getAlertUsers: {
            pending: true,
        },
        alertUsers: []
    }),
    [actions.CONTRACTS_GET_ALERT_USERS_FULFILLED]: (state, alertUsers) => ({
        ...state,
        getAlertUsers: {
            ...initialStatusState,
        },
        alertUsers,
    }),
    [actions.CONTRACTS_GET_ALERT_USERS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getAlertUsers: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_LIST_TINY_PENDING]: (state) => ({
        ...state,
        getTiny: {
            pending: true,
        },
        tiny: []
    }),
    [actions.CONTRACTS_LIST_TINY_FULFILLED]: (state, tiny) => ({
        ...state,
        getTiny: {
            ...initialStatusState,
        },
        tiny,
    }),
    [actions.CONTRACTS_LIST_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getTiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_PAYMENT_DETAILS_PENDING]: (state, { payment }) => ({
        ...state,
        [`getPaymentDetails-${payment.claimnumber}`]: {
            pending: true,
        },
        paymentDetails: {
            ...state.paymentDetails,
            [`${payment.claimnumber}`]: [],
        },
    }),
    [actions.CONTRACTS_PAYMENT_DETAILS_FULFILLED]: (state, { paymentDetails, payment }) => ({
        ...state,
        [`getPaymentDetails-${payment.claimnumber}`]: {
            ...initialStatusState,
        },
        paymentDetails: {
            ...state.paymentDetails,
            [`${payment.claimnumber}`]: paymentDetails,
        }
    }),
    [actions.CONTRACTS_PAYMENT_DETAILS_REJECTED]: (state, { errorMessage, payment }) => ({
        ...state,
        [`getPaymentDetails-${payment.claimnumber}`]: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_RULE_DELETE_PENDING]: (state) => ({
        ...state,
        ruleDelete: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_RULE_DELETE_FULFILLED]: (state) => ({
        ...state,
        ruleDelete: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACTS_RULE_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        ruleDelete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_RULE_RESET]: (state, errorMessage) => ({
        ...state,
        ruleDelete: {
            ...initialStatusState,
        },
    }),

    [actions.CONTRACTS_FEE_SCHEDULE_TYPES_PENDING]: (state) => ({
        ...state,
        getFeeScheduleTypes: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_TYPES_FULFILLED]: (state, feeScheduleTypes) => ({
        ...state,
        getFeeScheduleTypes: {
            ...initialStatusState,
        },
        feeScheduleTypes
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_TYPES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFeeScheduleTypes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_CREATE__FEE_SCHEDULE_PENDING]: (state) => ({
        ...state,
        createFeeSchedule: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_CREATE_FEE_SCHEDULE_FULFILLED]: (state) => ({
        ...state,
        createFeeSchedule: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACTS_CREATE_FEE_SCHEDULE_REJECTED]: (state, errorMessage) => ({
        ...state,
        createFeeSchedule: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_UPDATE__FEE_SCHEDULE_PENDING]: (state) => ({
        ...state,
        updateFeeSchedule: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_UPDATE_FEE_SCHEDULE_FULFILLED]: (state) => ({
        ...state,
        updateFeeSchedule: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACTS_UPDATE_FEE_SCHEDULE_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateFeeSchedule: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_EDIT_RESET]: (state,) => ({
        ...state,
        updateFeeSchedule: {
            ...initialStatusState,
        },
        createFeeSchedule: {
            ...initialStatusState,
        }
    }),


    //
    [actions.CONTRACTS_GET_FEE_SCHEDULE_CODES_PENDING]: (state) => ({
        ...state,
        getFeeScheduleCodes: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_GET_FEE_SCHEDULE_CODES_FULFILLED]: (state, { codes }) => ({
        ...state,
        getFeeScheduleCodes: {
            ...initialStatusState,
        },
        feeScheduleCodes: codes
    }),
    [actions.CONTRACTS_GET_FEE_SCHEDULE_CODES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFeeScheduleCodes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.COPY_CONTRACTS_PENDING]: (state) => ({
        ...state,
        copyContract: {
            pending: true,
        },
    }),
    [actions.COPY_CONTRACTS_FULFILLED]: (state) => ({
        ...state,
        copyContract: {
            ...initialStatusState,
        },
    }),
    [actions.COPY_CONTRACTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        copyContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.COPY_CONTRACTS_RESET]: (state) => ({
        ...state,
        copyContract: {
            ...initialStatusState,
        },
    }),

    [actions.DELETE_CONTRACT_PENDING]: (state) => ({
        ...state,
        contractDelete: {
            pending: true,
        },
    }),
    [actions.DELETE_CONTRACT_FULFILLED]: (state) => ({
        ...state,
        contractDelete: {
            ...initialStatusState,
        },
    }),
    [actions.DELETE_CONTRACT_REJECTED]: (state, errorMessage) => ({
        ...state,
        contractDelete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DELETE_CONTRACT_RESET]: (state, errorMessage) => ({
        ...state,
        contractDelete: {
            ...initialStatusState
        }
    }),

    [actions.CONTRACTS_RESET_FEE_SCHEDULE]: (state) => ({
        ...state,
        feeSchedule: []
    }),
    [actions.CONTRACTS_SERVICES_TAB_UPDATE]: (state, { servicesTab }) => ({
        ...state,
        servicesTab
    }),


    [actions.CONTRACTS_GET_CRITERIA_PENDING]: (state) => ({
        ...state,
        getContractCriteria: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_GET_CRITERIA_FULFILLED]: (state, contractsCriteria) => ({
        ...state,
        getContractCriteria: {
            ...initialStatusState,
        },
        contractsCriteria
    }),
    [actions.CONTRACTS_GET_CRITERIA_REJECTED]: (state, errorMessage) => ({
        ...state,
        getContractCriteria: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_GET_STATES_PENDING]: (state) => ({
        ...state,
        getStates: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_GET_STATES_FULFILLED]: (state, states) => ({
        ...state,
        getStates: {
            ...initialStatusState,
        },
        states
    }),
    [actions.CONTRACTS_GET_STATES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getStates: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_UPDATE_STATE_PENDING]: (state) => ({
        ...state,
        updateState: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_UPDATE_STATE_FULFILLED]: (state) => ({
        ...state,
        updateState: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACTS_UPDATE_STATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateState: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_GET_CASCODES_PENDING]: (state) => ({
        ...state,
        getCasCodes: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_GET_CASCODES_FULFILLED]: (state, casCodes) => ({
        ...state,
        getCasCodes: {
            ...initialStatusState,
        },
        casCodes
    }),
    [actions.CONTRACTS_GET_CASCODES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCasCodes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_GET_CONTRACTED_SERVICES_PENDING]: (state) => ({
        ...state,
        getContractedServices: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_GET_CONTRACTED_SERVICES_FULFILLED]: (state, contractedServices) => ({
        ...state,
        getContractedServices: {
            ...initialStatusState,
        },
        contractedServices
    }),
    [actions.CONTRACTS_GET_CONTRACTED_SERVICES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getContractedServices: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_GET_DELETE_DEPENDENCY_PENDING]: (state) => ({
        ...state,
        getContractDependency: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_GET_DELETE_DEPENDENCY_FULFILLED]: (state, results) => ({
        ...state,
        getContractDependency: {
            ...initialStatusState,
        },
        canDelete:results?.canDelete,
        dependencies:results?.records
    }),
    [actions.CONTRACTS_GET_DELETE_DEPENDENCY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getContractDependency: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_GET_TINY_BY_STATE_PENDING]: (state) => ({
        ...state,
        getTinyContractsByState: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_GET_TINY_BY_STATE_FULFILLED]: (state, tinyContractsByState) => ({
        ...state,
        getTinyContractsByState: {
            ...initialStatusState,
        },
        tinyContractsByState
    }),
    [actions.CONTRACTS_GET_TINY_BY_STATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        getTinyContractsByState: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACT_SET_DEPENDENCY_DIALOG]: (state, { dependencyDialog }) => ({
        ...state,
        dependencyDialog
    }),
    [actions.CONTRACT_SET_STATE_DIALOG]: (state, payload) => ({
        ...state,
        stateDialog: payload
    }),
    [actions.CONTRACT_SET_COPY_CONTRACT_NAME]: (state, { copyContractName }) => ({
        ...state,
        copyContractName
    }),

    [actions.COPY_CONTRACT_SET_DIALOG]: (state, { copyContractDialog }) => ({
        ...state,
        copyContractDialog
    })

    // [actions.CLIENTS_RESET]: () => ({...initialState }),
});
