import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createFloatingPoint } from '../../util/format';

const useStyles = makeStyles(theme => ({
    cell: {
        backgroundColor: '#EAEEF5',
    },
    tr: {
        "& td": {
            '@media (max-width: 1440px)': {
                padding: "6px 5px 6px 5px"
            },
            '@media (max-width: 1280px)': {
                padding: "3px 5px 3px 5px"
            },
            '@media (max-width: 1024px)': {
                padding: "6px 5px 6px 5px"
            }
        },
    },
    whiteBg:{
        background:'white',
        border:'none'
    },
    'padding-0':{
        padding:0
    },
    customFigureStyling:{
        background:'none',
        color:"white"
    }
}));

export default function TotalRow(props) {
    const classes = useStyles();
    const { fields, data, hasActions,whiteBg=false ,customText=''} = props;

    function makeTotal(field) {
        if (data && data.length > 0) {
            return `${customText}$ ${createFloatingPoint(data.reduce((acc, curr) => acc + curr[field.id], 0))}`;
        }
        return '';
    }

    function renderTotal(field) {
        const { options: { calculateTotal } } = field;
        if (calculateTotal) {
            return makeTotal(field);
        } else {
            return '';
        }
    }

    function renderCell(field) {
        const { options: { labelStyle } } = field;
        return (
            <TableCell className={`${classes.cell} ${whiteBg && classes.whiteBg} ${customText && `${classes["padding-0"]} ${classes["customFigureStyling"]}` }`} variant='head'><span className={labelStyle}>{renderTotal(field)}</span></TableCell>
        )
    }

    return (
        <TableRow className={classes.tr}>
            {fields.map((field, index) => renderCell(field))}
            {hasActions && <TableCell className={classes.cell} variant='head'></TableCell>}
        </TableRow>
    )
}