import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import { Box, Typography } from "@mui/material";

import { ChatBotWidget } from "chatbot-widget-ui";
import { chatBotConversation, chats } from "../../store/api/chatbot";
import { useData } from "@pure-ptr/react";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LinearProgress from "@mui/material/LinearProgress"; // Importing LinearProgress for the real progress bar
import TypingDots from "../TypingDots";

export const Chatbot = () => {
    const [messages, setMessages] = useState<any[]>([
        {
            role: "assistant",
            content: "Hi, Upload a contract to continue.",
        },
    ]);
    const [file, setFile] = useState<any>();
    const [credits, setCredits] = useState({ total: 1, consumed: 0 });
    const [previousChats, setPreviousChats] = useState<any>([]);

    useEffect(() => {
        const chatInput = document.querySelector(".chat-input");

        chatInput.classList.add("disabled-input");
        // Check if the file input is already added
        if (!chatInput.querySelector(".file-input")) {
            const fileInput: any = document.createElement("input");
            fileInput.type = "file";
            fileInput.className = "file-input";
            fileInput.style.display = "none";
            fileInput.addEventListener("change", handleFileChange);
            fileInputRef.current = fileInput;

            chatInput.appendChild(fileInput);

            const fileIconImg: any = document.createElement("div");
            const iconStyles = {
                cursor: "pointer", // Make it clickable
                width: "50px", // Size of the image
                height: "50px", // Size of the image
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "auto",
                marginBottom: "auto",
            };

            // Apply styles to the fileIconImg div
            Object.assign(fileIconImg.style, iconStyles);

            ReactDOM.render(
                <AttachFileIcon
                    id="attach-file"
                    fontSize="large"
                    sx={{ color: "#91bd28" }}
                />,
                fileIconImg
            );

            // Event to trigger file input when icon is clicked
            fileIconImg.addEventListener("click", () => {
                fileInputRef.current?.click();
            });

            chatInput.appendChild(fileIconImg);
        }

    }, []);


    const { isPending, data } = useData(async () => {
        if (!file) return;
        const chatBotResponse = await chatBotConversation(file);
        sessionStorage.setItem("chat-sessionId", chatBotResponse?.session_id);
        document.getElementById("progress-bar").style.display = "none";
        setMessages([
            {
                role: "user",
                content: (
                    <Box
                        component="span"
                        sx={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            textDecoration: "underline",
                        }}
                    >
                        {(file.get("file") as File).name}{" "}
                    </Box>
                ), // Show the progress bar alongside the filename
            },
            {
                role: "assistant",
                content: (
                    <Typography>
                        Your document is uploaded, how can I help you?.
                    </Typography>
                ),
            },
        ]);
        setCredits({ ...credits, consumed: credits.consumed + 0.1 });
        setFile(null);
        document
            .querySelector(".chat-input")
            .classList.remove("disabled-input");

        return chatBotResponse;
    }, [file]);

    const searchSearchQueryPayload = async (userQuery) => {
        const apiResponse = await chats({
            session_id: sessionStorage?.getItem("chat-sessionId"),
            query: userQuery,
            context: {
                previous_conversation: [...previousChats],
                document_summary:
                    "Contract between Hospital and Payer for EAPG payment method",
                conversation_intent: "Extracting insight of contract",
            },
        });
        setPreviousChats([
            ...previousChats,
            { query: userQuery, answer: apiResponse?.response },
        ]);
        return apiResponse?.response;
    };

    const fileInputRef = useRef<HTMLInputElement | null>(null); // Use ref to trigger file input click

    function formatText(text) {
        return text
            .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>") // Replace **text** with <b>text</b>
            .replace(/\n/g, "<br>"); // Replace newline characters with <br>
    }

    const handleBotResponse = (response: string) => {
        setCredits({ ...credits, consumed: credits.consumed + 0.1 });
        setMessages((prevMessages) => [
            ...prevMessages,
            {
                role: "assistant",
                content: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: formatText(
                                response || "Something Went Wrong !!!"
                            ),
                        }}
                    />
                ),
            },
        ]);
    };

    const handleNewMessage = (message: any) => {
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    // Handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData();
        const file = event.target.files?.[0];
        formData.append("file", file);

        setFile(formData);
        setMessages([
            {
                role: "user",
                content: (
                    <Box
                        component="span"
                        sx={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            textDecoration: "underline",
                        }}
                    >
                        {file.name}{" "}
                        <LinearProgress id="progress-bar" sx={{ mt: "10px" }} />
                    </Box>
                ), // Show the progress bar alongside the filename
            },
            { role: "assistant", content: <TypingDots /> },
        ]);
    };

    return (
        <div>
            <ChatBotWidget
                callApi={searchSearchQueryPayload}
                onBotResponse={handleBotResponse}
                handleNewMessage={handleNewMessage}
                messages={messages}
                primaryColor="#91bd28"
                inputMsgPlaceholder="Type your message"
                chatbotName={`SlicedBot Credits: ${credits.consumed?.toFixed(
                    2
                )}/${credits.total} $`}
                isTypingMessage="Typing..."
                IncommingErrMsg="Oops! Something went wrong. Try again."
                chatIcon={
                    <SmartToyIcon sx={{ height: "30px", width: "30px" }} />
                }
                botIcon={
                    <SmartToyIcon
                        sx={{
                            height: "20px",
                            width: "20px",
                            marginBottom: "7px",
                        }}
                    />
                }
                botFontStyle={{
                    fontFamily: "Arial",
                    fontSize: "14px",
                    color: "black",
                }}
                typingFontStyle={{
                    fontFamily: "Arial",
                    fontSize: "14px",
                    color: "#888",
                    fontStyle: "italic",
                }}
            />
        </div>
    );
};
