import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { IconButton, TablePagination, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Edit as EditIcon, Delete as DeleteIcon, Lock as LockIcon } from '@mui/icons-material';
import { DataTable, DataFilter, TablePaginationActions } from '../../../common';
import { DeleteConfirmation } from "../../../common/DeleteConfirmation";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useData, useStatePtr } from "@pure-ptr/react";
import { unlockUser } from "../../../store/api/users";
import { adjustCurrentPage } from "../../../util";

const useStyles = makeStyles(theme => ({
  textField: {
    width: 400,
  },
}));

export default function UsersPage({
  usersFilteredData, fields, sort, userListPending, userDeletePending,
  order, filter, history, actions: { setSort, setOrder, usersList, setFilter, userDelete }
}) {
  const classes = useStyles();

  const search = useLocation().search;
  const persist = new URLSearchParams(search).get('persist');

  const isUserLock = useStatePtr(false);
  const lockUserId = useStatePtr(null);
  const openUnlockConfirmDialog = useStatePtr(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (!persist) {
      setFilter('');
    }
    usersList();
  }, [usersList]);

  useEffect(() => {
      let currentPage = page + 1; 
      let totalRecords = usersFilteredData?.length;
      let pageSize = rowsPerPage;

      currentPage = adjustCurrentPage(currentPage, totalRecords, pageSize);
      if (page === currentPage - 1) return;
      setPage(currentPage - 1);
  }, [usersFilteredData]);

  const { isPending: isUnlockUserPending } = useData( async () => {
    if(isUserLock.value && lockUserId.value) {
      await unlockUser(lockUserId.value); 
      handleCallBack();
    }
  }, [ isUserLock.value ]);

  const handleUserEdit = (user) => {
    history.push(`/admin/users/edit/${user.id}`);
  }

  const handleCallBack = () => {
    usersList();
  }

  const handleUserDelete = (user) => {
    if (user) {
      userDelete(user.id, handleCallBack);
    }
  }

  const handleSearchInput = (value) => {
    setFilter(value);
  }

  const handleAddUser = () => {
    history.push('/admin/users/edit/new');
  }

  const handleUnlock = (id) => {
    lockUserId.set(id);
    openUnlockConfirmDialog.set(true);
  }

  const handleUnlockUser = () => {
    openUnlockConfirmDialog.set(false);
    isUserLock.set(true);
  }

  const handleCloseUnlockUserConfirmDialog = () => {
    openUnlockConfirmDialog.set(false);
    isUserLock.set(false);
  }

  function usersActions(rowData) {
    return (
      <div className='d-flex'>
        {rowData?.isLockedOut ?
            <IconButton
                color="primary"
                className='p-1 mr-1'
                aria-label="Unlock"
                onClick={() => handleUnlock(rowData.id)}
                size="large">
                <Tooltip title="Unlock the user">
                    <span className='fas fa-lock text-warning font-16'></span>
                </Tooltip>
            </IconButton> :
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Lock"
                // onClick={() => handleLock(rowData.id)}
                size="large"><Tooltip title="">
                    <span className='fas fa-lock-open text-secondary font-16'></span>
                </Tooltip>
            </IconButton>
        }
        <IconButton
          color="primary"
          className='p-1'
          aria-label="Edit"
          onClick={() => handleUserEdit(rowData)}
          size="large">
          <Tooltip title="Edit">
            <EditIcon />
          </Tooltip>
        </IconButton>
        <DeleteConfirmation handleDelete={() => handleUserDelete(rowData)} />

      </div>
    );
  }

  function handleRefresh() {
    usersList();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (<>
    <Helmet title="User Management" />
    <Portlet>
      <PortletHeader
        title="Users"
        toolbar={
          <PortletHeaderToolbar>
            <DataFilter
              onChange={handleSearchInput}
              placeholder="Search"
              cssClass={classes.textField}
              filter={filter}
            />
          </PortletHeaderToolbar>
        }
      />
      <PortletBody>
        <DataTable
          fields={fields}
          data={usersFilteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          onAdd={handleAddUser}
          order={order}
          onOrder={setOrder}
          sort={sort}
          onSort={setSort}
          loading={userListPending || userDeletePending || isUnlockUserPending}
          name="User"
          handleRefresh={handleRefresh}
          showPagination={true}
          footerPagination={() => (
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
              colSpan={fields.length + 2}
              count={usersFilteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          )}

          hasActions={true}
          renderActionColumn={usersActions}
        />
      </PortletBody>
    </Portlet>
    <Dialog open={openUnlockConfirmDialog.value} onClose={handleCloseUnlockUserConfirmDialog}>
        <DialogTitle>
          Unlock User
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to unlock this user ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={handleCloseUnlockUserConfirmDialog}>
            Cancel
          </Button>
          <Button
            type='button'
            onClick={handleUnlockUser}
            color="primary">
            Unlock User
          </Button>
        </DialogActions>
      </Dialog>
  </>);
}
