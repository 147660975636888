import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import {
    Box,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableFooter,
    TableCell,
    Grid,
    Paper,
    TableContainer,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Loadable } from '../loadable';

import Row from './Row';
import TotalRow from './TotalRow';
import { TableLevelAction } from '../tableLevelAction';

import './Datatable.scss';
import { FilterColumns } from '../filterColumns';
import { fromLocalStorage, toLocalStorage } from '../../util/storage';
import { ReorderColumns } from '../reorderColumns';
import { Header } from './header';
import {
    SortableContainer,
} from "react-sortable-hoc";
import { TableVirtuoso } from 'react-virtuoso'

const useStyles = makeStyles((theme) => ({
    tr: {
        "& th": {
            padding: '6px 24px 6px 16px',
            top: ({ tableInModal } : any ) => tableInModal ? "0px" : "50px",
            '@media (max-width: 1440px)': {
                padding: "6px 5px 6px 5px"
            },
            '@media (max-width: 1280px)': {
                padding: "3px 5px 3px 5px"
            },
            '@media (max-width: 1024px)': {
                top: ({ tableInModal } : any ) => tableInModal ? '0px' : '82px',
                padding: "6px 5px 6px 5px"
            },
            '@media (max-width: 830px)': {
                top: '0px!important'
            },
            '@media screen and (max-width: 1279px) and (min-width: 1025px)': {
                top: '0px!important'
            },
        },
    },
    trClass: {
        "& td": {
            padding: '6px 24px 6px 16px',
            '@media (max-width: 1440px)': {
                padding: "6px 5px 6px 5px"
            },
            '@media (max-width: 1280px)': {
                padding: "3px 5px 3px 5px"
            },
            '@media (max-width: 1024px)': {
                padding: "6px 5px 6px 5px"
            }
        },
    },
    tableContainer: {
        '@media (max-width: 820px)': {
            overflowX: 'scroll'
        },
        '@media screen and (max-width: 1279px) and (min-width: 1025px)': {
            padding: "3px 5px 3px 5px",
            overflowX: 'scroll'
        },
    },
    scrollerPaper: {
        boxShadow: 'none !important',
        overflowY: 'scroll',
        overflowX: 'scroll',
        '& > div': {
            overflowY: "clip !important",
            '@media (max-width: 820px)': {
                overflowX: 'scroll'
            },
            '@media screen and (max-width: 1281px) and (min-width: 821px)': {
                padding: "3px 5px 3px 5px",
                overflowX: 'scroll'
            },
        }

    },
    trVirtuoso: {
        "& th": {
            padding: '6px 24px 6px 16px',
            top: ({ tableInModal } : any ) => tableInModal ? "0px" : "50px",
            '@media (max-width: 1440px)': {
                padding: "6px 5px 6px 5px"
            },
            '@media (max-width: 1280px)': {
                padding: "3px 5px 3px 5px",
                top: '0px!important'
            },
            '@media (max-width: 1024px)': {
                padding: "6px 5px 6px 5px"
            },

        },
    },
    paginationContainer: {
        border: "1px solid lightgray",
        borderTop: "none",
        background: "#FFFFFF",
    },
    footerRow: {
        width: "fit-content",
        marginLeft: "auto",
    },
}));

export default function DataTable({
    intl,
    data = [], fields, size = "small", footerPagination, showPagination, showSelectAll,
    hasActions, hasCheckbox, loading, sort, onRadioSelect, hoverData, shouldRenderLazy = false,
    stickyHeader, selectedRadioItem, radioKey, isCollapsible, collapsibleItem, onSort,
    customTableClass='',customGridActionsColorClass='',
    onRowClick=undefined,
    handleClickColumn, displayTotal, tableInModal, renderActionColumn, sessionUserId, showReorderColumnsDialog,
    handleRefresh, tableLevelActions, name, onAdd, tableLevelLoading, actionLevelGrid, grayRowKey, isDnDEnabled, onDragEnd, orderPreference = [],
    customActionColumnName, pageName, showFilterColumnsDialog, hiddenFields = [],isSuperAdmin, actions: { setMulti, setFilterColumnDialogOpen,
        setReorderDialogOpen, setCheckboxSelected }
}) {

    const [expandedRow, SetExpandedRow] = useState();

    const classes = useStyles({ tableInModal });

    const { id: columnOrder, direction } = (sort && sort.length > 0 && sort[0]) || {};

    // configuration specifically for radio configuration.
    const [selectedRadio, setSelectedRadio] = useState(null);
    const isSelected = (rowData) => {
        if (selectedRadio) {
            return selectedRadio[radioKey] === rowData[radioKey];
        }
        return false;
    }
    const hasRadio = onRadioSelect !== undefined;

    useEffect(() => {
        if (selectedRadioItem) {
            setSelectedRadio(selectedRadioItem);
        }
    }, [selectedRadioItem]);

    const handleExpandedRow = (isExpanded) => {
        SetExpandedRow(isExpanded === expandedRow ? false : isExpanded);
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setCheckboxSelected(data.map(item => item.id));
        } else {
            setCheckboxSelected([]);
        }
    }

    // const openSelectAllConfirmation = (row) => {
    //     setSelectedRow(row);
    //     setOpenSelectAllDialog(true);
    // }

    // const handleSelectAllConfirmation = () => {
    //     setOpenSelectAllDialog(false);
    //     handleSelectAll();
    // }

    const handleRadioSelect = (rowData) => {
        setSelectedRadio(rowData.displayName);
        onRadioSelect(rowData);
    }

    const handleSort = ({ id, direction, fieldType }) => {
        if (!onSort) {
            return;
        }

        const sorts = [...sort];
        const updatedSort = { id, fieldType, direction };
        const oldSort = sorts.find((sort) => {
            return sort.id === id;
        });

        // sort exists, toggle sort
        if (oldSort && oldSort.id === id) {
            const index = sorts.indexOf(oldSort);
            updatedSort.direction = oldSort.direction === 'asc' ? 'desc' : 'asc';
            sorts[index] = updatedSort;
        }
        // new sort
        else {
            updatedSort.direction = 'desc';
            sorts.splice(0, sorts.length);
            sorts.push(updatedSort);
        }
        onSort(sorts);
    }

    const renderRowComponent = () => {
        return <>
            {data.map((rowData, index) => {
                return (
                    <Row
                        onRowClick={onRowClick}
                        index={`${index.toString()}`}
                        key={`column-${index}`}
                        rowData={rowData}
                        fields={filterFields()}
                        pageName={pageName}

                        isCollapsible={isCollapsible}
                        collapsibleItem={collapsibleItem}
                        expandedRow={expandedRow}
                        handleExpanded={handleExpandedRow}
                        onRadioSelect={() => handleRadioSelect(rowData)}
                        selectedRadio={isSelected(rowData)}
                        hasRadio={hasRadio}
                        handleClickColumn={handleClickColumn}
                        hoverData={hoverData}
                        hasCheckbox={hasCheckbox}
                        isDnDEnabled={isDnDEnabled}
                        isGray={isGrayed(rowData[grayRowKey])}

                        renderActionColumn={renderActionColumn}
                        hasActions={hasActions}
                        isSuperAdmin={isSuperAdmin}
                    />
                )
            })}
        </>
    }


    const isGrayed = (bool) => {
        if (bool === undefined) {
            return false
        }
        return !bool
    }

    const filterFields = () => {
        let tempFields = [];
        // re-ordering array
        if (orderPreference.length > 0) {
            tempFields = fields.sort((a, b) => {
                return orderPreference.indexOf(a.id) - orderPreference.indexOf(b.id);
            });

        } else {
            tempFields = [...fields];
        }
        // filtering array from hidden columns
        if (!hiddenFields || hiddenFields.length === 0) {
            return tempFields
        }
        return tempFields.filter(field => !hiddenFields.includes(field.id));
    }

    const getReorderableFields = () => {
        const fiteredFields = fields.filter(field => field?.options?.reorder !== false);
        if (!hiddenFields || hiddenFields.length === 0) {
            return fiteredFields
        }
        return fiteredFields.filter(field => !hiddenFields.includes(field.id));
    }

    const handleColumnsDialogOpen = () => {
        setFilterColumnDialogOpen(true);
    }
    const handleColumnsDialogClose = () => {
        setFilterColumnDialogOpen(false);
    }
    const handleColumnsFilterApply = (_hiddenFields) => () => {
        handleColumnsDialogClose();

        setMulti(pageName, { hiddenFields: _hiddenFields });
        const pagesData = fromLocalStorage(`${sessionUserId}-slicedhealth-table-data-v2`);
        if (pagesData) {
            toLocalStorage(`${sessionUserId}-slicedhealth-table-data-v2`,
                {
                    ...pagesData,
                    [pageName]: {
                        ...pagesData[pageName],
                        hiddenFields: _hiddenFields
                    }
                }
            );
        }
    }

    const handleReorderColumnsDialogOpen = () => {
        setReorderDialogOpen(true);
    }

    const handleReorderColumnsDialogClose = () => {
        setReorderDialogOpen(false);
    }

    const handleColumnsReorderApply = (items) => {
        handleReorderColumnsDialogClose();

        const _orderPreference = [...items.map((item) => item.id)]
        setMulti(pageName, { orderPreference: _orderPreference });
        const pagesData = fromLocalStorage(`${sessionUserId}-slicedhealth-table-data-v2`);
        if (pagesData) {
            toLocalStorage(`${sessionUserId}-slicedhealth-table-data-v2`,
                {
                    ...pagesData,
                    [pageName]: {
                        ...pagesData[pageName],
                        orderPreference: _orderPreference
                    }
                }
            );
        }
    }

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        onDragEnd(oldIndex, newIndex);
    };

    const handleSortStart = ({ node }) => {
        const tds = document.getElementsByClassName("SortableHelper")[0].childNodes as any;
        node.childNodes.forEach(
            (node, idx) => (tds[idx].style.width = `${node.offsetWidth}px`)
        );
    };

    const SortableTableBody = SortableContainer(({ children }) => (
        <TableBody>{children}</TableBody>
    ));

    const TableComponents = {
        Scroller: React.forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
        Table: (props) => <Table size={size} stickyHeader={stickyHeader} {...props} style={{ borderCollapse: 'separate' }} />,
        TableHead: TableHead,
        TableRow: React.forwardRef((props : any, ref : any ) => <TableRow id={`row-${props.item.id}`} className={classes.trClass} hover={true} {...props} ref={ref} />),
        TableBody: React.forwardRef((props :any, ref) => <TableBody {...props} ref={ref} />),
    }

    return (
        <div className={classnames('datatable', {
            'datatable--loading': loading
        })}>
            <Grid container spacing={1} className={customTableClass}>
                <Grid item xs={12} className={customGridActionsColorClass}>
                    <TableLevelAction
                        handleRefresh={handleRefresh}
                        actions={tableLevelActions}
                        name={name}
                        onAdd={onAdd}
                        loading={tableLevelLoading}
                        actionLevelGrid={actionLevelGrid}
                        handleColumnsDialogOpen={handleColumnsDialogOpen}
                        showFilterColumnsDialog={showFilterColumnsDialog}
                        handleReorderColumnsDialogOpen={handleReorderColumnsDialogOpen}
                        showReorderColumnsDialog={showReorderColumnsDialog}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Loadable loading={loading}>
                        <>
                            {shouldRenderLazy ?
                                <TableVirtuoso
                                    style={{ height: '56vh' }} data={data} components={TableComponents as any}
                                    fixedHeaderContent={() => {
                                        return <>
                                            <TableRow>
                                                <Header
                                                    key="header"
                                                    pageName={pageName}
                                                    fields={filterFields()}
                                                    hasActions={hasActions}
                                                    hasCheckbox={hasCheckbox}
                                                    showSelectAll={showSelectAll}
                                                    hasRadio={hasRadio}
                                                    onSort={handleSort}
                                                    direction={direction}
                                                    columnOrder={columnOrder}
                                                    onSelectAll={handleSelectAll}
                                                    data={data}
                                                    isCollapsible={isCollapsible}
                                                    isDnDEnabled={isDnDEnabled}

                                                    customActionColumnName={customActionColumnName}
                                                />
                                            </TableRow>
                                        </>
                                    }}
                                    itemContent={(index, rowData) => {
                                        return <Row
                                            index={`${index.toString()}`}
                                            key={`column-${index}`}
                                            rowData={rowData}
                                            fields={filterFields()}
                                            pageName={pageName}

                                            isCollapsible={isCollapsible}
                                            collapsibleItem={collapsibleItem}
                                            expandedRow={expandedRow}
                                            handleExpanded={handleExpandedRow}
                                            onRadioSelect={() => handleRadioSelect(rowData)}
                                            selectedRadio={isSelected(rowData)}
                                            hasRadio={hasRadio}
                                            handleClickColumn={handleClickColumn}
                                            hoverData={hoverData}
                                            hasCheckbox={hasCheckbox}
                                            isDnDEnabled={isDnDEnabled}
                                            isGray={isGrayed(rowData[grayRowKey])}
                                            shouldRenderLazy={shouldRenderLazy}

                                            renderActionColumn={renderActionColumn}
                                            hasActions={hasActions}
                                            isSuperAdmin={isSuperAdmin}
                                        />
                                    }}
                                    // fixedFooterContent={() => {
                                    //     return showPagination ? <TableRow className='footer-row'>
                                    //         {(data.length <= 0) && (
                                    //             <TableCell colSpan={fields.length}>No records found!</TableCell>
                                    //         )}
                                    //         {footerPagination()}
                                    //     </TableRow> : ''
                                    // }}
                                />
                                :
                                <div className={classes.tableContainer}>
                                    <Table size={size as any} stickyHeader={stickyHeader}>
                                        <TableHead>
                                            <TableRow className={classes.tr}>
                                                <Header
                                                    key="header"
                                                    pageName={pageName}
                                                    fields={filterFields()}
                                                    hasActions={hasActions}
                                                    hasCheckbox={hasCheckbox}
                                                    showSelectAll={showSelectAll}
                                                    hasRadio={hasRadio}
                                                    onSort={handleSort}
                                                    direction={direction}
                                                    columnOrder={columnOrder}
                                                    onSelectAll={handleSelectAll}
                                                    data={data}
                                                    isCollapsible={isCollapsible}
                                                    isDnDEnabled={isDnDEnabled}

                                                    customActionColumnName={customActionColumnName}
                                                />
                                            </TableRow>
                                        </TableHead>
                                        {isDnDEnabled ?
                                            <>
                                                <SortableTableBody
                                                    helperClass="SortableHelper"
                                                    onSortEnd={handleSortEnd}
                                                    onSortStart={handleSortStart}
                                                    useDragHandle
                                                    useWindowAsScrollContainer
                                                >
                                                    {renderRowComponent()}
                                                </SortableTableBody>
                                            </> :
                                            <TableBody>
                                                {renderRowComponent()}
                                                {displayTotal && (
                                                    <TotalRow
                                                        fields={filterFields()}
                                                        data={data}
                                                        hasActions={hasActions}
                                                    />
                                                )}

                                                {(data.length <= 0) && (
                                                    <TableRow>
                                                        <TableCell colSpan={fields.length}>No records found!</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        }

                                        {showPagination && (
                                            <TableFooter>
                                                <TableRow>
                                                    {footerPagination()}
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                    </Table>
                                </div>
                            }
                        </>

                        {shouldRenderLazy && showPagination ? (
                            <Box className={classes.paginationContainer} >
                                {" "}
                                <Box
                                    className={classes.footerRow}
                                >
                                    {data.length <= 0 && (
                                        <TableCell colSpan={fields.length}>
                                            No records found!
                                        </TableCell>
                                    )}
                                    {footerPagination()}
                                </Box>{" "}
                            </Box>
                        ) : (
                            ""
                        )}
                    </Loadable>
                </Grid>
            </Grid>
            <FilterColumns
                pageName={pageName}
                fields={fields}
                handleDialogClose={handleColumnsDialogClose}
                applyColumnFiter={handleColumnsFilterApply}
                _hiddenFields={hiddenFields}
            />
            <ReorderColumns
                pageName={pageName}
                getFields={getReorderableFields}
                handleDialogClose={handleReorderColumnsDialogClose}
                handleColumnsReorderApply={handleColumnsReorderApply}
            />
        </div>
    )
}