import {
    METHOD_GET,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson } from '../headers';
import { apiHandleResponse } from '../../util/api';
import { PureObject } from '@pure-ptr/core';
import { parseDate } from '../../util/date';
import { restEndpoint } from './common';
import { initialize } from 'react-ga';

const getJobStatistics = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/jobstatistics`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getS3Listners = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/listeners`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfRedshift = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/redshift`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfRDS = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/sql`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfECS = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/ecs`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfNetwork = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/network`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfSQS = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/sqs`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfS3 = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/s3`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfCloudwatch = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/cloudwatch`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfCloudfront = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/cloudfront`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfSecretManager = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/credentialstore`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

export class QSData extends PureObject {
    id: string;
    name: string;
    size: number;
    storage: string;
    createdAt: Date;
    updatedAt: Date;
    refreshSchedules: RefreshSchedule[] = [];

    static parse( data : any ){
        return {
            ...data,
            createdAt: parseDate( data.createdAt, 'date-time' ),
            updatedAt: parseDate( data.updatedAt, 'date-time' ),
        }
    }
}

const getQuickSightsDataSetList = async () => {
    const a = await fetch(`${SERVER_URL}/api/v1/controlroom/quicksights/datasets/list`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    });
    
    const data = await apiHandleResponse( a );

    data.sort( ( a, b ) =>
        a.updatedAt > b.updatedAt ? -1 : 1 
    );

    return QSData.array( data, QSData.parse );
};

export class QSDataSetDetails extends PureObject {
    id: string;
    lastUpdatedTime: Date;
    consumedSpiceCapacityInBytes = 0;
}

export async function getQuickSightsDataSetDetails( ids : string[], abort : AbortSignal ){
    const params = new URLSearchParams({
        ids : ids.join(',')
    });

    const a = await fetch(`${SERVER_URL}/api/v1/controlroom/quicksights/datasets/describe?${params}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
        signal: abort
    });
    
    const b = await apiHandleResponse( a );
    return QSDataSetDetails.array( b, ( x : any ) => x && ({
        ...x,
        lastUpdatedTime : new Date(x.lastUpdatedTime)   
    }) );
};

export async function getQuickSightsDataSetRefreshSchedules( ids : string[], abort : AbortSignal ) : Promise<RefreshSchedule[][]> {
    const params = new URLSearchParams({
        ids : ids.join(',')
    });

    const a = await fetch(`${SERVER_URL}/api/v1/controlroom/quicksights/datasets/refresh-schedules?${params}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
        signal: abort
    });
    
    const b = await apiHandleResponse( a );
    return b.map( x => RefreshSchedule.array( x, RefreshSchedule.parse ) );
};

export class RefreshSchedule extends PureObject {
    arn = '';
    refreshType = 'FULL_REFRESH';
    scheduleFrequency = { 
        interval: 'DAILY',
        refreshOnDay: { 
            dayOfMonth: '',
            dayOfWeek: null as string | null,
        },
        timeOfTheDay: '',
        timezone: 'America/New_York',
    };
    id = '';
    startAfterDateTime = new Date();

    hour = null as number | null;

    initialize(){
        this.hour = this.scheduleFrequency.interval === 'DAILY' ?
            parseInt( this.scheduleFrequency.timeOfTheDay.split(':')[0] ) : 
            null;
    }

    static parse( data : any ){
        return {
            ...data,
            startAfterDateTime : parseDate( data.startAfterDateTime, 'date-time' ),
        }
    }
}

export const refreshSchedulesEndpoint = ( datasetId : string ) =>
    restEndpoint( RefreshSchedule, `/api/v1/controlroom/quicksights/datasets/${datasetId}/refresh-schedules`);

export {
    getJobStatistics,
    getS3Listners,
    getHealthOfRedshift,
    getHealthOfRDS,
    getHealthOfECS,
    getHealthOfNetwork,
    getHealthOfSQS,
    getHealthOfS3,
    getHealthOfCloudwatch,
    getHealthOfCloudfront,
    getHealthOfSecretManager,
    getQuickSightsDataSetList
};