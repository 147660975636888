import { useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useData } from "@pure-ptr/react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { getSearchCriteriaDependentsList } from "../../../../store/api/search";
import { Loadable, DependentsList } from "../../../../common";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: "0.25rem",
    },
}));

export default function DeleteConfirmation({
    handleDelete,
    tooltip,
    buttonColor = undefined,
    customIcon,
    iconSize = undefined,
    isAllowedToDelete = true,
    dependenciesLength = 0,
    rowData = null,
}: {
    handleDelete: () => void;
    tooltip?: string;
    buttonColor?: "default" | "inherit" | "primary" | "secondary";
    customIcon?: JSX.Element;
    iconSize?: "small" | "medium";
    isAllowedToDelete?: boolean;
    dependenciesLength?: number | null;
    rowData?: any;
    setRecordId?: any;
    pending?: boolean;
}) {
    const [open, setOpen] = useState(false);
    const [recordId, setRecordId] = useState(null);

    const classes = useStyles();

    const { data, isPending, error } = useData(async () => {
        if (!recordId) {
            return;
        }
        const dependentsData = await getSearchCriteriaDependentsList(recordId);
        return dependentsData;
    }, [recordId]);
    let loading = isPending !== null;

    const handleClose = () => {
        setRecordId(null);
        setOpen(false);
    };

    const handleOpen = () => {
        setRecordId(rowData?.id);
        setOpen(true);
    };

    const handleConfirmDelete = () => {
        handleClose();
        handleDelete();
    };

    return (
        <>
            <IconButton
                color={buttonColor ?? "primary"}
                className={classes.button}
                aria-label="Delete"
                onClick={handleOpen}
                size={iconSize ?? "medium"}
            >
                <Tooltip title={tooltip ?? "Delete"}>
                    {customIcon ?? (
                        <DeleteIcon
                            sx={{
                                color: "#d9534f",
                            }}
                        />
                    )}
                </Tooltip>
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{ "& .MuiPaper-root": { minWidth: "350px" } }}
            >
                <Loadable loading={loading}>
                    <DialogTitle>Delete</DialogTitle>
                    {!loading && (
                        <DialogContent>
                            <DialogContentText>
                                {data?.canDelete
                                    ? `Are you sure you want to delete ${
                                          data?.records?.length > 0
                                              ? ", It has the following dependencies"
                                              : ""
                                      }`
                                    : "You Cannot Delete this criteria due to following dependencies"}

                                <DependentsList dependents={data?.records} isSimpleDependency={true}/>
                            </DialogContentText>
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        {data?.canDelete && (
                            <Button
                                onClick={handleConfirmDelete}
                                color="primary"
                            >
                                Delete
                            </Button>
                        )}
                    </DialogActions>
                </Loadable>
            </Dialog>
        </>
    );
}
