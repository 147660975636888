import {
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    METHOD_DELETE,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuth } from '../headers';
import { apiHandleResponse } from '../../util/api';

const getUsers = (clientId) => {
    let endpoint = `${SERVER_URL}/api/v3/users`;
    if (clientId) {
        endpoint = `${SERVER_URL}/api/v1/users/${clientId}`;
    }
    return fetch(endpoint, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getUser = (userId) => {
    return fetch(`${SERVER_URL}/api/v1/user/${userId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

// Get users by ClientId. A tiny list of id and name
const getUsersTiny = (clientId) => {
    return fetch(`${SERVER_URL}/api/v1/users/tiny/${clientId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getUsersTinyWithoutClient = () => {
    return fetch(`${SERVER_URL}/api/v1/users/tiny`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createUser = (user) => {
    return fetch(`${SERVER_URL}/api/v2/user`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(user)
    }).then(apiHandleResponse);
};

const updateUser = (user) => {
    return fetch(`${SERVER_URL}/api/v2/user`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(user)
    }).then(apiHandleResponse);
};

const getRoles = () => {
    return fetch(`${SERVER_URL}/api/v1/users/roles`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const deleteUser = (userId) => {
    return fetch(`${SERVER_URL}/api/v1/user/${userId}`, {
        method: METHOD_DELETE,
        headers: headersAuth(),
    }).then(apiHandleResponse);
};

const deactivateUser = (userId) => {
    return fetch(`${SERVER_URL}/api/v1/user/deactivate/${userId}`, {
        method: METHOD_DELETE,
        headers: headersAuth(),
    }).then(apiHandleResponse);
};

const activateUser = (userId) => {
    return fetch(`${SERVER_URL}/api/v1/user/activate/${userId}`, {
        method: METHOD_POST,
        headers: headersAuth(),
    }).then(apiHandleResponse);
}

const getUserRoles = () => {
    return fetch(`${SERVER_URL}/api/v1/user/roles`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getUsersTinyByRole = (role) => {
    return fetch(`${SERVER_URL}/api/v2/users/tiny/${role}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const unlockUser = async (userId) => {
    const response = await fetch(`${SERVER_URL}/api/v2/user/account/unlock/${userId}`, {
        method: METHOD_POST,
        headers: headersAuth(),
    });
    return apiHandleResponse(response);
}

export {
    getUsers,
    getUsersTiny,
    createUser,
    updateUser,
    getRoles,
    getUser,
    deleteUser,
    getUsersTinyWithoutClient,
    deactivateUser,
    activateUser,
    getUserRoles,
    getUsersTinyByRole,
    unlockUser
};