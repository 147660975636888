import {
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    SERVER_URL,
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const searchClaimsForModeling = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/model/search/init`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const searchClaimsForModelingPaginated = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/model/search`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const contractModelingLanding = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/models`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};


const contractModelingEstimate = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/model/estimate`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};


const analyzeContractModelingEstimate = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/model/estimate`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const lockModel = (modelId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/model/lock/${modelId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const unlockModel = (modelId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/model/unlock/${modelId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const contractModelingExecutionsLanding = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/model/executions`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const executionResultsLanding = (options, modelId, showHiddenClaims) => {
    return fetch(showHiddenClaims ? `${SERVER_URL}/api/v1/cms/model/execution/results/${modelId}/${showHiddenClaims}` : `${SERVER_URL}/api/v1/cms/model/execution/results/${modelId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const analysisResultsLanding = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/model/analysis/results`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getModelById = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/model/${id}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getExecutionResultsStats = (getStatspayload, modelId, showHiddenClaims) => {
    return fetch(showHiddenClaims ? `${SERVER_URL}/api/v2/cms/model/execution/results/total/${modelId}/${showHiddenClaims}` : `${SERVER_URL}/api/v2/cms/model/execution/results/total/${modelId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(getStatspayload),
    }).then(apiHandleResponse);
};
const stopExecutionModeling = (executionId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/model/analysis/stop/${executionId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getModelAllDetails = (analysisIds) => {
    var analysisIdsArr = analysisIds.split('/');
    analysisIds = [parseInt(analysisIdsArr[0]), parseInt(analysisIdsArr[1])]
    return fetch(`${SERVER_URL}/api/v2/cms/model/analysis/details`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(analysisIds),
    }).then(apiHandleResponse);
};

const getModelConsolidatedClaims = (analysisIds) => {
    var analysisIdsArr = analysisIds.split(',');
    analysisIds = [parseInt(analysisIdsArr[0]), parseInt(analysisIdsArr[1])]
    return fetch(`${SERVER_URL}/api/v2/cms/model/analysis/claimdata/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(analysisIds),
    }).then(apiHandleResponse);
};

const hideOrUnHideModelClaim = async (options) => {
    const { analysisIds, ishidden } = options;
    const response = await fetch(`${SERVER_URL}/api/v2/cms/model/analysis/claims/${ishidden}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(analysisIds),
    });
    return apiHandleResponse(response);
}

export {
    searchClaimsForModeling,
    searchClaimsForModelingPaginated,
    contractModelingLanding,
    contractModelingEstimate,
    lockModel,
    unlockModel,
    contractModelingExecutionsLanding,
    executionResultsLanding,
    analysisResultsLanding,
    getModelById,
    analyzeContractModelingEstimate,
    getExecutionResultsStats,
    stopExecutionModeling,
    getModelAllDetails,
    getModelConsolidatedClaims,
    hideOrUnHideModelClaim
};