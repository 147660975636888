import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useData } from "@pure-ptr/react";
import React, { useContext } from 'react';

import { getClaimsSummary } from "../../store/api/summaryDashboard";
import { asDollarsWithZero } from '../../util/format';

import { DashboardFiltersContext, useStyles } from './Dashboard';

export const TopClaimsWidget = () => {
    const classes = useStyles();
    const { dateRange, history } = useContext(DashboardFiltersContext);

    const { data, isPending } = useData( async () => {
        const result = await getClaimsSummary(dateRange.startdate, dateRange.enddate);
        return result;
    }, [ dateRange ]);

    const handleClaimSummaryAmountClick = (id) => {
        history.push(`/cms/claimAnalysis/view/${id}`);
    }

    return isPending ? (
        <CircularProgress />
    ) : (
        <Table>
            <TableHead>
                <TableRow key='claimsHeader'>
                    <TableCell>Variance</TableCell>
                    <TableCell>Contract</TableCell>
                    <TableCell>Claim Number</TableCell>
                    <TableCell>Type</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.slice(0, 5)?.map((claim) => (
                    <TableRow key={claim.claimNumber}>
                        <TableCell>
                            <Typography onClick={()=> handleClaimSummaryAmountClick(claim.id)}    
                            variant="body2" className={classes.link}>
                                {asDollarsWithZero(claim.variance)}
                            </Typography>
                        </TableCell>
                        <TableCell>{claim.contractName}</TableCell>
                        <TableCell>{claim.claimNumber}</TableCell>
                        <TableCell>{claim.type}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}