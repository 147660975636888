import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { checkContractsAssociation, createPayerContract, updatePayerContract } from '../../../store/actions/payers';
import { tiny } from '../../../store/actions/contracts';
import { getPayerPlansTiny } from '../../../store/actions/payers';
import PayerContractDialogComponent from './PayerContractDialog';
import { selectTiny } from '../../../store/selectors/contracts';
import { setV2 } from '../../../store/actions/pagesV2';

export const PayerContractDialog = connect(
    () => {
        
        const selectPayerContract = (state, props) => props.payerContract;
        const selectIsPricerSetup = (state, props) => props.isPricerSetup;

        const selectMode = (state, props) => {
            return props?.payerContract ? 'edit' : 'create';
        }
        const selectcontracts = (state, props) => selectTiny(state);
        const selectPayerPlans = (state, props) => state.payers.payerPlansTiny;

        const selectSortedContracts = createSelector(
            selectTiny,
            (contracts) => {
                return contracts.sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        const selectInitialValues = createSelector(
            selectMode,
            selectPayerContract,
            selectcontracts,
            selectPayerPlans,
            selectIsPricerSetup,
            (mode, payerContract, contracts, plans,isPricerSetup) => {
                const setupEditPayload={
                    includeInMachineReadable: payerContract?.includeInMachineReadable ?? false,
                        plan: payerContract?.payerPlan ? plans?.find(plan => payerContract.payerPlan.name === plan.name) ?? null : null,
                }
                if (mode === 'edit' && payerContract) {
                    
                    const payload = {
                        id: payerContract.id,
                        displayName: payerContract.displayName,
                        contracts: contracts.filter(contract => payerContract.contractIds?.some(y => y == contract.id)),
                        autoClaimAnalysis: payerContract.autoClaimAnalysis ?? false,
                        ...(isPricerSetup  ? setupEditPayload : {})
                        // includeInMachineReadable: payerContract.includeInMachineReadable ?? false,
                        // plan: payerContract.payerPlan ? plans.find(plan => payerContract.payerPlan.name === plan.name) ?? null : null,
                    }

                    return payload;
                }

                const setupCreatePayload={
                    includeInMachineReadable: false,
                    plan: null
                }
                return {
                    displayName: '',
                    contracts: [],
                    autoClaimAnalysis: false,
                    ...(isPricerSetup  ? setupCreatePayload : {})

                    // includeInMachineReadable: false,
                    // plan: null
                }
            }
        )

        return (state, props) => ({
            //create payerContract
            payerContractCreatePending: state.payers.payerCreateContract.pending,
            // update payerContract
            payerContractUpdatePending: state.payers.payerUpdateContract.pending,
            getPayerPlansTinyPending: state.payers.getPayerPlansTiny.pending,
            // edit
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            contractsList: selectSortedContracts(state as never),
            checkAssociationPending: state.payers.checkAssociation.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            payerContractCreate: createPayerContract,
            payerContractUpdate: updatePayerContract,
            contractTiny: tiny,
            getPayerPlansTiny: getPayerPlansTiny,
            setSelectedContracts: (item) => setV2('contractsv2-landing', 'selected', item),
            checkContractsAssociation: checkContractsAssociation,
        }, dispatch)
    })
)(injectIntl(PayerContractDialogComponent));