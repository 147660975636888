import { DataGridPro, GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import { PurePtr, useLocalStoragePtr } from "@pure-ptr/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set } from "../../store/actions/pages";
import { RootState } from "../../store/reducers";
import { useWindowEvent } from "../../util";
import { CombinedFilter, getPaginationFilter } from "../../util/pagination";
import { Field } from "../dataTableHeader/DataTableHeader";
import { AdvancedTableFilters } from "../pagedTable";
import { PopoverLink } from "../popoverLink";
import { SortOptions, PaginationFilters, createActionsField, dataGridStyles } from "./common";
import { translateLegacyFields } from "./LegacyGridColumns";

interface XDataGridProps {
    selectedIdsPtr?: PurePtr<readonly (string|number)[]>;
    loading: boolean;
    data: any[];
    showSearchFilters:boolean;
    name: string;
    paginationFilters: PaginationFilters;
    fields: Field[];
    onLoad: (sort: any, filter: any, pagination: any) => void;
    defaultSort: SortOptions;
    criteriasSaveEnabled: boolean;
    refresh: Promise<void>;
    actions?: (params: any) => React.ReactNode;
    defaultHiddenFields?: string[];
    localStorageKey?: string;
    defaultCustomFilter?: any;
}

export function XDataGrid({ 
    paginationFilters, defaultHiddenFields, defaultCustomFilter, selectedIdsPtr, loading, data, name, fields, onLoad,showSearchFilters, defaultSort, criteriasSaveEnabled, refresh, actions
}: XDataGridProps) {
    const apiRef = useGridApiRef();

    const columnDefs = useMemo(() => [
        ...translateLegacyFields(fields),
        ...(actions ? [createActionsField(actions)] : [])
    ], [fields, actions]);

    const autosizeOptions = useMemo(() => ({
        columns : columnDefs.map( x => x.field ).filter( x => x !== 'actions' ),
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
    }), [ fields ]);

    const initialColumnVisibility = useCallback(() =>
        Object.fromEntries( defaultHiddenFields.map( x => [ x, false ]))
    , [defaultHiddenFields]);

    const columnVisibilityModelPtr = useLocalStoragePtr( name + '-visibility', initialColumnVisibility ); 

    const resizeColumns = useCallback(() => {
        if( !loading && data.length > 0 ){
            // `sleep`/`setTimeout` is required because update rows is an
            // async function throttled to avoid choking on frequent changes.
            setTimeout(() => {
                apiRef.current.autosizeColumns( autosizeOptions )
            }, 0);
        }
    }, [ apiRef.current, loading, data ]);

    useEffect( resizeColumns, [ resizeColumns ]);
    useWindowEvent( 'resize', resizeColumns, 100 );

    return <>
        <DataGridPro
            apiRef={apiRef}
            autosizeOptions={autosizeOptions}
            localeText={{ noRowsLabel: "No Result Found" }}
            density="compact"
            columns={columnDefs}
            rows={data}
            disableColumnFilter
            loading={loading}
            sortingOrder={['desc', 'asc']}
            rowSelectionModel={selectedIdsPtr?.value}
            onRowSelectionModelChange={x => selectedIdsPtr.set(x)}

            checkboxSelection={ Boolean(selectedIdsPtr) } 
            disableRowSelectionOnClick

            columnVisibilityModel={columnVisibilityModelPtr.value}
            onColumnVisibilityModelChange={x => columnVisibilityModelPtr.set(x)}

            pagination
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={ toPaginationModel( paginationFilters.pagination ) }

            paginationMode="server"
            onPaginationModelChange={ x => 
                onLoad( paginationFilters.sort, paginationFilters.filters, { ...paginationFilters.pagination, currentPage: x.page + 1, recordsPerPage: x.pageSize })
            }
            rowCount={paginationFilters.pagination.totalRecords ?? 0}

            sortingMode="server"
            sortModel={paginationFilters.sort.map(x => ({
                field: x.id,
                sort: x.direction as any,
            }))}
            onSortModelChange={arr => 
                onLoad(arr.map(x => ({ id: x.field, direction: x.sort })), paginationFilters.filters, paginationFilters.pagination)
            }
            
            slotProps={ slotProps}

            sx={ dataGridStyles }
        />

        <AdvancedTableFilters
            showSearchFilters={showSearchFilters}
            name={name}
            fields={fields}
            onLoad={( sort, filter, pagination ) => onLoad( sort, filter, { ...pagination, currentPage: 1 })}
            refresh={refresh}
            defaultCustomFilter={defaultCustomFilter}
            defaultSort={defaultSort}
            criteriasSaveEnabled={criteriasSaveEnabled} />
    </>;
}

function toPaginationModel( { currentPage = 1, recordsPerPage, totalRecords = 0 } : PaginationFilters["pagination"] ) {
    return {
        pageSize: recordsPerPage,
        page: Math.min( Math.floor( totalRecords / recordsPerPage ) + 1, currentPage ) - 1,
    }
}

const slotProps = {
    columnsManagement: {
      disableShowHideToggle: true,
      getTogglableColumns( columns : GridColDef[] ) {
        return columns
            .filter( column => column.field !== 'actions' && column.field !== '__check__' )
            .map( column => column.field );
      }
    },
}