import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DashboardPageComponent from './dashboardPage';
import {
    selectDashboardListByClient,
    createDashboardUrlSelector,
    createDashboardUrlObjectSelector,
    selectDashboadUrlPending,
    selectDashboardFilters,
} from '../../store/selectors/dashboards';
import { createSelector } from 'reselect';
import { getUrl, urlReset, setFilter } from '../../store/actions/dashboards';

export const DashboardPage = connect(
    () => {
        const selectDashboardId = (state, props) => props.match.params.dashboardId;
        const selectDoesDashboardBelongsToCurrentClient = createSelector(
            selectDashboardId,
            selectDashboardListByClient,
            (dashboardId, dashboardList) =>
                dashboardList?.filter(
                    (item) => item?.id === Number(dashboardId)
                )?.length > 0
        );

        const selectDashboardUrl:any = createDashboardUrlSelector(selectDashboardId);
        const selectDashboardUrlObject:any = createDashboardUrlObjectSelector(selectDashboardId);

        return (state:any, props) => ({
            dashboardUrl: selectDashboardUrl(state, props),
            dashboardUrlObject: selectDashboardUrlObject(state, props),
            dashboardId: selectDashboardId(state, props),
            dashboardData: state?.dashboards?.url,
            canAccessChatbot: state?.session?.user.hasChatbotAccess,
            currentClient:state.dashboards.selectedClient,
            dashboardList: selectDashboardListByClient(state),
            dashboardFilters: selectDashboardFilters(state),
            dashboadUrlPending: selectDashboadUrlPending(state),
            doesDashboardBelongsToCurrentClient:selectDoesDashboardBelongsToCurrentClient(state,props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            dashboardGetUrl: getUrl,
            dashboardUrlReset: urlReset,
            dashboardSetFilter: setFilter,
        }, dispatch)
    })
)(DashboardPageComponent);