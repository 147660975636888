import React, { useEffect, useState } from "react";
import {
    Table,
    Button,
    Box,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Breadcrumbs,
    Grid,
    IconButton,
    TextField,
    Typography,
    InputAdornment,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Tooltip,
    Chip,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Loadable, TableLevelAction } from "../../../common";
import CloseIcon from "@mui/icons-material/Close";
import HeaderColumn from "../../../common/dataTable/HeaderColumn";
import SearchIcon from "@mui/icons-material/Search";

import ClearIcon from '@mui/icons-material/Clear';




const useStyles = makeStyles(theme => {
    return {
        searchBox: {
            float: "left",
            position: "absolute",
            left: 0,
            width: "100%",
            background: "#fff",
            borderRadius: "2px",
            border: "1px #bdbbbb solid",
            overflow: "hidden",
            zIndex: 999,
            minHeight: "100px",
            padding: "10px"
        },
        root: {
            height: 240,
            flexGrow: 1,
            maxWidth: 400
        },
        selectionTableRow: {
            cursor: "pointer"
        },
        folderDiv: {
            cursor: "pointer",
            textDecoration: "underline"
        },
        backBox: {
            width: "100%",
            marginBottom: "20px",
            textAlign: "left"
        },
        selectionRow: {
            cursor: "pointer"
        },
        chipBox: {
            paddingTop: '8px'
        },
        clearIcon:{
            fontSize:'14px',
            marginLeft:'5px',
        },
        breadCrumbItem:{
            textDecoration: "underline",
            color: "blueviolet",
            cursor: 'pointer'
        }
    };
});

function FeeScheduleSelectionComponent({data = [],
    intl,
    fields,
    sort,
    pendingTargetClient,
    clientData,
    pending,
    handleSetFeeSchedule,
    defaultValue,
    value,
    targetClient = null,
    actions: { feeScheduleSearch, feeScheduleSearchFromClient, setFeeSchedule }}) {

    const [isOpen, setOpen] = useState(false);
    const [searchText, setSearchText] = useState({text:"",clearAble:false});
    const [directoryId, setDirectoryId] = useState([]);
    const [feeScheduleSort, setFeeScheduleSort] = useState([{ id: "name", direction:'asc',orderBy: "asc" }]);
    const classes = useStyles();

    useEffect(() => {
        if (isOpen) {

            if(targetClient)
            {
            
                feeScheduleSearchFromClient({
                    targetClient:targetClient,
                    keyword: searchText.text,
                    directoryId: directoryId.length === 0
                    ? null
                    : directoryId[directoryId.length - 1]?.id 
                })
                
                return 
            }
            
            feeScheduleSearch({
                keyword: searchText.text,
                directoryId:
                    directoryId.length === 0
                        ? null
                        : directoryId[directoryId.length - 1]?.id
            });
        }
    }, [feeScheduleSearch, isOpen]);

    const handleClick = () => {
        setSearchText({text:"",clearAble:false});
        setOpen(true);
    };

    const handleDialogClose = () => {
        setDirectoryId([])
        setOpen(false);
        setSearchText({text:"",clearAble:false});
    };

    function handleSubmit() {

        if (searchText.text.length >= 3 || searchText.text.length === 0) {

            setSearchText({...searchText,clearAble:searchText.text.length>=3 ? true:false});

            if(targetClient)
            {
                feeScheduleSearchFromClient({
                    targetClient:targetClient,
                    keyword: searchText.text,
                    directoryId: directoryId.length === 0
                    ? null
                    : directoryId[directoryId.length - 1]?.id
                })
    
                return 

            }

            feeScheduleSearch({
                keyword: searchText.text,
                directoryId:
                    directoryId.length === 0
                        ? null
                        : directoryId[directoryId.length - 1]?.id
            });
        }
    }

    function handleRowClick(rowData) {
        handleSetFeeSchedule(rowData,directoryId?.length === 0
            ? "root"
            : directoryId[directoryId?.length - 1]?.id);
        setSearchText({text:rowData.name,clearAble:false});

        handleDialogClose();
    }

    const handleRefresh = () => {
        setSearchText({text:'',clearAble:false});
        if(targetClient)
        {
        
            feeScheduleSearchFromClient({
                targetClient:targetClient,
                keyword: "",
                directoryId: directoryId[directoryId.length - 1]?.id
            })

            return 
        }

        feeScheduleSearch({
            keyword: "",
            directoryId: directoryId[directoryId.length - 1]?.id 
        }); 
    };

    const handleSort = ({ sort, direction }) => {
        setFeeScheduleSort([{id: "name", direction:direction==='asc' ? 'desc':'asc' ,orderBy:direction==='asc' ? 'desc':'asc'}]);
    };

    const { id: columnOrder, direction } =
        (sort && sort.length > 0 && sort[0]) || {};

    const handleRedirectToDirectory = (dId,name,fromBreadCrumbs = false) => {
        let resultArray = [];
        if (fromBreadCrumbs) {
            for (const item of directoryId) {
                const isHierarchyEnded = item?.id === dId;
                resultArray.push({
                    ...item,
                    viewOnly: isHierarchyEnded ? true : false,
                });
                if (isHierarchyEnded) {
                    if (resultArray.length === 1) {
                        resultArray = [];
                    }
                    break;
                }
            }
            setDirectoryId([...resultArray]);
        } else {
            setDirectoryId(
                directoryId?.length === 0
                    ? [
                          { name: "Fee Schedules", id: undefined },
                          { id: dId, name, viewOnly: true },
                      ]
                    : [
                          ...directoryId?.map((item) => ({
                              ...item,
                              viewOnly: false,
                          })),
                          { id: dId, name, viewOnly: true },
                      ]
            );
        }
        if(targetClient)
        {
        
            feeScheduleSearchFromClient({
                targetClient:targetClient,
                keyword: "",
                directoryId:dId,
            })
            return 
        }
        
        feeScheduleSearch({
            keyword: "",
            directoryId:dId
        });
    };

    const getData = () => {
        data.sort((a, b) =>
            a.isDirectory === b.isDirectory
                ? feeScheduleSort[0]?.direction === "asc"
                    ? a.name.localeCompare(b.name)
                    : b.name.localeCompare(a.name)
                : a.isDirectory
                ? -1
                : 1
        );

        clientData.sort((a, b) =>
            a.isDirectory === b.isDirectory
                ? feeScheduleSort[0]?.direction === "asc"
                    ? a.name.localeCompare(b.name)
                    : b.name.localeCompare(a.name)
                : a.isDirectory
                ? -1
                : 1
        );

        return targetClient ? clientData : data;
    };

    const renderSearchTextField = () => {
        return (<>
            <TextField
                fullWidth
                label="Search"
                value={searchText.text}
                onChange={event => setSearchText({...searchText, text:event.target.value})}
                // variant='outlined'
                name="searchTextField"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleSubmit} size="large">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </>);
    };    


    return (<>
        <ClickAwayListener onClickAway={handleDialogClose}>
            <div className={classes.chipBox}>
                {defaultValue ? (
                    <Chip style={{maxWidth: '480px'}}
                        label={
                            searchText.text != "" && !isOpen
                                ? (searchText.text.length > 50 ? `${searchText.text.substring(0, 50)}...` : searchText.text)
                                : (value.length > 50 ? `${value.substring(0, 50)}...` : value)
                                || (defaultValue.length > 50 ? `${defaultValue.substring(0, 50)}...` : defaultValue)
                                || "Search Fee Schedules"
                        }
                        title={
                            searchText.text != "" && !isOpen
                                ? searchText.text : value
                                || defaultValue || "Search Fee Schedules"
                        }
                        onClick={handleClick}
                    />
                ) : (
                    <IconButton className="p-1" aria-label="Search" onClick={handleClick} size="large">
                        <Tooltip title="Search">
                            <SearchIcon />
                        </Tooltip>
                    </IconButton>
                )}

                {isOpen ? (
                    <Dialog
                        maxWidth="md"
                        fullWidth
                        open={isOpen}
                        onClose={handleDialogClose}
                    >
                        <Loadable loading={pending || pendingTargetClient}>
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={3}>
                                        <Typography variant="h6">
                                            Fee Schedules Search
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {renderSearchTextField()}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        className="text-right"
                                    >
                                        <IconButton aria-label="close" onClick={handleDialogClose} size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                {/* display data */}
                                {/* {data.map(item => <div> {item.name} -- {item.isDirectory ? 'Folder' : 'Fee schedule'} </div>)} */}

                                <Grid spacing={1} container>

                                <Grid item xs={6}>
                                            <Breadcrumbs separator=">" aria-label="breadcrumb">
                                                {directoryId?.map((item) =>
                                                    <Typography key={item?.id}
                                                        className={!item?.viewOnly && classes.breadCrumbItem}
                                                        onClick={() => !item?.viewOnly && handleRedirectToDirectory(item?.id, item?.name, true)}>
                                                        {item?.name}
                                                    </Typography>
                                                )
                                                }
                                            </Breadcrumbs>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TableLevelAction
                                            handleRefresh={handleRefresh}
                                            name={""}
                                            loading={false}
                                            actionLevelGrid={null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box
                                            className={`${classes.backBox}`}
                                        >

                                            {searchText.clearAble  && (
                                                <Button
                                                    variant="contained"
                                                    onClick={handleRefresh}
                                                >
                                                    Clear Search <ClearIcon className={`${classes.clearIcon}`} />
                                                </Button>
                                            )}
                                        </Box>
                                        <Table stickyHeader={true}>
                                            <TableHead>
                                                <TableRow
                                                    className={`fee-schedule-thr`}
                                                >
                                                    {fields?.map(
                                                        (field, index) =>
                                                            !field?.options
                                                                .hideFromGrid && (
                                                                <HeaderColumn
                                                                    key={`column-${index}`}
                                                                    field={
                                                                        field
                                                                    }
                                                                    onSort={
                                                                        handleSort
                                                                    }
                                                                    direction={                                                    
                                                                        feeScheduleSort.find((item:any)=>item?.name===field.name)?.direction
                                                                    }
                                                                    columnOrder={
                                                                        columnOrder
                                                                    }
                                                                    colSpan={
                                                                        index ===
                                                                            0 &&
                                                                        4
                                                                    }
                                                                />
                                                            )
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {getData()?.length === 0 &&
                                                    getData()?.length === 0 && (
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell
                                                                colSpan={5}
                                                            >
                                                                No records
                                                                found!
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                {getData()?.map(item => {
                                                    return (item ?.isDirectory && <TableRow className="fee-schedule-row" key={item.id}>
                                                        <TableCell
                                                            id={`folder-${item.id}`}
                                                            colSpan={4}
                                                        >
                                                            <div
                                                                id={`folder-${item.id}`}
                                                                className={
                                                                    classes.folderDiv
                                                                }
                                                                onClick={() =>
                                                                    handleRedirectToDirectory(
                                                                        item.id,
                                                                        item.name
                                                                    )
                                                                }
                                                            >
                                                                <IconButton id={`folder-${item.id}`} className="p-1 mr-2" size="large">
                                                                    <i
                                                                        id={`folder-${item.id}`}
                                                                        style={{
                                                                            color:
                                                                                "#FFCA35"
                                                                        }}
                                                                        className="fas fa-folder"
                                                                    ></i>
                                                                </IconButton>
                                                                {
                                                                    item.name
                                                                }
                                                            </div>
                                                        </TableCell>
                                                       
                                                    </TableRow>);
                                                })}
                                                {getData()?.map(item => {
                                                    return (
                                                        !item?.isDirectory &&
                                                        <TableRow
                                                            className={`fee-schedule-row ${classes.selectionRow}`}
                                                            onClick={() =>
                                                                handleRowClick(
                                                                    item
                                                                )
                                                            }
                                                        >
                                                            <TableCell
                                                                className="pl-4"
                                                                colSpan={4}
                                                            >
                                                                {item.name}
                                                            </TableCell>
                                                        
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className="p-4">
                                <Button variant="contained" onClick={handleDialogClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Loadable>
                    </Dialog>
                ) : null}
            </div>
        </ClickAwayListener>
    </>);
}

export default FeeScheduleSelectionComponent;