import { createSelector } from "reselect";
import { ROLE_SUPERADMIN, ROLE_PARTNER_ADMIN, ROLE_ADMIN, ROLE_PARTNER_USER } from '../constants/roles';
import { RootState } from "../reducers";

export const selectAuthenticated = (state : RootState) => state.session.authenticated;

export const selectPreAuthentication = (state : RootState) => state.session.preAuthentication;

export const selectSessionRole = (state : RootState) => (state.session.user && state.session.user.role) || '';

export const selectSessionUserId = (state : RootState) => state.session.user.id;

export const selectSessionUser = (state : RootState) => state.session.user;
export const selectForceForgotPW = (state : RootState) => state.session.forceForgotPW;

export const selectSessionUserClientId = (state : RootState) => state.session.user?.clientId;
export const selectSessionUserPartnerId = (state : RootState) => state.session.user?.partnerId;

export const selectHasCMSAccess = (state : RootState) => (state.session.user && state.session.user.hasCMSAccess) || false;

export const selectSessionUserPreferredClientId = (state : RootState) => state.session.user.preferredDashboardClientId;

export const selectSessionHasClaimProcessorAccess = (state : RootState) => {
    return state.session?.user?.hasClaimProcessorAccess ||
        state.session?.user?.role === ROLE_ADMIN ||
        state.session?.user?.role === ROLE_SUPERADMIN ||
        state.session?.user?.role === ROLE_PARTNER_ADMIN
};

export const selectSessionUserVendorManagementAccess = (state : RootState) => (state.session.user && state.session.user.hasVendorManagementAccess) || false;

export const selectHasUploadsAccess = (state : RootState) => (state.session.user && state.session.user.hasUploadAccess) || false;

export const selectHasPricerAccess = (state : RootState) => (state.session.user && state.session.user.hasPricerAccess) || false;

export const selectHasDashboardAccess = (state : RootState) => (state.session.user && state.session.user.hasDashboardAccess) || false;

export const selectHasCMSClaimAnalysisAccess = (state : RootState) => (state.session.user && state.session.user.hasCMSClaimAnalysisAccess) || false;

export const selectHasClientProductCMSAccess = (state : RootState) => (state.clients.products && state.clients.products.hasCMSAccess) || false;
export const selectClientProductCMS = (state : RootState) => (state.clients.products && state.clients.products) || {};

export const selectHasClientProductPricerAccess = (state : RootState) => (state.clients.products && state.clients.products.hasPricerAccess) || false;

export const selectHasClientProductVendorAccess = (state : RootState) => (state.clients.products && state.clients.products.hasVendorManagementAccess) || false;

export const selectHasClientProductDashboardAccess = (state : RootState) => (state.clients.products && state.clients.products.hasDashboardAccess) || false;

export const selectHasCustomClaimStatusAccess = (state : RootState) => state.clients.hasCustomClaimStatusAccess || false;
export const selectHasRCMReportAccess = (state : RootState) => state.session?.user?.hasCMSRCMStatusReportAccess || false;
export const selectHasSummaryDashboardAccess = (state : RootState) => state.clients.hasSummaryDashboardAccess || false;


export const createHasCMSAccess = (allowedRoleNames) => {
    const checkRoles = allowedRoleNames && allowedRoleNames.length;

    if (checkRoles) {
        return createSelector(
            selectSessionRole,
            selectHasCMSAccess,
            selectHasClientProductCMSAccess,
            (role, hasCMSAccess, clientCMSAccess) => {
                if (allowedRoleNames.some((roleName) => roleName === role)) {
                    return true
                }
                else if (!clientCMSAccess) {
                    return false
                }
                return clientCMSAccess && hasCMSAccess;
            }
        );
    }
    return () => true;
}

export const selectHasEstimatorAccess = (state : RootState) => {

    if (state.session.user && state.session.user.role === ROLE_SUPERADMIN) {
        return true
    }
    return Boolean(state.session.user && state.clients.products && state.session.user.hasEstimatorAccess && state.clients.products.hasEstimatorAccess)
}

export const selectHasPriceEstimateAccess = (state : RootState) => {

    if (state.session.user && state.session.user.role === ROLE_SUPERADMIN) {
        return true
    } else if (state.session.user &&
        (state.session.user.role === ROLE_PARTNER_ADMIN ||
            state.session.user.role === ROLE_ADMIN) &&
        (state.clients.products && state.clients.products.hasEstimatorAccess)
    ) {
        return true
    }
    return false
}

export const createHasSettingsAccessSelector = (allowedRoleNames) => {
    const checkRoles = allowedRoleNames && allowedRoleNames.length;

    if (checkRoles) {
        return createSelector(
            selectSessionRole,
            (role) => allowedRoleNames.some((roleName) => roleName === role)
        );
    }
    return () => true;
}

export const createHasPricerAccessSelector = (allowedRoleNames) => {
    const checkRoles = allowedRoleNames && allowedRoleNames.length;

    if (checkRoles) {
        return createSelector(
            selectSessionRole,
            selectHasClientProductPricerAccess,
            selectHasPricerAccess,
            (role, clientPricerAccess, hasPricerAccess) => {
                if (allowedRoleNames.some((roleName) => roleName === role)) {
                    return true
                } else if (!clientPricerAccess) {
                    return false
                }
                return clientPricerAccess && hasPricerAccess
            }
        );
    }
    return () => true;
}


export const createHasCMSClaimAnalysisAccess = (allowedRoleNames) => {
    const checkRoles = allowedRoleNames && allowedRoleNames.length;

    if (checkRoles) {
        return createSelector(
            selectSessionRole,
            selectHasCMSClaimAnalysisAccess,
            selectHasCMSAccess,
            selectHasClientProductCMSAccess,
            (role, hasCMSClaimAnalysisAccess, hasCMSAccess, clientCMSAccess) => {
                if (allowedRoleNames.some((roleName) => roleName === role)) {
                    return true
                } else if (!clientCMSAccess) {
                    return false
                }
                return (hasCMSClaimAnalysisAccess || hasCMSAccess) && clientCMSAccess
            }
        );
    }
    return () => true;
}

export const createHasUploadsAccessSelector = (allowedRoleNames) => {
    const checkRoles = allowedRoleNames && allowedRoleNames.length;

    if (checkRoles) {
        return createSelector(
            selectSessionRole,
            selectHasUploadsAccess,
            (role, hasUploadAccess) => allowedRoleNames.some((roleName) => roleName === role) || hasUploadAccess
        );
    }
    return () => true;
}

export const createHasSessionAccessSelector = (allowedRoleNames) => {
    const checkRoles = allowedRoleNames && allowedRoleNames.length;

    if (checkRoles) {
        return createSelector(
            selectSessionRole,
            (role) => allowedRoleNames.some((roleName) => roleName === role)
        );
    }
    return () => true;
}

export const createHasDashboardAccessSelector = (allowedRoleNames) => {
    const checkRoles = allowedRoleNames && allowedRoleNames.length;

    if (checkRoles) {
        return createSelector(
            selectSessionRole,
            selectHasClientProductDashboardAccess,
            selectHasDashboardAccess,
            (role, clientDashboardAccess, userDashboardAccess) => {
                if (allowedRoleNames.some((roleName) => roleName === role)) {
                    return true
                }
                return clientDashboardAccess && userDashboardAccess
            }
        );
    }
    return () => true;
}

export const createHasVendorAccessSelector = (allowedRoleNames) => {
    const checkRoles = allowedRoleNames && allowedRoleNames.length;

    if (checkRoles) {
        return createSelector(
            selectSessionRole,
            selectHasClientProductVendorAccess,
            selectSessionUserVendorManagementAccess,
            (role, clientVendorAcces, userVendorAccess) => {
                if (allowedRoleNames.some((roleName) => roleName === role)) {
                    return true
                }
                return clientVendorAcces && userVendorAccess
            }
        );
    }
    return () => true;
}

export const createHasSessionAuthenticationSelector = () => {
    return createSelector(
        selectAuthenticated,
        (isAuthenticated) => isAuthenticated
    );
}

export const selectIsSuperAdmin = createSelector(
    selectSessionRole,
    (sessionRole) => {
        if (sessionRole === ROLE_SUPERADMIN) {
            return true;
        }
        else {
            return false;
        }
    }
);

export const selectIsPartner = createSelector(
    selectSessionRole,
    (sessionRole) => {
        if (sessionRole === ROLE_PARTNER_ADMIN) {
            return true;
        }
        else {
            return false;
        }
    }
);


export const selectIsPartnerUser = createSelector(
    selectSessionRole,
    (sessionRole) => {
        if (sessionRole === ROLE_PARTNER_USER) {
            return true;
        }
        else {
            return false;
        }
    }
);

export const selectIsSuperadminOrPartner = createSelector(
    selectSessionRole,
    (sessionRole) => {
        if (sessionRole === ROLE_SUPERADMIN || sessionRole === ROLE_PARTNER_ADMIN) {
            return true;
        }
        else {
            return false;
        }
    }
);

export const selectIsAdmin = createSelector(
    selectSessionRole,
    (sessionRole) => {
        if (sessionRole === ROLE_ADMIN) {
            return true;
        }
        else {
            return false;
        }
    }
);

export const selectLoginErrorMessage = createSelector(
    (state) => state.session,
    (session) => session.login['errorMessage'] || session.construct['errorMessage']
);

export const selectLoginError = createSelector(
    (state) => state.session,
    (session) => session.login['error']
);

export const selectLoginPending = createSelector(
    (state) => state.session,
    (session) => session.login['pending'] || session.construct['pending']
);

export const selectChangePasswordErrorMessage = createSelector(
    (state) => state.session,
    (session) => session.changePassword['errorMessage']
);

export const selectChangePasswordError = createSelector(
    (state) => state.session,
    (session) => session.changePassword['error']
);

export const selectChangePasswordPending = createSelector(
    (state) => state.session,
    (session) => session.changePassword['pending']
);

export const selectChangePasswordDone = createSelector(
    (state) => state.session,
    (session) => session.changePassword['done']
);

export const selectForcedResetPasswordErrorMessage = createSelector(
    (state) => state.session,
    (session) => session.forcedResetPassword['errorMessage']
);

export const selectForcedResetPasswordError = createSelector(
    (state) => state.session,
    (session) => session.forcedResetPassword['error']
);

export const selectForcedResetPasswordPending = createSelector(
    (state) => state.session,
    (session) => session.forcedResetPassword['pending']
);

export const selectHasRCMPageAccess = createSelector(
    selectHasRCMReportAccess,
    selectClientProductCMS,
    selectIsSuperAdmin,
    (canAccessRCM, clientProducts, isSuperAdmin) => {
    
       return (isSuperAdmin ||
            (canAccessRCM && clientProducts?.hasCMSRCMStatusReportAccess));
    }
); 