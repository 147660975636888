import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { set,setMulti, triageLanding } from '../../store/actions/pages';
import { createSelector } from 'reselect';

import { claimAnalysisLanding } from '../../store/actions/pages';
import ConfidenceScoreTriagePageComponent from './ConfidenceScoreTriagePage';

import { getClaimStatuses, getConfidenceMeasures, setClaimUpdateStatusDialog, triageStats } from '../../store/actions/claimAnalysis';

import { claimAnalysisStats, download, flag, resetClaimProcessError, setBulkAcknowledge, setBulkFlagged, setDrilldown, setFlagToggle, setOpenAcknowledgeDialog, setOpenFlagDialog, setOpenPlayDialog, setSelectedClaims } from '../../store/actions/claimAnalysis';
import { tiny } from '../../store/actions/contracts';
import { npiRolesLanding } from '../../store/actions/npiRoles';
import { getPayersTiny } from '../../store/actions/payers';
import { getByName, reset } from '../../store/actions/search';
import { selectDefaultCustomFilters } from '../../store/selectors/claimAnalysis';
import { selectSelectedClientName } from "../../store/selectors/clients";
import { selectSessionHasClaimProcessorAccess } from '../../store/selectors/session';
import { list as getFacilities } from "../../store/actions/facilities";

export const selectContractsTiny = (state) => state.contracts.tiny;
export const selectPayersTiny = (state) => state.payers.tiny;
export const selectClaimStatusDropdownValues = (state) => state.claimAnalysis.claimStatuses || [];
export const selectNpiRolesDropdownValues = (state) => (state.npiRoles.data?.map((item)=>({label:item?.name,value:item?.name,name:item?.name})))

const selectPending = createSelector(
        (state) => state.pages,
        (state) => state.search.getByName.pending,
        ( pages, searchPending) => {
            return pages['triage-landing'].pending || searchPending
        }
    );

export const ConfidenceScoreTriagePage = connect(
    () => {
        return (state: any, props) => ({
            hasClaimProcessorAccess: selectSessionHasClaimProcessorAccess(state as never),
            selectedClientName: selectSelectedClientName(state as never),
            defaultCustomFilters: selectDefaultCustomFilters(state, props),
            claimStatusList:selectClaimStatusDropdownValues(state),
            pending:selectPending(state),
            showHidden:state.pages['triage-landing']?.showHidden,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            claimAnalysisLanding: triageLanding,
            getClaimAnalysisStats: triageStats,
            claimAnalysisSetDrilldown: setDrilldown,
            getClaimStatuses:getClaimStatuses,
            getNpiRoles:npiRolesLanding,
            searchGetByName: getByName,
            searchReset: reset,
            setCustomFilter: (value) => set('triage-landing', 'customFilter', value),
            setFilter: (value) => set('triage-landing', 'filter', value),
            resetSearchCriteria: (value) => set('triage-landing', 'selectedFilter', value),
            resetPagedTable: () => set('triage-landing', 'list', []),
            resetCriteriaWaiting: () => set('triage-landing', 'waitForCriteriaFilter', false),
            resetSearchAPICompleted: () => set('triage-landing', 'isSearchApiCompleted', false),
            downloadAnalysisReport: download,
            flagClaims: flag,
            getTinyContracts: tiny,
            getPayersTiny: getPayersTiny,
            resetClaimProcessError: resetClaimProcessError,
            setOpenAcknowledgeDialog: setOpenAcknowledgeDialog,
            setOpenFlagDialog: setOpenFlagDialog,
            setFlagToggle: setFlagToggle,
            setBulkFlagged: setBulkFlagged,
            setBulkAcknowledge: setBulkAcknowledge,
            setSelectedClaims: setSelectedClaims,
            setOpenPlayDialog: setOpenPlayDialog,
            setClaimUpdateStatusDialog:setClaimUpdateStatusDialog,
            setMulti: obj => setMulti("triage-landing", obj),
            getFacilities: getFacilities,
            getConfidenceMeasures:getConfidenceMeasures,
        }, dispatch)
    })
)(injectIntl(ConfidenceScoreTriagePageComponent));