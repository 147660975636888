import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ClaimAdjustmentsComponent from './ClaimAdjustments';
import { selectAdjustment, selectClaimAdjustments, selectServiceAdjustments } from '../../../../store/selectors/claimAnalysis';
import { createCurrencyDataField, createNumberDataField, createStringDataField } from '../../../../util/format';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import { getClaimAdjustments, getServiceAdjustments, setAdjustment } from '../../../../store/actions/claimAnalysis';
import { set } from '../../../../store/actions/pages';
import { renderHoverLink } from '../../../../common';

const DEFAULT_SORT = [{ id: 'casorder', orderBy: 'asc' }];

const selectFields = createSelector(
    () => [
        createStringDataField('casadjgroupcode', 'Group Code', { 
            sortable: true,
            renderCell : renderHoverLink( row => [
                {
                    label: "",
                    type: 'ELLIPSE',
                    data: row.reasonShortDescription,
                    longData: row.reasonDescription
                }
            ])
        }),
        createStringDataField('grouping', 'Group Code Description', { sortable: true }),
        createStringDataField('casadjreasoncode', 'Reason Code', { sortable: true, onHover: true }),
        createCurrencyDataField('casadjamount', 'Amount', { sortable: true }),
        createStringDataField('casadjquantity', 'Quantity', { sortable: true }),
        createNumberDataField('casorder', 'Order', { sortable: true }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectClaimAdjustmentOpen = createSelector(
    selectAdjustment,
    (adjustment : any ) => {
        return adjustment?.open || false;
    }
);

const selectClaimAdjustmentId = createSelector(
    selectAdjustment,
    (adjustment : any) => {
        return adjustment?.id || null;
    }
);

const selectClaimAdjustmentType = createSelector(
    selectAdjustment,
    (adjustment : any) => {
        return adjustment?.type || null;
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`service-adjustments-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectServiceAdjustmentsData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`service-adjustments-landing`].filter,
    selectSort,
    selectServiceAdjustments
);
const selectClaimAdjustmentsData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`service-adjustments-landing`].filter,
    selectSort,
    selectClaimAdjustments
);

// Return Data on the basis of Module Type i.e. either SERVICE || CLAIM
const selectGridData = createSelector(selectClaimAdjustmentType, selectServiceAdjustmentsData, selectClaimAdjustmentsData,
    (type, serviceAdjustments, claimAdjustments) => {
        if (type === 'SERVICE') {
            return serviceAdjustments
        } else if (type === 'CLAIM') {
            return claimAdjustments
        }
        return [];
    })

export const ClaimAdjustments = connect(
    () => {
        return (state, props) => ({
            open: selectClaimAdjustmentOpen(state),
            adjustmentId: selectClaimAdjustmentId(state),
            type: selectClaimAdjustmentType(state),
            fields: selectFields(state as never),
            filteredData: selectGridData(state, props),
            order: state.pages[`service-adjustments-landing`].order,
            filter: state.pages[`service-adjustments-landing`].filter,
            sort: selectSort(state, props),

            serviceAdjustmentPending: state.claimAnalysis.getServiceAdjustments.pending,
            claimAdjustmentPending: state.claimAnalysis.getClaimAdjustments.pending,
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setAdjustment: setAdjustment,
            getServiceAdjustments: getServiceAdjustments,
            getClaimAdjustments: getClaimAdjustments,
            setFilter: (value) => set(`service-adjustments-landing`, 'filter', value),
            setOrder: (value) => set(`service-adjustments-landing`, 'order', value),
            setSort: (value) => set(`service-adjustments-landing`, 'sort', value),
        }, dispatch)
    })
)(injectIntl(ClaimAdjustmentsComponent));