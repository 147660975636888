import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { XClientDataGrid } from '../../../../common';
import Draggable from 'react-draggable';
import { useStatePtr } from '@pure-ptr/react';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import RefreshIcon from '@mui/icons-material/Refresh';

const DEFAULT_SORT = [{ id: 'casorder', direction: 'asc' }];

function ClaimAdjustments( { intl, open, adjustmentId, type, fields, filteredData, serviceAdjustmentPending, claimAdjustmentPending,claimId,
    actions: { setAdjustment, getServiceAdjustments, getClaimAdjustments } }) {

    const [refresh, setRefresh] = useState(Promise.resolve());

    useEffect(() => {
        if (open && type) {
            if (type === 'SERVICE') {
                getServiceAdjustments(adjustmentId,claimId)
            } else if (type === 'CLAIM') {
                getClaimAdjustments(adjustmentId,claimId);
            }
        }
    }, [open, adjustmentId, type, refresh])


    const closeAdjustmentHandler = () => {
        const payload = { open: false }
        setAdjustment(payload);
    }

    const handleRefresh = () => {
        setRefresh(Promise.resolve());
    }

    function PaperComponent(props) {
        return (
            <Draggable
                handle="#claim-adjustments-dialog"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    const sortPtr = useStatePtr( DEFAULT_SORT );

    return (
        (<div>
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="md"
                open={open}
                onClose={closeAdjustmentHandler}
                PaperComponent={PaperComponent}
                aria-labelledby="claim-adjustments-dialog"
            >
                <DialogTitle style={{ cursor: 'move' }} id="claim-adjustments-dialog">
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            {type === 'CLAIM' ? 'Claim' : type === 'SERVICE' ? 'Service' : ''} Adjustments
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={closeAdjustmentHandler} size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <XClientDataGrid
                        fields={fields}
                        data={filteredData}
                        sortPtr={sortPtr}
                        loading={serviceAdjustmentPending || claimAdjustmentPending}
                        hideFooter

                        slots={{
                            toolbar: Toolbar
                        } as any }

                        slotProps={{
                            toolbar: {
                                handleRefresh
                            } as any
                        }}
                    />
                </DialogContent>
            </Dialog>
        </div>)
    );
}

const Toolbar = ({ handleRefresh }) =>
    <GridToolbarContainer>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleRefresh}>
            <RefreshIcon />
        </IconButton>
    </GridToolbarContainer>

export default ClaimAdjustments