import { PureCollection, PureObject } from '@pure-ptr/react';
import { DateRange } from '../../util/date';
import { http } from './common';

const list = async (options, significance) => {
    return http.post(`/api/v2/cms/analyses/${significance}`, options);
};

const triageList = async (options, showHiddenClaims) => {
    return http.post(`/api/v2/cms/analysis/triage/${showHiddenClaims}`, options);
};

const get = async (analysisId) =>
    http.get(`/api/v1/cms/analysis/details/${analysisId}`);

const getConsolidatedClaims = async (claimId, claimNumber) => 
    http.get(`/api/v2/cms/analysis/claimdata${claimId === 0 && claimNumber ? `/${claimId}/list/${claimNumber}` : `/${claimId}/list`}`);

const acknowledge = async (acknowledgePayload) => {
    return http.post(`/api/v1/cms/analysis/acknowledge`, acknowledgePayload);
};

const getStats = async (getStatspayload, significance=false) => {
    return http.post(`/api/v1/cms/analyses/total/${significance}`, getStatspayload);
};

const getTriageStats = async (getStatspayload, showHiddenClaims) => {
    return http.post(`/api/v2/cms/analysis/triage/summary/${showHiddenClaims}`, getStatspayload);
};

const downloadReport = async (payload,showSignificantVariance) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return http.post(`/api/v2/cms/analysis/report/${showSignificantVariance}?timezone=${timeZone}`, payload, 'octet-stream' );
};

const flag = async (flagPayload) => {
    return http.post(`/api/v1/cms/analysis/flag`, flagPayload);
};

const processClaims = async (payload) => {
    return http.post(`/api/v1/cms/analysis/claims`, payload);
};

const processBulkClaims = async (payload, significanceFlag) => {
    return http.post(`/api/v1/cms/analysis/claims/criteria/${significanceFlag}`, payload);
};

const getJSONView = async (claimNumber) => {
    return http.get(`/api/v1/cms/analysis/claimdata/${claimNumber}`);
};

const deleteByPayerContractId = async (payerContractId) => {
    return http.del(`/api/v1/cms/analyses/${payerContractId}`);
};

const deleteByContractId = async (contractId) => {
    return http.del(`/api/v1/cms/analyses/contract/${contractId}`);
};

const getClaimHistory = async (analysisId) => {
    return http.get(`/api/v1/cms/analysis/history/${analysisId}`);
};

const getClaimAdjustments = async (paymentId,analysisId) => {
    return http.get(`/api/v2/cms/analysis/claimadjustments/${paymentId}/${analysisId}`);
};

const getServiceAdjustments = async (servicePaymentId,analysisId) => {
    return http.get(`/api/v2/cms/analysis/serviceadjustments/${servicePaymentId}/${analysisId}`);
};

const getDRGS = async () => {
    return http.get(`/api/v1/cms/estimator/drgs`);
};

const getCPTS = async (query) => {
    return http.get(`/api/v1/cms/estimator/cpts/${query}`);
};

export interface RevenueCode {
    code: string;
    description: string;
    service: string;
}

const getRevenueCodes = async () : Promise<RevenueCode[]> =>
    http.get('/api/v1/cms/estimator/revenue');

export class RevenueCodeCollection extends PureCollection<RevenueCode> {
    getId( x : RevenueCode ) {
        return x.code;
    }
    
    static async fetch(){
        return RevenueCodeCollection.from( await getRevenueCodes() );
    }
}

export class Revenue extends PureObject {
    revCode : string;
    units : number;
    actualPay : number;

    get rate(){
        return this.actualPay / this.units;
    }
}

export class RevenueCollection extends PureCollection<Revenue> {
    getId(x: Revenue){
        return x.revCode;
    }

    static async fetch( contract_id : number, dateRange : DateRange ){
        return RevenueCollection.from(
            await http.get('/api/v1/cms/simulator/revenue', {
                contract_id : String( contract_id ), 
                from : dateRange.startdate,
                to : dateRange.enddate
            }),

            x => Revenue.object(x)
        );
    }
}

const getAssociatedCharges = async (payload) => {
    return http.post(`/api/v1/cms/estimator/associatedcharges`, payload);
};

const estimate = async (payload) => {
    return http.post(payload?.payerId ? `/api/v1/cms/estimate/payerId` : `/api/v1/cms/estimate`, payload);
};

const estimateHistoryLanding = async (options) => {
    return http.post(`/api/v1/cms/estimator/history`, options);
};

const listClaimAudits = async (options) => {
    return http.post(`/api/v1/cms/analysis/audits`, options);
};

const processClaimsFromAudit = async (options) => {
    return http.post(`/api/v1/cms/analysis/audit/claims/criteria`, options);
};

const getClaimServiceAdjustments = async (paymentId,claimId) => {
    return http.get(`/api/v2/cms/analysis/claimserviceadjustments/${paymentId}/${claimId}`);
};

const calculateOutOfPocketCost = async (options) => {
    return http.post(`/api/v1/cms/estimate/oop`, options);
};

const getEstimateById = async (id) => {
    return http.get(`/api/v1/cms/estimate/${id}`);
};

const editEstimateById = async (options) => {
    return http.put(options.payerId ? `/api/v1/cms/estimate/payerId/${options.estimationId}` : `/api/v1/cms/estimate/${options.estimationId}`, options);
};

const getDXCodes = async (keyword) => {
    return http.get(`/api/v1/cms/estimator/dxcodes/${keyword}`);
}

const getClaimAllDetails = async (analysisId) => {
    return http.get(`/api/v1/cms/analyses/${analysisId}/false`);
};

const processBulkOnDemand = async (payload) => {
    return http.post(`/api/v1/cms/analysis/bulkondemand`, payload);
};

const overrideContract = async (payload) => {
    return http.post(`/api/v2/cms/analysis/override/contract`, payload);
};

const getClaimComments = async (analysisId) => {
    return http.get(`/api/v1/cms/analyses/comments/${analysisId}`);
};

const removeOverridenContract = async (payload) => {
    return http.post(`/api/v1/cms/analysis/override/remove`, payload);
};

const getClaimStatuses = async () => {
    return http.get(`/api/v2/cms/analyse/status/list`);
};

const updateClaimStatus = async (payload) => {
    return http.post(`/api/v2/cms/analyses/status`, payload);
};
const updateSingleClaimStatus = async (payload,status,analysisId) => {
    return http.post(`/api/v2/cms/analyse/${analysisId}/status/${status}`, payload);
};

const listClaimStatusLogs = async (analysisId) => {
    return http.get(`/api/v2/cms/analyse/status/history/${analysisId}`);
};

const getPlaceOfServiceCodes = async () => {
    return http.get(`/api/v2/cms/placeofservice/list`);
};

const hideOrUnHideClaimFromDashboard = async (options) => {
    const { analysisIds, isHiddenFromDashboard } = options;
    http.post(`/api/v2/cms/analysis/triage/UpdateVisibility/${isHiddenFromDashboard}`, analysisIds);
}

const getConfidenceMeasures = async () => {
    return http.get(`/api/v2/cms/analysis/triage/confidencemeasure/list`);
};

export {
    acknowledge, calculateOutOfPocketCost, deleteByContractId, deleteByPayerContractId, downloadReport, editEstimateById, estimate,
    estimateHistoryLanding, flag, get, getAssociatedCharges, getClaimAdjustments, getClaimAllDetails, getClaimComments, getClaimHistory, getClaimServiceAdjustments, getClaimStatuses, getConsolidatedClaims, getCPTS, getDRGS, getDXCodes, getEstimateById, getJSONView, getPlaceOfServiceCodes, getRevenueCodes, getServiceAdjustments, getStats, list, listClaimAudits, listClaimStatusLogs, overrideContract, processBulkClaims, processBulkOnDemand, processClaims, processClaimsFromAudit, removeOverridenContract, updateClaimStatus,
    updateSingleClaimStatus, getTriageStats, triageList, hideOrUnHideClaimFromDashboard, getConfidenceMeasures
};

