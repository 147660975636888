import { AppBar, Box, Toolbar } from '@mui/material';
import { useLocalStoragePtr } from "@pure-ptr/react";
import React from 'react';
import Helmet from 'react-helmet';

import { ContentRow, useGlobalStore } from '../../common';
import { DateRangePicker } from '../../common/dateRangeControl';
import { getLast7Days, getLast30Days, getLast60Days, getLast90Days, getLast120Days, getLast6Months, getLast12Months} from '../../util/date';
import { fromLocalStorage } from '../../util/storage';

import { AddWidgetButton, Dashboard, DashboardFiltersContext, Widget } from './Dashboard';

import 'react-grid-layout/css/styles.css';
import { injectIntl } from 'react-intl';
import 'react-resizable/css/styles.css';
import { CasCodesWidget } from './CasCodesWidget';
import { TopClaimsWidget } from './TopClaimsWidget';
import { UnderpaymentsWidget } from './UnderpaymentsWidget';
import { PayerScorecardWidget } from './PayerScorecardWidget';
import { PayerDenialsWidget } from './PayerDenialsWidget';
import { CasDenialsWidget } from './CasDenialsWidget';

const widgetDefs : Widget[] = [
    { 
        id: "top-claims", 
        title: "Top 5 Claims", 
        Component: TopClaimsWidget,
    },
    {
        id: "underpayments", 
        title: "Underpayments", 
        Component: UnderpaymentsWidget, 

    },
    {
        id: "cas-codes", 
        title: "CAS Codes", 
        Component: CasCodesWidget, 
    },
    { 
        id: "payer-scorecard", 
        title: "Payer Scorecard", 
        Component: PayerScorecardWidget
    },
    { 
        id: "payer-denials", 
        title: "Payer Denials", 
        Component: PayerDenialsWidget
    },
    { 
        id: "cas-denials", 
        title: "CAS Denials", 
        Component: CasDenialsWidget
    },
]

function ClaimSummaryDashboardComponent({ history, intl }) {
    const clientId = useGlobalStore( x => x.dashboards.selectedClient );
    const userId = useGlobalStore( x => x.session.user.id );

    const dateRangePtr = useLocalStoragePtr( `${userId}:${clientId}:dashboard-filters`, getFilterValue );
    const layoutPtr = useLocalStoragePtr( `${userId}:${clientId}:dashboard-layout`, [] );

    return (
        <>
            <Helmet title="Summary Dashboard" />

            <AppBar position="static" color="default">
                <Toolbar>
                    <ContentRow spacing={2}
                        right={
                            <AddWidgetButton 
                                widgetDefs={widgetDefs} 
                                layoutPtr={ layoutPtr}
                            />
                        }
                    >
                        <Box display="flex" width={190}>
                            <DateRangePicker
                                disabled={false}
                                valuePtr={dateRangePtr} 
                                label="Date Range"
                                enabledSwitch={false} 
                                relativeFilterOptions={searchPeriods}
                                disableDateSection = {false}
                            />
                        </Box>
                    </ContentRow>
                </Toolbar>
            </AppBar>

            <DashboardFiltersContext.Provider 
                value={{ 
                    dateRange: dateRangePtr.value,
                    history
                }}
            >
                <Dashboard
                    widgetDefs={widgetDefs}
                    layoutPtr={layoutPtr}
                />
            </DashboardFiltersContext.Provider>
        </>
    );
}

const searchPeriods = [
    { id: "Last7Days", value: "Last 7 Days" },
    { id: "Last30Days", value: "Last 30 Days" },
    { id: "Last60Days", value: "Last 60 Days" },
    { id: "Last90Days", value: "Last 90 Days" },
    { id: "Last120Days", value: "Last 120 Days" },
    { id: "Last6Months", value: "Last 6 Months" },
    { id: "Last12Months", value: "Last 12 Months" },
    { id: "custom", value: "Custom" },
];

const getFilterValue = () => {
    const filterValue = fromLocalStorage('summary-dashboard-filter');
    if(filterValue) {
        if(filterValue === "Last7Days") {
            return getLast7Days();
        }
        else if(filterValue === "Last30Days") {
            return getLast30Days();
        }
        else if(filterValue === "Last60Days") {
            return getLast60Days();
        }
        else if(filterValue === "Last90Days") {
            return getLast90Days();
        }
        else if(filterValue === "Last120Days") {
            return getLast120Days();
        }
        else if(filterValue === "Last6Months") {
            return getLast6Months();
        }
        else if(filterValue === "Last12Months") {
            return getLast12Months();
        }
    }
    return getLast30Days();
}

export const ClaimSummaryDashboard = injectIntl(ClaimSummaryDashboardComponent);